<template>
  <div>
    <!-- Campos busca -->
    <div class="theme-hero-area theme-hero-area-primary">
      <div class="theme-hero-area-bg-wrap">
        <div
          class="theme-hero-area-bg ws-action"
          :style="{'background-image': 'url(' + require('@/assets/img/bg-home-hotel.jpg') + ')'}"
          data-parallax="true"
        ></div>
        <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
      </div>
      <div class="theme-hero-area-body">
        <div class="container">
          <div class="theme-search-area theme-search-area-white mt-90 mb-20">
            <div class="row">
              <div class="col-md-12">
                <box-search ref="boxsearch"></box-search>
              </div>
              <div class="col-md-12 mt-30">
                <div class="theme-search-area-header theme-search-area-header-sm">
                  <div class="visible-xs">
                    <h1
                      class="theme-search-area-title"
                    >{{ total_hotels }} Hotéis encontrados em {{ city_name }} para {{ nights }} {{ nightsLabel }}</h1>
                    <p class="theme-search-area-subtitle">
                      <i class="fa fa-calendar-check-o"></i>
                      &nbsp;Check-in: {{ checkin }}
                    </p>
                    <p class="theme-search-area-subtitle">
                      <i class="fa fa-calendar-check-o"></i>
                      &nbsp;Check-out: {{ checkout }}
                    </p>
                  </div>
                  <div class="hidden-xs">
                    <p class="theme-search-area-subtitle">
                      <strong>{{ total_hotels }}</strong> Hotéis encontrados em
                      <strong>{{ city_name }}</strong> para
                      <strong>{{ nights }} {{ nightsLabel }}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;
                      <strong>
                        <i class="fa fa-calendar-check-o"></i>&nbsp;Check-in:
                      </strong>
                      {{ checkin }}&nbsp;&nbsp;
                      <i class="fa fa-arrows-h"></i>&nbsp;&nbsp;
                      <strong>
                        <i class="fa fa-calendar-check-o"></i>&nbsp;Check-out:
                      </strong>
                      {{ checkout }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Campos busca -->
    <!-- Conteudo -->
    <div class="theme-page-section theme-page-section-gray">
      <div class="container">
        <div class="row row-col-static">
          <div class="col-md-3">
            <!-- Menu filtros -->
            <div class="sticky-col">
              <div class="theme-search-results-sidebar">
                <div class="_mb-20">
                  <iframe
                    width="100%"
                    height="100"
                    frameborder="0"
                    style="border:0"
                    :src="google_iframe_city"
                    allowfullscreen
                  ></iframe>
                </div>
                <div
                  class="theme-search-results-sidebar-sections _mb-20 _br-2 theme-search-results-sidebar-sections-white-wrap"
                >
                  <div class="theme-search-results-sidebar-section">
                    <h5 class="theme-search-results-sidebar-section-title">Ordenar</h5>
                    <div class="theme-search-results-sidebar-section-checkbox-list-items">
                      <div class="theme-search-results-sidebar-section-checkbox-list-item">
                        <p-check
                          class="p-svg p-default p-fill p-smooth"
                          type="checkbox"
                          name="check"
                          color="primary"
                          v-model="ordering"
                          true-value="asc"
                        >
                        <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                            style="stroke: white;fill:white"
                          ></path>
                        </svg>
                        <span class="pretty-space">Menor Preço</span>
                        </p-check>
                        <!-- <input id="price-slider" name="price-slider" data-min="50" data-max="10000"> -->
                      </div>
                      <div class="theme-search-results-sidebar-section-checkbox-list-item">
                        <p-check
                          class="p-svg p-default p-fill p-smooth"
                          type="checkbox"
                          name="check"
                          color="primary"
                          v-model="ordering"
                          true-value="desc"
                        >
                        <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                          <path
                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                            style="stroke: white;fill:white"
                          ></path>
                        </svg>
                        <span class="pretty-space">Maior Preço</span>
                        </p-check>
                        <!-- <input id="price-slider" name="price-slider" data-min="50" data-max="10000"> -->
                      
                      </div>
                    </div>
                  </div>
                  <div class="theme-search-results-sidebar-section">
                    <h5 class="theme-search-results-sidebar-section-title">Estrelas</h5>
                    <div class="theme-search-results-sidebar-section-checkbox-list">
                      <div class="theme-search-results-sidebar-section-checkbox-list-items">
                        <div class="theme-search-results-sidebar-section-checkbox-list-item">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.stars.five"
                            true-value='5'
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">5 Estrelas</span>
                          </p-check>
                          <!-- <span
                            class="theme-search-results-sidebar-section-checkbox-list-amount"
                          >200</span>-->
                        </div>

                        <div class="theme-search-results-sidebar-section-checkbox-list-item">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.stars.four"
                            true-value='4'
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">4 Estrelas</span>
                          </p-check>
                          <!-- <span
                            class="theme-search-results-sidebar-section-checkbox-list-amount"
                          >200</span>-->
                        </div>

                        <div class="theme-search-results-sidebar-section-checkbox-list-item">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.stars.three"
                            true-value='3'
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">3 Estrelas</span>
                          </p-check>
                          <!-- <span
                            class="theme-search-results-sidebar-section-checkbox-list-amount"
                          >200</span>-->
                        </div>

                        <div class="theme-search-results-sidebar-section-checkbox-list-item">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.stars.two"
                            true-value='2'
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">2 Estrelas</span>
                          </p-check>
                          <!-- <span
                            class="theme-search-results-sidebar-section-checkbox-list-amount"
                          >200</span>-->
                        </div>

                        <div class="theme-search-results-sidebar-section-checkbox-list-item">
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="check"
                            color="primary"
                            v-model="filter.stars.one"
                            true-value='1'
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">1 Estrela</span>
                          </p-check>
                          <!-- <span
                            class="theme-search-results-sidebar-section-checkbox-list-amount"
                          >200</span>-->
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="theme-search-results-sidebar-section">
                    <h5 class="theme-search-results-sidebar-section-title">Preço</h5>
                    <div class="theme-search-results-sidebar-section-price">
                      <vue-slider
                        v-model="filter.price"
                        ref="slider"
                        :min="filter.min"
                        :max="filter.max"
                        :lazy="true"
                        :tooltipDir="['bottom', 'bottom']"
                        :formatter="'R$ {value}'"
                        :speed="1"
                      ></vue-slider>
                      <!-- <input id="price-slider" name="price-slider" data-min="50" data-max="10000"> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Menu filtros -->
            <!-- Propaganda -->
            <div class="sticky-col _mob-h">
              <div class="theme-ad _mb-20">
                <a class="theme-ad-link" href="#"></a>
                <p class="theme-ad-sign">Propaganda</p>
                <img
                  class="theme-ad-img"
                  src="@/assets/img/banner-gol.png"
                  alt="Tarifas imperdiveis para seu destino"
                  title="Propaganda"
                >
              </div>
            </div>
            <!-- /Propaganda -->
          </div>
          <!-- Lista Hoteis -->
          <div class="col-md-9">
            <div class="theme-search-results _mb-10">
              <div class="row row-col-gap" data-gutter="10" v-if="!loading">
                <div class="col-md-4" v-for="result in results" :key="result.hotel.id">
                  <hotel :data="result"></hotel>
                </div>
              </div>
              <div class="row row-col-gap" data-gutter="10" v-else>
                <div class="col-md-4" v-for="i in 6" :key="i"> 
                  <content-loader
                    :height="335"
                    :width="285"
                    :speed="2"
                    primaryColor="#f1f1f1"
                    secondaryColor="#ecebeb"
                  >
                    <rect x="6.5" y="3.27" rx="0" ry="0" width="275" height="271.54" /> 
                    <rect x="6.5" y="285" rx="0" ry="0" width="275" height="6.9" /> 
                    <rect x="6.5" y="300.19" rx="0" ry="0" width="275" height="6.9" /> 
                    <rect x="208.5" y="314.27" rx="0" ry="0" width="72" height="14" />
                  </content-loader>
                </div>
              </div>
              <!-- <div class="row row-col-gap" data-gutter="10" v-else>
                <div class="col-md-12">
                  Nenhum hotel encontrado. Refaça sua busca ou filtro.
                </div>
              </div> -->
            </div>
            <!-- <a class="btn _tt-uc _fs-sm btn-white btn-block btn-lg" href="#">Mais resultados</a> -->
          </div>
          <!--/Lista Hoteis -->
        </div>
      </div>
    </div>
    <!-- /Conteudo -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import BoxSearch from "../../components/template/boxsearch.vue";

import Hotel from "../../components/items/Hotel.vue";
import lockscreen from "../../components/lockscreen/index.vue";
import moment from "moment";

import vueSlider from "vue-slider-component";
// import flights from "../../mixins/flights";
import travelers from "../../mixins/travelers";
import _ from 'underscore';
import { ContentLoader } from "vue-content-loader";

export default {
  data() {
    return {
      loadTime:null,
      city_name: "",
      rooms: "",
      adults: "",
      dt_start: "",
      dt_end: "",
      total_hotels: 0,
      destination: "",
      results: [],
      total_results: 0,
      next: 0,
      loading: false,
      check: null,
      nights: 0,
      google_iframe_city: "",
      filter: {
        stars: {
          one: false,
          two: false,
          three: false,
          four: false,
          five: false
        },
        price: [0, 500]
        
      },
      ordering: false,
      orderOriginResults: []
    };
  },
  components: {
    vueSlider,
    BoxSearch,
    Hotel,
    lockscreen,
    ContentLoader
  },
  mixins: [travelers],
  computed: {
    checkin() {
      return moment(this.dt_start, "YYYY-MM-DD").format(
        "dddd, DD [de] MMMM [de] YYYY"
      );
    },
    checkout() {
      return moment(this.dt_end, "YYYY-MM-DD").format(
        "dddd, DD [de] MMMM [de] YYYY"
      );
    },
    nightsLabel() {
      return this.$options.filters.pluralize("noite", this.nights);
    }
  },
  methods: {
    changeData() {
      this.city_name = this.$route.params.city;
      this.rooms = this.$route.params.room;
      this.adults = this.$route.params.adults;
      this.dt_start = this.$route.params.dt_start;
      this.dt_end = this.$route.params.dt_end;
      this.destination = this.$route.params.destination;
      let dt_start = moment(this.dt_start, "YYYY-MM-DD");
      let dt_end = moment(this.dt_end, "YYYY-MM-DD");
      this.nights = Math.round(moment.duration(dt_end.diff(dt_start)).asDays());
    },
    fetchData() {
      this.loading = true;
      this.changeData();

      const startLoad = Date.now();

      let url = `/api/hotel/list/${this.dt_start}/${this.dt_end}/${
        this.city_name
      }/${this.adults}`;

      if (this.destination) {
        url += `/${this.destination}`;
      }

      this.$http({
        url: url,
        method: "GET"
      }).then(response => {
        this.loading = false;
        this.loadTime = Date.now() - startLoad;
        if(this.loadTime > 5000) {
          console.log('too long search')
          this.$ga.event({
            eventCategory: 'Hotel',
            eventAction: 'Search',
            eventLabel: 'Too Long Search',
            eventValue: `${this.loadTime/1000} Seconds`
          })
        }

        if( response.data.results.length < 1 ) {
          this.$swal({
            title: "Não foi encontrado nenhuma disponibilidade para essa busca",
            type: "error",
            confirmButtonText: "SAIR"
          }).then(()=>{
            return this.$router.push('/');
          });
          return;
        }
        this.filter.price = [100, response.data.extra.max_price]
        this.filter.min = response.data.extra.min_price;
        this.filter.max = response.data.extra.max_price;
        this.total_hotels = response.data.total_results;
        this.results = response.data.results;
        this.providers = response.data.providers;
        this._results = _.clone(response.data.results);
        this.next = response.next;
        this.total_results = response.total_results;
        this.google_iframe_city = `https://maps.google.com/maps?q=${this.city_name}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
      }).catch((response) => {
        this.loading = false;
          this.$swal({
            title: "Não foi encontrado nenhuma disponibilidade para essa busca",
            type: "error",
          }).then(()=>{
            return this.$router.push('/');
          });
      });
    },
    applyOrdering() {
      let tmpResults = this.results;
      tmpResults = _.sortBy(this.results, 'price_selected');
      
      if (!this.orderOriginResults.length) {
        this.orderOriginResults = _.clone(this.results);
      }
      if (this.ordering == 'asc') {
        this.results = tmpResults;
      } else if(this.ordering == 'desc') {
        this.results = tmpResults.reverse();
      } else {
        this.results = _.clone(this.orderOriginResults);
        this.orderOriginResults = []
      }
    }
  },
  mounted() {
    this.fetchData();
    $("#price-slider").ionRangeSlider({
      type: "double",
      prefix: "$"
    });

    $("#price-slider-mob").ionRangeSlider({
      type: "double",
      prefix: "$"
    });
  },
  watch: {
    $route(to, from) {
      this.fetchData();
    },
    filter: {
      handler(newV, oldV) {
        if(this.loading) return false;
        this.loading = true;
        this.$nextTick(() => {
          let tmpResults = Array();
          let results = this.orderOriginResults.length ? this.orderOriginResults : this._results;
          results.forEach((el, idx) => {
            let price_selected = el.price_selected;
            if (newV.price[0]<=price_selected && newV.price[1]>=price_selected) {
              tmpResults.push(el);
            }
          });

          let selectedStars = _.filter(_.values(this.filter.stars), (el) => { return el != false; }).map(function(v){ return parseInt(v); });
          let starResults = Array();
          
          tmpResults.forEach((el, idx) => {
            let awards = _.findWhere(el.hotel.awards, {slug: 'star'});

            if (awards) {
              if (_.contains(selectedStars, parseInt(awards.rate))) {
                starResults.push(el);
              }
            }
          });
          
          
          this.results = selectedStars.length ? starResults : tmpResults;
          this.total_hotels = this.results.length;
          this.loading = false;
          this.applyOrdering();
        });

      },
      deep: true
    },
    ordering() {
      this.applyOrdering();
    }

  }
};
</script>

<style scoped>
.theme-search-area-header-sm .theme-search-area-subtitle {
  margin-top: 4px;
  font-size: 15px;
  font-weight: 100;
  opacity: 1;
}
.mt-60 {
  margin-top: 60px;
}
</style>