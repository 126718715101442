<template>
  <div class="row flight-detail">
    <div class="row col-xs-4 flight-detail-info">
      <p class="flight-detail-text-info text-right">
        <i class="fa fa-clock-o fa-fw ico-clock"></i>
        Duração:<br class="visible-xs-block"> {{ detail.flight_time | minutes_to_hours }}
      </p>
      <p class="flight-detail-text-info text-right">
        <i class="fa fa-plane ico-plane"></i>&nbsp;
        <span class="hidden-xs">Número do voo:</span>
        <span class="hidden-sm hidden-md hidden-lg">Voo:</span>
          <strong>&nbsp;{{ detail.carrier.iata_code }}{{ detail.flight_number | normalize_flight_number }}</strong>
      </p>
    </div>

    <div class="row col-xs-8 flight-detail-panel">
      <span
        v-if="indexKey > 0"
        class="flight-detail-mark-index">{{ indexKey }}</span>
      <div class="flight-detail-row">
        <div class="clearfix">
          <div class="col-sm-2">
            <p class="flight-detail-text-info">
              <strong>Partida</strong>
            </p>
          </div>
          <div class="col-sm-10">
            <p class="flight-detail-text-info">
              {{ detail.date_departure | date_to_weekday }}
            </p>
          </div>
        </div>
      </div>

      <div class="flight-detail-mark-down">
        <div class="clearfix">
          <div class="col-sm-2 flight-detail-mark">
            <p class="flight-detail-text-main">
              {{ detail.date_departure | date_to_hour }}<sup v-if="dateDiffDeparture > 0">+{{ dateDiffDeparture }}</sup>
            </p>
          </div>
          <div class="col-sm-10">
            <p class="flight-detail-text-main">
              <strong>{{ detail.origin.iata_code }}</strong> {{ detail.origin.name }}
            </p>
          </div>
        </div>
      </div>

      <div class="flight-detail-row flight-detail-mark-mid">
        <div class="clearfix">
          <div class="col-sm-2">
            <p class="flight-detail-text-info">
              <strong>Chegada</strong>
            </p>
          </div>
          <div class="col-sm-10">
            <p class="flight-detail-text-info">
              {{ detail.date_arrival | date_to_weekday }}
            </p>
          </div>
        </div>
      </div>

      <div class="">
        <div class="clearfix">
          <div class="col-sm-2 flight-detail-mark flight-detail-mark-up">
            <p class="flight-detail-text-main">
              {{ detail.date_arrival | date_to_hour }}<sup v-if="dateDiffArrival > 0">+{{ dateDiffArrival }}</sup>
            </p>
          </div>
          <div class="col-sm-10">
            <p class="flight-detail-text-main">
              <strong>{{ detail.destination.iata_code }}</strong> {{ detail.destination.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: [
    'detail',
    'indexKey',
    'dateDeparture'
  ],
  data() {
    return {
      dateDiffDeparture: this.diffDate(this.detail.date_departure),
      dateDiffArrival: this.diffDate(this.detail.date_arrival)
    }
  },
  methods: {
    diffDate(date) {
      return moment(date, 'YYYY, MM, DD').diff(moment(this.dateDeparture, 'YYYY, MM, DD'), 'days')
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/FlightDetail';
</style>
