<template>
  <ul class="list-unstyled">
    <li
      v-for="(optional, index) in optionals"
      :key="`car-optional-${index}`"
      :class="{ 'border-right-white' : !(index % 2) && device === 'desktop' }"
      class="col-lg-6 _pb-5 _pt-5">
      <p-check
        class="p-default p-curve p-fill p-smooth p-plain"
        name
        id
        color="primary"
        @change="$emit('handleOptional', $event, optional)">
        &nbsp;<span class="line-height-18">{{ optional.name }}</span>
      </p-check>
      <span class="pull-right">{{ optional.charge.unit_value | brl_price }}<span class="color-gray-light-mid size-14">/dia</span></span>
    </li>
  </ul>
</template>

<script>
import device from '@/mixins/device';

export default {
  mixins: [ device ],
  props: [
    'optionals'
  ]
};
</script>
