<template>
  <div class="_pt-20">
    <template v-if="flight">
      <div class="clearfix">
        <div class="col-xs-12">
          <!-- Detail Hourly -->
          <detail-hourly
            :segments="flight.segments"
            :direction="direction"></detail-hourly>
          <!-- // Detail Hourly -->
        </div>
      </div>
      <div class="bg-gray-lighter box-radius clearfix">
        <!-- Flight Segment -->
        <flight-segment
          v-for="(segment, index) in flight.segments"
          :key="`flight-segment-${index}`"
          :indexKey="index"
          :segment="segment"
          :date-departure="firstSegment.date_departure">
        </flight-segment>
        <!-- // Flight Segment -->
      </div>
    </template>
  </div>
</template>

<script>
import DetailHourly from '@/components/flight/DetailHourly'
import FlightSegment from '@/components/flight/FlightSegment'

export default {
  components: {
    DetailHourly,
    FlightSegment
  },
  props: [
    'flight'
  ],
  computed: {
    firstSegment () {
      return this.flight.segments[0]
    },
    direction() {
      return this.flight.direction === 'outbound' ? 'ida' : 'volta';
    }
  }
};
</script>
