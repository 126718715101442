import Vue from 'vue'
import Vuex from 'vuex'
// import VueCookies from 'vue-cookies'
/* MODULES */
import reserve from '@/store/modules/reserve'
import navigation from '@/store/modules/navigation'
import orders from '@/store/modules/orders'
import companies from '@/store/modules/companies'
import personal from '@/store/modules/personal'
import creditcard from '@/store/modules/creditcard'
import policy from '@/store/modules/policy'
import hotels from '@/store/modules/hotels'
import flights from '@/store/modules/flights'
import cars from '@/store/modules/cars'
import travelers from '@/store/modules/travelers'

Vue.use(Vuex);

const LOGIN = "LOGIN";
const LOGIN_SUCCESS = "LOGIN_SUCCESS";
const LOGOUT = "LOGOUT";
const UPDATE_CART = "UPDATE_CART";
const HANDLE_CUSTOMER = "HANDLE_CUSTOMER";
const WISHLIST = "WISHLIST";
const SET_FLIGHT = 'SET_FLIGHT';
const SET_FLIGHTS = 'SET_FLIGHTS';
const REMOVE_FLIGHT = 'REMOVE_FLIGHT';
const REMOVE_FLIGHTS = 'REMOVE_FLIGHTS';
const SET_CLEARSALE_TOKEN = 'SET_CLEARSALE_TOKEN';
const SET_CONNECTION_PROBLEM = 'SET_CONNECTION_PROBLEM';

const store = new Vuex.Store({
  state: {
    isLoggedIn: localStorage.getItem('loggedin') || false,
    connectionProblem: false,
    refresh_cart: false,
    customer: {},
    wishlist: {},
    user: localStorage.getItem('user') != 'undefined' ? JSON.parse(localStorage.getItem('user')) : null,
    clearSaleToken: '',
    incompleteAccount: false,
    countries: []
  },
  mutations: {
    [LOGIN](state) {
      state.pending = true;
    },
    [LOGIN_SUCCESS](state, data) {
      state.isLoggedIn = true;
      state.pending = false;
      state.user = data;
      state.connectionProblem = false;
      localStorage.setItem('loggedin', 1);
      localStorage.setItem('user', JSON.stringify(state.user));
    },
    [LOGOUT](state) {
      state.isLoggedIn = false;
      state.pending = false;
      state.user = null;
      state.customer = {};
      localStorage.removeItem('loggedin');
      localStorage.removeItem('user');
    },
    [UPDATE_CART](state) {
      state.refresh_cart = true;
    },
    [HANDLE_CUSTOMER](state, customer) {
      state.customer = customer
    },
    [WISHLIST](state, wishlist){
      state.wishlist = wishlist
    },
    [SET_FLIGHT](state, flight){
      localStorage.setItem('flight', JSON.stringify(flight));
    },
    [SET_FLIGHTS](state, flights){
      state.flights = flights;
      localStorage.setItem('flights', JSON.stringify(flights));
    },
    [REMOVE_FLIGHT](){
      localStorage.removeItem('flight');
    },
    [REMOVE_FLIGHTS](state){
      state.flights = [];
      localStorage.removeItem('flights');
    },
    [SET_CLEARSALE_TOKEN](state, token) {
      state.clearSaleToken = token
    },
    [SET_CONNECTION_PROBLEM](state) {
      state.connectionProblem = true
      state.isLoggedIn = false;
    }
  },
  actions: {
    login({ state, commit, rootState }) {
      commit(LOGIN);
    },
    login_success({ state, commit, rootState }) {
      commit(LOGIN_SUCCESS);
    },
    logout({ state, commit, rootState }) {
      commit(LOGOUT);
    },
    refresh_cart({ state, commit, rootState }) {
      commit(UPDATE_CART);
    },
    setFlight({state, commit}, flight){
      commit(SET_FLIGHT, flight)
    },
    setFlights({state, commit}, flights){
      commit(SET_FLIGHTS, flights)
    },
    removeFlight({state, commit, rootState}){
      commit(REMOVE_FLIGHT)
    },
    removeFlights({state, commit}){
      commit(REMOVE_FLIGHTS)
    },
    set_clearsale_token({commit}, token){
      commit(SET_CLEARSALE_TOKEN, token);
    },
    set_connection_problem({state, commit, rootState}){
      // commit(SET_CLEARSALE_TOKEN, token);
    }
  },
  getters: {
    isLoggedIn: state => {
      return state.isLoggedIn;
    },
    getConnectionProblem: state => {
      console.log('get connection problem')
      return state.connectionProblem;
    },
    getCustomer: state => {
      return state.customer;
    },
    getUser: state => {
      return state.user;
    },
    getWishList: state => {
      return state.wishlist;
    },
    get_clearsale_token: state => {
      if (!state.clearSaleToken) {
        return document.querySelector('#ClearSaleSessionID').value
      }
      return state.clearSaleToken;
    }
  }
});

store.registerModule('reserve', reserve);
store.registerModule('navigation', navigation);
store.registerModule('orders', orders);
store.registerModule('companies', companies);
store.registerModule('personal', personal);
store.registerModule('creditcard', creditcard);
store.registerModule('policy', policy);
store.registerModule('hotels', hotels);
store.registerModule('flights', flights);
store.registerModule('cars', cars);
store.registerModule('travelers', travelers);

export default store;
