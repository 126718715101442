export default {
  methods: {
    showSuccessAlert() {
      this.$swal({
        title: "Salvo com sucesso!",
        type: "success",
        confirmButtonText: "SAIR"
      });
    },
    showSuccessAlertNoButton() {
      this.$swal({
        title: "Salvo com sucesso!",
        type: "success",
        showConfirmButton:false,
        timer: 1000,
      });
    },
    showErrorAlert() {
      this.$swal({
        title: "Favor verificar os campos",
        type: "error",
        confirmButtonText: "SAIR"
      });
    },
  }
}
