<template>
  <div class="loading-animation">
    <span class="loading-plane">
      <i class="fa fa-plane"></i>
    </span>
    <span class="loading-bed">
      <i class="fa fa-bed"></i>
    </span>
    <span class="loading-car">
      <i class="fa fa-car"></i>
    </span>
    <span class="loading-suitcase">
      <i class="fa fa-suitcase"></i>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style>
</style>
