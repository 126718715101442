<template>
    <div>
        <div class="col-md-12">
            <h4 class="title-manage-account">Top Viajantes</h4>
        </div>
        <div class="col-md-12">
            <wait-loader v-if="loading"></wait-loader>
            <div v-else class="theme-account-history table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="text-center">Viajante</th>
                        <th class="text-center">Valor</th>
                    </tr>
                    </thead>
                    <tbody v-if="travelers.length !== 0">
                        <tr v-for="traveler in travelers">
                            <td class="text-center">{{ traveler.viajante }}</td>
                            <td class="text-center">{{ traveler.total_str }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="text-center">
                            <td colspan="2">Nenhum top viajante encontrado.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import WaitLoader from "../loader/wait-loader.vue";

    export default {
        name: "top-travelers",
        data() {
            return {
                travelers: [],
                loading: false,
            }
        },
        components: {
          WaitLoader,
        },
        created() {
            this.loading = true;
            this.$http
                .get("/api/top-travelers")
                .then(response => {
                    if (response.status === 200) {
                        this.travelers = response.data
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
</script>

<style scoped>

</style>