<template>
  <div>
    <div class="row">
      <div class="col-xs-9 col-sm-6 _mt-10">
        <!-- Car Thumb -->
        <car-thumb
          :image="item.vehicle.image_url"
          :model="`${item.vehicle.description} - ${item.vehicle.model}`">
        </car-thumb>
        <!-- // Car Thumb -->
      </div>
    </div>
    <div class="col-xs-12 col-md-7 col-lg-6 row">
      <!-- Car Dates -->
      <car-dates
        :start="item.start_date"
        :end="item.end_date"></car-dates>
      <!-- // Car Dates -->
      <div class="col-xs-12">
        <!-- Car Detail -->
        <car-detail :item="item"></car-detail>
        <!-- // Car Detail -->
      </div>
    </div>
    <hr class="visible-xs-block">
    <div
      :class="[{ 'border-left': device === 'desktop' }]"
      class="col-sm-6 col-md-5 col-lg-6">
      <!-- Car Info -->
      <car-info
        :traveler="item.traveler"
        :coverages="item.vehicle.coverages"
        :optionals="item.vehicle.optionals"></car-info>
      <!-- // Car Info -->
    </div>
    <!-- Values -->
    <div class="travel-list-values col-xs-12">
      <div class="travel-list-value size-16 weight-lighter">
        <span>Diárias</span>
        <span class="pull-right display-block">{{ item.fare | brl_price}}</span>
      </div>
      <div class="travel-list-value size-16 weight-lighter">
        <span>Taxas</span>
        <span class="pull-right display-block">{{ item.taxes | brl_price}}</span>
      </div>
      <div class="travel-list-value size-16 weight-lighter">
        <span class="size-18 weight-bold">Subtotal</span>
        <span class="size-18 weight-bold pull-right display-block">{{ item.total | brl_price }}</span>
      </div>
    </div>
    <!-- // Values -->
    <!-- Dialog Remove -->
    <dialog-remove :item="item">
      <template v-slot:description="{item}">
        <div class="row">
          <h2 class="col-xs-12 size-16 upper weight-bold color-gray-dark">
            {{ item.vehicle.description }}
          </h2>
          <div class="col-xs-6 col-sm-3 size-16">
            <div class="color-gray-light-mid weight-lighter">
              <i class="fa fa-calendar"></i> Check In:
            </div>
            <p>{{ item.start_date | date_to_weekday }}</p>
          </div>
          <div class="col-xs-6 col-sm-3 size-16">
            <div class="color-gray-light-mid weight-lighter">
              <i class="fa fa-calendar"></i> Check Out:
            </div>
            <p>{{ item.end_date | date_to_weekday }}</p>
          </div>
        </div>
      </template>
    </dialog-remove>
    <!-- Dialog Remove -->
  </div>
</template>

<script>
import CarThumb from '@/components/car/CarThumb';
import CarDates from '@/components/car/CarDates';
import CarDetail from '@/components/car/CarDetail';
import CarInfo from '@/components/car/CarInfo';
import DialogRemove from '@/views/travel/DialogRemove';
import device from '@/mixins/device'

export default {
  mixins: [ device ],
  components: {
    CarThumb,
    CarDates,
    CarDetail,
    CarInfo,
    DialogRemove
  },
  props: [
    'item'
  ]
};
</script>
