<template>
  <div class="theme-hero-area">
    <div class="theme-hero-area-bg-wrap">
      <div
        class="theme-hero-area-bg"
        :style="{'background-image': 'url(' + require('@/assets/img/bg-home.jpg') + ')'}"
      ></div>

      <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
    </div>
    <div class="theme-hero-area-body">
      <div class="theme-page-section theme-page-section-xl">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <div class="theme-login-header">
                <router-link to="/login">
                  <div class="center-block wdt-250 mt-30 mb-20">
                    <img
                      class="img-responsive"
                      src="@/assets/img/logo-login.png"
                      alt="Logo etrip"
                      title="ETrip"
                    >
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-md-10 col-md-offset-1 pb-50">
              <div class="row">
                <div class="theme-login theme-login-white">
                  <div class="theme-login-header">
                    <div class="theme-login-header text-center">
                      <h1 class="theme-login-title">Crie sua conta</h1>
                    </div>
                  </div>
                  <form @submit.prevent="signup">
                    <div class="theme-login-box">
                      <div class="col-md-6 padding-off">
                        <div class="col-md-12">
                          <div class="user-personal-data">
                            <!-- Dados pessoais -->
                            <!-- Subtitulo -->
                            <div class="col-md-12 padding-off">
                              <h4 class="title-manage-account">Dados pessoais</h4>
                            </div>
                            <!-- /Subtitulo -->
                            <!-- Nome completo -->
                            <div class="col-md-12 padding-off">
                              <div class="form-group theme-login-form-group">
                                <!-- <label>Nome Completo</label> -->
                                <input
                                  class="form-control"
                                  type="text"
                                  placeholder="Nome Completo*"
                                  name="full_name"
                                  v-model="account.full_name"
                                  v-validate="'required|alpha_spaces|min:2'"
                                  :class="{'alert-danger': submitted && errors.has('full_name')}"
                                >
                                <span class="error-message-forms">{{ errors.first('full_name') }}</span>
                              </div>
                            </div>
                            <!-- /Nome completo -->
                            <!-- E-mail -->
                            <div class="col-md-12 padding-off">
                              <div class="form-group theme-login-form-group">
                                <!-- <label>E-mail</label> -->
                                <input
                                  class="form-control"
                                  placeholder="E-mail*"
                                  type="text"
                                  name="email"
                                  v-model="account.email"
                                  v-validate="'required|email'"
                                  :class="{ 'alert-danger': submitted && errors.has('email') }"
                                  :disabled='lockFieldIfInvited'
                                >
                                <span class="error-message-forms">{{ errors.first('email') }}</span>
                              </div>
                            </div>
                            <!-- /E-mail -->
                            <div class="col-md-12 padding-off">
                              <!-- Data de Nascimento -->
                              <div class="col-sm-12 col-md-6 padding-left-off">
                                <div class="form-group theme-login-form-group">
                                  <!-- <label>Data de nascimento</label> -->
                                  <the-mask
                                    class="form-control"
                                    type="text"
                                    placeholder="Data de Nascimento*"
                                    name="birth_date"
                                    v-model="account.birth_date"
                                    v-validate="'required|min:8'"
                                    :mask="['##/##/####']"
                                    :class="{'alert-danger': submitted && errors.has('birth_date')}"
                                  />
                                  <span class="error-message-forms">{{ errors.first('birth_date') }}</span>
                                </div>
                              </div>
                              <!-- /Data de Nascimento -->
                              <!-- Telefone celular -->
                              <div class="col-sm-12 col-md-6 padding-right-off">
                                <div class="form-group theme-login-form-group">
                                  <!-- <label>Telefone Celular</label> -->
                                  <the-mask
                                    class="form-control"
                                    type="text"
                                    placeholder="Telefone Celular*"
                                    name="mobile_phone"
                                    v-model="account.mobile_phone"
                                    v-validate="'required|min:10'"
                                    :mask="['(##) ####-####', '(##) #####-####']"
                                    :class="{'alert-danger': submitted && errors.has('mobile_phone')}"
                                  />
                                  <span
                                    class="error-message-forms"
                                  >{{ errors.first('mobile_phone') }}</span>
                                </div>
                              </div>
                              <!-- /Telefone celular -->
                            </div>
                            <div class="col-md-6 padding-off">
                              <!-- Gênero -->
                              <div class="col-md-12 padding-off">
                                <div class="form-group theme-login-form-group">
                                  <div>Gênero*</div>
                                  <div class="btn-group theme-search-area-options-list mt-10">
                                    <div>
                                      <p-radio
                                        class="p-default p-round p-smooth p-plain"
                                        name="genero"
                                        id="Masculino"
                                        :value="1"
                                        v-model="account.gender"
                                        color="primary-o"
                                        v-validate="'required|included:1,2'"
                                      >&nbsp;Masculino</p-radio>
                                    </div>
                                    <div>
                                      <p-radio
                                        class="p-default p-round p-smooth p-plain mt-10"
                                        name="genero"
                                        id="Feminino"
                                        :value="2"
                                        v-model="account.gender"
                                        color="primary-o"
                                      >&nbsp;Feminino</p-radio>
                                    </div>
                                    <span class="error-message-forms">{{ errors.first('genero') }}</span>
                                  </div>
                                </div>
                              </div>
                              <!-- /Gênero -->
                              <!-- Produtos -->
                              <div class="col-md-12 padding-off">
                                <div class="form-group theme-login-form-group">
                                  <div>Quais produtos você costuma comprar?</div>
                                  <div
                                    class="theme-search-results-sidebar-section-checkbox-list-item mt-10"
                                  >
                                    <div>
                                      <p-check
                                        class="p-svg p-default p-fill p-smooth"
                                        color="primary"
                                        type="checkbox"
                                        name="buy_flights"
                                        id="id_buy_flights"
                                        v-model="account.buy_flights"
                                      >
                                        <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                          <path
                                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                            style="stroke: white;fill:white"
                                          ></path>
                                        </svg>
                                        <span class="pretty-space">Passagens aéreas</span>
                                      </p-check>
                                    </div>
                                    <div>
                                      <p-check
                                        class="p-svg p-default p-fill p-smooth mt-10"
                                        color="primary"
                                        type="checkbox"
                                        name="rent_cars"
                                        id="id_rent_cars"
                                        v-model="account.rent_cars"
                                      >
                                        <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                          <path
                                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                            style="stroke: white;fill:white"
                                          ></path>
                                        </svg>
                                        <span class="pretty-space">Locação de carro</span>
                                      </p-check>
                                    </div>
                                    <div>
                                      <p-check
                                        class="p-svg p-default p-fill p-smooth mt-10"
                                        color="primary"
                                        type="checkbox"
                                        name="buy_hotels"
                                        id="id_buy_hotels"
                                        v-model="account.buy_hotels"
                                      >
                                        <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                          <path
                                            d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                            style="stroke: white;fill:white"
                                          ></path>
                                        </svg>
                                        <span class="pretty-space">Hotéis</span>
                                      </p-check>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- /Produtos -->
                            </div>
                            <!-- Frequencia de compra de viagens -->
                            <div class="col-md-6 padding-off">
                              <div class="theme-login-checkbox form-group">
                                <label>Com qual frequência você compra viagens?</label>

                                <div>
                                  <p-radio
                                    class="p-default p-round p-smooth p-plain mt-10"
                                    color="primary-o"
                                    :value="1"
                                    v-model="account.frequency_purchases"
                                  >&nbsp;Menos de uma vez por mês</p-radio>
                                </div>

                                <div>
                                  <p-radio
                                    class="p-default p-round p-smooth p-plain mt-10"
                                    color="primary-o"
                                    :value="2"
                                    v-model="account.frequency_purchases"
                                  >&nbsp;Uma vez por mês</p-radio>
                                </div>

                                <div>
                                  <p-radio
                                    class="p-default p-round p-smooth p-plain mt-10"
                                    color="primary-o"
                                    :value="3"
                                    v-model="account.frequency_purchases"
                                  >&nbsp;Mais de uma vez por mês</p-radio>
                                </div>

                                <div>
                                  <p-radio
                                    class="p-default p-round p-smooth p-plain mt-10"
                                    color="primary-o"
                                    :value="4"
                                    v-model="account.frequency_purchases"
                                  >&nbsp;Mais de quatro vezes por mês</p-radio>
                                </div>
                              </div>
                            </div>
                            <!-- Frequencia de compra de viagens -->
                            <!-- Dados pessoais -->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="col-md-12 padding-off">
                          <h4 class="title-manage-account">Dados empresariais</h4>
                        </div>
                        <!-- CNPJ -->
                        <div class="col-md-6 padding-left-off">
                          <div class="form-group theme-login-form-group">
                            <the-mask
                              class="form-control"
                              type="text"
                              placeholder="CNPJ*"
                              name="company_document_number"
                              v-model="account.company.document_number"
                              v-validate="invalidCNPJ ? 'cnpj': 'required|min:14'"
                              @input="companyDocumentNumberLength($event)"
                              :mask="['##.###.###/####-##']"
                              :class="{'alert-danger': submitted && errors.has('company_document_number')}"
                              :disabled="lockFieldIfInvited ? true: false"
                            />
                            <span
                              class="error-message-forms"
                            >{{ errors.first('company_document_number') }}</span>
                          </div>
                        </div>
                        <!-- /CNPJ -->
                        <!-- Telefone fixo -->
                        <div class="col-md-6 padding-right-off">
                          <div class="form-group theme-login-form-group">
                            <the-mask
                              class="form-control"
                              type="tel"
                              placeholder="Telefone*"
                              name="company_phone"
                              v-validate="'min:10'"
                              v-model="account.company.phone"
                              :mask="['(##) ####-####', '(##) #####-####']"
                              :class="{'alert-danger': submitted && errors.has('company_phone')}"
                              :disabled="lockFieldIfInvited"
                            />
                            <span class="error-message-forms">{{ errors.first('company_phone') }}</span>
                          </div>
                        </div>
                        <!-- /Telefone fixo-->
                        <!-- Razão Social -->
                        <div class="col-md-12 padding-off">
                          <div class="form-group theme-login-form-group">
                            <input
                              class="form-control"
                              type="text"
                              placeholder="Razão social*"
                              name="company_name"
                              :disabled="true"
                              v-validate="'required|cnpj'"
                              v-model="account.company.name"
                              :class="{'alert-danger': submitted && errors.has('company_name')}"
                            >
                            <span class="error-message-forms">{{ errors.first('company_name') }}</span>
                          </div>
                        </div>
                        <!-- /Razão Social -->
                      </div>
                      <div class="col-md-6">
                        <div class="col-md-12 padding-off">
                          <div class="user-change-password">
                            <!-- Senha -->
                            <!-- Subtitulo -->
                            <h4 class="title-manage-account mt-10">Crie sua senha</h4>
                            <!-- /Subtitulo -->
                            <div class="form-group theme-login-form-group">
                              <div>
                                <!-- <label>Senha</label> -->
                                <input
                                  v-on:click="open_helper = true"
                                  class="form-control"
                                  placeholder="Senha*"
                                  type="password"
                                  name="password1"
                                  v-model="account.password1"
                                  v-validate="'required|min:8'"
                                  ref="password1"
                                  :class="{'alert-danger': submitted && errors.has('password1')}"
                                >
                                <span class="error-message-forms">{{ errors.first('password1') }}</span>
                              </div>
                              <div class="mt-15">
                                <!-- <label>Confirmar senha</label> -->
                                <input
                                  v-on:click="open_helper = true"
                                  class="form-control"
                                  placeholder="Confirmar Senha*"
                                  type="password"
                                  name="password2"
                                  v-model="account.password2"
                                  v-validate="'required|confirmed:password1|min:8'"
                                  data-vv-as="password1"
                                  :class="{'alert-danger': submitted && errors.has('password2')}"
                                >
                                <span class="error-message-forms">{{ errors.first('password2') }}</span>
                              </div>
                            </div>

                            <password-helper v-if="open_helper" />

                            <!-- /senha -->
                          </div>
                          <div class="theme-login-checkbox mb-20">
                            <div>
                              <p-check
                                class="p-svg p-default p-fill p-smooth"
                                color="primary"
                                type="checkbox"
                                name="terms"
                                v-model="account.terms"
                                v-validate="'required'"
                              >
                                <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                  <path
                                    d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                    style="stroke: white;fill:white"
                                  ></path>
                                </svg>
                                <span class="pretty-space">Li e concordo com os</span>
                              </p-check>
                              <a
                                href="/TermsConditions"
                                target="_blank"
                              >termos de uso *</a>
                              <br>
                              <span class="error-message-forms">{{ errors.first('terms') }}</span>
                            </div>

                            <button
                              type="submit"
                              class="btn btn-uc btn-dark btn-block btn-lg mt-20 mb-20"
                              href="#"
                            >
                              <i class="fa fa-sign-in" aria-hidden="true"></i> Criar conta
                            </button>
                            <p>Já possui uma conta? &nbsp;
                              <router-link to="/login">
                                <a>Entrar.</a>
                              </router-link>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <lockscreen :loading="loading" :message="loadingMessage"></lockscreen>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import lockscreen from "../components/lockscreen/index";
import PasswordHelper from "@/components/PasswordHelper.vue";

Validator.extend("cnpj", {
  getMessage: "CNPJ inválido!",
  validate: value => false
});

export default {
  data() {
    return {
      account: {
        full_name: null,
        fantasy_name: null,
        birth_date: null,
        gender: null,
        mobile_phone: null,
        phone: null,
        email: null,
        password1: null,
        password2: null,
        frequency_purchases: null,
        buy_flights: null,
        buy_hotels: null,
        rent_cars: null,
        terms: null,
        company: {
          document_number: null,
          name: null,
          fantasy_name: null,
          phone: null
        }
      },
      invalidCNPJ: null,
      companyDocumentNumberValid: false,
      error_messages: null,
      success: false,
      submitted: false,
      loading: false,
      loadingMessage: false,
      invited: false,
      open_helper: false
    };
  },
  created(){
    this.$ga.page('/');
  },
  computed: {
    lockFieldIfInvited() {
      return this.invited !==false;
    }
  },
  components: {
    lockscreen,
    PasswordHelper
  },
  beforeMount(){
    let invited = this.$route.query.invited || false;
    this.invited = invited;
  },
  mounted() {
    if (this.invited) {
      this.$http({
        url: `/api/invite/${this.invited}`,
        method: 'GET',
      }).then((response) => {
        let data = response.data;
        this.account.company.document_number = data.company.document_number;
        this.account.company.name = data.company.name;
        this.account.company.fantasy_name = data.company.fantasy_name;
        this.account.company.phone = data.company.phone;
        this.account.full_name = data.name
        this.account.email = data.email
        this.$nextTick(()=> {
          // this.invited = false;
        });
      });
    }
  },
  methods: {
    signup(e) {
      e.preventDefault();
      let router = this.$router;
      this.$validator.validate().then(valid => {
        this.submitted = true;
        if (valid) {
          this.loading = true;
          this.$http
            .post("/api/user/create", this.account)
            .then(response => {
              this.loading = false;
              if (response.status === 200) {
                this.success = true;
                this.$ga.event({
                  eventCategory: 'Authentication',
                  eventAction: 'New Registration',
                  eventLabel: 'New User',
                  eventValue:1
                })
                this.$swal({
                  title:
                    "Cadastro efetuado com sucesso, você receberá um email para confirmar sua conta",
                  type: "success",
                  showConfirmButton: false,
                  timer: 4000
                }).then(function(){
                  router.push("/login");
                });
              }
            })
            .catch(error => {
              this.loading = false;
              let errors = error.response.data;
              let last_error = false;

              if (error.response.data.message) {
                last_error = error.response.data.message;
              } else {
                for (let key in errors) {
                  let errors_field = errors[key]
                  errors_field.forEach(function(error, idx){
                    if (last_error === false) {
                      last_error = error
                    }
                  })
                }
              }

              if (last_error) {
                this.$swal({
                  title: last_error,
                  type: "error",
                  showConfirmButton: true,
                });
              }
            });
        }
      });
    },
    companyDocumentNumberLength() {
      this.invalidCNPJ = null;
      if (
        this.account.company.document_number &&
        this.account.company.document_number.length === 14 &&
        !this.invited
      ) {
        this.companyDocumentNumberValid = true;
        this.loading = true;
        this.loadingMessage = 'Consultando o CNPJ na receita federal, Aguarde.'
        
        this.$http
          .post("/api/cnpj/", {
            document_number: this.account.company.document_number
          })
          .then(response => {
            if (response.status === 200) {
              if (response.data.razao) {
                this.account.company.name = response.data.razao;
                this.account.company.fantasy_name = response.data.fantasia;
              }
              if (response.data.erro) {
                this.invalidCNPJ = true;
              }
              this.loading = false;
              this.loadingMessage = false;
            }
          }).catch((error) => {
            this.loading = false;
            this.loadingMessage = false;
            let message = 'Ocorreu um erro ao consultar o CNPJ';
            
            if (error.response.data.message) {
              message = error.response.data.message;
            }
            
            this.$swal({
              title: message,
              type: "error",
              showConfirmButton: true,
            }).then(() => {
              this.account.company.document_number = '';
              this.account.company.name = '';
              this.account.company.fantasy_name = '';
            });
          });
      }
    }
  }
};
const dict = {
  custom: {
    full_name: {
      alpha_spaces: "Campo nome completo deve conter apenas caracteres alfabéticos e espaços.",
      min: "Campo nome completo deve conter ao menos 2 caracteres",
    },
    email: {
      email: "E-mail inválido",
    },
    birth_date: {
      min: "Data de nascimento inválida",
    },
    company_document_number: {
      min: "CNPJ deve conter 14 caracteres",
    },
    password1: {
      min: "A senha deve conter no mínimo 8 caracteres",
    },
    password2: {
      required: "Campo confirmar senha é obrigatório",
      min: "A senha deve conter no mínimo 8 caracteres",
    },
    mobile_phone: {
      min: "Campo deve conter no mínimo 10 caracteres",
    },
    company_phone: {
      min: "Campo deve conter no mínimo 10 caracteres",
    },
  }
};
Validator.localize("en", dict);
</script>

<style scoped>
.theme-login-box {
  padding: 25px 10px;
}
.theme-login-social-login-facebook span,
.theme-login-social-login-google span,
.theme-login-social-login-linkedin span {
  padding-top: 6px !important;
}
.txt-signup-title {
  font-size: 18px;
}
.icheckbox-title {
  font-weight: normal;
  font-size: 15px;
}
.wdt-250 {
  width: 250px;
}
.theme-page-section-xl {
  padding: 10px 0;
}
.pb-50 {
  padding-bottom: 50px;
}
.theme-hero-area {
  height: 100vh;
}
</style>