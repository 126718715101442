import { axiosUpdateHeaders } from '@/axios'
import { state, mutations, SET_LOADING, RESET_LOADING } from '@/store/modules/common'

export const actions = {
  getVehicleAvailability ({ commit }, hash) {
    const axios = axiosUpdateHeaders();
    commit(SET_LOADING);

    return axios.get(`/api/vehicle/availability/`, { params: { hash } })
      .then(response => {
        commit(RESET_LOADING)

        if ([200, 201].includes(response.status)) {
          if (response.data.results.error) {
            return Promise.reject(response.data.results.error)
          }
          return Promise.resolve(response.data)
        }
      })
      .catch(error => {
        commit(RESET_LOADING)
        return Promise.reject(error)
      })
  },

  postVehicleBooking ({ commit }, data) {
    const axios = axiosUpdateHeaders();
    commit(SET_LOADING);

    return axios.post(`/api/vehicle/booking/`, data)
      .then(response => {
        commit(RESET_LOADING)

        if ([200, 201].includes(response.status)) {
          if (response.data.results.error) {
            return Promise.reject(response.data.results.error)
          }
          return Promise.resolve(response.data.results)
        }
      })
      .catch(error => {
        commit(RESET_LOADING)
        return Promise.reject(error)
      })
  }
}

export default {
  namespaced : true,
  state: { ...state },
  mutations: { ...mutations },
  actions
  /* getters */
}
