import { axiosUpdateHeaders } from '@/axios'
import { normalizePersonalAccount } from '@/store/helpers/normalizeResult'

export const state = {
  results: {},
  loading: false,
  alert: ''
}

export const mutations = {
  setResult (state, data) {
    state.results = normalizePersonalAccount(data)
  },
  setLoading (state) {
    state.loading = !state.loading;
  },
  setAlert (state, alert) {
    state.alert = alert;
  }
}

export const actions = {
  getPersonalAccount (context) {
    context.commit('setLoading');

    const userId = context.rootState.user.id;
    const axios = axiosUpdateHeaders();
    return axios.get(`/api/user/?id=${userId}`).then(response => {
      if (response.status === 200) {
        context.commit('setResult', response.data);
      }
      context.commit('setLoading');
    }).catch(error => {
      /* TODO HANDLE ERROR */
      context.commit('setLoading');
    })
  },
  postPersonalAccount (context, data) {
    context.commit('setLoading');

    const axios = axiosUpdateHeaders();
    return axios.post('/api/user/update/', data).then(response => {
      if (response.status === 200) {
        context.commit('setAlert', 'Cadastro pessoal atualizado com sucesso');

        // UPDATE CONTEXT USER
        context.rootState.user = {
          ...context.rootState.user,
          completedRegister: response.data.user.completedRegister
        }
      }
      if (response.status === 403) {
        context.commit('setAlert', 'A atualização falhou.');
      }
      context.commit('setLoading');
      return Promise.resolve(response)
    }).catch(error => {
      /* TODO HANDLE ERROR */
      context.commit('setLoading');
      return Promise.reject(error)
    })
  }
}

export default {
  namespaced : true,
  state,
  mutations,
  actions
  /* getters */
}
