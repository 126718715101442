export default {
  data(){
    return {
      travelers: []
    }
  },
  methods: {
    search_travelers(){
      this.$http.get('/api/user/').then(response => {
        if (response.status === 200) {
          // this.travelers = ;
          for (let traveler of response.data.results){
            this.get_traveler(traveler.id)
          }
        }
      }).catch(error => {
        console.log(error)
      })
    },
    get_traveler(id){
      this.$http.get('/api/user/', {params: {id: id}}).then(response => {
        if (response.status === 200) {
          this.travelers.push(response.data);
        }
      }).catch(error => {
        console.log(error)
      })
    }
  }
}
