<template>
  <div class="picked-flights">
    <template v-if="flights.outbound.id">
      <article>
        <header class="clearfix">
          <h2 class="picked-flights-title pull-left">Seu voo de ida:</h2>

          <button
            @click="$emit('handleRemove')"
            class="picked-flights-trigger"
            type="button">
            - Remover
          </button>
        </header>
        <!-- Flight Direction - Outbound -->
        <flight-direction
          :index-key="flights.outbound.id"
          :flight="flights.outbound"
          :journey="journey"></flight-direction>
        <!-- // Flight Direction - Outbound -->
      </article>
    </template>

    <template v-if="flights.inbound.id">
      <hr class="picked-flights-hr">
      <article class="clearfix">
        <header>
          <h2 class="picked-flights-title">Seu voo de volta:</h2>
        </header>

        <!-- Flight Direction - Inbound -->
        <flight-direction
          :index-key="flights.inbound.id"
          :flight="flights.inbound"
          :journey="journey"></flight-direction>
        <!-- // Flight Direction - Inbound -->
      </article>
    </template>
    <template v-if="journey.outbound_and_inbound && flights.inbound.id || (journey.outbound_and_inbound === 'false') && flights.outbound.id">
      <article class="row picked-flights-fares">
        <div class="col-xs-12 col-sm-7">
          <h2 class="picked-flights-fares-title">Mude a tarifa</h2>
          <!-- Price List -->
          <prices-list
            :picked-flight="journey"
            :prices="prices"
            :has-oubound-id="false"
            :hide-value="true"
            :carrier="flights.outbound.segments[0].carrier"
            @handlePrices="handlePrices"></prices-list>
          <!-- // Price List -->
        </div>

        <div class="col-xs-12 col-sm-5">
          <div class="picked-flights-taxes">
            <ul class="picked-flights-taxes-list">
              <li>Tarifa <span class="pull-right">{{ journey.price.total_fare | brl_price }}</span></li>
              <li
                v-for="(tax, index) in taxes"
                :key="`picked-flights-taxes-${index}`"
                class="clearfix">
                {{ tax.name }} <span class="pull-right">{{ tax.value | brl_price }}</span>
              </li>
            </ul>
          </div>
          <div class="picked-flights-total">
            Total <span class="pull-right">{{ journey.price.total | brl_price }}</span>
          </div>
          <a
            href="#"
            @click.prevent="$emit('handleReserve')"
            type="button"
            class="picked-flights-reserve-trigger">Reservar</a>
          <br class="clearfix">
        </div>
      </article>
    </template>
  </div>
</template>

<script>
import FlightDirection from '@/views/flights/search/FlightDirection'
import PricesList from '@/views/flights/search/PricesList'

export default {
  components: {
    FlightDirection,
    PricesList
  },
  props: [
    'journey'
  ],
  computed: {
    flights () {
      return this.journey.flights
    },
    taxes () {
      const taxes= {
        'DU tax': 'Taxa de serviço',
        'Taxa DU': 'Taxa de serviço',
        'Taxes': 'Taxa de embarque',
        'Fee': 'Taxa de serviço',
        'Taxas': 'Taxa de embarque',
        'taxes': 'Taxas'
      }

      return this.journey.price._taxes.map((tax) => ({
        name: taxes[tax.name],
        value: tax.value
      })).filter((tax) => tax.value)
    },
    prices () {
      return (this.journey.hasOwnProperty('prices')) ? this.journey.prices : this.journey.flights[this.journey.direction].prices
    }
  },
  methods: {
    handlePrices(price) {
      this.$emit('handleReprice', price)
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/PickedFlights';
</style>
