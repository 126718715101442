<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div class="col-md-10">
            <!-- Titulo -->
            <h1 class="theme-account-page-title" v-if="traveler.id">Editar viajante</h1>
            <h1 class="theme-account-page-title" v-else>Novo viajante</h1>
            <!-- /Titulo -->
            <form @submit.prevent="update">
              <div class="row">
                <div class="col-md-12 padding-off">
                  <div class="user-personal-data">
                    <!-- Dados pessoais -->
                    <!-- Subtitulo -->
                    <div class="col-md-12">
                      <h4 class="title-manage-account">Dados pessoais</h4>
                    </div>
                    <!-- /Subtitulo -->
                    <div class="clearfix"></div>
                    <!-- E-mail de Boas Vindas -->
                    <!-- Nome completo -->
                    <div class="col-md-6">
                      <div class="form-group theme-login-form-group">
                        <label>Nome Completo*</label>
                        <input
                          class="form-control"
                          type="text"
                          name="full_name"
                          v-model="traveler.full_name"
                          v-validate="'required|alpha_spaces|min:2'"
                          :class="{ 'alert-danger': submitted && errors.has('full_name') }"
                        >
                        <span class="error-message-forms">{{ errors.first('full_name') }}</span>
                      </div>
                    </div>
                    <!-- /Nome completo -->
                    <!-- E-mail -->
                    <div class="col-md-6">
                      <div class="form-group theme-login-form-group">
                        <label>E-mail*</label>
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          v-model="traveler.email"
                          v-validate="'required|email'"
                          :class="{ 'alert-danger': submitted && errors.has('email') }"
                        >
                        <span class="error-message-forms">{{ errors.first('email') }}</span>
                      </div>
                    </div>
                    <!-- /E-mail -->
                    <!-- CPF -->
                    <div class="col-md-3">
                      <div class="form-group theme-login-form-group">
                        <label>CPF*</label>
                        <the-mask
                          class="form-control"
                          type="text"
                          name="document_number"
                          v-model="traveler.document_number"
                          :mask="['###.###.###-##']"
                          v-validate="'required|min:11'"
                          :class="{ 'alert-danger': submitted && errors.has('document_number') }"
                        />
                        <span class="error-message-forms">{{ errors.first('document_number') }}</span>
                      </div>
                    </div>
                    <!-- /CPF -->
                    <!-- Data de Nascimento -->
                    <div class="col-md-3">
                      <div class="form-group theme-login-form-group">
                        <label>Data de nascimento*</label>
                        <the-mask
                          class="form-control"
                          type="text"
                          name="birth_date"
                          v-model="traveler.birth_date"
                          :mask="['##/##/####']"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('birth_date') }"
                        />
                        <span class="error-message-forms">{{ errors.first('birth_date') }}</span>
                      </div>
                    </div>
                    <!-- /Data de Nascimento -->
                    <!-- Telefone celular -->
                    <div class="col-md-3">
                      <div class="form-group theme-login-form-group">
                        <label>Celular*</label>
                        <the-mask
                          class="form-control"
                          type="text"
                          name="mobile_phone"
                          v-model="traveler.mobile_phone"
                          :mask="['(##) ####-####', '(##) #####-####']"
                          v-validate="'required|min:10'"
                          :class="{ 'alert-danger': submitted && errors.has('mobile_phone') }"
                        />
                        <span class="error-message-forms">{{ errors.first('mobile_phone') }}</span>
                      </div>
                    </div>
                    <!-- /Telefone celular -->
                    <!-- Gênero -->
                    <div class="col-md-3">
                      <div class="form-group theme-login-form-group">
                        <div>Gênero*</div>
                        <div class="btn-group theme-search-area-options-list mt-15">
                          <p-radio
                            class="p-default p-round p-smooth p-plain"
                            name="genero"
                            id="Masculino"
                            :value="1"
                            v-model="traveler.gender"
                            v-validate="'required|included:1,2'"
                            color="primary-o"
                          >&nbsp;Masculino</p-radio>
                          <p-radio
                            class="p-default p-round p-smooth p-plain"
                            name="genero"
                            id="Feminino"
                            :value="2"
                            v-model="traveler.gender"
                            color="primary-o"
                          >&nbsp;Feminino</p-radio>
                        </div>
                        <span class="error-message-forms">{{ errors.first('genero') }}</span>
                      </div>
                    </div>
                    <!-- /Gênero -->
                    <!-- admin -->
                    <div class="col-md-4">
                      <div class="form-group theme-login-form-group">
                        <div>Tornar Gerente?</div>
                        <div class="btn-group theme-search-area-options-list mt-15">
                          <p-check
                            class="p-default p-curve p-fill p-smooth"
                            name="manager"
                            id="manager"
                            :value="1"
                            v-model="traveler.is_manager"
                            color="primary"
                          >Sim</p-check>
                        </div>
                        <span class="error-message-forms">{{ errors.first('genero') }}</span>
                      </div>
                    </div>
                     <!-- is_active -->
                    <div class="col-md-3">
                      <div class="form-group theme-login-form-group">
                        <div>Usuário ativo?</div>
                        <div class="btn-group theme-search-area-options-list mt-15">
                          <p-check
                            class="p-default p-curve p-fill p-smooth"
                            name="active"
                            id="active"
                            :value="1"
                            v-model="traveler.is_active"
                            color="primary"
                          >Sim</p-check>
                        </div>
                        <span class="error-message-forms">{{ errors.first('genero') }}</span>
                      </div>
                    </div>
                    <!-- E-mail de Boas Vindas -->
                    <div class="col-md-6 padding-off" v-if="!traveler.id">
                      <div class="col-md-6">
                        <!-- E-mail de boas vindas -->
                        <div class="form-group">
                          <div class="theme-login-checkbox">
                            <div class="theme-search-results-sidebar-section-checkbox-list-item">
                              <p-check
                                      class="p-svg p-default p-fill p-smooth"
                                      type="checkbox"
                                      color="primary"
                                      name="welcome_mail"
                                      id="welcome_mail"
                                      v-model="traveler.welcome_mail"
                                      :value="1"
                              >
                                <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                  <path
                                    d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                    style="stroke: white;fill:white"
                                  ></path>
                                </svg>
                                <span class="pretty-space">Enviar e-mail de boas vindas</span>
                              </p-check>
                            </div>
                          </div>
                        </div>
                        <!-- E-mail de boas vindas -->
                      </div>
                    </div>
                    <!-- E-mail de Boas Vindas -->
                    <!-- Dados pessoais -->
                  </div>
                </div>
              </div>
              <!-- btn salvar -->
              <div class="theme-account-preferences-item-change-actions">
                <button
                  type="submit"
                  class="btn btn-lg btn-primary pull-right"
                  v-on:click="update"
                  v-if="traveler.id"
                >Salvar</button>
                <button
                  type="submit"
                  class="btn btn-lg btn-primary pull-right"
                  v-on:click="create"
                  v-if="!traveler.id"
                >Salvar</button>
              </div>
            </form>
            <!-- /btn salvar -->
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import moment from 'moment'
import MenuAccount from "../components/menuaccount.vue";
import lockscreen from "../components/lockscreen/index.vue";
import { Validator } from "vee-validate";

export default {
  data() {
    return {
      traveler: {
        id: this.$route.params.id,
        full_name: this.$route.params.full_name,
        email: this.$route.params.email,
        document_number: this.$route.params.document_number,
        birth_date: this.$route.params.birth_date,
        mobile_phone: this.$route.params.mobile_phone,
        gender: this.$route.params.gender,
        is_manager: false,
        welcome_mail: false,
        is_active: true
      },
      loading: false,
      submitted: false
    };
  },
  components: {
    MenuAccount,
    lockscreen
  },
  created() {
    if (this.$route.params.id) {
      this.loading = true;
      this.$http.get("/api/user/?id=" + this.$route.params.id).then(response => {
        if (response.status === 200) {
          if (response.data) {
            this.traveler = response.data;
            if(this.traveler.birth_date){
              this.traveler.birth_date = moment(this.traveler.birth_date, 'YYYYMMDD').format('DDMMYYYY')
            }
          }
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      });
    }
  },
  methods: {
    create(e) {
      e.preventDefault();
      // this.loading = true;
      if (!this.traveler.id) {
        this.$validator.validate().then(valid => {
          this.submitted = true;
           if (valid) {
            let router = this.$router;
            this.$http
              .post("/api/user/", this.traveler)
              .then(response => {
                if (response.status === 200) {
                  console.log('register traveler',response)
                  this.$ga.event({
                    eventCategory: 'Authentication',
                    eventAction: 'Register Traveler',
                    eventValue: 1,
                    eventLabel: 'Register new Traveler'
                  })
                  this.$swal({
                    title: "Viajante cadastrado com sucesso",
                    type: "success",
                    showConfirmButton: false,
                    timer: 2000
                  });
                  setTimeout(() => {
                    router.push("/travelers");
                  }, 2000);
                } else {
                  new Error(response.data);
                }
              })
              .catch(error => {
              this.loading = false;
              let errors = error.response.data;
              let last_error = false;

              if (error.response.data.message) {
                last_error = error.response.data.message;
              } else {
                for (let key in errors) {
                  let errors_field = errors[key]
                  errors_field.forEach(function(error, idx){
                    if (last_error === false) {
                      last_error = error
                    }
                  })
                }
              }
              if (last_error) {
                this.$swal({
                  title: last_error,
                  type: "error",
                  showConfirmButton: true,
                });
              }
            });
            }
        });
      }
    },
    update(e) {
      e.preventDefault();
      this.$validator.validate().then(valid => {
        this.submitted = true;
        if (valid) {
          this.loading = true;
          if (this.traveler.id) {
            this.$http
              .patch("/api/user/", this.traveler)
              .then(response => {
                if (response.status === 200) {
                  this.loading = false;
                  this.$swal({
                    title: 'Viajante atualizado com sucesso',
                    type: "success",
                    showConfirmButton: true,
                  });
                }
              })
              .catch(error => {
              this.loading = false;
              let errors = error.response.data;
              let last_error = false;

              if (error.response.data.message) {
                last_error = error.response.data.message;
              } else {
                for (let key in errors) {
                  let errors_field = errors[key]
                  errors_field.forEach(function(error, idx){
                    if (last_error === false) {
                      last_error = error
                    }
                  })
                }
              }
              if (last_error) {
                this.$swal({
                  title: last_error,
                  type: "error",
                  showConfirmButton: true,
                });
              }
            });
          }
        }
      });
    }
  }
};
const dict = {
  custom: {
    full_name: {
      alpha_spaces: "Campo nome completo deve conter apenas caracteres alfabéticos e espaços.",
      min: "Campo nome completo deve conter ao menos 2 caracteres",
    },

  }
};
Validator.localize("en", dict);
</script>

<style scoped>
  .pretty {
    line-height: 1.14
  }
</style>
