<template>
  <div
    class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white quantity-selector"
    data-increment="Quartos"
  >
    <div class="theme-search-area-section-inner">
      <i class="theme-search-area-section-icon lin lin-tag"></i>
      <select class="theme-search-area-section-input" v-on:change="changeHandle">
        <option value="1" selected>1 Quarto</option>
        <option value="2">2 Quartos</option>
        <option value="3">3 Quartos</option>
        <option value="4">4 Quartos</option>
        <option value="5">5 Quartos</option>
        <option value="6">6 Quartos</option>
        <option value="7">7 Quartos</option>
        <option value="8">8 Quartos</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: this.value
    };
  },
  props: ["value"],
  methods: {
    changeHandle(e) {
      this.$emit("input", this.content);
    }
  },
  mounted() {
    setTimeout(() => {
      $(this.$el).quantitySelector();
    }, 1000);
  }
};
</script>
