<template>
  <select style="width:100%"></select>
</template>

<script>
let retrieveSearchType = function(text) {
  let type_text = "";
  switch (text) {
    case "city":
      type_text = "Cidade";
      break;
    case "hotel":
      type_text = "Hotéis";
      break;
    case "region":
      type_text = "Regiões";
      break;
    default:
      break;
  }

  return type_text;
};

let processResults = function(data) {
  let tmp_data_type = [];

  data.forEach(function(el, idx) {
    let tmp_data = [];

    el.data.forEach(function(data, idx2) {
      let text = "";
      if (data.type == "hotel") {
        text = `${data.hotel_name} - ${data.city_name} - ${data.city_name}, ${data.country_name}`;
      } else if (data.type == "city") {
        text = `${data.city_name} - ${data.city_name}, ${data.country_name}`;
      } else {
        text = `${data.region_name} - ${data.country_name}`;
      }
      tmp_data.push({
        id: text,
        text: text,
        el: data
      });
    });

    tmp_data_type.push({
      text: retrieveSearchType(el.type),
      children: tmp_data
    });
  });
  return {
    results: tmp_data_type
  };
};
import _ from "underscore";
export default {
  props: ["value", "dataInitial"],
  data() {
    return {
      content: this.value
    };
  },
  methods: {
    changeHandle(e) {
      this.$emit("input", this.content);
    },
    setSelect() {
      let destination = $(this.$el);
      this.$nextTick(function() {
        let opt = {
          language: "pt-BR",
          minimumInputLength: 3,
          debug: true,
          placeholder: "Estado, Cidade ou Hotel",
          ajax: {
            quietMillis: 400,
            url: "/api/hotel/autocomplete/",
            processResults: processResults,
            dataType: "json"
          }
        };

        if (this.dataInitial) {
          opt = _.extend(opt, { data: this.dataInitial });
        }

        destination.select2(opt);

        destination.on("select2:select", e => {
          let data = e.params.data;
          this.content = data;
          this.changeHandle(e);
        });
      });
    }
  },
  mounted() {
    this.setSelect();
  }
};
</script>

<style>
</style>


