<template>
  <div class='loading-position'>
    <div class="loading-animation">
      <span class="loading-plane">
        <i class="fa fa-plane"></i>
      </span>
      <span class="loading-bed">
        <i class="fa fa-bed"></i>
      </span>
      <span class="loading-car">
        <i class="fa fa-car"></i>
      </span>
      <span class="loading-suitcase">
        <i class="fa fa-suitcase"></i>
      </span>
    </div>
    <p class="text-loading">{{getMessage}}</p>
  </div>
</template>

<script>
export default {
  props: ['message'],
  computed: {
    getMessage() {
      return this.message ? this.message : 'Carregando...'
    }
  }
};
</script>

<style>
.loading-animation {
  margin-top: 40px;
  position: absolute;
  left: 50%;
  margin: -60px 0 0 -60px;
  text-align: center;
}

.loading-position {
  margin-top: 40px;
  position: absolute;
  top: 50%;
  margin: 0 auto;
  width: 100%;
}

.loading-animation .loading-plane {
  border-radius: 50%;
  font-size: 50px;
  height: 100px;
  width: 100px;
  display: block;
  position: absolute;
  left: 42%;
  line-height: 115px;
  /*padding-top: 18px;
  padding-left: 27px;*/
}

.loading-animation .loading-bed {
  border-radius: 50%;
  font-size: 50px;
  height: 100px;
  width: 100px;
  display: block;
  position: absolute;
  left: 42%;
  line-height: 115px;
  /*padding-top: 15px;
  padding-left: 22px;*/
}

.loading-animation .loading-car {
  border-radius: 50%;
  font-size: 50px;
  height: 100px;
  width: 100px;
  display: block;
  position: absolute;
  left: 42%;
  line-height: 110px;
  /*padding-top: 15px;
  padding-left: 21px;*/
}

.loading-animation .loading-suitcase {
  border-radius: 50%;
  font-size: 50px;
  height: 100px;
  width: 100px;
  display: block;
  position: absolute;
  left: 42%;
  line-height: 110px;
  /*padding-top: 14px;
  padding-left: 24px;*/
}

.loading-animation span:nth-child(1) {
  opacity: 0;
  -webkit-animation: loader 2s infinite;
  animation: loader 2s infinite;
  color: #fff;
  background: #f9676b;
}

.loading-animation span:nth-child(2) {
  -webkit-animation: loader 2s infinite 0.5s;
  animation: loader 2s infinite 0.5s;
  opacity: 0;
  color: #fff;
  background: #f19c4f;
}

.loading-animation span:nth-child(3) {
  animation: loader 2s infinite 1s;
  -webkit-animation: loader 2s infinite 1s;
  opacity: 0;
  color: #fff;
  background: #00adef;
}

.loading-animation span:nth-child(4) {
  animation: loader 2s infinite 1.5s;
  -webkit-animation: loader 2s infinite 1.5s;
  opacity: 0;
  color: #fff;
  background: #07253f;
}

.text-loading {
  color: #ffffff;
  font-size: 35px;
  width: 100%;
  text-align: center;
}

@keyframes loader {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
  25% {
    opacity: 1;
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  26% {
    opacity: 0;
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
}

@-webkit-keyframes loader {
  0% {
    opacity: 1;
    -webkit-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
  25% {
    opacity: 1;
    -webkit-transform: rotateY(180deg);
    -o-transform: rotateY(180deg);
    -moz-transform: rotateY(180deg);
    -ms-transform: rotateY(180deg);
    transform: rotateY(180deg);
  }
  26% {
    opacity: 0;
    -webkit-transform: rotateY(0deg);
    -o-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    -moz-transform: rotateY(0deg);
    transform: rotateY(0deg);
  }
  100% {
    opacity: 0;
    -webkit-transform: rotateY(90deg);
    -o-transform: rotateY(90deg);
    -ms-transform: rotateY(90deg);
    -moz-transform: rotateY(90deg);
    transform: rotateY(90deg);
  }
}
</style>
