<template>
  <div>
    <div class="clearfix">
      <h2 class="col-xs-12 size-16">{{ car.provider.name }}-{{ car.vehicle.model }}</h2>
      <div class="col-xs-12 col-sm-6 _pl-0 _pr-0">
        <!-- Car Dates -->
        <car-dates
          :start="car.start_date"
          :end="car.end_date"></car-dates>
        <!-- // Car Dates -->
        <!-- Car Addresses -->
        <car-addresses
          :pickup="pickup"
          :delivery="delivery"></car-addresses>
        <!-- // Car Addresses -->
      </div>

      <div class="col-xs-12 col-sm-6">
        <!-- Car Description -->
        <car-description
          :passengers="car.vehicle.passengers"
          :transmission="car.vehicle.transmission"
          :air-conditioner="car.vehicle.air_conditioner"
          :doors="car.vehicle.doors"
          class="hidden-lg">
        </car-description>
        <!-- // Car Description -->
        <ul class="list-unstyled size-14 weight-lighter">
          <li>
            <i class="fa fa-check color-blue"></i> Retirada e devolução com tanque abastecido
          </li>
          <li>
            <i class="fa fa-check color-blue"></i> Quilometragem ilimitada
          </li>
          <li>
            <i class="fa fa-check color-blue"></i> Cancelamento gratuito
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import CarDates from '@/components/car/CarDates';
import CarAddresses from '@/components/car/CarAddresses';
import CarDescription from '@/components/car/CarDescription';

export default {
  components: {
    CarDates,
    CarAddresses,
    CarDescription
  },
  props:[
    'car'
  ],
  computed: {
    pickup () {
      return {
        street: this.car.pickup_agency.address,
        number: this.car.pickup_agency.address_number,
        complement: this.car.pickup_agency.address_complement,
        neighborhood: this.car.pickup_agency.neighborhood,
        city: this.car.pickup_agency.city.name,
        province: this.car.pickup_agency.city.state.initials,
        zipCode: this.car.pickup_agency.zip_code
      }
    },
    delivery () {
      return {
        street: this.car.delivery_agency.address,
        number: this.car.delivery_agency.address_number,
        complement: this.car.delivery_agency.address_complement,
        neighborhood: this.car.delivery_agency.neighborhood,
        city: this.car.delivery_agency.city.name,
        province: this.car.delivery_agency.city.state.initials,
        zipCode: this.car.delivery_agency.zip_code
      }
    }
  }
};
</script>
