<template>
  <div class="col-md-2 hidden-xs hidden-sm">
    <div class="theme-account-sidebar">
      <div class="theme-account-avatar">
        <p class="theme-account-avatar-name">Bem vindo,</p>
        <p class="">{{ $store.state.user.first_name }}</p>
      </div>
      <nav class="theme-account-nav">
        <ul class="theme-account-nav-list">
          <li>
            <router-link :to="{name:'myaccount'}">
              <a>
                <i class="fa fa-cog"></i>Minha Conta
              </a>
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'orders'}" exact>
              <a>
                <i class="fa fa-history"></i>Meus Pedidos
              </a>
            </router-link>
          </li>
          <li v-if="$store.getters.getUser.is_agents">
            <router-link :to="{name:'orders', query:{all_orders: true}}" exact>
              <a>
                <i class="fa fa-history"></i>Todos os Pedidos
              </a>
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'company'}">
              <a>
                <i class="fa fa-building"></i>Empresa
              </a>
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'cards'}">
              <a>
                <i class="fa fa-credit-card"></i>Cartões
              </a>
            </router-link>
          </li>
          <li v-if="$store.getters.getUser.is_manager">
            <router-link :to="{name:'travelers'}">
              <a>
                <i class="fa fa-user-circle-o"></i>Viajantes
              </a>
            </router-link>
          </li>
          <li>
            <router-link :to="{name:'reports'}">
              <a>
                <i class="fa fa-area-chart"></i>Relatórios
              </a>
            </router-link>
          </li>
          <li v-if="canApprove">
            <router-link :to="{name:'approvals'}">
              <a>
                <i class="fa fa-thumbs-up"></i>Aprovador
              </a>
            </router-link>
          </li>
        </ul>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    canApprove() {
      if (this.$store.getters.getUser.is_manager)
        return true;
        
      let groups = this.$store.getters.getUser.groups || [];
      let can_approve = false;
      groups.forEach(el => {
        console.log(el.name == 'approver', el)
        if (el.name == 'approver') {
          can_approve = true;
        }
      });
      return can_approve
    }
  }
};
</script>

<style scoped>
a {
  color: #7a7a7a;
  text-decoration: none;
}
a:hover,
a:focus {
  color: #333333;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  text-decoration: none !important;
}
</style>
