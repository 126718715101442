<template>
  <detail-dialog>
    <template v-slot:detail>
      <div class="clearfix">
        <h2 class="col-sm-12 size-18 upper weight-bold color-gray-dark">
          {{ item.vehicle.description }}
        </h2>
        <div class="col-sm-12">
          <!-- Car Thumb -->
          <car-thumb
            :image="item.vehicle.image_url"
            :model="item.vehicle.model">
          </car-thumb>
          <!-- // Car Thumb -->
        </div>
        <div class="col-xs-12 col-sm-7">
          <!-- Car Dates -->
          <car-dates
            :start="item.start_date"
            :end="item.end_date"></car-dates>
          <!-- // Car Dates -->
          <!-- Car Addresses -->
          <car-addresses
            :pickup="pickup"
            :delivery="delivery"></car-addresses>
          <!-- // Car Addresses -->
        </div>
        <div class="col-xs-12 col-sm-5">
          <p class="size-14 color-gray-dark">
            <span class="weight-lighter"><i class="fa fa-user color-gray-light-mid"></i> Viajante:</span>&nbsp;<strong>{{ item.traveler.full_name }}</strong>
          </p>
          <p class="size-14 color-gray-dark">
            <template v-if="item.vehicle.coverages.length">
              <span class="weight-lighter">Coberturas: </span>
              <span
                v-for="(coverage, index) in item.vehicle.coverages"
                :key="`travel-list-item-car-coverage-${index}-${coverage.id}`">
                {{ coverage.name }}<template v-if="index >= 0 && (index !== item.vehicle.coverages.length - 1)">, </template>
              </span>
            </template> <br>
            <template v-if="item.vehicle.optionals.length">
              <span class="weight-lighter">Opcionais: </span>
              <span
                v-for="(optional, index) in item.vehicle.optionals"
                :key="`travel-list-item-car-optional-${index}-${optional.id}`">
                {{ optional.name }}<template v-if="index >= 0 && (index !== item.vehicle.optionals.length - 1)">, </template>
              </span>
            </template>
          </p>
          <p class="size-14 color-gray-dark">
            <template v-if="item.vehicle.passengers">
              <i class="fa fa-users color-gray-light-mid"></i> {{ item.vehicle.passengers }} {{ 'pessoa' | pluralize(item.vehicle.passengers) }}
            </template><br>
            <template v-if="item.vehicle.bags">
              <i class="fa fa-suitcase color-gray-light-mid"></i> {{ item.vehicle.bags }} {{ 'mala' | pluralize(item.vehicle.bags) }}
            </template><br>
            <template v-if="item.vehicle.transmission">
              <span class="weight-lighter">Transmissão: </span>{{ item.vehicle.transmission }}
            </template><br>
            <template v-if="item.vehicle.doors">
              {{ item.vehicle.doors }} portas
            </template><br>
            <template v-if="item.vehicle.air_conditioner">Ar Condicionado</template><br>
          </p>
          <br>
        </div>
      </div>
    </template>
  </detail-dialog>
</template>

<script>
import DetailDialog from '@/components/ui/DetailDialog'
import CarThumb from '@/components/car/CarThumb';
import CarDates from '@/components/car/CarDates';
import CarAddresses from '@/components/car/CarAddresses';

export default {
  components: {
    DetailDialog,
    CarThumb,
    CarDates,
    CarAddresses
  },
  props: [
    'item'
  ],
  computed: {
    pickup () {
      return {
        street: this.item.pickup_agency.address.street,
        number: this.item.pickup_agency.address.number,
        complement: this.item.pickup_agency.address.complement,
        neighborhood: this.item.pickup_agency.address.neighborhood,
        city: this.item.pickup_agency.address.city,
        province: this.item.pickup_agency.address.province,
        zipCode: this.item.pickup_agency.address.zip_code
      }
    },
    delivery () {
      return {
        street: this.item.delivery_agency.address.street,
        number: this.item.delivery_agency.address.number,
        complement: this.item.delivery_agency.address.complement,
        neighborhood: this.item.delivery_agency.address.neighborhood,
        city: this.item.delivery_agency.address.city,
        province: this.item.delivery_agency.address.province,
        zipCode: this.item.delivery_agency.address.zip_code
      }
    }
  }
};
</script>
