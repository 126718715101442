<template>
  <div id="footer" :class="ChangeFooterClass">
    <div class="theme-footer" :class="DisplayHiddenClass" id="mainFooter">
      <div class="container _ph-mob-0">
        <div class="row row-eq-height row-mob-full mt-20" data-gutter="60">
          <div class="col-md-3">
            <div class="theme-footer-section theme-footer-">
              <router-link to="/">
                <a class="logo-etrip-footer center-block _mb-mob-30">
                  <img
                    class="img-responsive"
                    src="@/assets/img/logo-color.png"
                    alt="Logo etrip"
                    title="Ir para Home"
                  >
                </a>
              </router-link>
              <div class="theme-footer-brand-text mt-20">
                <p>A etrip pesquisa centenas de sites de viagens ao mesmo tempo para encontrar as melhores ofertas de passagens aéreas, hotéis baratos e estadias.</p>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row">
              <div class="col-md-4">
                <div class="theme-footer-section theme-footer-">
                  <h5 class="theme-footer-section-title">Institucional</h5>
                  <ul class="theme-footer-section-list">
                    <li>
                      <a href="https://etrip.com.br/fale-conosco/" target="_blank">Fale conosco</a>
                    </li>
                    <li>
                      <a
                        href="/PoliciesPrivacy"
                        target="_blank"
                      >Política de Privacidade</a>
                    </li>
                    <li>
                      <a
                        href="/TermsConditions"
                        target="_blank"
                      >Termos e condições</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4">
                <div class="theme-footer-section theme-footer-">
                  <h5 class="theme-footer-section-title">
                    <i class="fa fa-map-marker" aria-hidden="true"></i> Endereço
                  </h5>
                  <ul class="theme-footer-section-list">
                    <li>
                      <div class="theme-footer-brand-text">
                        <p>Rua da Consolação, 247
                          <br>9º Andar - Centro - São Paulo
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-md-4">
                <div class="theme-footer-section theme-footer-">
                  <h5 class="theme-footer-section-title">
                    <i class="fa fa-phone" aria-hidden="true"></i> Telefone
                  </h5>
                  <ul class="theme-footer-section-list">
                    <li>
                      <div class="theme-footer-brand-text">
                        <p>+55 (11) 4210-5500</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="row">
              <div class="col-md-4">
                <div class="theme-footer-section theme-footer- logos-footer center-block">
                  <a href="http://www.abav.com.br/" target="_blank">
                    <img
                      class="img-responsive"
                      src="@/assets/img/logo-abav.png"
                      alt="Logo ABAV - Associação Brasileira de Agências de Viagens"
                      title="Associação Brasileira de Agências de Viagens"
                    >
                  </a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="theme-footer-section theme-footer- logos-footer center-block">
                  <a href="http://www.iata.org/" target="_blank">
                    <img
                      class="img-responsive"
                      src="@/assets/img/logo-iata.png"
                      alt="Logo IATA - The International Air Transport Association"
                      title="IATA - The International Air Transport Association"
                    >
                  </a>
                </div>
              </div>
              <div class="col-md-4">
                <div class="theme-footer-section theme-footer- logos-footer center-block">
                  <img
                    class="img-responsive"
                    src="@/assets/img/logo-comodo.png"
                    alt="Logo Comodo Secure"
                    title="Comodo Secure"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="theme-copyright">
      <div class="container">
        <div class="row">
          <div class="col-md-6">
            <p class="theme-copyright-text">
              Copyright &copy; 2019. Todos os direitos reservados - Desenvolvido por
              <a
                href="http://www.itmss.com.br/"
                target="_blank"
              >ITM Tecnologia</a>
            </p>
          </div>
          <div class="col-md-6">
            <ul class="theme-copyright-social">
              <li>
                <a href="https://www.facebook.com/etripviagens/" target="_blank">
                  <i class="fa fa-facebook"></i>
                </a>
              </li>
              <li>
                <a href="https://www.linkedin.com/company/etrip/" target="_blank">
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
              <!-- <li>
                <a href="https://twitter.com/tourhouse" target="_blank">
                  <i class="fa fa-twitter"></i>
                </a>
              </li> -->
              <li>
                <a href="https://www.instagram.com/etrip_viagens/" target="_blank">
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div id="warning72" :class="{active:warning72}" v-if="showWarning72">
      <section>
        <a @click="warning72 = false"><i class="fa fa-times" aria-hidden="true"></i></a>
        Bem vindo a E-trip, precisamos de 72 horas para validar seu cadastro, neste período você só poderá efetuar pesquisas, contamos com seu entendimento, caso tenha uma necessidade emergencial favor entrar em contato com nosso atendimento.
        <i class="fa fa-caret-down" aria-hidden="true"></i>
      </section>
      <i class="fa fa-circle-o-notch rotatingSpinner" aria-hidden="true"></i>
      <div @click="warning72 = !warning72">
        <i class="fa fa-clock-o" aria-hidden="true"></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    ChangeFooterClass: function() {
      if (["/login", "/password_reset", "/forgot_password", "/cards"].includes(window.location.pathname)) {
        return "fixedFooter";
      }
      else{
        return "";
      }
    },
    DisplayHiddenClass: function() {
      if (["/login", "/password_reset", "/forgot_password", "/signup"].includes(window.location.pathname)) {
        return "hideFooterElement";
      }
      else{
        return "";
      }
    }
  },
  data(){
    return {
      warning72: true,
      showWarning72: false,
    }
  },
  mounted(){
    const localUser = JSON.parse(localStorage.user);
    if(localUser.companies[0].hasLowLimitNewCompany === true){
      this.showWarning72 = true
    }
  }
};
</script>

<style scoped>
.theme-footer-section {
  padding: 15px 0 !important;
  position: relative;
}
.logo-etrip-footer {
  width: 90px;
  margin-bottom: 10px;
}
.logos-footer {
  width: 90px;
}
.hideFooterElement{
display: none;
}
#warning72{
  position: fixed;
  bottom: 20px;
  right: 110px;
  background: #8bc53f;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  z-index: 50;
}

#warning72 div{
  color: #fff; 
  font-family: 'Blogger Sans', helvetica, Arial, sans-serif;
  font-size: 50px;
  padding: 0;
  height: 60px;
  cursor: pointer;
  position: relative;
  width: 100%;
  z-index: 2;
}
#warning72 div i{
  position: absolute;
  left: 9px;
  top: 5px;
}
#warning72 .rotatingSpinner{
  position: absolute;
  left: -15px;
  top: -15px;
  font-size: 90px;
  color: #fff;
  opacity: 0.5;
  z-index: 1;
}

#warning72 section{
  color: #fff;
  display: none;
  position: absolute;
  bottom: 100px;
  right: -90px;
  width: 250px;
  text-align: left;
  background: #1b356d;
  padding: 15px;
  border-radius: 5px;
  font-size: 14px;
}

#warning72 section .fa-caret-down{
  position: absolute;
  color: #1b356d;
  bottom: -30px;
  right: 105px;
  font-size: 50px;
}

#warning72 a{
  cursor: pointer;
  position: absolute;
  right: -12px;
  top: -12px;
  color: #1b356d;
  background: #fff;
  width: 25px;
  text-align: center;
  height: 25px;
  line-height: 25px;
  border-radius: 50px;
}

#warning72.active section{
  display: block;
}

@media only screen and (min-width: 320px) {
  .fixedFooter {
    position: relative;
    width: 100%;
    bottom: 0;
    z-index: 9;
  }
}

@media only screen and (min-width: 480px) {
  .fixedFooter {
    position: relative;
    width: 100%;
    bottom: 0;
    z-index: 9;
  }
}

@media only screen and (min-width: 768px) {
  .fixedFooter {
    position: relative;
    width: 100%;
    bottom: 0;
    z-index: 9;
  }
}

@media only screen and (min-width: 992px) {
  .fixedFooter {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 9;
  }
}

@media only screen and (min-width: 1200px) {
  .fixedFooter {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 9;
  }
}
#footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
</style>
