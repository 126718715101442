<template>
  <nav
    class="navbar navbar-default navbar-inverse navbar-theme navbar-theme-abs navbar-black-transparent"
    id="main-nav" v-if="!$route.meta.hideHeader"
  >
    <div class="container">
      <div class="navbar-inner nav">
        <div class="navbar-header">
          <button
            class="navbar-toggle collapsed"
            data-target="#navbar-main"
            data-toggle="collapse"
            type="button"
            area-expanded="false"
          >
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <router-link to="/">
            <a class="navbar-brand">
              <img src="@/assets/img/logo-color.png" alt="Logo ETrip" title="Ir para Home">
            </a>
          </router-link>
        </div>
        <div class="collapse navbar-collapse" id="navbar-main">
          <ul class="nav navbar-nav navbar-left">
            <!-- <li>
              <router-link to="/hotels">
                <a>Hotéis</a>
              </router-link>
            </li>
            <li>
              <router-link to="/flights">
                <a>Passagens</a>
              </router-link>
            </li>
            <li>
              <router-link to="/cars">
                <a>Carros</a>
              </router-link>
            </li> -->
          </ul>
          <ul class="nav navbar-nav navbar-right" v-if="$store.state.isLoggedIn">
            <li class="navbar-nav-item-user dropdown" v-if="$store.getters.getUser.is_agents">
              <a href="/agent/travels/" class="dropdown-toggle count-info" target="_blank">
                <i class="fa fa-cogs navbar-nav-item-user-icon"></i>Painel de Operações
              </a>
            </li>
            <li class="navbar-nav-item-user dropdown">
              <router-link to="/travel">
                <a class="dropdown-toggle count-info">
                  <i class="fa fa-shopping-cart navbar-nav-item-user-icon"></i>Viagem
                </a>
              </router-link>
            </li>
            <li class="navbar-nav-item-user dropdown">
              <a
                class="dropdown-toggle"
                data-toggle="dropdown"
                role="button"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="fa fa-user-circle-o navbar-nav-item-user-icon"></i>Preferências
              </a>
              <ul class="dropdown-menu">
                <li>
                  <router-link to="/myaccount">
                    <a>Minha Conta</a>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'orders'}" exact>
                    <a>Meus Pedidos</a>
                  </router-link>
                </li>
                <li v-if="$store.getters.getUser.is_agents">
                  <router-link :to="{name:'orders', query:{all_orders: true}}" exact>
                    <a>Todos os Pedidos</a>
                  </router-link>
                </li>
                <li>
                  <router-link to="/company">
                    <a>Empresa</a>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'cards'}">
                    <a>Cartões</a>
                  </router-link>
                </li>
                <li v-if="$store.getters.getUser.is_manager">
                  <router-link to="/travelers">
                    <a>Viajantes</a>
                  </router-link>
                </li>
                <li>
                  <router-link :to="{name:'reports'}">
                    <a>Relatórios</a>
                  </router-link>
                </li>
                <li v-if="canApprove">
                  <router-link :to="{name:'approvals'}">
                    <a>Aprovador</a>
                  </router-link>
                </li>
                <li>
                  <a href="javascript:;" v-on:click="logout">
                    <i class="fa fa-sign-in" aria-hidden="true"></i>
                    Sair
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Logout from "../../mixins/Logout.js";
export default {
  mixins: [Logout],
  computed: {
    canApprove() {
      if (this.$store.getters.getUser.is_manager)
        return true;
        
      let groups = this.$store.getters.getUser.groups || [];
      let can_approve = false;
      groups.forEach(el => {
        console.log(el.name == 'approver', el)
        if (el.name == 'approver') {
          can_approve = true;
        }
      });
      return can_approve
    }
  }
};
</script>

<style scoped>
.navbar-theme {
  margin-bottom: 0;
  border-radius: 0;
  background: rgba(0, 0, 0, 0.4);
  border: none;
  opacity: 1;
}
.navbar-theme .navbar-toggle {
  margin-right: 0;
  margin-top: 12px;
}
.navbar-brand {
  float: left;
  height: 60px;
  padding: 13px 0px;
  font-size: 18px;
  line-height: 20px;
}
@media (min-width: 768px){
  .navbar-right {
    float: right !important;
    margin-right: -15px;
    margin-top: 5px;
  }
}
.navbar-right li a {
  color: #FFFFFF;
  text-decoration: none;
} 
.navbar-right li a i{
  color: #FFFFFF;
  text-decoration: none;
}
.navbar-theme.navbar-inverse .navbar-nav .navbar-nav-item-user-icon {
  color: #FFFFFF;
  opacity: 1;
}
.navbar-inverse .navbar-nav > li > a {
  color: #FFFFFF;
} 
.navbar-theme.navbar-inverse .navbar-nav>li:hover>a {
  color: #FFFFFF;
}
/* .navbar-theme .dropdown-menu li {
  background-color: #eeeeee;
} */
/* .navbar-theme .dropdown-menu>li>a:hover {
  background: #0093d2;
  color: #003371;
} */
/* @media (max-width: 767px){
  .navbar-inverse .navbar-nav .open .dropdown-menu > li > a {
    color: #003371;
  }
} */
@media (max-width: 992px){
  .navbar-theme.navbar-inverse .navbar-nav>li {
    border-bottom: 1px solid transparent;
  }
}
.navbar-inverse .navbar-collapse, .navbar-inverse .navbar-form {
  border-color: transparent;
}
/* .navbar-inverse .navbar-toggle .icon-bar {
    background-color: #003371;
} */
</style>
