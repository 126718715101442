// import hotel from '@/__helpers__/hotel-details'
import { axiosUpdateHeaders } from '@/axios'
import { state, mutations, SET_LOADING, RESET_LOADING } from '@/store/modules/common'

export const actions = {
  getHotel ({ commit, rootState }, hash) {
    const axios = axiosUpdateHeaders();
    commit(SET_LOADING)

    return axios.get(`/api/hotel/details/${hash}`)
      .then(response => {
        if (response.status === 200) {
          commit(RESET_LOADING)
          return Promise.resolve(response.data)
        } else {
          commit(RESET_LOADING)
          return Promise.reject(response.data)
        }
      })
      .catch(error => {
        commit(RESET_LOADING)
        return Promise.reject(error)
      })

    // TEST
    // return new Promise((resolve, reject) => {
    //   commit(SET_LOADING);
    //   window.setTimeout(() => {
    //     commit(RESET_LOADING);
    //     if (hotel) resolve(hotel);
    //     // else reject()
    //   }, 3000);
    // });
  }
}

export default {
  namespaced : true,
  state: { ...state },
  mutations: { ...mutations },
  actions
  /* getters */
}
