<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div class="col-md-10">
            <!-- Titulo -->
            <h1 class="theme-account-page-title">Empresa</h1>
            <!-- /Titulo -->
            <form @submit.prevent="update">
              <fieldset :disabled="disabledIfNotManagement" >
                <div class="row">
                  <div class="col-md-6 padding-off">
                    <div class="user-personal-data">
                      <!-- Dados pessoais -->
                      <!-- Subtitulo -->
                      <div class="col-md-12">
                        <h4 class="title-manage-account">Dados empresariais</h4>
                      </div>
                      <!-- /Subtitulo -->
                      <div class="col-md-12 padding-off">
                        <!-- CNPJ -->
                        <div class="col-md-6">
                          <div class="form-group theme-login-form-group">
                            <label>CNPJ*</label>
                            <the-mask
                              class="form-control"
                              type="text"
                              name="company_document_number"
                              v-model="company.document_number"
                              :mask="['##.###.###/####-##']"
                              v-validate="'required|min:14'"
                              :class="{'alert-danger': submitted && errors.has('company_document_number')}"
                            />
                            <span class="error-message-forms">{{ errors.first('company_document_number') }}</span>
                          </div>
                        </div>
                        <!-- /CNPJ -->
                        <!-- Telefone fixo -->
                        <div class="col-md-6">
                          <div class="form-group theme-login-form-group">
                            <label>Telefone*</label>
                            <the-mask
                              class="form-control"
                              type="text"
                              name="phone"
                              v-model="company.phone"
                              :mask="['(##) ####-####', '(##) #####-####']"
                              v-validate="'required|min:10'"
                              :class="{ 'alert-danger': submitted && errors.has('phone') }"
                            />
                            <span class="error-message-forms">{{ errors.first('phone') }}</span>
                          </div>
                        </div>
                        <!-- /Telefone fixo-->
                      </div>
                      <!-- Razão Social -->
                      <div class="col-md-12">
                        <div class="form-group theme-login-form-group">
                          <label>Razão social*</label>
                          <input
                            class="form-control"
                            type="text"
                            name="name"
                            v-model="company.name"
                            v-validate="'required'"
                            :class="{ 'alert-danger': submitted && errors.has('name') }"
                          >
                          <span class="error-message-forms">{{ errors.first('name') }}</span>
                        </div>
                      </div>
                      <!-- /Razão Social -->
                      <!-- Nome Fantasia -->
                      <div class="col-md-12">
                        <div class="form-group theme-login-form-group">
                          <label>Nome fantasia*</label>
                          <input
                            class="form-control"
                            type="text"
                            name="fantasy_name"
                            v-model="company.fantasy_name"
                            v-validate="'required'"
                            :class="{ 'alert-danger': submitted && errors.has('fantasy_name') }"
                          >
                          <span class="error-message-forms">{{ errors.first('fantasy_name') }}</span>
                        </div>
                      </div>
                      <!-- /Nome Fantasia -->
                      <!-- Dados pessoais -->
                    </div>
                  </div>
                  <div class="col-md-6 padding-off">
                    <div class="user-personal-data mt-m-50">
                      <!-- Dados Residenciais -->
                      <!-- Subtitulo -->
                      <div class="col-md-12">
                        <h4 class="title-manage-account">Endereço empresarial</h4>
                      </div>
                      <!-- /Subtitulo -->
                      <div class="col-md-12 padding-off">
                        <!-- CEP -->
                        <div class="col-md-4">
                          <div class="form-group theme-login-form-group">
                            <label>CEP*</label>
                            <the-mask
                              class="form-control"
                              type="tel"
                              name="zip_code"
                              v-model="company.address.zip_code"
                              :mask="['#####-###']"
                              v-validate="'required|min:8'"
                              :class="{ 'alert-danger': submitted && errors.has('zip_code') }"
                            />
                            <span class="error-message-forms">{{ errors.first('zip_code') }}</span>
                          </div>
                        </div>
                        <!-- /CEP -->
                        <!-- Endereço -->
                        <div class="col-md-8">
                          <div class="form-group theme-login-form-group">
                            <label>Endereço*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="street"
                              v-model="company.address.street"
                              v-validate="'required'"
                              :class="{ 'alert-danger': submitted && errors.has('street') }"
                            >
                            <span class="error-message-forms">{{ errors.first('street') }}</span>
                          </div>
                        </div>
                        <!-- /Endereço -->
                      </div>
                      <div class="col-md-12 padding-off">
                        <!-- Número -->
                        <div class="col-md-4">
                          <div class="form-group theme-login-form-group">
                            <label>Número*</label>
                            <input
                              class="form-control"
                              type="tel"
                              name="number"
                              v-model="company.address.number"
                              v-validate="'required'"
                              :class="{ 'alert-danger': submitted && errors.has('number') }"
                            >
                            <span class="error-message-forms">{{ errors.first('number') }}</span>
                          </div>
                        </div>
                        <!-- /Número -->
                        <!-- Complemento -->
                        <div class="col-md-8">
                          <div class="form-group theme-login-form-group">
                            <label>Complemento</label>
                            <input
                              class="form-control"
                              type="text"
                              v-model="company.address.complement"
                            >
                          </div>
                        </div>
                        <!-- /Complemento -->
                      </div>
                      <div class="col-md-12 padding-off">
                        <!-- Bairro -->
                        <div class="col-md-6">
                          <div class="form-group theme-login-form-group">
                            <label>Bairro*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="neighborhood"
                              v-model="company.address.neighborhood"
                              v-validate="'required'"
                              :class="{ 'alert-danger': submitted && errors.has('neighborhood') }"
                            >
                            <span class="error-message-forms">{{ errors.first('neighborhood') }}</span>
                          </div>
                        </div>
                        <!-- /Bairro -->
                        <!-- Cidade -->
                        <div class="col-md-6">
                          <div class="form-group theme-login-form-group">
                            <label>Cidade*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="city"
                              v-model="company.address.city"
                              v-validate="'required'"
                              :class="{ 'alert-danger': submitted && errors.has('city') }"
                            >
                            <span class="error-message-forms">{{ errors.first('city') }}</span>
                          </div>
                        </div>
                        <!-- /Cidade -->
                      </div>
                      <div class="col-md-12 padding-off">
                        <!-- Estado -->
                        <div class="col-md-6">
                          <div class="form-group theme-login-form-group">
                            <label>Estado*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="province"
                              v-model="company.address.province"
                              v-validate="'required'"
                              :class="{ 'alert-danger': submitted && errors.has('province') }"
                            >
                            <span class="error-message-forms">{{ errors.first('province') }}</span>
                          </div>
                        </div>
                        <!-- /Estado -->
                        <!-- País -->
                        <div class="col-md-6">
                          <div class="form-group theme-login-form-group">
                            <label>País*</label>
                            <select 
                              v-model="company.address.country_id" name="country" id="" class="form-control" v-validate="'required'" 
                              :class="{ 'alert-danger': submitted && errors.has('form-account.country') }">
                              <option :value="country.id" v-for="country in $store.state.countries">
                                {{ country.name }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <!-- <div class="col-md-6">
                          <div class="form-group theme-login-form-group">
                            <label>País*</label>
                            <input
                              class="form-control"
                              type="text"
                              name="country"
                              v-model="company.address.country"
                              v-validate="'required'"
                              :class="{ 'alert-danger': submitted && errors.has('country') }"
                            >
                            <span class="error-message-forms">{{ errors.first('country') }}</span>
                          </div>
                        </div> -->
                        <!-- /País -->
                      </div>
                      <!-- Dados Residenciais -->
                    </div>
                  </div>
                </div>
              </fieldset>
              <div class="theme-account-preferences-item-change-actions">
                <button type="submit" class="btn btn-lg btn-primary pull-right" v-if="!disabledIfNotManagement">Salvar</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import Vue from "vue";
import loader from "../components/loader/loader";
import MenuAccount from "../components/menuaccount.vue";
import lockscreen from "../components/lockscreen/index.vue";
import moment from "moment";
import alerts from "../mixins/alerts";
import { Validator } from "vee-validate";
import MixinForm from '../mixins/Forms.js';

export default {
  data() {
    return {
      company: {
        id: null,
        name: null,
        slug: null,
        fantasy_name: null,
        document_number: null,
        phone: null,
        credit_card_payment: null,
        invoice_payment: null,
        month_limit: null,
        address: {
          id: null,
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          city: null,
          province: null,
          country: null,
          zip_code: null
        }
      },
      loading: false,
      submitted: false
    };
  },
  components: {
    MenuAccount,
    lockscreen
  },
  mixins: [alerts, MixinForm],
  computed: {
    disabledIfNotManagement() {
      return this.$store.getters.getUser.is_manager == false ? true : false;
    },
    zipcode() {
      return this.company.address.zip_code;
    }
  },
  created() {
    this.loading = true;
    this.$http
      .get("/api/companies/")
      .then(response => {
        if (response.status === 200) {
          this.company = Object.assign(
            {},
            this.company,
            response.data.results[0]
          );
          if (response.data.results[0].address) {
            this.company.address = response.data.results[0].address[0];
          }
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
  },
  watch: {
    zipcode(vnew, vold) {
      if(vnew == vold || !vold) return false;
      this.loading = true;
      this.searchZipCode(vnew).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    zipcode_data(vNew) {
      this.company.address.street = vNew.address;
      this.company.address.neighborhood = vNew.district;
      this.company.address.province = vNew.state;
      this.company.address.city = vNew.city;
      this.company.address.country_id = 253;
    }
  },
  methods: {
    update(e) {
      e.preventDefault();
      this.$validator.validate().then(valid => {
        this.submitted = true;
        if (valid) {
          this.$http
            .patch("/api/companies/", this.company)
            .then(response => {
              if (response.status === 200) {
                this.showSuccessAlert();
              } else {
                new Error(response.data);
              }
            })
            .catch(error => {
              console.log(error);
            });
        }
      });
    }
  }
};
const dict = {
  custom: {
    company_document_number: {
      min: "Campo CNPJ deve conter ao menos 14 caracteres",
    },
  }
};
Validator.localize("en", dict);
</script>

<style>
</style>
