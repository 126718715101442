<template>
  <div class="theme-search-area">
    <form @submit.prevent="search_hotel">
      <div class="theme-search-area-form">
        <div class="row" data-gutter="10">
          <div class="col-md-6">
            <div
              class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-fade-white"
            >
              <div class="theme-search-area-section-inner">
                <i class="theme-search-area-section-icon lin lin-location-pin"></i>
                <autocomplete
                  class="form-control"
                  type="text"
                  name="destination"
                  v-model="destination"
                  v-validate="'required'"
                  :dataInitial="AutoCompleteDataInitial"
                  ref="hotelautocomplete"
                  input-class="{ 'alert-danger': submitted && errors.has('destination') }"
                ></autocomplete>
              </div>
              <span class="error-message-forms">{{ errors.first('destination') }}</span>
            </div>
          </div>
          <div class="col-md-3">
            <div class="row" data-gutter="10">
              <div class="col-md-12">
                <div
                  class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
                >
                  <div class="theme-search-area-section-inner">
                    <i class="theme-search-area-section-icon lin lin-calendar"></i>
                    <date-range-picker
                      class="form-control"
                      name="selectedDate"
                      v-model="selectedDate"
                      v-validate="'required'"
                      :init="init"
                      ref="rangedate"
                      :placeholder="'Datas de check-in e check-out'"
                    ></date-range-picker>
                  </div>
                  <span class="error-message-forms">{{ errors.first('selectedDate') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-2">
            <div class="row" data-gutter="10">
              <!-- <div class="col-md-6">
                <div
                  class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white quantity-selector"
                  data-increment="Quartos"
                >
                  <div class="theme-search-area-section-inner">
                    <i class="theme-search-area-section-icon lin lin-tag"></i>
                    <select class="theme-search-area-section-input" v-model="rooms">
                      <option value="1" selected>1 Quarto</option>
                      <option value="2">2 Quartos</option>
                      <option value="3">3 Quartos</option>
                      <option value="4">4 Quartos</option>
                      <option value="5">5 Quartos</option>
                      <option value="6">6 Quartos</option>
                      <option value="7">7 Quartos</option>
                      <option value="8">8 Quartos</option>
                    </select>
                  </div>
                </div>
              </div> -->
              <div class="col-md-12">
                <div
                  class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white quantity-selector"
                  data-increment="Adultos"
                >
                  <div class="theme-search-area-section-inner">
                    <i class="theme-search-area-section-icon lin lin-people"></i>
                    <select class="theme-search-area-section-input" v-model="adults">
                      <option value="1" selected>1 Adulto</option>
                      <option value="2">2 Adultos</option>
                      <option value="3">3 Adultos</option>
                      <option value="4">4 Adultos</option>
                      <option value="5">5 Adultos</option>
                      <option value="6">6 Adultos</option>
                      <option value="7">7 Adultos</option>
                      <option value="8">8 Adultos</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-1">
            <button
              type="submit"
              class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary"
            >
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import Autocomplete from "./fields/hotels/Autocomplete.vue";
import DateRangePicker from "./fields/DateRangePicker.vue";
import moment from "moment";
import RoomsField from "./fields/hotels/Rooms.vue";
import AdultsField from "./fields/hotels/Adults.vue";
import alerts from "../mixins/alerts";

export default {
  data() {
    return {
      destination: null,
      selectedDate: null,
      rooms: 1,
      adults: 1,
      init: {},
      AutoCompleteDataInitial: [],
      submitted: false
    };
  },
  components: {
    Autocomplete,
    DateRangePicker,
    RoomsField,
    AdultsField
  },
  beforeMount() {
    let params = this.$route.params;

    if (!Object.keys(params).length) return false;

    this.init = {
      startDate: params.dt_start,
      endDate: params.dt_end
    };

    if (!this.destination) {
      this._fillAutoComplete();
      this.fillAdults();
      // this.fillRooms();
    }
  },
  computed: {
    originalDateStart() {
      return this.dateBR28901(this.selectedDate.dateStart);
    },
    originalDateEnd() {
      return this.dateBR28901(this.selectedDate.dateEnd);
    }
  },
  methods: {
    _fillAutoComplete(params) {
      if( this.$route.name == 'hotelslist'){
        if (params) {
          this.init = {
            startDate: params.startDate.format("YYYY-MM-DD"),
            endDate: params.endDate.format("YYYY-MM-DD")
          };
          
          if(params.startDate || params.endDate) {
            this.selectedDate = {
              dateStart: params.startDate,
              dateEnd: params.endDate
            };
          }
        } else {
          params = this.$route.params;
        }
  
        if (!Object.keys(params).length) return false;
  
        let text = params.destination
          ? `${params.destination}, ${params.city}`
          : params.city;
  
        // later we need adjust this type. Workaround for now.
        // bug: after refresh browser we need repopulate the select2 data.
        // this cause an bug because we dont have type.
        this.AutoCompleteDataInitial = [
          {
            text: text,
            id: 1,
            el: {
              hotel_name: params.destination ? params.destination : "",
              city_name: params.city,
              type: params.destination ? "hotel" : "city"
            }
          }
        ];
  
        this.destination = this.AutoCompleteDataInitial[0];
      }
    },
    fillAdults() {
      this.adults = parseInt(this.$route.params.adults);
    },
    fillRooms(){
      this.rooms = parseInt(this.$route.params.room);
    },
    dateBR28901(date) {
      const DATE_BR_FORMAT = "DD/MM/YYYY";
      const DATE_ISO_8601 = "YYYY-MM-DD";

      return moment(date, DATE_BR_FORMAT).format(DATE_ISO_8601);
    },
    submit(e) {
      this.$nextTick(() => {
        let dt_start = this.dateBR28901(this.selectedDate.dateStart);
        let dt_end = this.dateBR28901(this.selectedDate.dateEnd);

        let rooms = parseInt(this.rooms);
        let adults = parseInt(this.adults);

        let hotel_name = this.destination.el.hotel_name;

        let urls = {
          hotel: `/hotels/search/${dt_start}/${dt_end}/${
            this.destination.el.city_name
          }/${rooms}/${adults}/${hotel_name}`,
          region: `/hotels/search/${dt_start}/${dt_end}/${
            this.destination.el.city_name
          }/${rooms}/${adults}`,
          city: `/hotels/search/${dt_start}/${dt_end}/${
            this.destination.el.city_name
          }/${rooms}/${adults}`
        };

        this.$router.push(urls[this.destination.el.type]);
      });
    },

    setPredefinedCitySearch(city) {
      let params = {
        city: city,
        startDate: moment(moment()).add(1, "days"),
        endDate: moment(moment()).add(5, "days")
      };
      this._fillAutoComplete(params);
      this.$refs.hotelautocomplete.setSelect();
      this.$refs.rangedate.setInitDate();
      this.submit();
    },

    search_hotel(e) {
      e.preventDefault();
      this.$validator.validate().then(valid => {
        this.submitted = true;
        if (valid) {
          this.submit(e);
        } else {
          this.$swal({
            title: "Favor verificar os campos de busca",
            type: "error",
            confirmButtonText: "SAIR"
          });
        }
      });
    }
  }
};
</script>


<style>
</style>
