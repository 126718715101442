/*
 *
 */
export const initialState = {
  name: '',
  path: ''
};

export const state = {
  current: { ...initialState },
  previous: { ...initialState }
};

export const mutations = {
  setCurrent(state, data) {
    const {name, path} = data;
    state.current = {
      name,
      path
    }
  },
  setPrevious(state, data) {
    const {name, path} = data;
    state.previous = {
      name,
      path
    }
  }
};

export const actions = {
  saveToFrom(context, data) {
    context.commit('setCurrent', data.to);
    context.commit('setPrevious', data.from);
  }
};

export default {
  namespaced : true,
  state,
  mutations,
  actions
  /* getters */
};
