<template>
    <ul class="list-unstyled list-inline">
      <li class="text-center">
        <i class="color-gray-light-mid fa fa-male" title="Ocupantes"></i><br>
        <span>{{ passengers || 0 }}</span>
      </li>
      <li class="text-center">
        <i class="color-gray-light-mid fa fa-cog" title="Câmbio"></i><br>
        <span>{{ transmission }}</span>
      </li>
      <li
        v-if="airConditioner"
      class="text-center">
        <i class="color-gray-light-mid fa fa-snowflake-o" title="Ar-condicionado"></i><br>
        <span>A/C</span>
      </li>
      <li class="text-center">
        <i class="color-gray-light-mid fa fa-car" title="Portas"></i><br>
        <span>{{ doors }} portas</span>
      </li>
    </ul>
</template>

<script>
export default {
  props: [
    'passengers',
    'transmission',
    'airConditioner',
    'doors'
  ]
};
</script>
