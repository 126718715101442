<template>
  <div class="mt-10 mb-10">
    <div class="col-sm-6">
      <div class="hidden-xs box-list-item border-bottom size-18">
        Total <span class="pull-right weight-mid">{{ total | brl_price }}</span>
      </div>
      <div class="visible-xs-block box-list-item size-18">
        Total <span class="pull-right weight-mid">{{ total | brl_price }}</span>
      </div>
    </div>
    <div class="col-sm-6">
      <div class="col-sm-10">
        <div class="form-group theme-payment-page-form-item">
          <i class="fa fa-angle-down"></i>
          <select
            v-model="creditcard_flight"
            @change="handleSelectCreditcard"
            name="creditcard_flight"
            class="form-control"
            :class="{ 'alert-danger': !validation }">
            <option value="">Selecione o cartão de crédito</option>
            <option
              v-for="card in cards"
              :key="card.id"
              :value="card">
              {{ card.name }} **** **** **** {{ card.partial_number }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-12 mt-10">
        <a
          @click.prevent="$emit('handleParentAction', 'card-register')"
          href="#"
          class="btn-link size-14 weight-normal mt-20">+Adicionar Novo Cartão</a>
      </div>
    </div>

    <article
      v-for="(item, index) in items"
      :key="`checkout-flight-item-${index}`"
      class="col-xs-12">
      <div class="box-list-item border-bottom clearfix">
        <div
          :class="[{ 'col-sm-4': item.flight.length === 1 }, { 'col-sm-2': item.flight.length > 1 }]"
          class="col-xs-6 padding-off">
          <flight-direction
            :segments="getFlightsByDirection(item.flight, 'outbound')"
            :travelers="item.travelers"></flight-direction>
        </div>
        <div
          v-if="item.flight.length > 1"
          :class="[{ 'col-sm-4': item.flight.length === 1 }, { 'col-sm-2': item.flight.length > 1 }]"
          class="col-xs-6 padding-off">
          <flight-direction
            :segments="getFlightsByDirection(item.flight, 'inbound')"
            :travelers="item.travelers"></flight-direction>
        </div>

        <div class="hidden-xs col-sm-2 text-right">
          <div class="size-12 weight-lighter">
            Tarifa e Taxa de embarque
          </div>
          <div class="size-14 weight-bold">
            {{ item.values['Total'].amount | brl_price}}
          </div>
          <div class="size-12 weight-lighter">
            Taxa de Serviço<sup>[2]</sup>
          </div>
          <div class="size-14 weight-bold">
            {{ item.values['FEE'].amount | brl_price}}
          </div>
        </div>
        <!-- MOBILE -->
        <div class="visible-xs-block clearfix">
          <div class="col-xs-8 padding-off size-12 weight-lighter">
            Tarifa e Taxa de embarque
          </div>
          <div class="col-xs-4 padding-off size-14 weight-bold text-right">
            {{ item.values['Total'].amount | brl_price}}
          </div>
        </div>
        <div class="visible-xs-block clearfix">
          <div class="col-xs-8 padding-off size-12 weight-lighter">
            Taxa de Serviço*
          </div>
          <div class="col-xs-4 padding-off size-14 weight-bold text-right">
            {{ item.values['FEE'].amount | brl_price}}
          </div>
        </div>
        <!-- // MOBILE -->
        <div class="col-sm-6 mb-10">
          <div class="col-sm-8">
            <label>Parcelamento:</label>
            <installments-info
              :flight="item"
              :credit_card="creditcard_flight"
              @input="(selected_installment) => {
                item.selected_installment = selected_installment;
                handleSelectedInstallment(item);
              }"></installments-info>
          </div>
        </div>
      </div>
    </article>

    <div class="col-xs-12 mt-10 mb-10">
      <p class="col-xs-12 size-11 weight-lighter color-gray-light-mid">
        <sup>[2]</sup> O débito referente a taxa de serviço será cobrado à vista separadamente do valor da passagem no seu cartão de crédito
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import FlightDirection from '@/views/checkout/FlightDirection';
import InstallmentsInfo from "@/components/items/flight/InstallmentsInfo.vue";

export default {
  components: {
    FlightDirection,
    InstallmentsInfo
  },
  props: [
    'order',
    'items',
    'validation',
    'creditcards'
  ],
   data () {
    return {
      creditcard_flight: ''
    }
  },
  computed: {
    ...mapGetters('reserve', ['totalByOrder']),
    total () {
      return this.totalByOrder('flights')
    },
    cards () {
      return this.creditcards.filter((card) => !card.corporate_virtual_hotel);
    }
  },
  methods: {
    ...mapMutations('reserve', {
      'setFlightInstallments': 'SET_INSTALLMENTS'
    }),
    handleSelectedInstallment (item) {
      this.setFlightInstallments(item);
    },
    handleSelectCreditcard () {
      const data = {
        target: {
          value: this.creditcard_flight.id,
          name: 'creditcard_flight'
        }
      }

      this.$emit('input', data);
    },
    getFlightsByDirection (flight, direction) {
      return flight.filter(flight => flight.direction === direction)
    }
  }
};
</script>
