<template>
  <section
    :class="[{ 'hero': hero }]"
    class="checkout-steps">
    <ul
      role="list"
      aria-label="Checkout"
      class="list-unstyled list-inline">
      <li :class="{'active' : (routes.indexOf(current.name) !== -1) }">
        <span class="checkout-steps-number">1</span>
        <br class="visible-xs-block">Detalhes
      </li>
      <li class="checkout-steps-divider"></li>
      <li :class="{'active' : current.name === 'travel'}">
        <template v-if="current.name === 'checkout'">
          <router-link to="/travel">
            <span class="checkout-steps-number">2</span>
            <br class="visible-xs-block">Confira sua viagem
          </router-link>
        </template>
        <template v-else>
          <span class="checkout-steps-number">2</span>
          <br class="visible-xs-block">Confira sua viagem
        </template>
      </li>
      <li class="checkout-steps-divider"></li>
      <li :class="{'active' : current.name === 'checkout'}">
        <template v-if="current.name !== 'checkout' && total">
          <router-link to="/checkout">
            <span class="checkout-steps-number">3</span>
            <br class="visible-xs-block">Pagamento
          </router-link>
        </template>
        <template v-else>
          <span class="checkout-steps-number">3</span>
          <br class="visible-xs-block">Pagamento
        </template>
      </li>
    </ul>
  </section>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: [ 'hero' ],
  computed: {
    ...mapState('reserve', [
      'total'
    ]),
    ...mapState('navigation', [
      'previous',
      'current'
    ]),
    ...mapState('orders', [
      'routes'
    ])
  },
};
</script>

<style lang="scss">
@import '@/scss/components/template/CheckoutSteps';
</style>
