<template>
    <div class="row">
        <div class="col-md-12 padding-off">
            <div class="user-personal-data">
                <div class="col-md-12">
                    <h4 class="title-manage-account">Últimas indicações</h4>
                </div>
                <div class="col-md-12">
                    <div class="theme-account-history table-responsive">
                        <table class="table">
                            <thead>
                            <tr>
                                <th class="text-center">Empresa indicada</th>
                                <th class="text-center">Bônus</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr class="text-center">
                                <td colspan="2">Nenhuma movimentação encontrada.</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "last-indications"
    }
</script>

<style scoped>

</style>