<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div v-if="order" class="col-md-10">
            <div class="col-md-12 padding-off">
              <h1 class="theme-account-page-title">Pedido nº {{order.number}}</h1>
            </div>
            <div class="col-md-12 theme-account-history padding-off">
              <!-- Titulo -->
              <h4 class="title-manage-account">Dados do pedido</h4>
              <!-- /Titulo -->
              <div class="col-md-6 padding-left-off theme-account-history">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <td>Data do pedido</td>
                      <td>{{order.creation_date|date_to_human}}</td>
                    </tr>
                    <tr>
                      <td>Solicitante</td>
                      <td>{{order.requester.full_name}}</td>
                    </tr>
                    <tr>
                      <td>E-mail</td>
                      <td>{{order.requester.email}}</td>
                    </tr>
                    <tr>
                      <td>Celular</td>
                      <td>{{order.requester.mobile_phone}}</td>
                    </tr>
                    <!-- <tr>
                      <td>Status do pedido</td>
                      <td>
                        <i
                          class="fa fa-lg"
                          :class="{
                          'fa-check-circle text-success': order.status.slug==='finished',
                          'fa-times-circle text-danger': order.status.slug==='canceled',
                          'fa-clock-o text-warning': order.status.slug==='processing',
                        }"
                        ></i>
                        &nbsp;{{ order.status.name }}
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
              <div class="col-md-6 padding-right-off theme-account-history">
                <table class="table table-sm">
                  <tbody>
                    <tr>
                      <td>Total</td>
                      <td>{{ order.total|brl_price }}</td>
                    </tr>
                    <tr>
                      <td>Desconto</td>
                      <td>R$ 0,00</td>
                    </tr>
                    <tr>
                      <td>Pagar no fornecedor</td>
                      <td>{{ order.total|brl_price }}</td>
                    </tr>
                    <!-- <tr>
                      <td>Pagar na e-trip</td>
                      <td>R$ 0,0</td>
                    </tr> -->
                    <tr>
                      <td>Cartão</td>
                      <td>************ {{ creditcard }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- <button
                class="btn btn-danger pull-right _tt-uc mt-10"
                v-if="order.status.slug!='canceled'"
                v-on:click="cancelOrder()"
              >Cancelar pedido</button> -->
            </div>

            <div class="col-md-12 padding-off mt-10">
              <!-- Titulo -->
              <h4 class="title-manage-account">Itens</h4>
              <!-- /Titulo -->
              <div id="accordion">
                <template v-for="item in order.order_item">
                  <hotel-item
                    :item="item"
                    :key="item.id"
                    :fetchParent="fetchData"
                    v-if="item.product==1"
                  ></hotel-item>

                  <!-- iterate over  fligt item !!!!! (FLIGHT)  -->
                  <flight-item :item="item" :key="item.id" v-if="item.product==2" :fetchParent="fetchData"></flight-item>
                  <!-- -->
                  <car-item :item="item" :key="item.id" v-if="item.product === 3"></car-item>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import MenuAccount from "../components/menuaccount.vue";
import HotelItem from "../components/items/order/hotel.vue";
import FlightItem from "../components/items/order/flight.vue";
import CarItem from "../components/items/order/car";
import lockscreen from "../components/lockscreen/index";

export default {
  data() {
    return {
      order: this.$route.params.order,
      loading: false
    };
  },
  components: {
    MenuAccount,
    HotelItem,
    FlightItem,
    CarItem,
    lockscreen,
  },
  computed: {
    creditcard() {
      if (this.order.order_item[0].creditcard) {
        return `${this.order.order_item[0].creditcard.partial_number}
                ${this.order.order_item[0].creditcard.card_type}`;
      }
      return "";
    }
  },
  beforeCreate() {},
  methods: {
    cancelOrder() {
      this.loading = true;
      this.$http({
        url: "/api/user/order/cancel",
        method: "POST",
        data: {
          order_number: this.order.number
        }
      })
        .then(response => {
          this.loading = false;
          this.$swal({
            title: "Pedido cancelado com sucesso!",
            type: "success",
            showConfirmButton: true
          });
          this.fetchData();
        })
        .catch(error => {
          this.loading = false;
          this.$swal({
            title: error.response.data.message,
            type: "error",
            showConfirmButton: true
          });
        });
    },
    fetchData() {
      this.loading = true;
      let order_id = this.$route.params.id;
      this.$http({
        url: `/api/user/order/${order_id}`,
        method: "GET"
      }).then(response => {
        this.loading = false;
        this.order = response.data;
      });
    }
  },
  beforeMount() {
    this.fetchData();
  }
};
</script>

<style scoped>
.theme-search-area-section-input {
  display: block;
  width: 100%;
  border: none !important;
  outline: none !important;
  height: 55px;
  padding-left: 16px;
  font-size: 16px;
  background: none;
}
.theme-search-area-section-select {
  display: block;
  width: 100%;
  border: none !important;
  outline: none !important;
  height: 55px;
  padding-left: 12px;
  font-size: 16px;
  background: none;
  position: relative;
  color: #7a7a7a;
}
.theme-account-history table {
  margin-bottom: 0;
  font-size: 14px;
  background: #fff;
  border-radius: 0;
}
.change-modal {
  border-radius: 0;
}
.change-modal-header {
  border: 0 !important;
}
.change-modal-footer {
  border: 0 !important;
}
.content-change-modal {
  height: 350px;
  overflow-y: auto;
  font-size: 14px;
  padding-left: 40px;
  padding-right: 40px;
}
.changes-travalers {
  padding: 10px;
  background-color: #ffffff;
}
</style>
