<template>
  <section>
    <!-- Collapse Item -->
    <collapse-item
      v-if="items.length > 0"
      :key="`checkout-list-collapse-${indexKey}`"
      :class="{ 'has-error': submitted && !validation }"
      class="ui-collapse-item"
      @handleParentAction="handleDialog">
      <template v-slot:trigger="{ active, handleCollapse }">
        <div class="ui-collapse-header" role="tab">
          <a
            @click.prevent="handleCollapse"
            :aria-expanded="active"
            :aria-controls="`checkout-list-collapse-${indexKey}`"
            :href="`#checkout-list-collapse-${indexKey}`"
            role="button">
            <span>
              <i :class="`fa fa-${order.ico}`"></i> {{ order.name }}<span v-if="!validation" class="color-magenta">*</span>
            </span>
            <i v-if="!active" class="fa fa-chevron-down"></i>
            <i v-if="active" class="fa fa-chevron-up"></i>
          </a>
        </div>
      </template>
      <template v-slot:content="{ active, handleParentAction }">
        <div
          :aria-labelledby="`checkout-list-collapse-${indexKey}`"
          :id="`checkout-list-collapse-${indexKey}`"
          :class="[{ 'active' : active }]"
          role="tabpanel"
          class="ui-collapse-panel">

          <slot
            name="content"
            :order="order"
            :items="items"
            :validation="validation"></slot>
        </div>
      </template>
    </collapse-item>
    <!-- // Collapse Item -->
  </section>
</template>

<script>
import CollapseItem from '@/components/ui/CollapseItem';

export default {
  components : {
    CollapseItem,
  },
  props: [
    'indexKey',
    'order',
    'items',
    'validation',
    'submitted'
  ],
  methods: {
    handleDialog (component) {
      this.$emit('handleDialog', component);
    }
  }
};
</script>
