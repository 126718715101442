<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div class="col-md-10">
            <!-- Titulo -->
            <h1 class="theme-account-page-title">Minha conta</h1>
            <!-- /Titulo -->
            <form @submit.prevent="update('form-account')" data-vv-scope="form-account">
              <div class="row">
                <div class="col-md-6 padding-off">
                  <div class="user-personal-data">
                    <!-- Dados pessoais -->
                    <!-- Subtitulo -->
                    <div class="col-md-12">
                      <h4 class="title-manage-account">Dados pessoais</h4>
                    </div>
                    <!-- /Subtitulo -->
                    <!-- Nome completo -->
                    <div class="col-md-12">
                      <div class="form-group theme-login-form-group">
                        <label>Nome completo*</label>
                        <input
                          class="form-control"
                          type="text"
                          name="full_name"
                          v-model="account.full_name"
                          v-validate="'required|alpha_spaces|min:2'"
                          :class="{'alert-danger': submitted && errors.has('form-account.full_name')}"
                        >
                        <span
                          class="error-message-forms"
                        >{{ errors.first('form-account.full_name') }}</span>
                      </div>
                    </div>
                    <!-- /Nome completo -->
                    <!-- E-mail -->
                    <div class="col-md-12">
                      <div class="form-group theme-login-form-group">
                        <label>E-mail*</label>
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          v-model="account.email"
                          v-validate="'required|email'"
                          :class="{'alert-danger': submitted && errors.has('form-account.email')}"
                          disabled='disabled'
                        >
                        <span class="error-message-forms">{{ errors.first('form-account.email') }}</span>
                      </div>
                    </div>
                    <!-- /E-mail -->
                    <!-- E-mail Secundário -->
                    <div class="col-md-12">
                      <div class="form-group theme-login-form-group">
                        <label>E-mail Adicional</label>
                        <input
                          class="form-control"
                          type="text"
                          name="additional_email"
                          v-validate="'email'"
                          v-model="account.additional_email"
                          :class="{'alert-danger': submitted && errors.has('form-account.additional_email')}"
                        >
                        <span
                            class="error-message-forms"
                          >{{ errors.first('form-account.additional_email') }}</span>
                      </div>
                    </div>
                    <!-- /E-mail Secundário -->
                    <div class="col-md-12 padding-off">
                      <!-- CPF -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>CPF*</label>
                          <the-mask
                            class="form-control"
                            type="tel"
                            name="document_number"
                            v-model="account.document_number"
                            :mask="['###.###.###-##']"
                            v-validate="'required|min:11'"
                            :class="{'alert-danger': submitted && errors.has('form-account.document_number')}"
                          />
                          <span
                            class="error-message-forms"
                          >{{ errors.first('form-account.document_number') }}</span>
                        </div>
                      </div>
                      <!-- /CPF -->
                      <!-- Data de Nascimento -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>Data de nascimento*</label>
                          <the-mask
                            class="form-control"
                            type="text"
                            name="birth_date"
                            v-model="account.birth_date"
                            :mask="['##/##/####']"
                            v-validate="'required|min:8'"
                            :class="{'alert-danger': submitted && errors.has('form-account.birth_date')}"
                          />
                          <span
                            class="error-message-forms"
                          >{{ errors.first('form-account.birth_date') }}</span>
                        </div>
                      </div>
                      <!-- /Data de Nascimento -->
                    </div>
                    <div class="col-md-12 padding-off">
                      <!-- Telefone fixo -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>Telefone*</label>
                          <the-mask
                            class="form-control"
                            type="tel"
                            name="phone"
                            v-model="account.phone"
                            :mask="['(##) ####-####']"
                            v-validate="'min:10'"
                            :class="{'alert-danger': submitted && errors.has('form-account.phone')}"
                          />
                          <span class="error-message-forms">{{ errors.first('form-account.phone') }}</span>
                        </div>
                      </div>
                      <!-- /Telefone fixo -->
                      <!-- Telefone celular -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>Celular*</label>
                          <the-mask
                            class="form-control"
                            type="tel"
                            name="mobile_phone"
                            v-model="account.mobile_phone"
                            :mask="['(##) ####-####', '(##) #####-####']"
                            v-validate="'required|min:10'"
                            :class="{'alert-danger': submitted && errors.has('form-account.mobile_phone')}"
                          />
                          <span
                            class="error-message-forms"
                          >{{ errors.first('form-account.mobile_phone') }}</span>
                        </div>
                      </div>
                      <!-- /Telefone celular -->
                    </div>
                    <!-- Gênero -->
                    <div class="col-md-12">
                      <div class="form-group theme-login-form-group">
                        <div>Gênero*</div>
                        <div class="btn-group theme-search-area-options-list">
                          <p-radio
                            class="p-default p-round p-smooth p-plain"
                            name="genero"
                            id="Masculino"
                            :value="1"
                            v-model="account.gender"
                            color="primary-o"
                            v-validate="'required|included:1,2'"
                          >&nbsp;Masculino</p-radio>
                          <p-radio
                            class="p-default p-round p-smooth p-plain"
                            name="genero"
                            id="Feminino"
                            :value="2"
                            v-model="account.gender"
                            color="primary-o"
                          >&nbsp;Feminino</p-radio>
                        </div>
                        <span class="error-message-forms">{{ errors.first('form-account.genero') }}</span>
                      </div>
                    </div>
                    <!-- /Gênero -->
                    <!-- Dados pessoais -->
                  </div>
                </div>
                <div class="col-md-6 padding-off">
                  <!-- Dados Residenciais -->
                  <!-- Subtitulo -->
                  <div class="col-md-12">
                    <h4 class="title-manage-account">Endereço residencial</h4>
                  </div>
                  <!-- /Subtitulo -->
                  <div class="col-md-12 padding-off">
                    <!-- CEP -->
                    <div class="col-md-4">
                      <div class="form-group theme-login-form-group">
                        <label >CEP*</label>
                        <the-mask
                          class="form-control"
                          type="tel"
                          name="zip_code"
                          v-model="account.address.zip_code"
                          :mask="['#####-###']"
                          v-validate="'required|min:8'"
                          :class="{ 'alert-danger': submitted && errors.has('form-account.zip_code') }"
                        />
                        <span
                          class="error-message-forms"
                        >{{ errors.first('form-account.zip_code') }}</span>
                      </div>
                    </div>
                    <!-- /CEP -->
                    <!-- Endereço -->
                    <div class="col-md-8">
                      <div class="form-group theme-login-form-group">
                        <label>Endereço*</label>
                        <input
                          class="form-control"
                          type="text"
                          name="street"
                          v-model="account.address.street"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('form-account.street') }"
                        >
                        <span class="error-message-forms">{{ errors.first('form-account.street') }}</span>
                      </div>
                    </div>
                    <!-- /Endereço -->
                  </div>
                  <div class="col-md-12 padding-off">
                    <!-- Número -->
                    <div class="col-md-4">
                      <div class="form-group theme-login-form-group">
                        <label>Número*</label>
                        <input
                          class="form-control"
                          type="tel"
                          name="number"
                          v-model="account.address.number"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('form-account.number') }"
                        >
                        <span class="error-message-forms">{{ errors.first('form-account.number') }}</span>
                      </div>
                    </div>
                    <!-- /Número -->
                    <!-- Complemento -->
                    <div class="col-md-8">
                      <div class="form-group theme-login-form-group">
                        <label>Complemento</label>
                        <input
                          class="form-control"
                          type="text"
                          v-model="account.address.complement"
                        >
                      </div>
                    </div>
                    <!-- /Complemento -->
                  </div>
                  <div class="col-md-12 padding-off">
                    <!-- Bairro -->
                    <div class="col-md-6">
                      <div class="form-group theme-login-form-group">
                        <label>Bairro*</label>
                        <input
                          class="form-control"
                          type="text"
                          name="neighborhood"
                          v-model="account.address.neighborhood"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('form-account.neighborhood') }"
                        >
                        <span
                          class="error-message-forms"
                        >{{ errors.first('form-account.neighborhood') }}</span>
                      </div>
                    </div>
                    <!-- /Bairro -->
                    <!-- Cidade -->
                    <div class="col-md-6">
                      <div class="form-group theme-login-form-group">
                        <label>Cidade*</label>
                        <input
                          class="form-control"
                          type="text"
                          name="city"
                          v-model="account.address.city"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('form-account.city') }"
                        >
                        <span class="error-message-forms">{{ errors.first('form-account.city') }}</span>
                      </div>
                    </div>
                    <!-- /Cidade -->
                  </div>
                  <div class="col-md-12 padding-off">
                    <!-- Estado -->
                    <div class="col-md-6">
                      <div class="form-group theme-login-form-group">
                        <label>Estado*</label>
                        <input
                          class="form-control"
                          type="text"
                          name="province"
                          v-model="account.address.province"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('form-account.province') }"
                        >
                        <span
                          class="error-message-forms"
                        >{{ errors.first('form-account.province') }}</span>
                      </div>
                    </div>
                    <!-- /Estado -->
                    <!-- País -->
                    <div class="col-md-6">
                      <div class="form-group theme-login-form-group">
                        <label>País*</label>
                        <select 
                          v-model="account.address.country" name="country" id="" class="form-control" v-validate="'required'" 
                          :class="{ 'alert-danger': submitted && errors.has('form-account.country') }">
                          <option :value="country.id" v-for="country in $store.state.countries">
                            {{ country.name }}
                          </option>
                        </select>
                        <!-- <input
                          class="form-control"
                          type="text"
                          name="country"
                          v-model="account.address.country"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('form-account.country') }"
                        > -->
                        <span class="error-message-forms">{{ errors.first('form-account.country') }}</span>
                      </div>
                    </div>
                    <!-- /País -->
                  </div>
                  <!-- Dados Residenciais -->
                  <div class="col-md-12">
                    <div
                      class="theme-account-preferences-item-change-actions mt-80 hidden-xs hidden-sm"
                    >
                      <button type="submit" class="btn btn-lg btn-primary pull-right">Salvar</button>
                    </div>
                    <div class="theme-account-preferences-item-change-actions hidden-md hidden-lg">
                      <button type="submit" class="btn btn-lg btn-primary btn-100">Salvar</button>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
                <div class="col-md-6 padding-off">
                  <!-- Nova senha -->
                  <!-- Subtitulo -->
                  <div class="col-md-12">
                    <h4 class="title-manage-account mt-30">Alterar senha</h4>
                  </div>
                  <div class="col-md-12">
                    <div class="theme-account-preferences-item-change-actions">
                      <a
                        class="btn btn-lg btn-primary-inverse btn-100"
                        data-toggle="modal"
                        data-target="#modal-new-password"
                      >Clique aqui para alterar sua senha</a>
                    </div>
                  </div>
                  <!-- /Subtitulo -->
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <!-- Modal nova senha -->
      <div
        class="modal fade"
        id="modal-new-password"
        tabindex="-1"
        role="dialog"
        aria-labelledby="modal-changeLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog" role="document">
          <form
            @submit.prevent="update_password('form-password')"
            data-vv-scope="form-password"
            autocomplete="off"
          >
            <div class="modal-content change-modal">
              <div class="modal-header change-modal-header">
                <button
                  type="button"
                  id="hideModalNewPassword"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <h3 class="modal-title text-center" id="exampleModalLabel">Alterar senha</h3>
              </div>
              <div class="modal-body">
                <div class="form-group theme-login-form-group">
                  <div class="mt-15">
                    <label>Senha Atual*</label>
                    <input
                      class="form-control"
                      type="password"
                      name="password"
                      v-model="password"
                      v-validate="'required'"
                      :class="{ 'alert-danger': submitted && errors.has('form-password.password') }"
                    >
                    <span class="error-message-forms">{{ errors.first('form-password.password') }}</span>
                  </div>
                  <div class="mt-15">
                    <label>Nova senha*</label>
                    <input
                      @focus="open_helper = true"
                      class="form-control"
                      type="password"
                      name="password1"
                      v-model="password1"
                      v-validate="'required|min:8'"
                      ref="password1"
                      :class="{ 'alert-danger': submitted && errors.has('form-password.password1') }"
                    >
                    <span class="error-message-forms">{{ errors.first('form-password.password1') }}</span>
                  </div>
                  <div class="mt-15">
                    <label>Confirmar nova senha*</label>
                    <input
                      @focus="open_helper = true"
                      class="form-control"
                      type="password"
                      name="password2"
                      v-model="password2"
                      v-validate="'required|confirmed:password1|min:8'"
                      data-vv-as="password1"
                      :class="{ 'alert-danger': submitted && errors.has('form-password.password2') }"
                    >
                    <span class="error-message-forms">{{ errors.first('form-password.password2') }}</span>
                  </div>

                  <password-helper v-if="open_helper" />

                </div>
              </div>
              <div class="clearfix"></div>
              <div class="modal-footer change-modal-footer">
                <div class="col-md-6 padding-left-off">
                  <button
                    type="button"
                    class="btn _tt-uc btn-primary-inverse btn-lg mt-10 btn-block"
                    data-dismiss="modal"
                    v-on:click="clean_passwords"
                  >Cancelar</button>
                </div>
                <div class="col-md-6 padding-right-off">
                  <button
                    type="submit"
                    class="btn _tt-uc btn-primary btn-lg mt-10 btn-block"
                  >Salvar nova senha</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- /Modal nova senha -->
    </div>
    <lockscreen :loading="loading"></lockscreen>
    <!-- /Modelo de página -->
  </div>
</template>

<script>
import Vue from "vue";
import loader from "../components/loader/loader";
import Logout from "../mixins/Logout.js";
import MenuAccount from "../components/menuaccount.vue";
import lockscreen from "../components/lockscreen/index.vue";
import moment from "moment";
import alerts from "../mixins/alerts";
import { Validator } from "vee-validate";
import MixinForm from '../mixins/Forms.js';
import PasswordHelper from "@/components/PasswordHelper.vue";

export default {
  data: function() {
    return {
      account: {
        full_name: null,
        fantasy_name: null,
        document_number: null,
        birth_date: null,
        gender: null,
        mobile_phone: null,
        phone: null,
        email: null,
        additional_email: null,
        frequency_purchases: null,
        buy_flights: null,
        buy_hotels: null,
        rent_cars: null,
        address: {
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          city: null,
          province: null,
          country: null,
          zip_code: null
        }
      },
      password: null,
      password1: null,
      password2: null,
      loading: false,
      submitted: false,
      open_helper: false
    };
  },
  components: {
    MenuAccount,
    lockscreen,
    PasswordHelper,
  },
  mixins: [Logout, alerts, MixinForm],
  mounted() {
    this.loading = true;
    this.$http
      .get("/api/user/?id=" + this.$store.state.user.id)
      .then(response => {
        if (response.status === 200) {
          this.account = Object.assign({}, this.account, response.data);
          if (this.account.birth_date) {
            this.account.birth_date = Vue.filter("br_date")(
              this.account.birth_date
            );
          }
        }
        setTimeout(() => {
          this.loading = false;
        }, 1000);
      })
      .catch(error => {
        console.log(error);
      });
  },
  computed: {
    zipcode() {
      return this.account.address.zip_code;
    }
  },
  watch: {
    zipcode(vnew, vold) {
      if(vnew == vold || !vold) return false;
      this.loading = true;
      this.searchZipCode(vnew).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    zipcode_data(vNew) {
      this.account.address.street = vNew.address;
      this.account.address.neighborhood = vNew.district;
      this.account.address.province = vNew.state;
      this.account.address.city = vNew.city;
      this.account.address.country = 253;
    }
  },
  methods: {
    update(scope) {
      this.$validator.validateAll(scope).then(valid => {
        this.submitted = true;
        let router = this.$router;
        if (valid) {
          if (this.account.birth_date) {
            this.account.birth_date = moment(
              this.account.birth_date,
              "DD/MM/YYYY"
            ).format("DDMMYYYY");
          }
          this.$http
            .post("/api/user/update/", this.account)
            .then(response => {
              if (response.status === 200) {
                this.showSuccessAlert();
              } else {
                new Error(response.data);
              }
            })
            .catch(error => {
              let last_error;
              if (error.response.data.message) {
                last_error = error.response.data.message;
              } else {
                for (let key in errors) { 
                  let errors_field = errors[key]
                  errors_field.forEach(function(error, idx){
                    if (last_error === false) {
                      last_error = error
                    }
                  })
                }
              }
              if (last_error) {
                this.$swal({
                  title: last_error,
                  type: "error",
                  showConfirmButton: true,
                });
              }
            });
        }
      });
    },
    update_password(scope) {
      this.$validator.validateAll(scope).then(valid => {
        this.submitted = true;
        this.$http
          .post("/api/user/update_password/", {
            password: btoa(this.password),
            password1: btoa(this.password1),
            password2: btoa(this.password2)
          })
          .then(response => {
            if (response.status === 200) {
              this.$swal({
                title: "Senha alterada com sucesso!",
                type: "success",
                showConfirmButton: false,
                timer: 2000
              });
              $('#modal-new-password').modal('hide');
            }
          })
          .catch(error => {
            this.loading = false;
            let errors = error.response.data;
            this.$swal({
              title: error.response.data.message,
              type: "error",
              showConfirmButton: true
            });
          })
          .finally(() => {
            this.clean_passwords();
          });
      });
    },
    clean_passwords() {
      this.password = null;
      this.password1 = null;
      this.password2 = null;
    }
  }
};
const dict = {
  custom: {
    full_name: {
      alpha_spaces: "Campo nome completo deve conter apenas caracteres alfabéticos e espaços.",
      min: "Campo nome completo deve conter ao menos 2 caracteres",
    },
    email: {
      email: "E-mail inválido",
    },
    additional_email: {
      email: "E-mail inválido",
    },
    document_number: {
      min: "CPF deve conter 11 caracteres",
    },
    birth_date: {
      min: "Data de nascimento inválida",
    },
    phone: {
      min: "Campo telefone deve conter no mínimo 10 caracteres",
    },
    mobile_phone: {
      min: "Campo celular deve conter no mínimo 10 caracteres",
    },
    zip_code:{
      min: "Campo CEP deve conter no mínimo 8 caracteres",
    },
    password1: {
      required: "Campo senha é obrigatório"
    },
    password2: {
      required: "Campo confirmar senha é obrigatório",
      confirmed: "As senhas não conferem"
    }
  }
};
Validator.localize("en", dict);
</script>

<style scoped>
.change-modal {
  border-radius: 0;
}
.change-modal-header {
  border: 0 !important;
}
.change-modal-footer {
  border: 0 !important;
}
.content-change-modal {
  height: 350px;
  overflow-y: auto;
  font-size: 14px;
  padding-left: 40px;
  padding-right: 40px;
}
.input-group-card {
  display: inline-flex !important;
}
.theme-account-page-title {
  border-bottom: 1px solid #e6e6e6;
}
.fs-18 {
  font-size: 18px;
}
.mt-80 {
  margin-top: 80px;
}
</style>
