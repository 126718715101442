import { axiosUpdateHeaders } from '@/axios'
import { normalizeCompany } from '@/store/helpers/normalizeResult'

export const state = {
  results: {},
  loading: false,
  alert: ''
}

export const mutations = {
  setResult (state, data) {
    // state.results = normalizeCompany(data.results[0])
    state.results = data.results[0];
  },
  setLoading (state) {
    state.loading = !state.loading;
  },
  setAlert (state, alert) {
    state.alert = alert;
  }
}

export const actions = {
  getCompanies (context) {
    context.commit('setLoading');

    const axios = axiosUpdateHeaders();
    return axios.get('/api/companies/').then(response => {
      if (response.status === 200) {
        context.commit('setResult', response.data);
      }
      context.commit('setLoading');
    }).catch(error => {
      /* TODO HANDLE ERROR */
      context.commit('setLoading');
    })
  },
  patchCompany (context, data) {
    context.commit('setLoading');

    const axios = axiosUpdateHeaders();
    return axios.patch('/api/companies/', data).then(response => {
      if (response.status === 200) {
        context.commit('setAlert', 'Cadastro empresarial atualizado com sucesso.');
      } else {
        context.commit('setAlert', response.data.message);
      }
      context.commit('setLoading');
      return Promise.resolve(response)
    }).catch(error => {
      /* TODO HANDLE ERROR */
      context.commit('setAlert', error.response.data.message);
      context.commit('setLoading');
      return Promise.reject(error)
    })
  }
}

export default {
  namespaced : true,
  state,
  mutations,
  actions
  /* getters */
}
