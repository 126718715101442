<template>
  <div
    class="theme-search-results-item _br-3 _mb-10 theme-search-results-item-bs theme-search-results-item-lift theme-search-results-item-grid"
  >
    <div class="banner _h-20vh _h-mob-30vh banner-">
      <div class="banner-bg" :style="{backgroundImage: `url('${getFirstImage}')`}"></div>
    </div>
    <div class="theme-search-results-item-grid-body">

      <!-- <a class="theme-search-results-item-mask-link" href="javascript:void(0)" v-on:click="openDetailLink()"></a> -->
      <router-link
        :to="{ name: 'hoteldetails', params: { hash: getHash } }"
        class="theme-search-results-item-mask-link">
      </router-link>

      <div class="theme-search-results-item-grid-header">
          <ul class="theme-search-results-item-hotel-stars">
            <template v-if="getStar()">
              <li v-for="n in getStar()" :key="n">
                <i class="fa fa-star"></i>
              </li>
            </template>
            <template v-else>
              <li >
                <i class="fa fa-star text-white"></i>
              </li>
            </template>
          </ul>
        <h5 class="theme-search-results-item-title" v-text="data.hotel.name"></h5>
      </div>
      <div class="theme-search-results-item-grid-caption">
        <div class="row" data-gutter="10">
          <!-- <div class="col-xs-6">
            <div class="theme-search-results-item-hotel-rating">
              <p class="theme-search-results-item-hotel-rating-title">
                <b>7.6 Good</b>
                <br>7933 reviews
              </p>
            </div>
          </div>-->
          <div class="col-xs-12">
            <div class="theme-search-results-item-price">
              <p class="theme-search-results-item-price-tag" v-text="getPriceLower"></p>
              <!-- <p class="theme-search-results-item-price-sign">menor preço</p> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';

export default {
  props: ["data"],
  methods: {
    getStar() {
      if (typeof this.data.hotel.awards != 'undefined') {

        let star_obj = _.findWhere(this.data.hotel.awards, { slug: 'star' });
        if (typeof star_obj != 'undefined'){
          return parseInt(star_obj.rate);
        }
      }
      return 0;
    }
    //  ,
    // openDetailLink() {
    //   // window.open(this.getDetailLink, '_blank');
    //   this.$router.push(this.getDetailLink);
    // }
  },
  computed: {
    getFirstImage() {
      if (this.data.hotel.images.length) {

        let image = _.findWhere(this.data.hotel.images, { default : true });
        if (image) {
          return image.url;
        }

        return this.data.hotel.images[0].url
      } else {
        return require('@/assets/img/img-hotel-350x232.png')
      }
    },
    getPriceLower() {
      if (this.data.price_selected > 0) {
        return this.data.price_selected.toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2
        });
      }
      return 'Indisponivel'
    },
    getHash() {
      return this.data.link_rooms.split('hash=')[1];
    }
    // ,
    // getHotelId() {
    //   return this.data.hotel.id;
    // }
    // ,
    // getDetailLink() {
    //   return `/hotels/details/${this.getHash}`;
    // },
  },
  mounted() {
    // console.log("mounted");
  }
};
</script>

<style scoped>
.theme-search-results-item-grid .theme-search-results-item-price-tag {
  font-size: 18px;
  line-height: 1em;
  font-weight: bold;
}
</style>
