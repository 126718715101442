<template>
  <div>
    <multiselect
      v-model="selectedTravelers"
      @input="handleTravelers"
      :options="sortTravelers"
      :max="max"
      :preselect-first="false"
      :multiple="true"
      :close-on-select="true"
      :clear-on-select="false"
      :preserve-search="true"
      :class="{ 'has-error': hasError }"
      track-by="full_name"
      label="full_name"
      placeholder="Selecione os viajantes*"
      selectedLabel="Selecionado"
      selectLabel="Clique para selecionar"
      deselectLabel="Clique para desmarcar seleção"
      name="selectedTravelers">
      <template
        slot="selection"
        slot-scope="{ values, search, isOpen }">
        <span
          class="form-control"
          v-if="values.length &amp;&amp; !isOpen">
          {{ values.length }} viajantes selecionados
        </span>
      </template>
      <template slot="maxElements">
        <span>
          Máximo de viajantes selecionados. Remova para selecionar outro.
        </span>
      </template>
    </multiselect>
    <span
      v-if="!selectedTravelers.length"
      class="size-12 weight-normal">* Obrigatório selecionar os viajantes</span>
    <div
      v-if="selectedTravelers.length"
      class="clearfix _pt-5">
      <div
        v-for="(traveler, index) in selectedTravelers"
        :key="`traveler-${index}`"
        class="col-xs-12 padding-off size-16 _pt-5 weight-normal clearfix">
        <span class="pull-left _pt-5">{{ traveler.full_name }}</span>
        <button
          @click.prevent="selectedTravelers.splice(index, 1)"
          class="btn-magenta-inverse pull-right">
          - Remover
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import Multiselect from "vue-multiselect";
import { mapActions, mapState } from 'vuex';

export default {
  components: {
    Multiselect
  },
  props: [
    'max',
    'travelers',
    'error'
  ],
  data () {
    return {
      alert: null,
      selectedTravelers: []
    }
  },
  computed: {
    ...mapState('travelers', {
      'loading': state => state.loading,
      'listTravelers': state => state.travelers
    }),
    sortTravelers () {
      return _.sortBy(this.listTravelers, 'full_name');
    },
    hasError () {
      return this.error && !this.selectedTravelers.length
    }
  },
  mounted () {
    this.alert = null;

    this.searchTravelers()
      .catch(error => {
        this.alert = 'Não foi possível buscar os viajantes'
      })
  },
  methods: {
    ...mapActions('travelers', ['searchTravelers']),
    handleTravelers (travelers) {
      this.$emit('update:travelers', travelers)
    }
  }
};
</script>

<style scoped lang="scss">
@import '~vue-multiselect/dist/vue-multiselect.min.css';
@import '@/scss/components/forms/MultiselectTravelers';
</style>
