import _ from 'underscore'

/*
 * Hotels
 */
const normalizeHotel = ({name, full_address}) => ({name, full_address})

const normalizeRoom = ({hotel, description, nights, adults, quantity, checkin, checkout, breakfast_included, guests_list, taxes_included, extras_included}) => ({
  description,
  nights,
  adults,
  quantity,
  checkin,
  checkout,
  breakfast_included,
  guests_list,
  taxes_included,
  extras_included,
  hotel: normalizeHotel(hotel)
})

const normalizeValues = ({amount, valuetype}) => ({
  name: valuetype.name,
  amount
  // [valuetype.name]: amount
})

const normalizeHotels = ({product, id, total, taxes, fare, room, value}) => ({
  product,
  id,
  total,
  taxes,
  fare,
  room: normalizeRoom(room),
  values: _.chain(value).map(data => normalizeValues(data)).value(),
})

export const normalizeOrderHotels = (data) =>  _.chain(data)
  .map(data => normalizeHotels(data))
  .value()

/*
 * Flights
 */
const normalizeSegment = ({airline, departure, arrival, origin, origin_city, destination, destination_city, departure_str, arrival_str, direction, flight_number}) => ({
  airline,
  departure,
  arrival,
  origin,
  origin_city,
  destination,
  destination_city,
  departure_str,
  arrival_str,
  direction,
  flight_number
})

// const normalizeFlight = ({segments}) => _.chain(segments).map(data => normalizeSegment(data)).groupBy('direction').value()
const normalizeFlight = ({segments}) => _.chain(segments).map(data => normalizeSegment(data)).value()

const normalizeTraveler = ({full_name}) => ({full_name})

const normalizeFlights = ({product, id, total, taxes, fare, value, flight, travelers}) => ({
  product,
  id,
  total,
  taxes,
  fare,
  values: _.chain(value).map(data => normalizeValues(data)).flatten().indexBy('name').value(),
  flight: normalizeFlight(flight),
  locator: flight.locator,
  travelers: _.chain(travelers).map(data => normalizeTraveler(data)).value()
})

export const normalizeOrderFlights = (data) =>  _.chain(data)
  .map(data => normalizeFlights(data.order_item))
  .value()

/*
 * Cars
 */
const normalizeCars = ({product, id, total, taxes, fare, value, vehicle}) => ({
  product,
  id,
  total,
  taxes,
  fare,
  values: _.chain(value).map(data => normalizeValues(data)).value(),
  vehicle: vehicle.vehicle,
  pickup_agency : vehicle.pickup_agency,
  delivery_agency : vehicle.delivery_agency,
  start_date: vehicle.start_date,
  end_date: vehicle.end_date,
  traveler: vehicle.traveler
})

export const normalizeOrderCars = (data) =>  _.chain(data)
  .map(data => normalizeCars(data))
  .value()
