<template>
  <div class="theme-payment-page-form-item form-group">
    <i class="fa fa-angle-down" v-if="!loading_spinner"></i>
    <i class="fa fa-spinner fa-spin price-fare-rules" v-if="loading_spinner" style="font-size:16px" ></i>
    <select class="form-control" v-model="installment" v-on:change="changeHandle($event)">
      <option v-for="plan in plans" :key="plan.id" :value="plan.installments_number">
          {{ plan | credit_card_installments}}
      </option>
    </select>
  </div>
</template>

<script>
import spinner from "../../spinner/plane-spinner.vue";
export default {
    props: [
        'flight',
        'credit_card',
        'value'
    ],
    components: {
      spinner
    },
    data() {
        return {
            plans: [],
            installment: {},
            loading_spinner: false
        }
    },
    methods: {
        changeHandle(e) {
            this.$emit('input', this.installment);
        },
        fetchData() {
          this.loading_spinner = true;
          this.plans = [];
          return this.$http.get(
              '/api/flight/paymentinfo', {
              params: {
                  reservation_code: this.flight.locator,
                  credit_card: this.credit_card.id
              }
          }).then(response => {
            this.loading_spinner = false;
            if (response.data.results) {
              for (let plan of response.data.results.payment_plans) {
                if (plan.installments.length) {
                  this.plans.push(plan)
                }
              }
              this.installment = this.plans[0].installments_number;
              this.changeHandle();
            } else {
              this.plans = []
            }
            this.loading = false;
          }).catch(error => {
            console.log(error)
          })
        }
    },

    watch: {
      credit_card(data) {
        if (data) {
          this.fetchData();
        } else {
          this.plans = [];
        }
      }
    },
    beforeMount() {
      this.fetchData();
    }

}
</script>

<style scoped>
  .fa fa-spinner{
    position: absolute
  }
</style>
