<template>
  <ul
    class="nav nav-tabs nav-justified travel-tabs"
    role="tablist">
    <li
      :class="{'active' : 'Photos' === active}"
      :aria-selected="'Photos' === active"
      role="tab"
      tabindex="0">
      <a
        :href="`#detailPhotos`"
        @click.prevent="$emit('handleSelected', 'Photos')"
        class="travel-tabs-link">
        <i class="fa fa-fw fa-image"></i>
        <span>Fotos</span>
      </a>
    </li>

    <li
      :class="{'active' : 'Info' === active}"
      :aria-selected="'Info' === active"
      role="tab"
      tabindex="0">
      <a
        :href="`#detailInfo`"
        @click.prevent="$emit('handleSelected', 'Info')"
        class="travel-tabs-link">
        <i class="fa fa-fw fa-info"></i>
        <span>Detalhes</span>
      </a>
    </li>

    <li
      :class="{'active' : 'Map' === active}"
      :aria-selected="'Map' === active"
      role="tab"
      tabindex="0">
      <a
        :href="`#detailMap`"
        @click.prevent="$emit('handleSelected', 'Map')"
        class="travel-tabs-link">
        <i class="fa fa-fw fa-map"></i>
        <span>Mapa</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: [ 'active' ]
};
</script>
