<template>
  <div class="container box-space-nav">
    <!-- Checkout Steps -->
    <checkout-steps></checkout-steps>
    <!-- // Checkout Steps -->
    <div class="row min-height">
      <!-- Tabs Group -->
      <tabs-group
        :key="`tabs-group-${selected}`"
        :selected="selected"
        :alert="error"
        class="col-sm-8">
        <template v-slot:alert="{ alert }">
          <!-- Alert -->
          <alert-helper
            :alert="alert"
            :close="true"
            type="warning"></alert-helper>
          <!-- // Alert -->
        </template>
        <template v-slot:triggers="{ active, handleSelected }">
          <!-- Travel Tabs -->
          <travel-tabs
            :orders="orders"
            :active="active"
            @handleSelected="handleSelected"></travel-tabs>
          <!-- // Travel Tabs -->
        </template>
        <template v-slot:content="{ active }">
          <!-- Travel List -->
          <travel-list
            :key="`travel-list-${active}`"
            :items="reserves[active]"
            :order="orders[active]">
            <template v-slot="{item, order}">
              <components
                :is="order.component"
                :item="item"></components>
            </template>
          </travel-list>
          <!-- //  Travel List -->
        </template>
      </tabs-group>
      <!-- // Tabs Group -->
      <!-- Travel Summary -->
      <travel-summary class="col-sm-4"></travel-summary>
      <!-- // Travel Summary -->
      <!-- LOCKSCREEN -->
      <lockscreen :loading="loading"></lockscreen>
      <!-- // LOCKSCREEN -->
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import CheckoutSteps from '@/components/template/CheckoutSteps'
import TabsGroup from '@/components/ui/TabsGroup'
import TabContent from '@/components/ui/TabContent'
import TravelTabs from '@/views/travel/TravelTabs'
import TravelList from '@/views/travel/TravelList'
import HotelItem from '@/views/travel/HotelItem'
import FlightItem from '@/views/travel/FlightItem'
import CarItem from '@/views/travel/CarItem'
import TravelSummary from '@/views/travel/TravelSummary'
import AlertHelper from '@/components/ui/AlertHelper'
import lockscreen from "@/components/lockscreen/index"

export default {
  components: {
    CheckoutSteps,
    TabsGroup,
    TabContent,
    TravelTabs,
    TravelList,
    HotelItem,
    FlightItem,
    CarItem,
    TravelSummary,
    AlertHelper,
    lockscreen
  },
  created () {
    this.$ga.page({
      page: '/',
      title: 'Shopping Cart',
      location: window.location.href
    });

    this.getReserves();
  },
  computed: {
    ...mapState('reserve', [
      'flights',
      'hotels',
      'cars',
      'loading',
      'error'
    ]),
    ...mapState('navigation', [
      'current',
      'previous'
    ]),
    ...mapState('orders', {
      routes: state => state.routes,
      orders: state => state.types
    }),
    reserves () {
      return {
        hotels: this.hotels,
        flights: this.flights,
        cars: this.cars
      }
    },
    selected () {
      const reserves = this.reserves;
      const hasItems = Object.keys(reserves).filter(key => reserves[key].length)[0];

      return (this.routes.indexOf(this.previous.name) > -1)
        ? `${this.previous.name.split('details')[0]}s`
        : (hasItems) ? hasItems : 'hotels';
    }
  },
  methods: {
    ...mapActions('reserve', [
      'getReserves'
    ])
  }
};
</script>
