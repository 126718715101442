<template>
  <ul class="theme-breadcrumbs _mt-20 -mb-20 theme-breadcrumbs-default">
    <li>
      <a
        @click.prevent="$router.go(-1)"
        class="btn-link">{{ goBack }}</a>
    </li>
    <li>
      <h2 class="h4 _mt-0 _mb-0 pull-left">{{ current }}&nbsp;</h2>
      <slot />
    </li>
  </ul>
</template>

<script>
export default {
  props: [
    'goBack',
    'current'
  ]
};
</script>
