<template>
    <div
      id="detailMap"
      class="detail-map"
      role="listitem">
      <div class="fa fa-spinner fa-spin fa-2x"></div>
      <iframe
        :src="googleIframe"
        width="100%"
        height="350px"
        style="border:0;"
        allowfullscreen
        allowtransparency></iframe>
    </div>
  </div>
</template>

<script>
export  default {
  props: [
    'active',
    'googleIframe'
  ]
};
</script>

<style lang="scss">
.detail-map {
  background-color: #f6f6f6;
  position: relative;

  .fa-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    margin: -20px 0 0 -20px;
  }

  iframe {
    position: relative;
    z-index:1;
  }
}
</style>
