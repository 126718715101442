import { axiosUpdateHeaders } from '@/axios'
import { state, mutations, SET_LOADING, RESET_LOADING } from '@/store/modules/common'

const SET_TRAVELERS = 'SET_TRAVELERS';
const RESET_TRAVELERS = 'RESET_TRAVELERS';

export const actions = {
  getTravelers ({ commit }) {
    const axios = axiosUpdateHeaders();
    commit(SET_LOADING);

    return axios.get('/api/user/travelers')
      .then(response => {
        if (response.status === 200) {
          commit(RESET_LOADING);
          return Promise.resolve(response.data)
        }
      }, error => {
        commit(RESET_LOADING);
        return Promise.reject(error.response)
      })
  },

  getTraveler ({ commit }, id) {
    const axios = axiosUpdateHeaders();

    return axios.get('/api/user/', {params: {id: id}})
      .then(response => {
        if (response.status === 200 && response.data.birth_date !== null) {
          commit(SET_TRAVELERS, response.data)
        }
      }, error => {
        commit(RESET_LOADING);
        return Promise.reject(error.response)
      })
  },

  searchTravelers ({ commit, dispatch }) {
    const axios = axiosUpdateHeaders();
    commit(SET_LOADING);
    commit(RESET_TRAVELERS);

    return axios.get('/api/user/')
      .then(response => {
        if (response.status === 200) {
          commit(RESET_LOADING);
          for (const traveler of response.data.results){
            dispatch('getTraveler', traveler.id);
          }
        }
      }, error => {
        commit(RESET_LOADING);
        return Promise.reject(error.response)
      })
  }
}

export default {
  namespaced : true,
  state: {
     ...state,
     travelers: []
  },
  mutations: {
    ...mutations,
    [SET_TRAVELERS](state, payload) {
      state.travelers = [
        ...state.travelers,
        payload
      ]
    },
    [RESET_TRAVELERS](state) {
      state.travelers = []
    }
  },
  actions
  /* getters */
}
