<template>
  <div>
    <modal-dialog>
      <template v-slot:trigger="{ openModal }">
        <a
          @click.prevent="openModal"
          class="btn-link size-14 weight-normal">
          ver detalhes
        </a>
      </template>

      <template
        slot="body"
        :item="item">
        <slot
          name="detail"
          :item="item"></slot>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import ModalDialog from '@/components/ui/ModalDialog'

export default {
  components: {
    ModalDialog
  },
  props: [
    'item'
  ],
  data() {
    return {
      active: false
    }
  }
};
</script>
