<template>
  <div class="price-detail">
    <template v-if="hasFarerules">
      <a
        v-if="link"
        title="Regras Tarifárias"
        ref="farerulesLink"
        target="_blank"
        :href="link"
        class="btn-link btn-link-ico">
        &nbsp;
        <i
          class="fa fa-info-circle"
          title="Clique aqui para visualizar as regras tarifárias"></i>
      </a>
      <button
        v-else
        :disabled="loading"
        @click.prevent="fetchFareRules"
        type="button"
        class="btn-link btn-link-ico">
        <i
          class="fa"
          :class="!loading ? `fa-info-circle` : `fa-spinner fa-spin`"
          title="Clique aqui para visualizar as regras tarifárias"></i>
      </button>

      <single-dialog
        :active="active"
        @handleDialog="closeModal">
        <div
          slot="header"
          class="_mr-15 _ml-15 mb-10 mt-20">
          <h4 class="_mr-15 _ml-15">Regras Tarifárias</h4>
        </div>
        <div
          slot="body"
          class="clearfix _mr-15 _ml-15">
          <p
            v-for="(rule, index) in rules.split('\n')"
            :key="`rule-${index}`">
            <small>{{ rule }}</small>
          </p>
        </div>
      </single-dialog>
    </template>
  </div>
</template>

<script>
import SingleDialog from '@/components/ui/SingleDialog'
import farerulesLink from '@/data/farerulesLink'

export default {
  components: {
    SingleDialog
  },
  props: [
    'carrier',
    'links',
    'index'
  ],
  data() {
    return {
      rules: '',
      loading: false,
      active: false,
      farerulesLink
    }
  },
  computed: {
    hasFarerules () {
      return (this.links.length)
        ? this.links.filter((link) => (Object.keys(link).indexOf('farerules'))>-1).length > 0
        : this.links.hasOwnProperty('farerules')
    },
    link () {
      return (this.carrier && this.farerulesLink.hasOwnProperty(this.carrier.iata_code))
        ? this.farerulesLink[this.carrier.iata_code]
        : null
    }
  },
  methods: {
    closeModal () {
      this.active = false
    },
    openModal () {
      this.active = true
    },
    fetchFareRules () {
      const links = (this.links.hasOwnProperty('farerules')) ? [ this.links ] : this.links
      for ( let link of links ) {
        if ( this.rules.length === 0 ) {
          this.loading = true
          this.$http({
            url: '/api/flight/fare-rules',
            method: 'GET',
            params: {
              farerules: link.farerules
            }
          })
            .then(this.success)
            .catch(this.fails)
        } else {
          this.active = true
        }
      }
    },
    success (response) {
      this.loading = false
      if ( response.data.results.fare_rules.length ) {
        this.active = true
        this.rules = response.data.results.fare_rules
      } else {
        this.fails()
      }
    },
    fails () {
      this.active = false
      this.loading = false
      this.clickFareruleLink()
    },
    clickFareruleLink () {
      if (this.$refs.farerulesLink) {
        this.$refs.farerulesLink.click()
      }
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/PriceDetail';
</style>

