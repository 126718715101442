<template>
    <div>
        <!-- Modelo de página -->
        <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
            <div class="container">
                <div class="row mt-90">
                    <!-- Componente menu -->
                    <menu-account></menu-account>
                    <!-- /Componente menu -->
                    <div class="col-md-10">
                        <h1 class="theme-account-page-title">
                            Relatórios
                            <a href="/api/order/report" title="Download">
                                <small><i class="fa fa-download"></i></small>
                            </a>
                        </h1>
                        <div class="row">
                            <div class="col-md-12 padding-off">
                                <cashback></cashback>
                                <last-orders></last-orders>
                                <div class="row">
                                    <div class="col-md-6 padding-off">
                                        <service-type></service-type>
                                    </div>
                                    <div class="col-md-6 padding-off">
                                        <airlines></airlines>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 padding-off">
                                        <top-travelers></top-travelers>
                                    </div>
                                    <div class="col-md-6 padding-off">
                                        <top-routes></top-routes>
                                    </div>
                                </div>
                                <last-indications></last-indications>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Modelo de página -->
        <lockscreen :loading="loading"></lockscreen>
    </div>
</template>

<script>
    import MenuAccount from "../components/menuaccount.vue";
    import lockscreen from "../components/lockscreen/index.vue";
    import LastOrders from "../components/reports/last-orders.vue";
    import Cashback from "../components/reports/cashback.vue";
    import ServiceType from "../components/reports/service-type.vue";
    import Airlines from "../components/reports/airlines.vue";
    import TopTravelers from "../components/reports/top-travelers.vue";
    import TopRoutes from "../components/reports/top-routes.vue";
    import LastIndications from "../components/reports/last-indications.vue";

    export default {
        name: "Reports",
        data() {
            return {
                loading: false
            }
        },
        components: {
            MenuAccount,
            lockscreen,
            LastOrders,
            Cashback,
            ServiceType,
            Airlines,
            TopTravelers,
            TopRoutes,
            LastIndications
        },
    }
</script>

<style scoped>

</style>