import jQuery from 'jquery';
import moment from 'moment';
import momentDurationFormat from 'moment-duration-format'; 
import typeahead from 'jquery-typeahead';
import countdown from 'jquery-countdown';
import smoothscroll from 'smooth-scroll';
import select2 from 'select2';
import datarangepicker from 'bootstrap-daterangepicker';

window.$ = jQuery;
window.$.fn.select2.defaults.set('language', 'pt-br');
window.jQuery = jQuery;
window.moment = moment;
window.SmoothScroll = smoothscroll;