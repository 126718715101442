import Globals from './globals.js'; // eslint-disable-line no-unused-vars

import Vue from 'vue'
import Axios from 'axios';
import App from './App.vue'
import router from './router'
import store from '@/store'
import './registerServiceWorker'
import bootstrap from 'bootstrap'; // eslint-disable-line no-unused-vars
import pluralize from 'pluralize';
import moment from 'moment';
import VeeValidate from "vee-validate";
import VueTheMask from 'vue-the-mask'
import PrettyCheckbox from 'pretty-checkbox-vue';
import VueSweetalert2 from 'vue-sweetalert2';
import VueCookies from 'vue-cookies';
import { VueReCaptcha } from 'vue-recaptcha-v3';
import lztring from 'lz-string';
import VueAnalytics from 'vue-analytics';


require('select2/dist/js/i18n/pt-BR');
Vue.config.devtools = true;
// Vue.config.productionTip = false;

Vue.use(VueCookies);
Vue.use(VeeValidate);
Vue.use(VueTheMask);
Vue.use(PrettyCheckbox);
Vue.use(VueSweetalert2);
Vue.use(VueCookies);
Vue.use(VueReCaptcha, { siteKey: process.env.VUE_APP_SITE_KEY });

let isProd = process.env.NODE_ENV === 'production';

Vue.use(VueAnalytics, {
    id: isProd ?'UA-101293938-1':'UA-111130505-1',
    router,
    debug:{
      enable: !isProd,
      sendHitTask: true
    }
})

let axios = Axios.create({
  headers: {
    'X-CSRFToken': VueCookies.get('csrftoken')
  }
});

axios.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if( error.response.status == 401) {
    if (router.app.$route.path != '/login') {
      store.commit('LOGOUT');
    }
  }
  return Promise.reject(error);
});

Vue.prototype.$http = axios
Vue.prototype.$eventHub = new Vue();

// load countries

var countries = localStorage.getItem('settings::countries')

if (!countries) {
  axios.get('/api/misc/countries').then((response) => {
    localStorage.setItem('settings::countries', lztring.compressToUTF16(JSON.stringify(response.data)));
    store.state.countries = response.data;
  })
} else {
  countries = JSON.parse(lztring.decompressFromUTF16(countries));
  store.state.countries = countries;
}


Vue.filter('brl_price', function (value) {
  value = value ? parseFloat(value) : parseFloat(0);
  return value.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2
  });
});

Vue.filter('price', function (value) {
  value = value ? parseFloat(value) : parseFloat(0);
  return value.toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
});

moment.locale('pt-br');

Vue.filter('date_to_human', function (date) {
  return moment(date, 'YYYY-MM-DD').format('dddd, DD [de] MMMM [de] YYYY');
});

Vue.filter('datetime_to_human', function (date) {
  return moment(date, 'YYYY-MM-DDThh:mm').format('dddd, DD [de] MMMM [de] YYYY [às] HH:mm');
});

Vue.filter('short_date_to_human', function (date) {
  return moment(date, 'YYYY-MM-DD').format('DD[,] MMMM YY');
});

Vue.filter('date_to_weekday', (date) => {
  return moment(date, 'YYYY-MM-DDThh:mm').format('ddd, D [de] MMM');
});

Vue.filter('date_to_monthday', (date) => {
  return moment(date, 'YYYY-MM-DDThh:mm').format('D [de] MMMM');
});

Vue.filter('format_date', function (date, format, format_entrie='YYYY-MM-DD HH:mm:ss') {
  return moment(date, format_entrie).utc().local().format(format);
});

Vue.filter('br_date', function(date) {
  return moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY');
});

Vue.filter('date_to_hour', function (date) {
  return moment(date, 'YYYY-MM-DDTHH:mm:ss').format('HH:mm');
});

Vue.filter('pluralize', function(value, count) {
  return pluralize(value, count);
});

Vue.filter('minutes_to_hours', function (minutes) {
  return String(Math.floor(minutes/60)) + 'h' + String(minutes % 60) + 'm'
});

Vue.filter('default_val', function (value, text) {
  if (typeof value == 'string'){
    value = parseFloat(value);
  }

  if (isNaN(value) || value == 0) return text;
  return value;
});

Vue.filter('credit_card_installments', function(plan){
  let item = '';
  if (plan.installments_number === 1){
    item = plan.installments_number + 'x ' + Vue.filter('brl_price')(plan.installments[0].amount)
  }
  if (plan.installments_number === 2){
    item = plan.installments_number + 'x ' + Vue.filter('brl_price')(plan.installments[0].amount) + ' na primeira parcela, ' + Vue.filter('brl_price')(plan.installments[1].amount) + ' na segunda'
  }
  if (plan.installments_number > 2){
    item = plan.installments_number + 'x ' + Vue.filter('brl_price')(plan.installments[0].amount) + ' na primeira parcela, ' + Vue.filter('brl_price')(plan.installments[1].amount) + ' nas demais'
  }
  return item
});

// @todo make helper this kind of fncs
Vue.prototype.updateUser = () => {
  return new Promise(function(resolve, reject) {
    axios.get('/api/user/loggedin').then((response) => {
      if(!response) return reject();
      let user = response.data;
      router.app.$store.commit('LOGIN_SUCCESS', user);
      resolve(response);
    }).catch((response) => {
      router.app.$store.commit('LOGOUT');
      reject(response);
    });
  })
}

Vue.filter('subtract_dates_to_hours', function (dates) {
  let date_start = moment(dates[0]);
  let date_end = moment(dates[1]);
  let minutes = date_end.diff(date_start, 'minutes');
  return String(Math.floor(minutes/60)) + 'h' + String(minutes % 60) + 'm'
});

Vue.filter('substract_dates_to_days', (date) => {
  return moment(date[1], 'YYYY, MM, DD').diff(moment(date[0], 'YYYY, MM, DD'), 'days');
});

Vue.filter('normalize_flight_number', (str) => {
  /*
   * 1000 to 6999 range national flights numbers
   * 7000 to 8999 range international
   * come with or whitout iata_code
   */
  return str.split('').reverse().join('').substring(0, 4).split('').reverse().join('');
});

Vue.filter('normalize_flight_number', (str) => {
  /*
   * 1000 to 6999 range national flights numbers
   * 7000 to 8999 range international
   * come with or whitout iata_code
   */
  return str.split('').reverse().join('').substring(0, 4).split('').reverse().join('');
});

router.afterEach(function () {

  // workaround for rdstation
  if(process.env.NODE_ENV!='development') {
    $('.rdstation').remove();
    let script = $('<script>');
    script.attr('class', 'rdstation');
    script.attr('src', 'https://d335luupugsy2.cloudfront.net/js/loader-scripts/f38a24af-c526-47d1-8824-9752bc217cda-loader.js');
    script.appendTo('body');
  }

})

router.beforeEach((to, from, next) => {
  let $http = router.app.$http;
  let env = process.env.NODE_ENV;
  let loggedin = JSON.parse(localStorage.getItem('loggedin'));

  if ((to.name === 'paymentsuccess' && from.name !== 'checkout')) {
    return next({
      path: '/'
    });
  }

  /* Store 'from' and 'to' router info */
  store.dispatch('navigation/saveToFrom', {to, from})

  document.title = to.meta.title ? to.meta.title : 'E-Trip - Agência online de viagens a negócios';

  if ((to.name !== 'login' && to.name !== 'signup')) {
    router.app.updateUser().then((response) => {
      if (to.meta.requiresAuth) {
        router.app.$http.defaults.headers['X-CSRFToken'] = VueCookies.get('csrftoken');
        if (loggedin === 1) {
          return next();
        } else {
          return next({
            path: '/login',
            query: to.redirectedFrom
          });
        }
      } else {
        next();
      }
    }).catch(() => {
      if (to.meta.requiresAuth) {
        return next({
          path: '/login',
          query: to.redirectedFrom
        });
      } else {
        next();
      }
    });
  } else {
    let connection_problem = false;
    if (router.app.$store) {
      connection_problem = router.app.$store.getters.getConnectionProblem
    }

    if (((to.name === 'login' || to.name === 'signup')&& loggedin && !connection_problem)) {
      return next({
        path: '/'
      });
    }
    next();
  }
});

const errors_dict = {
  en: {
    messages: {
      required: () => 'Campo obrigatório',
      email: () => 'E-mail inválido',
    }
  }
};

new Vue({
  store,
  router,
  render: h => h(App),
  mounted() {
    this.$validator.localize(errors_dict);

    if(process.env.NODE_ENV!='development') {
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window,document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');

      fbq('init', '284903712450321');
      fbq('track', 'PageView');

      (
        function(){
          let _linkedin_partner_id = "1121249"; window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []; window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          (function(){var s = document.getElementsByTagName("script")[0]; var b = document.createElement("script"); b.type = "text/javascript";b.async = true; b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"; s.parentNode.insertBefore(b, s);})();
        }
      )();

      (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:1334231,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    }

  }
}).$mount('#app');

if (module.hot) {
  module.hot.accept('./router.js', function () {
    console.log('Accepting the updated printMe module!');
    printMe();
  })
}
