<template>
  <div>
    <hero-steps :type="'hotel'"></hero-steps>

    <!-- Complete Registration -->
    <div class="container">
      <complete-registration class="col-xs-12"></complete-registration>
    </div>
    <!-- // Complete Registration -->

    <div class="container min-height _mb-20">
      <div class="col-sm-12 mb-10 clearfix">
        <details-breadcrumb
          :go-back="'Hotéis'"
          :current="hotel.name">
          <template slot:default>
            <ul
              v-if="stars > 0"
              class="theme-search-results-item-hotel-stars pull-left">
              <li v-for="star in stars" :key="`star-${star}`">
                <i class="fa fa-star"></i>
              </li>
            </ul>
            <p
              v-if="fare"
              class="pull-left h5 _mt-0 _mb-0">
              <span class="weight-normal">à partir de</span>&nbsp;
              <strong class="h4">{{ fare | brl_price }}</strong>
              <span>/noite</span>
            </p>
          </template>
        </details-breadcrumb>
      </div>

      <div class="col-xs-12 col-sm-9 _pb-40">
        <!-- Tabs Group -->
        <tabs-group :selected="'Photos'">
          <template v-slot:triggers="{ active, handleSelected }">
            <detail-tabs
              :active="active"
              @handleSelected="handleSelected"></detail-tabs>
          </template>
          <template v-slot:content="{ active }">
            <div
              v-if="active"
              class="_pt-15 _pb-15 clearfix" role="list">
              <detail-photos
                v-if="active === 'Photos'"
                :images="hotel.images"></detail-photos>
              <detail-info
                v-if="active === 'Info'"
                :policies="policies"></detail-info>
              <detail-map
                v-if="active === 'Map'"
                :google-iframe="googleIframe"></detail-map>
            </div>
          </template>
        </tabs-group>
        <!-- // Tabs Group -->

        <div
          v-if="Object.keys(params).length"
          class="row">

          <!-- Alert Warning -->
          <div
            id="hotel-details-error"
            class="col-xs-12 clearfix">
            <alert-helper
              :alert="error"
              :type="'danger'"
              :close="true">
            </alert-helper>
          </div>
          <!-- // Alert Warning -->

          <!-- <div class="col-xs-12">
            <h2 class="_mt-0 _pb-10 h4 border-bottom-blue">Sua Reserva</h2>
          </div> -->
          <div class="col-xs-12 col-md-5 _mt-5">
            <div class="col-xs-6 size-18 _mb-0 _pr-0 _pl-0">
              <div class="color-gray-light-mid weight-lighter">
                <i class="fa fa-calendar"></i>
                Check In:
              </div>
              <p class="_mb-0">{{ params.date_start | date_to_weekday }}</p>
            </div>
            <div class="col-xs-6 size-18 _mb-0 _pr-0 _pl-0">
              <div class="color-gray-light-mid weight-lighter">
                <i class="fa fa-calendar"></i>
                Check Out:
              </div>
              <p class="_mb-0">{{ params.date_end | date_to_weekday }}</p>
            </div>
            <div class="col-xs-12 _pr-0 _pl-0">
              <p class="size-14 _mb-0">
                {{ params.rooms }} {{ 'quarto' | pluralize(params.rooms) }},
                {{ params.adults }} {{ 'adulto' | pluralize(params.adults) }},
                {{ params.nights }} {{ 'noite' | pluralize(params.nights) }}
              </p>
            </div>
          </div>

          <div
            id="hotel-details-select-traveler"
            class="col-xs-12 col-md-7 _mt-10">
            <!-- Select Travelers -->
            <select-travelers
              :traveler.sync="traveler"
              :submitted="submitted"></select-travelers>
            <!-- // Select Travelers-->
          </div>
        </div>

        <!-- Rooms List -->
        <rooms-list
          @handleReserve="handleReserve"
          :rooms="rooms"
          :provider="provider"></rooms-list>
        <!-- // Rooms List -->
      </div>

      <div class="col-xs-12 col-sm-3">
        <!-- Propaganda -->
        <div class="theme-ad">
          <a class="theme-ad-link" href="#"></a>
          <p class="theme-ad-sign">Propaganda</p>
          <img
            class="theme-ad-img"
            src="@/assets/img/banner_anuncio_gol.jpg"
            alt="Image Alternative text"
            title="Image Title" />
        </div>
        <!-- Propaganda -->
      </div>
    </div>

    <lockscreen :loading="loadingHotel || loadingReserve"></lockscreen>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapActions, mapState } from 'vuex';
import HeroSteps from '@/components/template/HeroSteps';
import CompleteRegistration from '@/components/CompleteRegistration';
import DetailsBreadcrumb from '@/components/template/DetailsBreadcrumb';
import AlertHelper from '@/components/ui/AlertHelper';
import TabsGroup from '@/components/ui/TabsGroup';
import DetailTabs from '@/views/hotel/details/DetailTabs';
import DetailPhotos from '@/views/hotel/details/DetailPhotos';
import DetailInfo from '@/views/hotel/details/DetailInfo';
import DetailMap from '@/views/hotel/details/DetailMap';
import SelectTravelers from '@/components/forms/SelectTravelers';
import RoomsList from '@/views/hotel/details/RoomsList';
import lockscreen from '@/components/lockscreen/index.vue';
import scrollToElement from '@/mixins/scrollToElement';

export default {
  mixins: [ scrollToElement ],
  components: {
    HeroSteps,
    CompleteRegistration,
    DetailsBreadcrumb,
    AlertHelper,
    TabsGroup,
    DetailTabs,
    DetailPhotos,
    DetailInfo,
    DetailMap,
    SelectTravelers,
    RoomsList,
    lockscreen
  },
  data () {
    return {
      rooms: [],
      policies: {},
      hotel: {},
      googleIframe: '',
      search: {},
      params: {},
      provider: {
        config: {
          fareField: null
        }
      },
      traveler: null,
      submitted: false,
      error: null
    }
  },
  mounted () {
    this.getHotel(this.$route.params.hash)
      .then(this.setHotel)
      .catch(this.setError);
  },
  computed: {
    ...mapState('hotels', {
      'loadingHotel': state => state.loading
    }),
    ...mapState('reserve', {
      'loadingReserve': state => state.loading
    }),
    stars () {
      if (!this.hotel.hasOwnProperty('awards')) return 0;
      const star = _.findWhere(this.hotel.awards, { slug: 'star' });
      return (star) ? parseInt(star.rate) : 0;
    },
    fare () {
      return this.search[this.provider.config.fareField]
    }
  },
  methods: {
    ...mapActions('hotels', ['getHotel']),
    ...mapActions('reserve', ['postReserve']),
    setHotel (data) {
      const search_data = data.search_data;
      this.hotel = search_data.search.hotel;
      this.provider = data.provider;
      this.policies = search_data.policies;
      this.rooms = search_data.rooms;
      this.search = _.omit(search_data.search, ['hotel', 'policies']);
      this.params = search_data.params;
      this.googleIframe = `https://maps.google.com/maps?q=${this.hotel.address}&t=&z=13&ie=UTF8&iwloc=&output=embed`;
    },
    setError () {
      this.$router.push('/not-found');
    },
    handleReserve (room, price) {
      this.submitted = true;
      this.error = null;

      if (this.traveler) {
        this.postReserve({
          search_data: this.search,
          hotel_data: this.hotel,
          room_data: room,
          price_data: price,
          traveler: this.traveler,
          policies: this.policies
        }).then(() => {
          this.$router.push('/travel');
        }).catch(() => {
          /* TODO refactor if response has error that must be show */
          this.submitted = false;
          this.error = 'Não foi possível salvar sua reserva.'
          this.scrollToElement('hotel-details-error');
        })
      } else {
        /**/
        this.scrollToElement('hotel-details-error');
      }
    }
  }
};
</script>
