<template>
    <div class="user-personal-data">
        <div class="col-md-12">
            <h4 class="title-manage-account">Top Rotas</h4>
        </div>
        <div class="col-md-12">
            <wait-loader v-if="loading"></wait-loader>
            <div v-else class="theme-account-history table-responsive">
                <table class="table">
                    <thead>
                    <tr>
                        <th class="text-center">Rota</th>
                        <th class="text-center">Quantidade</th>
                    </tr>
                    </thead>
                    <tbody v-if="routes.length !== 0">
                        <tr v-for="route in routes">
                            <td class="text-center">{{ route.route }}</td>
                            <td class="text-center">{{ route.qtd }}</td>
                        </tr>
                    </tbody>
                    <tbody v-else>
                        <tr class="text-center">
                            <td colspan="2">Nenhuma top rota encontrada.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import WaitLoader from "../loader/wait-loader.vue";
    export default {
        name: "top-routes",
        components: {
            WaitLoader,
        },
        data() {
            return {
                routes: [],
                loading: false
            }
        },
        created() {
            this.loading = true;
            this.$http
                .get("/api/top-routes")
                .then(response => {
                    if (response.status === 200) {
                        this.routes = response.data
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
</script>

<style scoped>

</style>