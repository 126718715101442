<template>
  <div class="mt-20">
    <div class="col-sm-11 row">
      <div
        :class="[{ 'border-right' : inbound.length }]"
        class="col-sm-6">
        <!-- Flight Hourly -->
        <flight-hourly
          :key="`flight-hourly-outbound`"
          :segments="outbound"></flight-hourly>
        <!-- // Flight Hourly -->
        <!-- Flight Detail -->
        <flight-detail
          :item="{
            segments: outbound,
            travelers: item.travelers
          }"></flight-detail>
        <!-- // Flight Detail -->
      </div>
      <div
        v-if="inbound.length"
        class="col-sm-6">
        <!-- Flight Hourly -->
        <flight-hourly
          :key="`flight-hourly-outbound`"
          :segments="inbound"></flight-hourly>
        <!-- // Flight Hourly -->
        <!-- Flight Detail -->
        <flight-detail
          :item="{
            segments: inbound,
            travelers: item.travelers
          }"></flight-detail>
        <!-- // Flight Detail -->
      </div>
    </div>
    <!-- Values -->
    <div class="travel-list-values col-xs-12">
      <div class="travel-list-value size-16 weight-lighter">
        <span>Tarifa</span>
        <span class="pull-right display-block">{{ item.values['Tarifa'].amount | brl_price}}</span>
      </div>
      <div class="travel-list-value size-16 weight-lighter">
        <span>Taxa de embarque</span>
        <span class="pull-right display-block">{{ item.values['Taxa de embarque'].amount | brl_price}}</span>
      </div>
      <div class="travel-list-value size-16 weight-lighter">
        <span>Taxa de Serviço</span>
        <span class="pull-right display-block">{{ item.values['FEE'].amount | brl_price}}</span>
      </div>
      <div class="travel-list-value size-16 weight-lighter">
        <span class="size-18 weight-bold">Subtotal</span>
        <span class="size-18 weight-bold pull-right display-block">{{ item.total | brl_price }}</span>
      </div>
    </div>
    <!-- // Values -->
    <!-- Dialog Remove -->
    <dialog-remove
      :item="item"
      :outbound="outbound"
      :inbound="inbound">
      <template v-slot:description>
        <div class="mt-20 mb-20 row">
          <div class="col-sm-6">
            <!-- Flight Hourly -->
            <flight-hourly
              :key="`dialog-remove-outbound`"
              :segments="outbound"></flight-hourly>
            <!-- // Flight Hourly -->
          </div>
          <div
            v-if="inbound.length"
            class="col-sm-6">
            <!-- Flight Hourly -->
            <flight-hourly
              :key="`dialog-remove-inbound`"
              :segments="inbound"></flight-hourly>
            <!-- // Flight Hourly -->
          </div>
        </div>
      </template>
    </dialog-remove>
    <!-- Dialog Remove -->
  </div>
</template>

<script>
import FlightHourly from '@/components/flight/FlightHourly'
import FlightDetail from '@/components/flight/FlightDetail'
import DialogRemove from '@/views/travel/DialogRemove'

export default {
  components: {
    FlightHourly,
    FlightDetail,
    DialogRemove
  },
  props: [
    'item'
  ],
  computed: {
    outbound () {
      return this.item.flight.filter(flight => flight.direction === 'outbound') || []
    },
    inbound () {
      return this.item.flight.filter(flight => flight.direction === 'inbound') || []
    }
  }
};
</script>
