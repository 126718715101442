export default {
  methods: {
    scrollToElement (elementID) {
      const position = document.getElementById(elementID).offsetTop;

      window.scrollTo({
        top: position,
        behavior: 'smooth'
      });
    }
  }
}
