<template>
  <div class="card mb-10">
    <div class="card-header" id="headingOne">
      <div class="changes-travalers theme-account-history table-responsive">
        <table class="table table-sm">
          <thead>
            <tr>
              <th></th>
              <th>Item</th>
              <th>Viajante</th>
              <th>Status</th>
              <th>Subtotal</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <i class="fa fa-building theme-account-history-type-icon"></i>
              </td>
              <td>
                <div v-text="item.room.hotel.name"></div>
                <div>
                  <small class="theme-account-history-type-icon" v-text="item.room.description"></small>
                </div>
                <div>
                  <small class="theme-account-history-type-icon" v-text="nights"></small>
                </div>
                <div>
                  <small>Reserva Nr. {{ item.external_id }}</small>
                </div>
              </td>
              <td v-text="travelers"></td>
              <td>
                <div>
                  <p>
                    <i class="fa fa-lg"
                      :class="[
                        {'fa-clock-o text-warning': getStatus(item.status) === 'Processando'},
                        {'fa-clock-o text-warning': getStatus(item.status) === 'Reservado'},
                        {'fa-check-circle text-success': getStatus(item.status) === 'Emitido'},
                        {'fa-times-circle text-danger': getStatus(item.status) === 'Cancelado'},
                        {'fa-times-circle text-danger': getStatus(item.status) === 'Pagamento Recusado'},
                        {'fa-clock-o text-warning': getStatus(item.status) === 'Aguardando Aprovação'}
                      ]"></i>&nbsp;{{ getStatus(item.status) }}
                  </p>
                </div>
              </td>
              <td>
                <div>{{ item.total|brl_price }}</div>
                <div>
                  <small class="theme-account-history-type-icon">Valor: {{ item.fare|brl_price }}</small>
                </div>
                <div>
                  <small class="theme-account-history-type-icon">Taxas: {{ item.taxes|brl_price }}</small>
                </div>
              </td>
              <td>
                <button
                  class="btn btn-primary _tt-uc pull-right"
                  data-toggle="collapse"
                  :data-target="`#hotel-${item.id}`"
                  aria-expanded="true"
                  :aria-controls="'hotel-'+item.id"
                >+ Mais detalhes</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div :id="'hotel-'+item.id" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
        <div class="card-body">
          <div class="changes-travalers theme-account-history table-responsive">
            <table class="table table-sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Check-in</th>
                  <th>Check-out</th>
                  <th></th>
                  <th>Detalhe do preço</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td v-text="checkin"></td>
                  <td v-text="checkout"></td>
                  <td>Estadia de {{ nights }}</td>
                  <td>
                    <div v-text="adults"></div>
                    <div v-text="nights"></div>
                  </td>
                  <td>
                    <button
                      class="btn btn-danger _tt-uc pull-right"
                      v-if="canCancel"
                      v-on:click="cancelBooking(item)"
                    >Cancelar item</button>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="col-md-12 text-center mt-10 mb-10" v-if="item.room.hotel.cancel_policies">
              <a
                class="btn btn-primary _tt-uc"
                data-toggle="modal"
                data-target="#ModalCancelPolicies"
              >Clique aqui para visualizar a política de cancelamento</a>
            </div>

            <!-- Modal Politica de Cancelamento -->
            <div
              class="modal fade"
              id="ModalCancelPolicies"
              tabindex="-1"
              role="dialog"
              aria-labelledby="ModalCancelPolicies"
              aria-hidden="true"
            >
              <div class="modal-dialog" role="document">
                <div class="modal-content change-modal">
                  <div class="modal-header change-modal-header">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>
                    <h3
                      class="modal-title text-center"
                      id="ModalCancelPolicies"
                    >Política de cancelamento</h3>
                  </div>
                  <div class="modal-body content-change-modal">
                    <p></p>
                  </div>
                  <div class="modal-footer change-modal-footer">
                    <button
                      type="button"
                      class="btn _tt-uc btn-primary btn-lg btn-block"
                      data-dismiss="modal"
                    >Fechar</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /Modal Politica de Cancelamento -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import _ from "underscore";
export default {
  props: ["item", "fetchParent"],
  computed: {
    nights() {
      return `${this.item.room.nights} ${this.$options.filters.pluralize(
        "diária",
        this.item.room.nights
      )}`;
    },
    adults() {
      return `${this.item.room.adults} ${this.$options.filters.pluralize(
        "adulto",
        this.item.room.adults
      )}`;
    },
    checkin() {
      return moment(this.item.room.checkin).format("DD/MM/YYYY");
    },
    checkout() {
      return moment(this.item.room.checkout).format("DD/MM/YYYY");
    },
    canCancel() {
      return _.contains([3], this.item.status);
    },
    travelers() {
      if(this.item.travelers.length != 0){
        return this.item.travelers[0].full_name
      } else {
        return '-'
      }
    }
  },
  methods: {
    getStatus(status) {
      let statuses = {
        1: "Processando",
        2: "Reservado",
        3: "Emitido",
        4: "Cancelado",
        6: "Pagamento Recusado",
        7: "Cancelado",
      };

      if (this.item.approval) {
        if (this.item.approval.status==1) {
          return 'Aguardando Aprovação';
        }
      }

      return statuses[status];
    },
    cancelBooking(item) {
      this.$swal({
        title: "Tem certeza que deseja cancelar essa reserva?",
        type: "question",
        showConfirmButton: true,
        showCancelButton: true,
      }).then((result) => {
        if(result.value) {
          this.$parent.loading = true;
          this.$http({
            url: "/api/user/order/item/cancel",
            method: "POST",
            data: {
              orderitem_id: item.id
            }
          })
          .then((response) => {
            this.$parent.loading = false;
            this.$swal({
              title: "Pedido cancelado com sucesso!",
              type: "success",
              showConfirmButton: true
            }).then(() =>{
              console.log(this.$parent);
              this.fetchParent();
            });
            this.$ga.event({
              eventCategory: 'Hotel',
              eventAction: 'Cancel',
              eventLabel: 'Cancel Hotel booking;'
            })
          })
          .catch((error) => {
            this.$parent.loading = false;
            this.$swal({
              title: "Não foi possível cancelar seu pedido, tente novamente",
              type: "error",
              showConfirmButton: true
            });
          });
        }
      })
    },

  }
};
</script>

<style>
</style>
