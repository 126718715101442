<template>
  <p class="size-14 color-gray-dark">
    <span class="weight-lighter">Viajante:</span>&nbsp;<strong>{{ traveler.first_name }} {{ traveler.last_name }}</strong><br>
    <span class="weight-lighter">Coberturas: </span>
    <template v-if="coverages.length">
      <span
        v-for="(coverage, index) in coverages"
        :key="`travel-list-item-car-coverage-${index}-${coverage.id}`">
        {{ coverage.name }}<template v-if="index >= 0 && (index !== coverages.length - 1)">, </template>
      </span>
    </template>
    <template v-if="optionals.length">
      <br>
      <span class="weight-lighter">Opcionais: </span>
      <span
        v-for="(optional, index) in optionals"
        :key="`travel-list-item-car-optional-${index}-${optional.id}`">
        {{ optional.name }}<template v-if="index >= 0 && (index !== optionals.length - 1)">, </template>
      </span>
    </template>
  </p>
</template>

<script>
export default {
  props: [
    'traveler',
    'coverages',
    'optionals'
  ]
};
</script>
