<template>
  <div class="theme-hero-area">
    <div class="theme-hero-area-bg-wrap">
      <div
        class="theme-hero-area-bg"
        :style="{'background-image': 'url(' + require('@/assets/img/bg-home.jpg') + ')'}"
      ></div>
      <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
    </div>
    <div class="theme-hero-area-body">
      <div class="theme-page-section theme-page-section-xl">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <div class="theme-login-header">
                <div class="center-block wdt-250 mt-30 mb-20">
                  <img
                    class="img-responsive"
                    src="@/assets/img/logo-login.png"
                    alt="Logo etrip"
                    title="ETrip"
                  >
                </div>
              </div>
              <div class="col-md-12">
                <h1
                  class="theme-login-title theme-login-title-white text-center mb-20"
                >Cadastrar nova senha</h1>
              </div>
            </div>
            <div class="col-md-6 col-md-offset-3">
              <div class="theme-login theme-login-white">
                <div class="theme-login-box">
                  <div class="theme-login-box-inner">
                    <form @submit.prevent="doReset">
                      <p class="theme-login-pwd-reset-text">Digite sua nova senha abaixo:</p>
                      <div class="form-group theme-login-form-group">
                        <input
                          @focus="open_helper = true"
                          class="form-control"
                          type="password"
                          placeholder="Nova senha"
                          name="password_1"
                          v-model="password1"
                          v-validate="'required'"
                          ref="password_1"
                          :class="{ 'alert-danger': errors.has('password_1') }"
                        >
                        <span class="error-message-forms">{{ errors.first('password_1') }}</span>
                      </div>
                      <div class="form-group theme-login-form-group">
                        <input
                          @focus="open_helper = true"
                          class="form-control"
                          type="password"
                          placeholder="Confirmar nova senha"
                          name="password_2"
                          v-model="password2"
                          v-validate="'required|confirmed:password_1'"
                          data-vv-as="password_1"
                          :class="{ 'alert-danger': errors.has('password_2') }"
                        >
                        <span class="error-message-forms">{{ errors.first('password_2') }}</span>
                      </div>

                      <password-helper v-if="open_helper" />

                      <button class="btn btn-uc btn-dark btn-block btn-lg" href="#" type="submit">
                        <i class="fa fa-check" aria-hidden="true"></i> Alterar senha
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";
import PasswordHelper from '@/components/PasswordHelper';

export default {
  components: {
    PasswordHelper
  },
  data() {
    return {
      password1: "",
      password2: "",
      open_helper: false,
    };
  },
  methods: {
    doReset(e) {
      e.preventDefault();
      let query = this.$route.query;
      let user = query.user;
      let token = query.token;
      this.$validator.validateAll().then(result => {
        if (!result) return false;
        this.$http({
          url: `/api/user/password-reset/${user}/${token}/`,
          method: "POST",
          data: {}
        }).then(() => {
          this.$http({
            url: `/api/user/password-reset/${user}/set-password/`,
            method: "POST",
            data: {
              new_password1: this.password1,
              new_password2: this.password2
            }
          }).then(response => {
            if (typeof response.data.error == "undefined") {
              this.$swal({
                title: response.data.message,
                type: "success",
                showConfirmButton: false,
                timer: 3000,
                onClose: () => {
                  this.$router.push("/login");
                }
              });
            } else {
              this.$swal({
                title: response.data.message,
                type: "error",
                showConfirmButton: false,
                timer: 3000
              });
            }
          });
        });
      });
    }
  }
};

const dict = {
  custom: {
    password_1: {
      required: "O campo senha é obrigatório"
    },
    password_2: {
      required: "O campo confirmar senha é obrigatório",
      confirmed: "As senhas não conferem"
    },
  }
};
Validator.localize("en", dict);
</script>

<style scoped>
.theme-login-title-white {
  color: #ffffff;
}
.wdt-250 {
  width: 250px;
}
.theme-hero-area {
  height: 100vh;
}
</style>