<template>
  <div class="theme-hero-area">
    <div class="theme-hero-area-bg-wrap">
      <!-- Background image = imagem principal do hotel -->
      <div
        class="theme-hero-area-bg ws-action"
        :style="{'background-image': `url(${require(`@/assets/img/bg-home-${type}.jpg`)})`}"></div>
      <!-- Background image = imagem principal do hotel -->
      <div class="theme-hero-area-mask theme-hero-area-mask-half"></div>
      <div class="theme-hero-area-inner-shadow"></div>
    </div>
    <div class="theme-hero-area-body">
      <div class="container">
        <div class="theme-search-area theme-search-area-white">
          <checkout-steps :hero="true"></checkout-steps>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CheckoutSteps from '@/components/template/CheckoutSteps'

export default {
  components: {
    CheckoutSteps
  },
  props: [
    'type'
  ]
};
</script>
