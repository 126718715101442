// import _ from 'underscore'
import moment from 'moment'

export const normalizeCompany = ({document_number, fantasy_name, id, name, phone, address}) => ({
  document_number,
  fantasy_name,
  id,
  name,
  phone,
  address: address[0]
})

export const normalizeAddress = ({
  street,
  number,
  complement,
  neighborhood,
  city,
  province,
  country,
  zip_code
}) => ({
  street,
  number,
  complement,
  neighborhood,
  city,
  province,
  country,
  zip_code
})

export const normalizeBirthDate = (date) => (moment(date, 'YYYY-MM-DD').format('DDMMYYYY'))

export const normalizePersonalAccount = (account) => {
  account.birth_date = normalizeBirthDate(account.birth_date);
  return account;
}

// THE FULL OBJECT MUST BE APPLIED
// export const normalizePersonalAccount = ({
//   id,
//   full_name,
//   document_number,
//   birth_date,
//   gender,
//   mobile_phone,
//   phone,
//   email,
//   additional_email,
//   frequency_purchases,
//   buy_flights,
//   buy_hotels,
//   rent_cars,
//   address
// }) => ({
//   id,
//   full_name,
//   document_number,
//   birth_date: normalizeBirthDate(birth_date),
//   gender,
//   mobile_phone,
//   phone,
//   email,
//   additional_email,
//   frequency_purchases,
//   buy_flights,
//   buy_hotels,
//   rent_cars,
//   address: normalizeAddress(address)
// });
