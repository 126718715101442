<template>
  <div class="theme-hero-area theme-hero-area-full">
    <div class="theme-hero-area-bg-wrap">
      <div
        class="theme-hero-area-bg"
        :style="{'background-image': 'url(' + require('@/assets/img/bg-home-flight.jpg') + ')'}"
      ></div>
      <div class="theme-hero-area-mask theme-hero-area-mask-dark"></div>
      <div class="theme-hero-area-inner-shadow"></div>
    </div>
    <div class="theme-hero-area-body theme-hero-area-body-vert-center">
      <div class="container">
        <div class="theme-page-404 _ta-c">
          <h1 class="theme-page-404-title"></h1>
          <p class="theme-page-404-subtitle">Desculpe, não conseguimos encontrar esta página. Pode ser um link antigo ou a página foi movida</p>
          <router-link to="/">
            <a class="btn btn-ghost btn-xxl btn-white btn-uc" href="#">Ir para Home</a>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created(){
    this.$ga.event({
      eventCategory: 'Error',
      eventAction: 'Not Found',
      eventLabel: 'Not Found',
      eventValue: 1
    });
  }
};
</script>

<style scoped>
.theme-page-404-subtitle {
  font-size: 33px;
  margin-bottom: 45px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=65);
}
</style>
