<template>
  <aside class="mb-20">
    <h2 class="h4 box-pad-5"><strong>Resumo da Viagem</strong></h2>
    <ul class="border-blue box-list list-unstyled clearfix size-16 weight-lighter">
      <li class="box-list-item border-bottom">
        Hotéis <span class="pull-right weight-bold">{{ totalHotels | brl_price }}</span>
      </li>
      <li class="box-list-item border-bottom">
        Passagens <span class="pull-right weight-bold">{{ totalFlights | brl_price }}</span>
      </li>
      <li class="box-list-item">
        Carros <span class="pull-right weight-bold">{{ totalCars | brl_price }}</span>
      </li>
    </ul>
    <span class="btn-gray btn-block btn-extra-large clearfix">
      <span class="pull-left">Total</span> <span class="pull-right">{{ total | brl_price }}</span>
    </span>
    <router-link
      to="/checkout"
      v-if="total > 0"
      class="btn-blue btn-block btn-extra-large">
      Ir para pagamento
    </router-link>
    <router-link
      to="/"
      class="btn-gray-inverse btn-block btn-extra-large">
      <i class="fa fa-home fa-fw"></i>
      Continuar Comprando
    </router-link>
    <br>
  </aside>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  computed: {
    ...mapState('reserve', [
      'total'
    ]),
    ...mapGetters('reserve', ['totalByOrder']),
    totalHotels() {
      return this.totalByOrder('hotels')
    },
    totalFlights() {
      return this.totalByOrder('flights')
    },
    totalCars() {
      return this.totalByOrder('cars')
    }
  }
};
</script>
