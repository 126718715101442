<template>
  <div class="flight-item">
    <slot
      name="header"
      :handleCollapse="handleCollapse"
      :active="active"
      :index-key="flightItem.id"
      :flight="flight"></slot>

    <slot name="content"
      :handleCollapse="handleCollapse"
      :active="active"
      :index-key="flightItem.id"
      :flight="flight">
    </slot>
  </div>
</template>

<script>
export default {
  props: [
    'flightItem',
    'priceSlug',
    'priceList',
    'indexKey'
  ],
  data() {
    return {
      active: false
    }
  },
  computed: {
    flight() {
      return {
        price: this.priceList.filter(price => (price.brand.slug.toUpperCase() === this.priceSlug.toUpperCase()))[0] || {
          brand: {name: this.priceSlug.toUpperCase(), slug: this.priceSlug},
          links: this.priceList[0].links,
          baggage_included: this.priceList[0].baggage_included,
        },
        direction: this.flightItem.direction,
        origin: this.flightItem.segments[0].origin.iata_code,
        destination: this.flightItem.segments[this.flightItem.segments.length - 1].destination.iata_code,
        // TODO - VERIFY IF THERE IS ANOTHER CARRIER OBJECT IN PARENT
        carrier: this.flightItem.segments[0].carrier,
        travelTime: this.flightItem.travel_time,
        direct: this.flightItem.direct,
        originCity: this.flightItem.origin.name,
        destinationCity: this.flightItem.destination.name,
        dateDeparture: this.flightItem.segments[0].date_departure,
        dateArrival: this.flightItem.segments[this.flightItem.segments.length - 1].date_arrival,
        segmentsLen: this.flightItem.segments.length,
        segments: this.flightItem.segments,
        designators: this.flightItem.segments.map((segment) => (`${segment.flight_number}`))
      }
    }
  },
  methods: {
    handleCollapse() {
      const active = !this.active;
      this.active = active;
    }
  }
};
</script>
