<template>
  <section class="clearfix col-sm-3 content-date">
    <h2 class="col-xs-6 col-sm-12 content-date-weekday">
      {{ content.dateDeparture | date_to_weekday }}
    </h2>
    <div class="col-xs-6 col-sm-12 clearfix content-date-carrier">
      <img :src="require(`@/assets/img/carriers/${ content.carrier.iata_code }.png`)" alt="" />
      <h3>Operado por {{ content.carrier.name }}</h3>
    </div>
    <div class="col-xs-12 clearfix">
      <div class="content-date-designators">
        <i class="fa fa-plane ico-plane"></i>&nbsp;
        <span
          v-for="(designator, index) in content.designators"
          :key="`flight-number-${index}-${designator}`">
          {{ content.carrier.iata_code }}{{ designator | normalize_flight_number }}
        </span>
      </div>
    </div>
  </section>
</template>

<script>

export default{
  props: [
    'content'
  ]
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/ContentDate';
</style>
