import { axiosUpdateHeaders } from '@/axios'

export const state = {
  results: [],
  loading: false,
  alert: ''
}

export const mutations = {
  setResult (state, data) {
    state.results = data.results;
  },
  setLoading (state) {
    state.loading = !state.loading;
  },
  setAlert (state, alert) {
    state.alert = alert;
  }
}

export const actions = {
  getCreditcards (context) {
    context.commit('setLoading');

    const axios = axiosUpdateHeaders();
    return axios.get('/api/cards/').then(response => {
      if (response.status === 200) {
        context.commit('setResult', response.data);
      }
      context.commit('setLoading');
    }).catch(error => {
      /* TODO HANDLE ERROR */
      context.commit('setLoading');
    })
  },
  postCreditcard (context, data) {
    context.commit('setLoading');
    context.commit('setAlert', '');

    const axios = axiosUpdateHeaders();
    return axios.post('/api/cards/', data).then((response) => {
      if (response.status === 200 || response.status === 201) {
        // UPDATE AVAILABLE CARDS
        context.dispatch('getCreditcards');

        context.commit('setAlert', 'Cartão criado com sucesso.');
        context.commit('setLoading');
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.data)
      }
    })
    .catch(error => {
      /* TODO HANDLE ERROR */
      // context.commit('setAlert', error.response.data.message);
      context.commit('setLoading');
      return Promise.reject(error.response.data)
    });
  }
}

export default {
  namespaced : true,
  state,
  mutations,
  actions
  /* getters */
}
