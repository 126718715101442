<template>
  <div>
    <div class="theme-hero-area theme-hero-area-primary">
      <div class="theme-hero-area-bg-wrap">
        <div
          class="theme-hero-area-bg ws-action"
          :style="{'background-image': 'url(' + require('@/assets/img/bg-home-flight.jpg') + ')'}"
          data-parallax="true"
        ></div>
        <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
      </div>
      <div class="theme-hero-area-body hgt-280">
        <div class="container">
          <div class="_pt-70 _pb-30 _pv-mob-50">
            <div class="theme-search-area-white theme-search-area-header">
              <h1 class="theme-search-area-title theme-search-area-title-lg"></h1>
              <p class="theme-search-area-subtitle"></p>
            </div>
            <div class="tab-content _pt-15 _pb-15 tab-content-curved">
              <flight-search></flight-search>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Componetizar -->
    <div class="theme-page-section theme-page-section-dark" style="background-color:#eee">
      <div class="container">
        <div class="theme-page-section-header">
          <h5 class="theme-page-section-title text-left"></h5>
          <p class="theme-page-section-subtitle text-left"></p>
        </div>
        <div class="row row-col-gap" data-gutter="20">
          <div class="col-md-4">
            <div
              class="banner _h-33vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
            >
              <div
                class="banner-bg"
                :style="{'background-image': 'url(' + require('@/assets/img/500x400.png') + ')'}"
              ></div>
              <div class="banner-mask"></div>
              <a class="banner-link" href="#"></a>
              <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                <h5 class="banner-title"></h5>
                <p class="banner-subtitle"></p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="banner _h-33vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
            >
              <div
                class="banner-bg"
                :style="{'background-image': 'url(' + require('@/assets/img/500x400.png') + ')'}"
              ></div>
              <div class="banner-mask"></div>
              <a class="banner-link" href="#"></a>
              <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                <h5 class="banner-title"></h5>
                <p class="banner-subtitle"></p>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div
              class="banner _h-33vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
            >
              <div
                class="banner-bg"
                :style="{'background-image': 'url(' + require('@/assets/img/500x400.png') + ')'}"
              ></div>
              <div class="banner-mask"></div>
              <a class="banner-link" href="#"></a>
              <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                <h5 class="banner-title"></h5>
                <p class="banner-subtitle"></p>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div
              class="banner _h-40vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
            >
              <div
                class="banner-bg"
                :style="{'background-image': 'url(' + require('@/assets/img/800x600.png') + ')'}"
              ></div>
              <div class="banner-mask"></div>
              <a class="banner-link" href="#"></a>
              <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                <h5 class="banner-title">Hospedagens</h5>
                <p class="banner-subtitle">As melhores opções em até 12x SEM JUROS!</p>
              </div>
            </div>
          </div>
          <div class="col-md-7">
            <div
              class="banner _h-40vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
            >
              <div
                class="banner-bg"
                :style="{'background-image': 'url(' + require('@/assets/img/800x600.png') + ')'}"
              ></div>
              <div class="banner-mask"></div>
              <a class="banner-link" href="#"></a>
              <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                <h5 class="banner-title"></h5>
                <p class="banner-subtitle"></p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="banner _h-40vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
            >
              <div
                class="banner-bg"
                :style="{'background-image': 'url(' + require('@/assets/img/800x600.png') + ')'}"
              ></div>
              <div class="banner-mask"></div>
              <a class="banner-link" href="#"></a>
              <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                <h5 class="banner-title"></h5>
                <p class="banner-subtitle"></p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div
              class="banner _h-40vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
            >
              <div
                class="banner-bg"
                :style="{'background-image': 'url(' + require('@/assets/img/800x600.png') + ')'}"
              ></div>
              <div class="banner-mask"></div>
              <a class="banner-link" href="#"></a>
              <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                <h5 class="banner-title"></h5>
                <p class="banner-subtitle"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Componetizar -->
  </div>
</template>

<script>
import FlightSearch from "../components/flightsearch.vue";
export default {
  components: {
    FlightSearch
  },
  created() {
      let params = this.$route.params
      this.$ga.page({
        page: "/",
        title: 'Flights',
        location: window.location.href
      });
  },
  methods: {
  }
};
</script>

<style>
</style>