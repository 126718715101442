<template>
  <div class="clearfix prices-grid">
    <ul class="col-xs-4 text-right">
      <li>
        <strong>Melhor preço<span class="hidden-xs"> por companhia</span></strong>
      </li>
      <template v-if="!active">
        <li v-for="(stop) in Object.keys(lowestPrices.values)">
          <template v-if="Number(stop) >= 1">
            {{ stop }}
            <template v-if="Number(stop) === 1">parada</template>
            <template v-else>paradas <span class="hidden-xs">ou mais</span></template>
          </template>
          <template v-else>
            direto
          </template>
        </li>
      </template>
    </ul>
    <div class="col-xs-8">
      <template v-if="!active">
        <ul
          v-for="(carrier) in lowestPrices.header"
          :key="`prices-grid-carrier-${carrier}`"
          :class="[`col-xs-${12/lowestPrices.header.length}`, lowestPrices.header.length > 1 ? 'text-center' : 'text-left']">
          <li class="prices-grid-carrier">
            <img :src="require(`@/assets/img/carriers/${carrier}.png`)" alt="" />
            {{ lowestPrices.carriers[carrier].name.split(' ')[0] }}
          </li>
          <li
            v-for="(stop) in Object.keys(lowestPrices.values)"
            :key="`prices-grid-stops-${stop}`">
            <template v-if="lowestPrices.values[stop][carrier]">
              <span
                :class="{ 'active' : lowestPrices.values[stop][carrier].best_price }"
                class="prices-grid-handle"
                href="#">
                {{ lowestPrices.values[stop][carrier].price | brl_price }}
              </span>
            </template>
          </li>
        </ul>
      </template>
      <template v-else>
        <ul class="col-xs-12">
          <li>
            <template v-if="selected.stops > 1">
              {{ selected.stops }} {{ selected.stops === 1 ? `parada`: `paradas ou mais`}}
            </template>
            <template v-else>
              direto
            </template> |
            <span class="prices-grid-carrier text-center">
              <img :src="require(`@/assets/img/carriers/${selected.iata_code}.png`)" alt="" />
              {{ lowestPrices.carriers[selected.iata_code].name.split(' ')[0] }}
            </span> | <span>{{ selected.lowest_price | brl_price }}</span>
            <button
              type="button"
              class="pull-right prices-grid-remove">
              X remover
            </button>
          </li>
        </ul>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'lowestPrices'
  ],
  data() {
    return {
      selected: {},
      active: false
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/PricesGrid';
</style>
