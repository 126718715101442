<template>
  <div>
    <modal-dialog :handleClose="handleClose">
      <template v-slot:trigger="{ openModal, active }">
        <a
          @click.prevent="openModal"
          class="btn-magenta-inverse travel-list-remove">
          - Remover
        </a>
      </template>
      <template v-slot:header>
        <div class="col-xs-12 clearfix">
          <div class="size-20 mt-20 col-xs-12">Remover item da viagem?</div>
        </div>
      </template>
      <template v-slot:body="{ closeModal }">
        <div class="col-xs-12 clearfix">
          <!-- SLOT -->
          <slot
            name="description"
            :item="item"></slot>
          <!-- // SLOT -->
          <div class="mt-20 mb-20 clearfix">
            <div class="travel-list-value size-16 weight-lighter">
              <span class="size-18 weight-bold">Subtotal</span>
              <span class="size-18 weight-bold pull-right display-block">{{ item.total | brl_price }}</span>
            </div>
          </div>
          <div class="mb-20 mt-20">
            <button
              @click="closeModal"
              class="btn-magenta-inverse btn-mid"
              type="button">
              Cancelar
            </button>&nbsp;
            <button
              @click="handleRemove(item.id)"
              class="btn-magenta btn-mid"
              type="button">
              Remover
            </button>
          </div>
        </div>
      </template>
    </modal-dialog>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ModalDialog from '@/components/ui/ModalDialog'

export default {
  components: {
    ModalDialog
  },
  props: [
    'item'
  ],
  data () {
    return {
      handleClose: ''
    }
  },
  methods: {
    ...mapActions('reserve', [
      'postReserveRemove'
    ]),
    handleRemove (id) {
      this.handleClose = '';

      this.postReserveRemove(id).then(() => {
        this.handleClose = false;
      }).catch(() => {
        this.handleClose = false;
      });
    }
  }
};
</script>
