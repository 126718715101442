<template>
  <aside class="mb-20">
    <h2 class="h4 box-pad-5">Sua Reserva</h2>
    <ul class="border-blue box-list list-unstyled clearfix size-14 weight-lighter">
      <li
        v-for="(charge, index) in filteredCharges"
        :key="`charge-${index}`"
        class="box-list-item border-bottom clearfix">
        {{ charge.description }}
        <span
          v-if="charge.unit_name && charge.unit_name === 'Day'"
          class="size-11">
          {{ charge.quantity ? charge.quantity + 'x': '1x' }} {{ charge.unit_value | brl_price }}
        </span>
        <span class="pull-right weight-bold">{{ charge.amount | brl_price }}</span>
      </li>
      <li
        :class="{ 'border-bottom': (optionals.length !== 0) }"
        class="box-list-item clearfix">
        {{ fee.description }}
        <span
          v-if="fee.unit_name && fee.unit_name === 'Day'"
          class="size-11">
          {{ fee.quantity ? fee.quantity + 'x': '1x' }} {{ fee.unit_value | brl_price }}
        </span>
        <span class="pull-right weight-bold">{{ fee.amount | brl_price }}</span>
      </li>
      <li
        v-if="optionals.length"
        class="box-list-item clearfix border-bottom">
        <strong class="size-11">Opcionais</strong>
      </li>
      <li
        v-for="(optional, index) in optionals"
        :key="`optional-${index}`"
        :class="{ 'border-bottom': index < (optionals.length - 1) }"
        class="box-list-item clearfix">
        {{ optional.name }}
        <span class="size-11">
          {{ dailyRate ? dailyRate + 'x': '1x' }} {{ optional.charge.unit_value | brl_price }}
        </span>
        <span class="pull-right weight-bold">
          {{ optional.charge.amount | brl_price }}
        </span>
      </li>
    </ul>
    <span class="btn-gray btn-block btn-extra-large clearfix">
      <span class="pull-left">Total</span>
      <span class="pull-right">{{ total | brl_price }}</span>
    </span>
    <br>
  </aside>
</template>

<script>
export default {
  props: [
    'dailyRate',
    'charges',
    'optionals',
    'coverages'
  ],
  computed: {
    filteredCharges () {
      return this.charges.filter(charge => (charge.description !== 'TAXA ADMINISTRATIVA'));
    },
    chargesAmount () {
      return this.charges.filter(charge => (charge.description !== 'TAXA ADMINISTRATIVA' && charge.description !== 'TAXA SERVIÇO')).map(charge => charge.amount);
    },
    optionalsAmount () {
      return this.optionals.map(optional => optional.charge.amount);
    },
    subTotal () {
      return [
        ...this.chargesAmount,
        ...this.optionalsAmount
        /* coverages not included into the sum to calculate percentage */
      ].reduce((memo, num) => (memo + num));
    },
    total () {
      return [
        this.fee.amount,
        ...this.filteredCharges.map(charge => charge.amount),
        ...this.optionalsAmount
        /* coverages not included into the sum to calculate percentage */
      ].reduce((memo, num) => (memo + num));
    },
    fee () {
      let fee = this.charges.filter(charge => (charge.description === 'TAXA ADMINISTRATIVA'))[0];
      fee.amount = this.subTotal * (12 / 100);
      fee.unit_value = fee.amount;
      return { ...fee };
    }
  }
};
</script>
