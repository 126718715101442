<template>
  <div class="theme-search-area">
    <form @submit.prevent="search_flights">
      <div class="theme-search-area-form">
        <div class="row" data-gutter="10">
          <div class="col-md-6">
            <div class="row" data-gutter="10">
              <div class="col-md-6">
                <div
                  class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-fade-white"
                >
                  <div class="theme-search-area-section-inner">
                    <i class="theme-search-area-section-icon lin lin-location-pin"></i>
                    <autocomplete
                      class="form-control"
                      type="text"
                      name="origin"
                      v-model="origin"
                      v-validate="'required'"
                      :dataInitial="OriginFlightAutoCompleteDataInitial"
                      :placeholder="'Aeroporto de Origem'"
                    ></autocomplete>
                  </div>
                  <span class="error-message-forms">{{ errors.first('origin') }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div
                  class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
                >
                  <div class="theme-search-area-section-inner">
                    <i class="theme-search-area-section-icon lin lin-location-pin"></i>
                    <autocomplete
                      class="form-control"
                      type="text"
                      name="destination"
                      v-model="destination"
                      v-validate="'required'"
                      :dataInitial="DestinationFlightAutoCompleteDataInitial"
                      :placeholder="'Aeroporto de Destino'"
                    ></autocomplete>
                  </div>
                  <span class="error-message-forms">{{ errors.first('destination') }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row" data-gutter="10">
              <div class="col-md-8">
                <div
                  class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
                >
                  <div class="theme-search-area-section-inner">
                    <i class="theme-search-area-section-icon lin lin-calendar"></i>
                    <date-range-picker
                      class="form-control"
                      name="date"
                      v-validate="'required'"
                      v-model="date"
                      :init="init"
                      :singlePick="!outbound_and_inbound"
                      ref="rangedate"
                      :placeholder="outbound_and_inbound ? 'Datas de ida e volta': 'Data de ida'"
                      sameDate="true"
                    ></date-range-picker>
                  </div>
                  <span class="error-message-forms">{{ errors.first('date') }}</span>
                </div>
              </div>
              <div class="col-md-4">
                <div
                  class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white quantity-selector"
                  data-increment="Passengers"
                >
                  <div class="theme-search-area-section-inner">
                    <i class="theme-search-area-section-icon lin lin-people"></i>
                    <select
                      class="theme-search-area-section-input"
                      v-model="adults"
                      
                    >
                      <option value="1" selected>1 Adulto</option>
                      <option value="2">2 Adultos</option>
                      <option value="3">3 Adultos</option>
                      <option value="4">4 Adultos</option>
                      <option value="5">5 Adultos</option>
                      <option value="6">6 Adultos</option>
                      <option value="7">7 Adultos</option>
                      <option value="8">8 Adultos</option>
                    </select>
                  </div>
                </div>              
              </div>
            </div>
          </div>
          <div class="col-md-1">
            <button
              type="submit"
              class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary"
            >
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
    <div class="theme-search-area-options theme-search-area-options-center clearfix">
      <div class="btn-group theme-search-area-options-list">
        <p-radio
          class="p-default p-round p-smooth p-plain"
          name="radio1"
          id="flight-option-1"
          color="primary-o"
          v-model="outbound_and_inbound"
          :checked="true"
          :value="true"
        >&nbsp;Ida e volta</p-radio>
        <p-radio
          class="p-default p-round p-smooth p-plain"
          name="radio1"
          color="primary-o"
          id="flight-option-2"
          :value="false"
          v-model="outbound_and_inbound"
        >&nbsp;Somente ida</p-radio>
      </div>
    </div>
  </div>
</template>

<script>
import Autocomplete from "./fields/flights/Autocomplete.vue";
import DateRangePicker from "./fields/DateRangePicker.vue";
import moment from "moment";
import alerts from "../mixins/alerts";

export default {
  data() {
    return {
      OriginFlightAutoCompleteDataInitial: [],
      DestinationFlightAutoCompleteDataInitial: [],      
      init: {},
      origin: '',
      destination: '',
      date: '',
      adults: 1,
      submitted: false,
      outbound_and_inbound: true,

    };
  },
  props: ["flight"],
  components: {
    Autocomplete,
    DateRangePicker
  },
  beforeMount() {
    let params = this.$route.params;

    if (!Object.keys(params).length) return false;

    this.init = {
      startDate: params.outbound,
      endDate: params.inbound
    };

    if (!params.inbound) {
      this.outbound_and_inbound = false;
    }

    this.adults = params.adults;

  },
  mounted() {
    if (this.$route.params.origin) {
      this._fillAutoComplete({ text: this.$route.params.origin }, "origin");
    }
    if (this.$route.params.destination) {
      this._fillAutoComplete({ text: this.$route.params.destination }, "destination");
    }
  },
  methods: {
    _fillAutoComplete(params, field) {
      if (!Object.keys(params).length) return false;
      if (field === "origin") {
        this.OriginFlightAutoCompleteDataInitial = [
          {
            text: params.text,
            id: params.text,
            el: {}
          }
        ];
        this.origin = this.OriginFlightAutoCompleteDataInitial[0];
      }
      if (field === "destination") {
        this.DestinationFlightAutoCompleteDataInitial = [
          {
            text: params.text,
            id: params.text,
            el: {}
          }
        ];
        this.destination = this.DestinationFlightAutoCompleteDataInitial[0];
      }
    },
    search_flights(e) {
      e.preventDefault();
      this.$validator.validate().then(valid => {
        this.submitted = true;
        if (valid) {

          const DATE_BR_FORMAT = 'DD/MM/YYYY';
          const DATE_ISO_8601 = 'YYYY-MM-DD';
          let going = moment(this.date.dateStart, DATE_BR_FORMAT).format(DATE_ISO_8601);
          let returning = moment(this.date.dateEnd, DATE_BR_FORMAT).format(DATE_ISO_8601);
          
          let url;
          if (this.outbound_and_inbound){
            url = `/flights/search/${this.origin.id}/${this.destination.id}/${this.adults}/${going}/${returning}`;
          } else {
            url = `/flights/search/${this.origin.id}/${this.destination.id}/${this.adults}/${going}`;
          }
          this.$router.push(url);

        } else {
          this.$swal({
            title: "Favor verificar os campos de busca",
            type: "error",
            confirmButtonText: "SAIR"
          });
        }
      });
    }
  }
};
</script>

<style scoped>
#daterange {
  height: 55px !important;
  padding: 6px 40px;
  border: 0 !important;
  color: #6a6a6a !important;
  border-radius: 3px !important;
}
.theme-search-area-options-list {
  margin-right: -5px;
  color: #ffffff;
}
</style>
