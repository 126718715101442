<template>
  <section class="row flight-direction">
    <!-- SLOT Flight Item -->
    <flight-slot
      :flight-item="flight"
      :price-slug="journey.price.slug.toUpperCase()"
      :price-list="flight.prices">
      <!-- :price-list="journey.prices || flight.prices"> -->
      <template v-slot:header="{handleCollapse, active, flight, indexKey}">
        <div class="clearfix">
          <!-- Content Date -->
          <content-date :content="flight"></content-date>
          <!-- // Content Date -->
          <!-- Content Hourly -->
          <content-hourly
            :active="active"
            :content="flight"></content-hourly>
          <!-- // Content Hourly -->

          <!-- Content Detail -->
          <content-detail
            :indexKey="indexKey"
            :active="active"
            :content="flight"
            :link-class="'pull-right'"
            @handleCollapseUpdate="handleCollapse">
            <template v-slot:main="{price}">
              <div class="flight-direction-price-title">
                <i
                  class="fa fa-suitcase ico-suitcase"
                  :class="{ 'ico-disabled': price.baggage_included }"></i>

                <!-- Price Detail // FARE RULES -->
                <price-detail
                  :index="price.id"
                  :links="price.links"
                  :carrier="flight.segments[0].carrier"></price-detail>
                <!-- // Price Detail // FARE RULES -->
                {{ price.brand.name }}
                <span v-if="price.base">{{ price.base | brl_price }}</span>
              </div>
            </template>
          </content-detail>
          <!-- // Content Detail -->
        </div>
      </template>

      <template v-slot:content="{handleCollapse, active, flight, indexKey}">
        <section
          v-show="active"
          :id="`content-details-${flight.direction}-${indexKey}`"
          class="row flight-direction-detail">
          <div class="col-xs-12">
            <button
              @click="handleCollapse"
              class="btn-link pull-right flight-direction-detail-close"
              type="button">
              fechar <i class="fa fa-chevron-up"></i>
            </button>
          </div>
          <!-- Flight Detail -->
          <flight-detail
            v-for="(detail, index) in flight.segments"
            :key="`flight-detail-${flight.direction}-${index}`"
            :detail="detail"
            :date-departure="flight.dateDeparture"
            :index-key="index"
            ></flight-detail>
          <!-- // Flight Detail -->
        </section>
      </template>
    </flight-slot>
    <!-- // SLOT Flight Item -->
  </section>
</template>

<script>
import FlightSlot from '@/views/flights/search/FlightSlot';
import ContentDate from '@/views/flights/search/ContentDate';
import ContentHourly from '@/views/flights/search/ContentHourly';
import ContentDetail from '@/views/flights/search/ContentDetail';
import PriceDetail from '@/views/flights/search/PriceDetail';
import FlightDetail from '@/views/flights/search/FlightDetail';

export default {
  components: {
    FlightSlot,
    ContentDate,
    ContentHourly,
    ContentDetail,
    PriceDetail,
    FlightDetail
  },
  props: [
    'flight',
    'journey'
  ]
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/FlightDirection';
</style>
