<template>
  <div>
    <div class="modal fade" id="modalReject" tabindex="-1" role="dialog" aria-labelledby="modal-changeLabel" aria-hidden="true">
      <form id="formReject" @submit.prevent="reject($event)">
        <div class="modal-dialog" role="document">
          <div class="modal-content change-modal">
            <div class="modal-header change-modal-header">
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h3 class="modal-title text-center" id="exampleModalLabel"></h3>
            </div>
            <div class="modal-body">
              <div class="ro">
                <div class="col-md-12">
                    <label>
                      Motivo da recusa
                    </label>
                    <select class="form-control" v-model="rejection.reason">
                      <option value=""></option>
                      <option v-for="reason in justifications" :key="reason.id" :value="reason.id" v-text="reason.name"></option>
                    </select>
                  
                </div>
              </div>
            </div>
            <div class="clearfix"></div>
            <div class="modal-footer change-modal-footer">
              <div class="col-md-6 padding-left-off">
                <button
                  type="button"
                  v-on:click="cancel_reject()"
                  class="btn _tt-uc btn-primary-inverse btn-lg mt-10 btn-block"
                  data-dismiss="modal"
                >Cancelar</button>
              </div>
              <div class="col-md-6 padding-right-off">
                <button
                  type="submit"
                  class="btn _tt-uc btn-primary btn-lg mt-10 btn-block"
                >Recusar</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div class="col-md-10">
            <div class="col-md-12 padding-off">
              <h1 class="theme-account-page-title">Aprovador</h1>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-8 padding-left-off">
                  <div
                    class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
                  >
                    <div class="theme-search-area-section-inner">
                      <input
                        type="text"
                        placeholder="Pesquise pelo nº do pedido"
                        data-provide="typeahead"
                        class="theme-search-area-section-input"
                        v-model="search"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3 padding-left-off">
                  <div
                    class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
                  >
                    <div class="theme-search-area-section-inner">
                      <select class="theme-search-area-section-select" v-model="search_status">
                        <option value="0">Todos</option>
                        <option value="1">Abertas</option>
                        <option value="2">Aprovadas</option>
                        <option value="3">Rejeitadas</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 padding-off">
                  <button
                    v-on:click="doSearch"
                    class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary"
                  >
                    <i aria-hidden="true" class="fa fa-search"></i>
                  </button>
                </div>
              </div>

              <div class="col-md-12 mt-20">
                <div class="theme-account-history table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Pedido</th>
                        <th>Data</th>
                        <th>Informações</th>
                        <th>Total</th>
                        <th>Status</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody v-if="orders.length">
                      <template v-for="order in orders">
                        <tr :class="{danger: check_out_of_time(order.item )}">
                          <td class>
                            <a href="javascript:void(0)" v-on:click="open_details(order.item.order)">
                              <p class="theme-account-history-type-title">{{order.item.order.number}}</p>
                            </a>
                          </td>
                          <td class>
                            <p
                              class="theme-account-history-date"
                            >{{order.creation_date | short_date_to_human}}</p>
                          </td>
                          <td>
                            <div class="pb-20">
                              <template v-if="order.item.product === 2">
                                <template v-for="segments in order.item.flight.segments" >
                                  <p class="theme-account-history-type-title" :key="segments.id">
                                    {{segments.origin}}
                                    <i class="fa fa-exchange" aria-hidden="true"></i>
                                    {{segments.destination}}
                                    <br><small>Saída: {{segments.departure | date_to_human}} - {{segments.departure | date_to_hour}}</small>
                                    <br><small>Chegada: {{segments.arrival | date_to_human}} - {{segments.arrival | date_to_hour}}</small>
                                    <br><small>Localizador: {{ order.item.flight.locator }}</small>
                                  </p>
                                </template>
                              </template>
                              
                              <template v-if="order.item.product === 1">
                                <p class="theme-account-history-type-title">
                                  <small>
                                    Hotel: {{order.item.room.hotel.name}}<br>
                                    Quarto: {{order.item.room.description}}<br>
                                    Checkin: {{order.item.room.checkin | date_to_human}}<br>
                                    Checkout: {{order.item.room.checkout | date_to_human}}
                                    <br>
                                  </small>
                                </p>
                              </template>
                              <template v-if="order.item.product === 3">
                                <p class="theme-account-history-type-title">
                                  <small>
                                    Carro: {{order.item.vehicle.vehicle.description}}<br>
                                    Retirada: {{order.item.vehicle.pickup_agency.name}}<br>
                                    Data: {{order.item.vehicle.start_date | date_to_human}}<br>
                                    <hr>
                                    Devolução: {{order.item.vehicle.delivery_agency.name}}<br>
                                    Data: {{order.item.vehicle.end_date | date_to_human}}<br>
                                  </small>
                                </p>
                              </template>
                              <br>
                              <small>
                                Solicitante: {{ order.item.order.requester.full_name }} <br>
                                <hr>
                                <template v-for="traveler in order.item.travelers">
                                  Viajantes: <br>
                                  {{ traveler.full_name }} <br>
                                </template>
                              </small>
                            </div>
                          </td>
                          <td>
                            <p class="theme-account-history-item-price">{{order.item.total | brl_price}}</p>
                          </td>
                          <td>
                            <p>
                              <i
                                class="fa fa-lg"
                                :class="{'fa-times-circle text-danger': order.status === 3,
                                'fa-check-circle text-success': order.status === 2,
                                'fa-clock-o text-warning': order.status === 1,
                                }"
                              ></i>
                              &nbsp;
                              <span class="text-warning" v-if="order.status===1">aberto</span>
                              <span class="text-warning" v-if="order.status===2">Aprovado</span>
                              <span class="text-warning" v-if="order.status===3">Rejeitado</span>
                              <span class="text-warning" v-if="order.current_stage.name">
                                <br>
                                Nível: {{ order.current_stage.name }}
                              </span>
                              
                            </p>
                          </td>
                          <td>
                            <div v-if="order.status==1">
                              <a v-on:click="approve(order)" class="btn btn-sm btn-success">Aprovar</a>
                              <a data-toggle="modal" v-on:click="to_reject(order)" data-target="#modalReject" class="btn btn-sm btn-danger">Recusar</a>
                              <p v-if="check_out_of_time(order.item)" class="text-warning">Fora do prazo de antecedencia</p>
                            </div>
                          </td>
                        </tr>
                      </template>
                    </tbody>
                    <tbody v-else>
                      <tr class="text-center">
                        <td colspan="6">Não existem itens</td>
                      </tr>
                    </tbody>
                  </table>                
                </div>
                <button v-on:click="nextPage(search, next_page)" v-if="next_page" class="btn-block theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary">
                  Carregar mais resultados
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import MenuAccount from "../../components/menuaccount.vue";
import lockscreen from "../../components/lockscreen/index.vue";
import _ from "underscore";
export default {
  data() {
    return {
      search: null,
      search_status: "1",
      orders: [],
      loading: false,
      next_page: null,
      justifications: [],
      rejection: {
        reason: null,
        current_item: null
      }
    };
  },
  components: {
    MenuAccount,
    lockscreen
  },
  created: function() {
    this.fetchJustifications();
    this.fetchData();
  },
  computed: {
    filteredList() {
      return this.orders.filter(order => {
        let number = order.number.toLowerCase();
        if (number.includes(this.search.toLowerCase())) {
          if (this.search_status !== "all") {
            return order.status === this.search_status;
          }
          return true;
        }
      });
    }
  },
  methods: {
    check_out_of_time(item) {
      let user = this.$store.getters.getUser;
      let company  = user.companies[0];
      let policy = company.policy || false;
      let is_out_of_time = false;

      if (policy) {
        let antecedence_config = policy.antecedence_config;

        if (antecedence_config) {
          if (is_out_of_time) return;

          let orderitem = item;
          let antecedence = _.findWhere(antecedence_config, {product: orderitem.product});
          let future = moment(item.modification_date).add(antecedence.hours, 'hours');

          if (orderitem.product == 1) {
            // hotel
            is_out_of_time = future > moment(orderitem.room.checkin);
          }

          if (orderitem.product == 2) {
            // flight
            is_out_of_time = future > moment(orderitem.flight.segments[0].departure);
          }

          if (orderitem.product == 3) {
            // vehicle
            is_out_of_time = future > moment(orderitem.vehicle.start_date);
          }
          return is_out_of_time
        }
      } 

      return is_out_of_time;
    },
    fetchData() {
      this.get_data().then((response) => {
        this.orders = response.data.results;
      })
    },
    doSearch() {
      this.get_data(this.search).then((response) => {
        this.orders = response.data.results;
      });
    },
    nextPage(term, more_data_url) {
      this.get_data(this.search, more_data_url).then((response) => {
        this.orders = this.orders.concat(response.data.results);
      });
    },
    get_data(term=null, more_data_url=null) {
      return new Promise((resolve, reject) => {
        let url = "/api/policy/list";
        if (more_data_url) {
          url = more_data_url
        }

        if (term) {
          if (url.indexOf('?') > 0) {
            url = `${url}&term=${term}`;
          } else {
            url = `${url}?term=${term}`;
          }
        }

        if (this.search_status) {
          if (url.indexOf('?') > 0) {
            url = `${url}&status=${this.search_status}`;
          } else {
            url = `${url}?status=${this.search_status}`;
          }
        }

        this.loading = true;
        this.$http
          .get(url)
          .then(response => {
            this.loading = false;
            this.next_page = response.data.next;         
            return resolve(response)
          })
          .catch(error => {
            console.log(error);
            return reject(error)
          });
      });
    },
    approve(item) {
      console.log(item)
      this.$swal({
          title: "Tem certeza que deseja aprovar essa reserva?",
          type: "question",
          showConfirmButton: true,
          showCancelButton: true,
        }).then((result) => {
          if (result.dismiss) return false;
          this.loading = true;
          this.$http.post('/api/policy/approve', {approval_request: item.id}).then(response => {
            this.loading = false
            this.$swal({
              title: "reserva aprovada com sucesso!",
              type: "success",
              showConfirmButton: true
            }).then(()=>{
              this.fetchData();
            })
          }).catch((error) => {
            this.loading = false;
            // debugger
            let message = 'Ocorreu um problema ao aprovar essa reserva';
            if (error.response.data.message) {
              message = error.response.data.message;
            }
            
            this.$swal({
              title: message,
              type: "error",
              showConfirmButton: true
            });
          });
        });
    },
    to_reject(item){
      this.rejection.current_item = item.id
    },
    cancel_reject() {
      this.rejection.current_item = null;
      this.rejection.reason = null;
    },
    reject() {
      if (!this.rejection.current_item) return false;
      this.loading = true;
      this.$http.post('/api/policy/reject', {
        approval_request: this.rejection.current_item,
        reason: this.rejection.reason
      }).then(response => {
        this.loading = false;
        this.$swal({
          title: response.data.message,
          type: "success",
          showConfirmButton: true
        }).then(()=> {
          
          $('#modalReject').modal('hide');
          this.fetchData();
        })
      }).catch((error) => {
        this.loading = false;
        // debugger
        let message = 'Ocorreu um problema ao recusar essa reserva';
        if (error.response.data.message) {
          message = error.response.data.message;
        }
        
        this.$swal({
          title: message,
          type: "error",
          showConfirmButton: true
        });
      });
    },
    fetchJustifications() {
      this.$http.get('/api/policy/reasons_reject').then(response => {
        this.justifications = response.data;
      });
    },
    open_details(order) {
      this.$router.push({
        name: "order_details",
        params: { order: order, id: order.id }
      });
    }
  }
};
</script>

<style scoped>
.theme-search-area-section-input {
  display: block;
  width: 100%;
  border: none !important;
  outline: none !important;
  height: 55px;
  padding-left: 16px;
  font-size: 16px;
  background: none;
}
.theme-search-area-section-select {
  display: block;
  width: 100%;
  border: none !important;
  outline: none !important;
  height: 55px;
  padding-left: 12px;
  font-size: 16px;
  background: none;
  position: relative;
  color: #7a7a7a;
}
.theme-account-history-type-icon {
  opacity: 0.65;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=65);
  padding-left: 3px;
  padding-right: 3px;
}
</style>
