import _ from 'underscore';
import moment from 'moment';

import { mapActions } from 'vuex'

function initial_data() {

  return {
    flight: {
      origin: null,
      origin_text: null,
      destination: null,
      destination_text: null,
      date: null,
      going: null,
      returning: null,
      adults: 1,
      travelers: [],
      outbound_and_inbound: 'true',
      carrier: null,
      hash: null,
      booking: null,
      price: {
        outbound_id: null,
        outbound: 0,
        inbound_id: null,
        inbound: 0,
        journey_id: null,
        total: 0,
        id: null,
        slug: null,
        taxes: 0,
        discount: 0
      },
      payment_info: {
        outbound_installments: 1,
        inbound_installments: 1,
        plans: []
      },
      flights: {
        outbound: {
          id: null,
        },
        inbound: {
          id: null,
        }
      }
    },
    filter: {
      segments_1: null,
      segments_2: null,
      segments_3: null,
      class: null,
      price: [0, 5000],
      going_time: [0, 24],
      airlines: {}

    },
    selected_traveler: null,
    next_url: null,
    outbound_flights: [],
    inbound_flights: [],
    total_outbound_results: 0,
    total_inbound_results: 0,
    loading: false,
    limit: 800,
    show_spinner: null,
    submitted: false
  }
}

export default {
  data() {
    let data = initial_data();

    // let outbound_and_inbound;

    // if (this.$route.params.inbound) {
    //   outbound_and_inbound = 'true';
    // } else {
    //   outbound_and_inbound = 'false';
    // }

    // data.flight.outbound_and_inbound = outbound_and_inbound;
    return data
  },
  methods: {
    search_flights(flight, inbound_url, next_url) {
      const DATE_BR_FORMAT = 'DD/MM/YYYY';
      const DATE_ISO_8601 = 'YYYY-MM-DD';

      if (next_url) {
        this.loading = true;
        this.$http.get(next_url).then(response => {
          if (response.status === 200) {
            this.next_url = response.data.next_url;
            this.results = [this.results, response.data.results];
          }
        }).catch(error => {
          console.log(error)
        }).finally(() => {
          this.loading = false;
        })
      }

      if (flight && !inbound_url) {
        // Clean flight before search with new params
        this.cleanSelectedFlights();

        if (flight.origin.value) {
          flight.origin = flight.origin.value
        }
        if (flight.destination.value) {
          flight.destination = flight.destination.value
        }

        this.loading = true;
        flight.going = moment(flight.date.dateStart, DATE_BR_FORMAT).format(DATE_ISO_8601);

        if (this.flight.outbound_and_inbound === 'true') {
          flight.returning = moment(flight.date.dateEnd, DATE_BR_FORMAT).format(DATE_ISO_8601);
        } else {
          flight.returning = null
        }

        this.$http.get('/api/flight/availability', {params: flight}).then(response => {
          if (response.status === 200) {
            this.next_url = response.data.next;
            this.outbound_flights = response.data.results;
            this.sortFlightsByPrices(response.data.results);
            this.total_results = response.data.total_results;
            // Save flights results on localstorage
            // this.setFlights(response.data.results);
          }
        }).catch(error => {
          this.$swal({
            title: error.response.data.message,
            type: "error",
            confirmButtonText: "SAIR"
          });
        }).finally(() => {

          // Go to flight list if path not contains '/flight/list'
          if (!['/flight/list', '/flight/list#'].includes(this.$route.path)) {
            this.$router.push('/flights/list')
          }
          this.loading = false;
        })
      }
      if (inbound_url) {
        this.loading = true;
        this.$http.get('/api/flight' + inbound_url).then(response => {
          if (response.status === 200) {
            this.inbound_flights = response.data.results;
            this.results = response.data.results;
            this.total_results = response.data.total_results;
          }
        }).catch(error => {
          this.loading = false;
          this.$swal({
            title: error.response.data.message,
            type: "error",
            confirmButtonText: "SAIR"
          });
         }).finally(() => {
          this.loading = false;
        })
      }
    },
    switch_flights(direction) {
      this.loading = true;
      if (direction === 'outbound') {
        this.results = this.outbound_flights
      }
      if (direction === 'inbound') {
        this.results = this.inbound_flights
      }
      this.loading = false;
    },

    cleanSelectedFlights() {
      return new Promise((resolve, reject) => {
        try {
          this.flight.flights = initial_data().flight.flights;
          this.flight.hash = null;
          this.flight.price = initial_data().flight.price;
          this.results = this.outbound_flights;
          this.total_outbound_results = 0;
          this.total_inbound_results = 0;
          // this.filter.going_time = [0, 24];
          Object.keys(this.filter.airlines).forEach((vl) => {
            this.filter.airlines[vl] = false;
          })

          let filters = _.omit(initial_data().filter, 'airlines');

          Object.keys(filters).forEach((k) => {
            this.filter[k] = filters[k];
          })
          resolve();

        } catch ($e) {
          console.log("Error on clean selected flights execution", $e);
          return reject('Error on clean selected flights execution');
        }
      })
    },
    flight_select(flight, price, price_total_inbound) {
      if (flight.direction === 'outbound') {

        // clean inbound flight if outbound exists
        if (this.flight.flights.outbound.id) {
          this.flight.flights.inbound = {id: null};
          this.flight.price.inbound_id = null;
          this.flight.price.inbound = 0;
          this.flight.price.journey_id = null;
          this.flight.price.slug = null;
          this.flight.price.taxes = 0;
        }

        this.flight.flights.outbound = flight;
        this.flight.price.outbound = price.base;
        this.flight.price.total = price.total;
        this.flight.price.outbound_id = price.id;
        this.flight.price.journey_id = price.id;
        this.flight.price.slug = price.brand.name;
        this.flight.direction = flight.direction;
        this.flight.price.taxes = price.taxes.length ? price.taxes.map(function(x,y){return x.value}).reduce(function(a, b){
          return a+b
        }) : 0;
        this.outbound_flights = this.results;

        if (flight.links && this.flight.outbound_and_inbound === 'true') {
          if (flight.links[0].inbound) {
            this.flight.hash = flight.links[0].inbound.split('hash=')[1];
          }
          if (flight.links[0].pricing) {
            this.flight.hash = flight.links[0].pricing.split('hash=')[1];
          }
        }
        if (this.flight.outbound_and_inbound === 'true') {
          this.search_flights(null, flight.links[0].inbound);
        }
      }

      this.flight.price.id = price.id;
      this.flight.price.slug = price.brand.name;
      if (flight.direction === 'inbound') {
        this.flight.flights.inbound = flight;
        this.flight.price.inbound = price_total_inbound;
        this.flight.price.total = price.total;
        this.flight.price.taxes = price.taxes.length ? price.taxes.map(function(x,y){return x.value}).reduce(function(a, b){
          return a+b
        }): 0;
        this.flight.price.inbound_id = price.id;
        this.flight.price.journey_id = price.id;
        this.flight.price.slug = price.brand.name;
        this.inbound_flights = this.results;

      }
      this.flight.direction = flight.direction;
      this.flight.price.brand = price.brand;
      this.flight.price._taxes = price.taxes;
      this.flight.price.total_fare = price.base;
      this.flight.price.discount += price.discount;

      // clean price filter
      // this.filter.going_time = [0, 24];

    },
    flight_get_prices(flight, spinner, price) {
      window.scrollTo(0, 0);
      this.loading = true;

      if (flight.direction === 'outbound' && this.flight.outbound_and_inbound === 'true'){
        this.flight_select(flight, price);
      }else{
        if (flight.links) {
          if (flight.links[0].inbound) {
            this.flight.hash = flight.links[0].inbound.split('hash=')[1];
          }
          if (flight.links[0].pricing) {
            this.flight.hash = flight.links[0].pricing.split('hash=')[1]
          }
        }

        if (flight.direction === 'inbound' && this.flight.hash || flight.direction === 'outbound' && this.flight.outbound_and_inbound === 'false') {
          this.$http.get('/api/flight/pricing', {params: {hash: this.flight.hash}}).then(response => {
            if ([200, 201].includes(response.status)) {
              if (flight.prices.length){
                console.log(response)
                let price_response = _.sortBy(response.data.results.prices, 'total');
                let price_total_inbound = price.base

                let price_new = price_response.map(obj => obj).filter(
                  function(value, index, arr){
                     if (value.brand.slug.toUpperCase() === price.brand.slug.toUpperCase()) {
                       return value
                      }
                    }
                )
                this.flight.prices = price_response;
                this.flight_select(flight, price_new[0], price_total_inbound);

                // flight.lowest_price = flight.prices[0].total;
                this.loading = false;
              }
            }
          }).catch(error => {
            console.log(error);
            this.$swal({
              title: "A tarifa selecionada não está mais disponível",
              type: "error",
              confirmButtonText: "SAIR"
            });
            this.loading = false;
          })
        } else {
          console.log('loading off')
          this.loading = false;
        }
      }
    },
    get_min_price(prices) {
      let mapped_prices = prices.map(d => d.total);
      return Math.min(mapped_prices);
    },
    get_max_price(prices) {
      let mapped_prices = prices.map(d => d.total);
      return Math.max(mapped_prices);
    },
    flight_booking() {

      if (this.flight.travelers == null || !this.flight.travelers.length){
          this.$swal({
            title: "Favor selecionar o viajante",
            type: "error",
            confirmButtonText: "SAIR"
          })
      }
      if (this.flight.travelers.length != this.flight.adults){
        this.$swal({
          title: "Favor selecionar todos os viajantes",
          type: "error",
          confirmButtonText: "SAIR"
        })
      } else
        if (this.selected_price && this.flight.travelers.length) {
          this.loading = true;
          this.$http.post(
            '/api/flight/booking/', {
              hash: this.flight.hash,
              // price_id: this.selected_price.id,
              travelers: this.flight.travelers
            }).then(response => {
            if ([200, 201].includes(response.status)) {
              if (response.data.results) {
                this.flight.booking = response.data.results.booking;
                this.trackAction('Booking')
              }
              this.loading = false;
            }
          }).catch(error => {
            this.loading = false;
            this.$swal({
              title: error.response.data.message,
              type: "error",
              confirmButtonText: "SAIR"
            })
          }).finally(() => {
            if (this.flight.booking) {
              this.$router.push('/travel');
            }
          })
        }
    },
    changeTravelers(travelers) {
      if (travelers > 1) {
        for (let index in travelers - 1) {
          this.flight.travelers.push({luggage: 0})
        }
      }
    },
    setAirlineFilter(airline) {
      if (!this.filter.airlines.includes(airline)) {
        this.filter.airlines.push(airline)
      } else {
        this.filter.airlines.splice(this.filter.airlines.indexOf(airline), 1);
      }
    },
    sortFlightsByPrices(flights){
      let total_outbound_results = 0;
      let total_inbound_results = 0;

      let filtered_flights = [];

      flights.forEach(function (flight) {

        let flight_items = [];

        flight.flights.forEach(function (flight_item) {

          if (flight_item.prices.length){
            flight_item.prices = _.sortBy(flight_item.prices, 'total');

            if (flight_item.prices.length){

              flight_item.lowest_price = flight_item.prices[0].base;

              if (flight_item.direction === 'outbound'){
                total_outbound_results += 1;
              }

              if (flight_item.direction === 'inbound'){
                total_inbound_results += 1;
              }
              // console.log(flight_item.segments[0].date_departure, flight_item.segments[flight_item.segments.length-1].date_arrival, 'AAA')
              // console.log(moment(flight_item.segments[0].date_departure).diff, moment(flight_item.segments[flight_item.segments.length-1].date_arrival).diff)
              if(flight_item.segments && flight_item.segments[0] && flight_item.segments[0].date_departure) {
                flight_item.travel_time = moment.duration(moment(flight_item.segments[flight_item.segments.length-1].date_arrival)
                                                .diff(moment(flight_item.segments[0].date_departure), 'hours', true), 'hours').format('HH[h]mm[min]');

              }
              if(flight_item.segments && flight_item.segments[0] && flight_item.segments[0].date_departure){
                flight_items.push(flight_item)
              }
            }
          }

        });

        let items = _.sortBy(flight_items, 'lowest_price');
        _.map(items, function (item) {
          if(item){
            let filtered_flight = JSON.parse(JSON.stringify(flight));
            filtered_flight.flights = [item];
            filtered_flight.lowest_price = item.lowest_price;
            filtered_flight.direct = item.direct;
            filtered_flights.push(filtered_flight)

          }
        });
      });

      this.total_outbound_results = total_outbound_results;
      this.total_inbound_results = total_inbound_results;
      // return items;
      var tmp_flights = _.sortBy(filtered_flights, 'lowest_price');
      tmp_flights = _.groupBy(tmp_flights, 'direct');

      let boolean_keys = Object.keys(tmp_flights).sort(function(a, b) {
        return a==='true' ? -1: 1;
      })

      return boolean_keys.map((el) => {
        if (el=='true')
            el = true
          else
            el = false;

        return tmp_flights[el]
      }).flat();
    },
    trackAction(action){
      this.$ga.event({
        eventAction: action,
        eventCategory: 'Flight',
        eventValue: true
      })
    },
    flight_reserve() {
      this.$router.push(`/flights/details/${this.flight.hash}/${this.flight.price.slug}`)
      // this.$router.push(`/flight/details/${this.flight.hash}/${this.flight.price.slug}`)
    },
    initial_data() {
      return initial_data();
    },
    getCarriers(){
      let carriers = _.groupBy(this.results, function (flight) {
        return flight.carrier.name
      });
      this.carriers = Object.keys(carriers);
    },
  },
};
