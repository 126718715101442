<template>
  <div>
    <div class="theme-page-section theme-page-section-xl theme-page-section-gray">
      <div class="container">
        <div class="row">
          <div class="col-md-12 text-center">
            <div class="theme-payment-success-header">
              <i class="fa fa-check-circle theme-payment-success-header-icon"></i>
              <h1 class="theme-payment-success-title">Pedido registrado com sucesso!</h1>
              <p
                class="theme-payment-success-subtitle"
              >Você será avisado quando o pagamento for realizado</p>
            </div>
          </div>
          <div class="col-md-4 col-md-offset-4">
            <div class="theme-payment-success">
              <div class="theme-payment-success-box">
                <ul class="theme-payment-success-summary">
                  <li>
                    Número do pedido
                    <span>{{ order.number }}</span>
                  </li>
                  <li>
                    Data
                    <span>{{ order.order_date|date_to_human }}</span>
                  </li>
                  <li>
                    Valor pago em cartão de crédito
                    <span>{{ order.payment_creditcard|brl_price }}</span>
                  </li>
                  <li>
                    Valor pago no check-in
                    <span>{{ order.payment_inloco|brl_price }}</span>
                  </li>
                </ul>
                <p class="theme-payment-success-check-order">Para consultar mais detalhes, acesse
                  <router-link :to="{name: 'orders'}">meus pedidos</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      order: {}
    }
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$http({
        url: '/api/reserve/success',
        method: 'get'
      }).then((response) => {
        let data = response.data;
        this.order = data;
      });
    },
    track() {
      this.$ga.page(
        {
          page: '/',
          title: 'Payment Success',
          location: window.location.href
        }
      )
    }
  }
};
</script>

<style scoped>
.theme-payment-success-header-icon {
  font-size: 70px;
  display: block;
  line-height: 1em;
  margin-bottom: 15px;
  color: #0093d2;
  text-shadow: 2px 5px 10px #9de1fe;
}
</style>
