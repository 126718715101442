<template>
    <div class="user-personal-data">
        <div class="col-md-12">
            <h4 class="title-manage-account">Tipo de Serviço</h4>
        </div>
        <div class="col-md-12">
            <wait-loader v-if="loading"></wait-loader>
            <GChart v-else type="PieChart" :data="chartData"  :options="chartOptions"/>
        </div>
    </div>
</template>

<script>
    import { GChart } from 'vue-google-charts';
    import WaitLoader from "../loader/wait-loader.vue";
    export default {
        name: "service-type",
        components: {
            GChart,
            WaitLoader,
        },
        data () {
            return {
                chartData: null,
                loading: false,
                chartOptions: {
                    chart: {
                        title: 'Tipo de Serviço',
                        subtitle: '',
                    },
                    slices: {
                        0: {color: '#1c91c0'},
                        1: {color: '#6f9654'},
                    }
                }
            }
        },
        created() {
            this.loading = true;
            this.$http
                .get("/api/service-type")
                .then(response => {
                    if (response.status === 200) {
                        let service_type = response.data;
                        this.chartData = [
                            ['Serviço', 'Total'],
                            [service_type.hotel.label, service_type.hotel.total],
                            [service_type.aereo.label, service_type.aereo.total]
                        ];
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
</script>

<style scoped>

</style>