<template>
  <div>
    <h2 class="margin-off size-16 upper weight-normal color-gray-light-mid">
      <span class="upper">{{ segments[0].direction === 'outbound' ? 'ida' : 'volta' }}</span>&nbsp;<strong class="hidden-xs color-gray-dark weight-normal">{{ segments[0].departure | date_to_monthday }}</strong>
      <strong class="visible-xs-block color-gray-dark weight-normal">{{ segments[0].departure | date_to_weekday }}</strong>
    </h2>
    <div class="clearfix">
      <p class="size-14 weight-bold margin-off">
        {{ segments[0].origin }}<i class="fa fa-plane fa-fw ico-plane"></i>{{ segments[segments.length - 1].destination }}
      </p>
      <p class="size-14 margin-off">
        <span class="weight-lighter">{{ 'voos' | pluralize(segments.length) }}</span>
          <template v-for="(segment, index) in segments">
          {{ segment.airline.iata }} {{ segment.flight_number | normalize_flight_number }}
          </template>
      </p>
    </div>
    <!-- Flight Detail -->
    <flight-detail
      :item="{
        segments,
        travelers
      }"></flight-detail>
    <!-- // Flight Detail -->
    <hr class="visible-xs-block">
  </div>
</template>

<script>
import FlightDetail from '@/components/flight/FlightDetail';

export default {
  components: {
    FlightDetail
  },
  props: [
    'segments',
    'travelers'
  ]
}
</script>
