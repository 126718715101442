<template>
  <div>
    <h2 class="margin-top-off size-18 upper weight-normal color-gray-light-mid">
      <span class="upper">{{ direction }}</span>&nbsp;<strong class="color-gray-dark weight-normal">{{ segments[0].date_departure | date_to_monthday }}</strong>
    </h2>
    <div class="row">
      <p class="col-xs-3 text-right min-line-height">
        <span class="size-18">{{ segments[0].date_departure | date_to_hour }}</span><br>
        <span class="size-16 weight-mid color-gray-light-mid">{{ segments[0].origin.iata_code }}</span><br>
        <span class="size-12 weight-normal">{{ segments[0].origin.city_name }}</span><br>
      </p>
      <div class="col-xs-6 text-center _pr-0 _pl-0">
        <div class="hour-mark">
          <i class="fa fa-plane fa-fw ico-plane"></i>
        </div>
        <p class="size-14">
          <!-- <span class="weight-lighter">{{ 'voos' | pluralize(segments.length) }}:</span> -->
          <span
            v-for="(segment, index) in segments"
            :key="`flight-hourly-fligh-number-${index}`">
            {{ segment.carrier.iata_code }} {{ segment.flight_number | normalize_flight_number }}
          </span>
        </p>
      </div>
      <p class="col-xs-3 text-left min-line-height">
        <span class="size-18">{{ segments[segments.length - 1].date_arrival | date_to_hour }}<sup v-if="dateDiffArrival > 0" class="color-magenta">+{{ dateDiffArrival }}</sup></span><br>
        <span class="size-16 weight-mid color-gray-light-mid">{{ segments[segments.length - 1].destination.iata_code }}</span><br>
        <span class="size-12 weight-normal">{{ segments[segments.length - 1].destination.city_name }}</span><br>
      </p>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: [
    'segments',
    'direction'
  ],
  computed: {
    dateDiffArrival () {
      return moment(this.segments[this.segments.length - 1].date_arrival, 'YYYY, MM, DD').diff(moment(this.segments[0].date_departure, 'YYYY, MM, DD'), 'days')
    }
  }
};
</script>
