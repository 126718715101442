<template>
  <section
    role="list"
    class="_pt-20">
    <article
      v-for="(item, index) in rooms"
      :key="`rooms-list-item-${index}`"
      role="listitem"
      class="rooms-list">
      <div class="clearfix">
        <div class="rooms-list-thumb">
          <template v-if="item.images.length">
            <img
              :src="item.images[0].url"
              alt="" />
          </template>
          <template v-else>
            <img
              :src="require('@/assets/img/img-hotel-350x232.png')"
              alt="" />
          </template>
        </div>
        <h2 class="h4 _mt-0 rooms-list-text">{{ item.room.name }}</h2>
        <div class="rooms-list-text">
          <p class="size-12">
            <i class="fa fa-male"></i>
            <template v-if="item.room.max_occupancy < 11">
              Capacidade: {{ item.room.max_occupancy }} {{ 'pessoa' | pluralize(item.room.max_occupancy) }}
            </template>
            <template v-if="item.room.max_occupancy > 10">
              Capacidade: 10+ pessoas
            </template>
            <template v-if="item.amenities.length > 0">
              <span
                v-for="(amenitie, index) in item.amenities"
                :key="`amenitie-${item.id}-${index}`">
                / {{ amenitie.detail }}
              </span>
            </template>
            <button
              @click.prevent="handleDialogOpen(item)"
              class="btn-link size-12 weight-lighter _pt-0 _pb-0"
              type="button">ver mais</button>
            <template v-if="item.amenities.length === 0">
              <br>Detalhes não disponibilizado pelo hotel.
            </template>
          </p>
        </div>
      </div>
      <div
        v-for="price in item.prices"
        :key="`rooms-prices-${price.id}`"
        class="rooms-list-item-price clearfix">
        <div class="col-xs-6 col-sm-4 col-md-3 col-lg-2 _pr-10 _pl-10 rooms-list-item-price-night">
          <h2>
            Preço por noite:
            <span>R$</span>&nbsp;<strong>{{ price.night | price }}</strong>
          </h2>
        </div>
        <div class="col-xs-6 col-sm-3 col-md-2 col-lg-2 _pr-10 _pl-10 rooms-list-item-price-total">
          <h3>
            Total do período:
            <span>R$</span>&nbsp;<strong>{{ price.total | price }}</strong>
          </h3>
        </div>
        <div class="col-xs-12 col-sm-5 col-md-4 col-lg-5 mt-10">
          <p>Taxas inclusas</p>
          <p
            v-if="price.breakfast_included"
            class="color-primary">
            <i class="fa fa-coffee"></i> Incluí café da manhã
          </p>
          <p
            v-else
            class="color-magenta">
            <i class="fa fa-coffee"></i> Não incluí café da manhã
          </p>
          <p
            v-if="price.refundable"
            class="color-primary">
            <i class="fa fa-thumbs-o-up"></i> Reembolsável
          </p>
          <p
            v-else
            class="color-magenta">
            <i class="fa fa-thumbs-o-down"></i> Não reembolsável
          </p>
        </div>
        <div
          v-if="getComissionedPrice(item)"
          class="col-xs-12 col-sm-12 col-md-3 mt-15 mb-15">
          <button
            @click.prevent="handleReserve(item, price)"
            class="btn-blue btn-block btn-mid"
            type="button">RESERVAR</button>
        </div>
        <div
          v-if="price.policies.length"
          class="col-xs-12 mt-10 mb-10">
          <p
            v-for="(policie, index) in price.policies"
            :key="`policies-${price.id}-${index}`"
            class="size-14 weight-lighter"> {{policie.detail}}</p>
        </div>
      </div>
    </article>

    <single-dialog
      :active="dialog"
      :id="`dialog-detail`"
      @handleDialog="handleDialogClose"
      class="color-gray-dark">
      <room-dialog
        slot="body"
        :item="item"></room-dialog>
    </single-dialog>
  </section>
</template>

<script>
import _ from 'underscore'
import SingleDialog from '@/components/ui/SingleDialog';
import RoomDialog from '@/views/hotel/details/RoomDialog';

export default {
  components: {
    SingleDialog,
    RoomDialog
  },
  props: [
    'rooms',
    'provider'
  ],
  data () {
    return {
      item: null,
      dialog: false
    }
  },
  methods: {
    handleDialogOpen (item) {
      this.item = item
      this.dialog = true
    },
    handleDialogClose (item) {
      this.item = null
      this.dialog = false
    },
    getComissionedPrice (item) {
      return _.findWhere(item.prices, { name: this.provider.config.fareName[0].toUpperCase() });
    },
    handleReserve (room, price) {
      this.$emit('handleReserve', room, price);
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/hotel/details/RoomsList';
</style>
