<template>
  <div>
    <h2 class="col-xs-8 size-18 upper weight-bold color-gray-dark">
      {{ item.room.hotel.name }}
    </h2>
    <div class="col-sm-12 row">
      <div class="col-xs-6 col-sm-3 size-18">
        <div class="color-gray-light-mid weight-lighter">
          <i class="fa fa-calendar"></i> Check In:
        </div>
        <p>{{ item.room.checkin | date_to_weekday }}</p>
      </div>
      <div class="col-xs-6 col-sm-3 size-18">
        <div class="color-gray-light-mid weight-lighter">
          <i class="fa fa-calendar"></i> Check Out:
        </div>
        <p>{{ item.room.checkout | date_to_weekday }}</p>
      </div>
      <div class="col-xs-12 col-sm-6 border-left">
        <hr class="visible-xs-block">
        <p class="size-14 color-gray-dark">
          <span class="weight-lighter">{{ nights }}, {{ adults }}</span> <br />
          <i class="fa fa-bed fa-fw color-gray-light-mid"></i>&nbsp;{{ item.room.description }} <br />
          <i class="fa fa-user fa-fw color-gray-light-mid"></i>&nbsp;
          <span class="weight-lighter">Viajante:</span>&nbsp;<strong>{{ item.room.guests_list }}</strong>
        </p>
      </div>
    </div>
    <!-- Values -->
    <div class="travel-list-values col-xs-12">
      <div class="travel-list-value size-16 weight-lighter">
        <span>Diárias</span>
        <span class="pull-right display-block">{{ item.fare | brl_price}}</span>
      </div>
      <div class="travel-list-value size-16 weight-lighter">
        <span>Taxas</span>
        <span class="pull-right display-block">{{ item.taxes | brl_price}}</span>
      </div>
      <div class="travel-list-value size-16 weight-lighter">
        <span class="size-18 weight-bold">Subtotal</span>
        <span class="size-18 weight-bold pull-right display-block">{{ item.total | brl_price }}</span>
      </div>
    </div>
    <!-- // Values -->
    <!-- Dialog Remove -->
    <dialog-remove :item="item">
      <template v-slot:description="{item}">
        <div class="row">
          <h2 class="col-xs-12 size-16 upper weight-bold color-gray-dark">
            {{ item.room.hotel.name }}
          </h2>
          <div class="col-xs-6 col-sm-3 size-16">
            <div class="color-gray-light-mid weight-lighter">
              <i class="fa fa-calendar"></i> Check In:
            </div>
            <p>{{ item.room.checkin | date_to_weekday }}</p>
          </div>
          <div class="col-xs-6 col-sm-3 size-16">
            <div class="color-gray-light-mid weight-lighter">
              <i class="fa fa-calendar"></i> Check Out:
            </div>
            <p>{{ item.room.checkout | date_to_weekday }}</p>
          </div>
        </div>
      </template>
    </dialog-remove>
    <!-- // Dialog Remove -->
  </div>
</template>

<script>
import DialogRemove from '@/views/travel/DialogRemove'

export default {
  components: {
    DialogRemove
  },
  props: [
    'item'
  ],
  computed: {
    nights() {
      return `${this.item.room.nights} ${this.$options.filters.pluralize(
        'diária',
        this.item.room.nights
      )}`;
    },
    adults() {
      return `${this.item.room.adults} ${this.$options.filters.pluralize(
        'adulto',
        this.item.room.adults
      )}`;
    },
  }
};
</script>
