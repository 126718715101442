<template>
  <select style="width:100%"></select>
</template>

<script>
let retrieveSearchType = function(text) {
  let type_text = "";
  switch (text) {
    case "city":
      type_text = "Cidade";
      break;
    case "airport":
      type_text = "Aeroportos";
      break;
    default:
      break;
  }

  return type_text;
};

let processResults = function(data) {
  let data_group = []
  let tmp_data_type = [];
  _.sortBy(data.results, 'type').forEach(function(data, idx) {
    let params;
    
    if (typeof tmp_data_type[retrieveSearchType(data.type)] == 'undefined'){
      tmp_data_type[retrieveSearchType(data.type)] = {
        text: retrieveSearchType(data.type),
        children: []
      };
    }

    if (data.type=='airport') {
      let text = `${data.airport_iata_code} - ${data.airport_name} - ${data.city_name}, ${data.country_name}`;
      params = {
        id: data.airport_iata_code,
        value: data.airport_iata_code,
        text: text
      }
    } else if (data.type=='city' && data.city_iata_code) {
      let text = `${data.city_name}, ${data.state_name}, ${data.country_name}`;
      params = {
        id: data.city_iata_code,
        value: data.city_iata_code,
        text: text
      }
    }
    
    if (typeof params != 'undefined') {
      tmp_data_type[retrieveSearchType(data.type)].children.push(params);
    }
    
  });

  Object.keys(tmp_data_type).forEach((el, idx) => {
    data_group.push(tmp_data_type[el]);
  });
  console.log(data_group)
  return {
    results: data_group
  };
};
import _ from 'underscore';

export default {
  props: ['value', 'dataInitial', 'placeholder'],
  data() {
    return {
      content: this.value
    }
  },
  methods: {
    changeHandle(e) {
      this.$emit('input', this.content);
    }
  },

  mounted() {
    let destination = $(this.$el);
    this.$nextTick(function() {
      let opt = {
        language: 'pt-BR',
        minimumInputLength: 3,
        debug: true,
        placeholder: this.placeholder,
        ajax: {
          quietMillis: 400,
          url: '/api/flight/autocomplete/',
          processResults: processResults,
          dataType: 'json'
        }
      };

      if(this.dataInitial) {
        opt = _.extend(opt, {'data': this.dataInitial});
      }

      destination.select2(opt);

      destination.on('select2:select', (e) => {
        this.content = e.params.data;
        this.changeHandle(e);
      });
    });
  }
};
</script>

