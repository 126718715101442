<template>
    <div class="row">
        <div class="col-md-12 padding-off">
            <div class="user-personal-data">
                <div class="col-md-12">
                    <h4 class="title-manage-account">Cashback etrip</h4>
                </div>
                <div class="col-md-12">
                    <wait-loader v-if="loading"></wait-loader>
                    <div v-else class="theme-account-preferences">
                        <div class="theme-account-preferences-item">
                            <p class="theme-account-preferences-item-value">
                                Crédito disponível: R$ {{ cashback }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WaitLoader from "../loader/wait-loader.vue";

    export default {
        name: "cashback",
        data() {
            return {
                cashback: null,
                loading: false,
            }
        },
        components: {
            WaitLoader,
        },
        created() {
            this.loading = true;
            this.$http
                .get("/api/cashback-available")
                .then(response => {
                    if (response.status === 200) {
                        this.cashback = response.data.cashback
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
</script>

<style scoped>

</style>