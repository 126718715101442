<template>
  <form
    @submit.prevent="handleSubmit"
    name="company"
    class="clearfix">
    <div class="col-md-6 padding-off">
      <div class="col-md-12">
        <h4 class="title-manage-account">Dados empresariais</h4>
      </div>
      <!-- CNPJ -->
      <div class="col-md-6">
        <div class="form-group theme-login-form-group">
          <label>CNPJ*</label>
          <the-mask
            v-model="form.document_number"
            :mask="['##.###.###/####-##']"
            v-validate="'required'"
            :class="{'alert-danger': submitted && errors.has('document_number')}"
            class="form-control"
            type="text"
            name="document_number"
            disabled='disabled' />
          <span class="error-message-forms">{{ errors.first('document_number') }}</span>
        </div>
      </div>
      <!-- /CNPJ -->
      <!-- Telefone fixo -->
      <div class="col-md-6">
        <div class="form-group theme-login-form-group">
          <label>Telefone*</label>
          <the-mask
            class="form-control"
            type="text"
            name="phone"
            v-model="form.phone"
            :mask="['(##) ####-####', '(##) #####-####']"
            v-validate="'required'"
            :class="{ 'alert-danger': submitted && errors.has('phone') }"
          />
          <span class="error-message-forms">{{ errors.first('phone') }}</span>
        </div>
      </div>
      <!-- /Telefone fixo-->
      <!-- Razão Social -->
      <div class="col-md-12">
        <div class="form-group theme-login-form-group">
          <label>Razão social*</label>
          <input
            class="form-control"
            type="text"
            name="name"
            v-model="form.name"
            v-validate="'required'"
            :class="{ 'alert-danger': submitted && errors.has('name') }"
            disabled='disabled'
          >
          <span class="error-message-forms">{{ errors.first('name') }}</span>
        </div>
      </div>
      <!-- /Razão Social -->
      <!-- Nome Fantasia -->
      <div class="col-md-12 mb-20">
        <div class="form-group theme-login-form-group">
          <label>Nome fantasia*</label>
          <input
            class="form-control"
            type="text"
            name="fantasy_name"
            v-model="form.fantasy_name"
            v-validate="'required'"
            :class="{ 'alert-danger': submitted && errors.has('fantasy_name') }"
            disabled='disabled'
          >
          <span class="error-message-forms">{{ errors.first('fantasy_name') }}</span>
        </div>
      </div>
      <!-- /Nome Fantasia -->
      <div class="col-sm-12 mb-20 clearfix">
        <small>*Campos obrigatórios</small>
      </div>
    </div>
    <div class="col-md-6 padding-off">
      <!-- Subtitulo -->
      <div class="col-md-12">
        <h4 class="title-manage-account">Endereço empresarial</h4>
      </div>
      <!-- /Subtitulo -->
      <!-- CEP -->
      <div class="col-md-12 padding-off">
        <div class="col-md-4">
          <div class="form-group theme-login-form-group">
            <label>CEP*</label>
            <the-mask
              class="form-control"
              type="tel"
              name="zip_code"
              v-model="form.address.zip_code"
              :mask="['#####-###']"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('zip_code') }"
            />
            <span class="error-message-forms">{{ errors.first('zip_code') }}</span>
          </div>
        </div>
        <!-- /CEP -->
        <!-- Endereço -->
        <div class="col-md-8">
          <div class="form-group theme-login-form-group">
            <label>Endereço*</label>
            <input
              class="form-control"
              type="text"
              name="street"
              v-model="form.address.street"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('street') }"
            >
            <span class="error-message-forms">{{ errors.first('street') }}</span>
          </div>
        </div>
        <!-- /Endereço -->
      </div>
      <div class="col-md-12 padding-off">
        <!-- Número -->
        <div class="col-md-4">
          <div class="form-group theme-login-form-group">
            <label>Número*</label>
            <input
              class="form-control"
              type="tel"
              name="number"
              v-model="form.address.number"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('number') }"
            >
            <span class="error-message-forms">{{ errors.first('number') }}</span>
          </div>
        </div>
        <!-- /Número -->
        <!-- Complemento -->
        <div class="col-md-8">
          <div class="form-group theme-login-form-group">
            <label>Complemento</label>
            <input class="form-control" type="text" v-model="form.address.complement">
          </div>
        </div>
        <!-- /Complemento -->
      </div>
      <div class="col-md-12 padding-off">
        <!-- Bairro -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Bairro*</label>
            <input
              class="form-control"
              type="text"
              name="neighborhood"
              v-model="form.address.neighborhood"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('neighborhood') }"
            >
            <span class="error-message-forms">{{ errors.first('neighborhood') }}</span>
          </div>
        </div>
        <!-- /Bairro -->
        <!-- Cidade -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Cidade*</label>
            <input
              class="form-control"
              type="text"
              name="city"
              v-model="form.address.city"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('city') }"
            >
            <span class="error-message-forms">{{ errors.first('city') }}</span>
          </div>
        </div>
        <!-- /Cidade -->
      </div>
      <div class="col-md-12 padding-off">
        <!-- Estado -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Estado*</label>
            <input
              class="form-control"
              type="text"
              name="province"
              v-model="form.address.province"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('province') }"
            >
            <span class="error-message-forms">{{ errors.first('province') }}</span>
          </div>
        </div>
        <!-- /Estado -->
        <!-- País -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>País*</label>
            <select
              v-model="form.address.country_id" name="country" id="" class="form-control" v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('form-account.country') }">
              <option :value="country.id" v-for="country in $store.state.countries">
                {{ country.name }}
              </option>
            </select>
            <span class="error-message-forms">{{ errors.first('country') }}</span>
          </div>
        </div>
        <!-- /País -->
      </div>
    </div>
    <div class="clearfix col-sm-6 col-sm-offset-6 padding-off mt-20 mb-20">
      <!-- Parent Action -->
      <slot :handleParentAction="() => $emit('handleParentAction')"></slot>
      <!-- // Parent Action -->
      <div class="col-xs-6">
        <button
          :disabled="disabled"
          type="submit"
          class="btn-blue btn-block btn-extra-large">
          Salvar
        </button>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data() {
    return {
      form: {
        id: null,
        name: null,
        fantasy_name: null,
        document_number: null,
        phone: null,
        address: {
          id: null,
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          city: null,
          province: null,
          country_id: null,
          zip_code: null
        }
      },
      submitted: false,
      disabled: false
    };
  },
  mounted () {
    this.getCompanies();
  },
  computed: {
    ...mapState('companies', ['results'])
  },
  watch: {
    results (data) {
      const form = { ...this.form };
      this.form = { ...form, ...data };
    }
  },
  methods: {
    ...mapActions('companies', [
      'getCompanies',
      'patchCompany'
    ]),
    handleSubmit (event) {
      this.$validator.validate().then(valid => {
        this.submitted = true;

        if (valid) {
          this.disabled = true;
          this.patchCompany(this.form)
            .then(this.handleParent)
            .catch(this.handleParent)
        }
      }).catch(error => {
        /**/
      })
    },
    handleParent () {
      if (this.$listeners && this.$listeners.handleParentAction) {
        this.$emit('handleParentAction');
      }
      this.disabled = false;
    }
  }
};
</script>

<style scoped>
/* TODO MOVE TO COMMON */
label {
  margin-bottom: 0;
}
.theme-login-form-group {
  margin-bottom: 10px;
}
.form-control {
  height: 48px;
}
</style>
