<template>
  <div>
    <div class="theme-hero-area">
      <div class="theme-hero-area-bg-wrap">
        <div
            class="theme-hero-area-bg ws-action"
            :style="{'background-image': 'url(' + require('@/assets/img/bg-home-hero.jpg') + ')'}"
        ></div>
        <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
        <div class="theme-hero-area-inner-shadow"></div>
      </div>
      <div class="theme-hero-area-body hgt-280">
        <div class="container">
          <div class="theme-search-area-tabs _pt-70 _pb-30 _pv-mob-50">
            <!-- <div class="theme-search-area-tabs-header _ta-c _c-w _mb-60">
              <h1 class="theme-search-area-tabs-title">Sua agência online de viagens a negócios</h1>
              <p
                class="theme-search-area-tabs-subtitle"
              >Reduza em até 25% suas despesas com viagens a negócios</p>
            </div>-->
            <box-search ref="boxsearch" :flight="flight" :loading="loading"></box-search>
          </div>
        </div>
      </div>
    </div>
    <!-- Componetizar -->
    <div class="theme-page-section theme-page-section-dark" style="background-color:#eee">
      <div class="container">
        <div class="theme-page-section-header">
          <h5 class="theme-page-section-title text-left">Os melhores destinos</h5>
          <p class="theme-page-section-subtitle text-left"></p>
        </div>
        <div class="row row-col-gap" data-gutter="20">
          <div class="col-md-4">
            <a href="#" v-on:click="setPredefinedCity('sao_paulo')">
              <div
                  class="banner _h-33vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
              >
                <div
                    class="banner-bg"
                    :style="{'background-image': 'url(' + require('@/assets/img/home-pub-sp.jpg') + ')'}"
                ></div>
                <div class="banner-mask"></div>
                <a class="banner-link" href="#"></a>
                <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title">São Paulo</h5>
                  <p class="banner-subtitle">"A cidade que não para"</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="#" v-on:click="setPredefinedCity('rio_de_janeiro')">
              <div
                  class="banner _h-33vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
              >
                <div
                    class="banner-bg"
                    :style="{'background-image': 'url(' + require('@/assets/img/home-pub-rj.jpg') + ')'}"
                ></div>
                <div class="banner-mask"></div>
                <a class="banner-link" href="#"></a>
                <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title">Rio de Janeiro</h5>
                  <p class="banner-subtitle">"Cidade maravilhosa"</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-4">
            <a href="#" v-on:click="setPredefinedCity('curitiba')">
              <div
                  class="banner _h-33vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
              >
                <div
                    class="banner-bg"
                    :style="{'background-image': 'url(' + require('@/assets/img/home-pub-pr.jpg') + ')'}"
                ></div>
                <div class="banner-mask"></div>
                <a class="banner-link" href="#"></a>
                <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title">Curitiba</h5>
                  <p class="banner-subtitle">"Cidade sorriso"</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-5">
            <a href="#" v-on:click="setPredefinedCity('belo_horizonte')">
              <div
                  class="banner _h-40vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
              >
                <div
                    class="banner-bg"
                    :style="{'background-image': 'url(' + require('@/assets/img/home-pub-bh.jpg') + ')'}"
                ></div>
                <div class="banner-mask"></div>
                <a class="banner-link" href="#"></a>
                <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title">Belo Horizonte</h5>
                  <p class="banner-subtitle">"Cidade-jardim"</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-7">
            <a href="#" v-on:click="setPredefinedCity('brasilia')">
              <div
                  class="banner _h-40vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
              >
                <div
                    class="banner-bg"
                    :style="{'background-image': 'url(' + require('@/assets/img/home-pub-df.jpg') + ')'}"
                ></div>
                <div class="banner-mask"></div>
                <a class="banner-link" href="#"></a>
                <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title">Brasília</h5>
                  <p class="banner-subtitle">"Capital federal"</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-6">
            <a href="#" v-on:click="setPredefinedCity('recife')">
              <div
                  class="banner _h-40vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
              >
                <div
                    class="banner-bg"
                    :style="{'background-image': 'url(' + require('@/assets/img/home-pub-pe.jpg') + ')'}"
                ></div>
                <div class="banner-mask"></div>
                <a class="banner-link" href="#"></a>
                <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title">Recife</h5>
                  <p class="banner-subtitle">"Veneza Brasileira"</p>
                </div>
              </div>
            </a>
          </div>
          <div class="col-md-6">
            <a href="#" v-on:click="setPredefinedCity('porto_alegre')">
              <div
                  class="banner _h-40vh _br-3 _bsh-xl _bsh-light banner-animate banner-animate-mask-in"
              >
                <div
                    class="banner-bg"
                    :style="{'background-image': 'url(' + require('@/assets/img/home-pub-rs.jpg') + ')'}"
                ></div>
                <div class="banner-mask"></div>
                <a class="banner-link" href="#"></a>
                <div class="banner-caption _pt-100 banner-caption-bottom banner-caption-grad">
                  <h5 class="banner-title">Porto Alegre</h5>
                  <p class="banner-subtitle">"Capital dos gaúchos"</p>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- /Componetizar -->
  </div>
</template>

<script>
  import BoxSearch from "../boxsearch.vue";
  import flights from "../../../mixins/flights"

  export default {
    components: {
      BoxSearch
    },
    mixins:[flights],
    methods: {
      setPredefinedCity(city) {
        this.$refs.boxsearch.setPredefinedCitySearch(city)
      }
    }
  };
</script>


<style scoped>
  .banner-link {
    z-index: 0 !important;
  }

  .banner-bg {
    z-index: 0 !important;
  }

  .banner-caption-bottom {
    z-index: 0 !important;
  }
</style>
