var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mt-20"},[_c('div',{staticClass:"col-sm-11 row"},[_c('div',{staticClass:"col-sm-6",class:[{ 'border-right' : _vm.inbound.length }]},[_c('flight-hourly',{key:`flight-hourly-outbound`,attrs:{"segments":_vm.outbound}}),_c('flight-detail',{attrs:{"item":{
          segments: _vm.outbound,
          travelers: _vm.item.travelers
        }}})],1),(_vm.inbound.length)?_c('div',{staticClass:"col-sm-6"},[_c('flight-hourly',{key:`flight-hourly-outbound`,attrs:{"segments":_vm.inbound}}),_c('flight-detail',{attrs:{"item":{
          segments: _vm.inbound,
          travelers: _vm.item.travelers
        }}})],1):_vm._e()]),_c('div',{staticClass:"travel-list-values col-xs-12"},[_c('div',{staticClass:"travel-list-value size-16 weight-lighter"},[_c('span',[_vm._v("Tarifa")]),_c('span',{staticClass:"pull-right display-block"},[_vm._v(_vm._s(_vm._f("brl_price")(_vm.item.values['Tarifa'].amount)))])]),_c('div',{staticClass:"travel-list-value size-16 weight-lighter"},[_c('span',[_vm._v("Taxa de embarque")]),_c('span',{staticClass:"pull-right display-block"},[_vm._v(_vm._s(_vm._f("brl_price")(_vm.item.values['Taxa de embarque'].amount)))])]),_c('div',{staticClass:"travel-list-value size-16 weight-lighter"},[_c('span',[_vm._v("Taxa de Serviço")]),_c('span',{staticClass:"pull-right display-block"},[_vm._v(_vm._s(_vm._f("brl_price")(_vm.item.values['FEE'].amount)))])]),_c('div',{staticClass:"travel-list-value size-16 weight-lighter"},[_c('span',{staticClass:"size-18 weight-bold"},[_vm._v("Subtotal")]),_c('span',{staticClass:"size-18 weight-bold pull-right display-block"},[_vm._v(_vm._s(_vm._f("brl_price")(_vm.item.total)))])])]),_c('dialog-remove',{attrs:{"item":_vm.item,"outbound":_vm.outbound,"inbound":_vm.inbound},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('div',{staticClass:"mt-20 mb-20 row"},[_c('div',{staticClass:"col-sm-6"},[_c('flight-hourly',{key:`dialog-remove-outbound`,attrs:{"segments":_vm.outbound}})],1),(_vm.inbound.length)?_c('div',{staticClass:"col-sm-6"},[_c('flight-hourly',{key:`dialog-remove-inbound`,attrs:{"segments":_vm.inbound}})],1):_vm._e()])]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }