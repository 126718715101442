<template>
  <div
    class="result-list"
    role="list">
    <div
      v-for="(result, index) in results"
      :key="`result-list-${index}`"
      role="listitem">
      <!-- List Item -->
      <template v-if="result.flights">
        <result-item
            v-for="(flight, index) in result.flights"
            :key="`result-item-${flight.id}-${index}`"
            :index-key="flight.id"
            :parent-result="result"
            :result="flight"
            :picked-flight="pickedFlight"
            @handlePrices="handlePrices">
        </result-item>
      </template>
      <!-- // List Item -->
    </div>
  </div>
</template>

<script>
import ResultItem from '@/views/flights/search/ResultItem';

export default {
  props: [
    'results',
    'outboundInbound',
    'pickedFlight'
  ],
  components: {
    ResultItem
  },
  methods: {
    handlePrices(flight, price) {
      this.$emit('handlePrices', flight, '', price)
    }
  }
};
</script>
