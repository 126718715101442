<template>
  <div class="col-xs-12 box-radius bg-gray-lighter-plus">
    <div class="detail-mark-down">
      <div class="clearfix">
        <div class="col-xs-12 detail-mark">
          <span class="size-14 weight-normal color-gray-light-mid">Endereço para retirada:</span>
        </div>
      </div>
    </div>
    <div class="detail-mark-mid">
      <div class="clearfix">
        <div class="col-xs-12">
          <p class="weight-lighter">
            {{ pickup.street }}, {{ pickup.number }}
            <template v-if="pickup.complement">, {{ pickup.complement }}</template> <br >
            {{ pickup.neighborhood }} - {{ pickup.zip_code }} <br>
            {{ pickup.city }} - {{ pickup.province }}
          </p>
        </div>
      </div>
    </div>
    <div class="clearfix">
      <div class="col-xs-12 detail-mark-up detail-mark">
        <span class="size-14 weight-normal color-gray-light-mid">Endereço para devolução:</span>
      </div>
    </div>
    <div class="clearfix">
      <div class="col-xs-12">
        <p class="weight-lighter">
            {{ delivery.street }}, {{ delivery.number }}
            <template v-if="delivery.complement">, {{ delivery.complement }}</template> <br >
            {{ delivery.neighborhood }} - {{ delivery.zip_code }} <br>
            {{ delivery.city }} - {{ delivery.province }}
          </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'pickup',
    'delivery'
  ]
};
</script>
