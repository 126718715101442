<template>
  <aside class="mb-20">
    <h2 class="h4 box-pad-5">Sua Reserva</h2>
    <ul class="border-blue box-list list-unstyled clearfix size-16 weight-lighter">
      <li class="box-list-item border-bottom">
        {{ 'Adulto' | pluralize(journey.adults) }}
        <span
          v-if="selectedPrice"
          class="pull-right weight-bold">
          {{ selectedPrice.base | brl_price }}
        </span>
      </li>
      <li
        v-for="(tax, index) in selectedPrice.taxes"
        v-if="tax.value"
        :key="`tax-${index}`"
        :class="{ 'border-bottom': index < (selectedPrice.taxes.length - 1) }"
        class="box-list-item clearfix">
        <template v-if="taxes.hasOwnProperty(tax.name)">
          {{ taxes[tax.name] }}
          <span class="pull-right weight-bold">{{ tax.value | brl_price }}</span>
        </template>
        <template v-else>
          Taxa
          <span class="pull-right weight-bold">{{ tax.value | brl_price }}</span>
        </template>
      </li>
    </ul>
    <span class="btn-gray btn-block btn-extra-large clearfix">
      <span class="pull-left">Total</span>
      <span class="pull-right">{{ selectedPrice.total | brl_price }}</span>
    </span>
    <br>
  </aside>
</template>

<script>
export default {
  props: [
    'journey',
    'selectedPrice'
  ],
  data () {
    return {
      taxes: {
        'DU tax': 'Taxa de serviço',
        'Taxa DU': 'Taxa de serviço',
        'Taxes': 'Taxa de embarque',
        'Fee': 'Taxa de serviço',
        'Taxas': 'Taxa de embarque',
        'Taxa de embarque': 'Taxa de embarque'
      }
    }
  }
};
</script>
