<template>
  <div class="col-md-6 mb-30" :id="traveler.id">
    <div class="theme-account-traveler">
      <p class="pull-right" v-if="traveler.is_active">Ativo</p>
      <p class="pull-right" v-else>Desativado</p>
      <avatar :username="traveler.full_name" :size="60" class="theme-account-traveler-icon"></avatar>
      <!--<i class="theme-account-traveler-icon" style="background-color: #ff0066;">R</i>-->
      <div class="theme-account-traveler-body">
        <p class="theme-account-traveler-name">{{traveler.full_name}}</p>
        <ul class="theme-account-traveler-details">
          <li>{{traveler.email}}</li>
          <li v-if="traveler.is_manager">Administrador</li>
        </ul>
      </div>
      <ul class="theme-account-traveler-actions">
        <li class="pull-left">
          <!-- <a class="actions-traveler" v-if="traveler.is_active">Desativar viajante</a> -->
          <!-- <a class="actions-traveler" v-else>Ativar viajante</a> -->
        </li>
        <li class="pull-right">
          <a class="actions-traveler r-30">
            <router-link :to="{name: 'edit-traveler', params: traveler}">Editar</router-link>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Avatar from "vue-avatar";

export default {
  name: "card-traveler",
  props: ["traveler"],
  components: {
    Avatar
  }
};
</script>

<style scoped>
.actions-traveler {
  position: absolute;
  bottom: 30px;
}
.r-30 {
  right: 30px;
}
</style>
