<template>
  <div class="theme-hero-area">
    <div class="theme-hero-area-bg-wrap">
      <div
        class="theme-hero-area-bg"
        :style="{'background-image': 'url(' + require('@/assets/img/bg-home.jpg') + ')'}"></div>
      <div class="theme-hero-area-bg theme-hero-grad-mask"></div>
    </div>
    <div class="theme-hero-area-body">
      <div class="theme-page-section theme-page-section-xl">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <span class="center-block mt-20 _mb-30">
                <img
                  class="img-responsive"
                  src="@/assets/img/logo-color.png"
                  alt="Logo Tour House"
                  title="Tour House">
              </span>
              <div class="col-md-12 theme-login-white">
                <h1 class="theme-login-title theme-login-title-white text-center mb-20 h2">Cadastrar nova senha</h1>
              </div>
            </div>
            <div class="col-md-6 col-md-offset-3">
              <div class="theme-login theme-login-white">
                <div class="theme-login-box">
                  <div class="theme-login-box-inner">
                      <h3 class="mt-5 theme-login-form text-center">Sua senha expirou</h3>
                      <p class="theme-login-pwd-reset-text text-center">Para sua segurança, cadastre uma nova</p>
                      <form
                        @submit.prevent="update_password('form-password')"
                        data-vv-scope="form-password"
                        autocomplete="off"
                      >
                      <div class="form-group theme-login-form-group">
                        <input
                          class="form-control"
                          type="password"
                          placeholder="Senha Atual"
                          name="password"
                          v-model="password"
                          v-validate="'required'"
                          ref="password1"
                        :class="{ 'alert-danger': submitted && errors.has('form-password.password') }">
                        <span class="error-message-forms">{{ errors.first('form-password.password') }}</span>
                      </div>
                      <p class="mb-5 _mt-30 theme-login-pwd-reset-text">Digite sua nova senha abaixo:</p>
                      <div class="form-group theme-login-form-group">
                        <input
                          class="form-control"
                          type="password"
                          placeholder="Nova senha"
                          name="password1"
                          v-model="password1"
                          v-validate="'required|min:8'"
                          ref="password1"
                        :class="{ 'alert-danger': submitted && errors.has('form-password.password1') }">
                        <span class="error-message-forms">{{ errors.first('form-password.password1') }}</span>
                      </div>
                      <div class="form-group theme-login-form-group">
                        <input
                          class="form-control"
                          type="password"
                          placeholder="Confirmar nova senha"
                          name="password2"
                          v-model="password2"
                          v-validate="'required|confirmed:password1|min:8'"
                          data-vv-as="password1"
                          :class="{ 'alert-danger': submitted && errors.has('form-password.password2') }">
                        <span class="error-message-forms">{{ errors.first('form-password.password2') }}</span>
                      </div>

                      <button class="btn btn-uc btn-dark btn-block btn-lg" href="#" type="submit">
                        <i class="fa fa-check" aria-hidden="true"></i> Alterar senha
                      </button>

                      <div class="clearfix mt-15">
                        <password-helper />
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import PasswordHelper from '@/components/PasswordHelper';
import store from '@/store'

export default {
  components: {
    PasswordHelper
  },
  data() {
    return {
      password: null,
      password1: '',
      password2: '',
      submitted: false,
      open_helper: false
    };
  },
  methods: {
    ...mapActions('user', [
      'postPasswordReset',
      'postSetPassword'
    ]),

    update_password(scope) {
      this.$validator.validateAll(scope).then(() => {
        this.submitted = true;
        this.$http
          .post("/api/user/update_password/", {
            password: btoa(this.password),
            password1: btoa(this.password1),
            password2: btoa(this.password2)
          })
          .then(response => {
            if (response.status === 200) {
              store.dispatch('auth/getUserLoggedin')
              setTimeout(() => {
                this.$router.push('/')
              }, 2005)
              this.$swal({
                title: response.data.message,
                type: "success",
                showConfirmButton: false,
                timer: 2000
              });
            }
          })
          .catch(error => {
            this.$swal({
              title: error.response.data.message,
              type: "error",
              showConfirmButton: true
            });
          })
          .finally(() => {
            this.clean_passwords();
          });
      });
    },

    clean_passwords() {
      this.password = null;
      this.password1 = null;
      this.password2 = null;
    }
  }
};

</script>

<style scoped>
.theme-hero-area {
  min-height: 100vh;
  height: auto;
}
</style>
