<template>
  <div class="home">
    <div>
      <hero-home></hero-home>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroHome from "@/components/template/home/hero.vue";

export default {
  name: "home",
  components: {
    HeroHome,
  },
  created() {
    this.$ga.page({
      page: "/",
      title: "Home Page",
      location: window.location.href
    });
  }
};
</script>
