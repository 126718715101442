<template>
  <div class="price-list">
    <ul>
      <template v-if="prices">
        <price-item
          v-for="(price, index) in prices"
          :key="`price-item-${price.id}-${index}`"
          :carrier="carrier"
          :price="price"
          :index-key="index"
          :active="!hasOuboundId && (price.base == lowestPrice)
            || hasOuboundId && (price.brand.slug == pickedFlight.price.brand.slug)
            || hideValue && (pickedFlight.price.slug.toUpperCase() == price.brand.name.toUpperCase())"
          :disabled="!(!hasOuboundId || pickedFlight.price.slug.toUpperCase() == price.brand.name.toUpperCase())"
          :hide-value="hideValue"
          @handlePrices="handlePrices">
        </price-item>
      </template>
    </ul>
    <h5
      v-if="hasOuboundId && outboundInbound && prices.length > 1"
      class="text-center">
      <small>
        *A tarifa do voo de volta deve ser a mesma do voo de ida
      </small>
    </h5>
  </div>
</template>

<script>
import PriceItem from '@/views/flights/search/PriceItem'

export default {
  components: {
    PriceItem
  },
  props: [
    'lowestPrice',
    'pickedFlight',
    'prices',
    'hasOuboundId',
    'hideValue',
    'carrier'
  ],
  computed: {
    outboundInbound() {
      return this.pickedFlight.outbound_and_inbound
    }
  },
  methods: {
    handlePrices(price) {
      this.$emit('handlePrices', price)
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/PricesList';
</style>

