<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-lg">
      <div class="container">
        <div class="row mt-30 row-col-static row-col-mob-gap" id="sticky-parent" data-gutter="60">
          <div class="col-md-12">
            <!-- Titulo -->
            <h1 class="theme-account-page-title">Reservando</h1>
            <!-- /Titulo -->
          </div>
          <div class="col-md-8">
            <div class="theme-payment-page-sections">
              <div class="theme-payment-page-sections-item">
                <div class="theme-search-results-item theme-payment-page-item-thumb">
                  <h3 class="theme-search-results-item-title">
                    <strong>WZ Hotel Jardins</strong>
                  </h3>
                  <h4>
                    <strong>Superior casal - Solteiro</strong>
                  </h4>
                  <div class="row" data-gutter="20">
                    <div class="col-md-9">
                      <div class="row" data-gutter="20">
                        <div class="col-md-5">
                          <ul class="theme-search-results-item-hotel-room-features">
                            <li>
                              <i class="fa fa-coffee" aria-hidden="true"></i>Café da manhã
                            </li>
                            <li>
                              <i class="fa fa-wifi" aria-hidden="true"></i>Wifi Grátis
                            </li>
                            <li>
                              <i class="fa fa-snowflake-o" aria-hidden="true"></i>Ar Condicionado
                            </li>
                            <li>
                              <i class="fa fa-heartbeat" aria-hidden="true"></i>Academia
                            </li>
                          </ul>
                        </div>
                        <div class="col-md-5">
                          <ul class="theme-search-results-item-hotel-room-features">
                            <li>
                              <i class="fa fa-plus" aria-hidden="true"></i>Frigobar
                            </li>
                            <li>
                              <i class="fa fa-plus" aria-hidden="true"></i>Cofre
                            </li>
                            <li>
                              <i class="fa fa-plus" aria-hidden="true"></i>Secador de Cabelo
                            </li>
                            <li>
                              <i class="fa fa-plus" aria-hidden="true"></i>Piscina
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3">
                      <div class="theme-search-results-item-img-wrap">
                        <img
                          class="theme-search-results-item-img"
                          src="@/assets/img/350x232.png"
                          alt="Image Alternative text"
                          title="Image Title"
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="theme-payment-page-sections-item">
                <h3 class="theme-payment-page-sections-item-title">Selecione o Viajante</h3>
                <div class="row">
                  <div class="col-md-6">
                    <div class="theme-payment-page-form-item form-group">
                      <i class="fa fa-angle-down"></i>
                      <select class="form-control">
                        <option>John Doe, john_doe772@gmail.com, +1 839 322 1329</option>
                        <option>Maya Smith, hello_maya95@yahoo.com, +1 096 337 4451</option>
                        <option>Tommy Jones, tommy238123@gmail.com, +1 285 365 1322</option>
                        <option>Lucy Doe, lucy_kitty_11@gmail.com, +1 732 929 0466</option>
                      </select>
                    </div>
                  </div>
                </div>
                <a
                  class="theme-payment-page-sections-item-new-link"
                  data-toggle="modal"
                  data-target="#modal-new-traveler"
                >+ Adicionar novo viajante</a>
              </div>
              <!-- Modal Modal novo viajante -->
              <div
                class="modal fade"
                id="modal-new-traveler"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-changeLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content change-modal">
                    <div class="modal-header change-modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h3 class="modal-title text-center" id="exampleModalLabel">Novo Viajante</h3>
                    </div>
                    <div class="modal-body">
                      <!-- Dados pessoais -->
                      <!-- Subtitulo -->
                      <div class="col-md-12">
                        <h4 class="title-manage-account">Dados Pessoais</h4>
                      </div>
                      <!-- /Subtitulo -->
                      <div class="clearfix"></div>
                      <!-- E-mail de Boas Vindas -->
                      <!-- Nome completo -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>Nome Completo</label>
                          <input class="form-control" type="text" value>
                        </div>
                      </div>
                      <!-- /Nome completo -->
                      <!-- E-mail -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>E-mail</label>
                          <input class="form-control" type="text" value>
                        </div>
                      </div>
                      <!-- /E-mail -->
                      <!-- CPF -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>CPF</label>
                          <input class="form-control" type="text" value>
                        </div>
                      </div>
                      <!-- /CPF -->
                      <!-- Data de Nascimento -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>Data de nascimento</label>
                          <input class="form-control" type="text" value>
                        </div>
                      </div>
                      <!-- /Data de Nascimento -->
                      <!-- Telefone celular -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>Celular</label>
                          <input class="form-control" type="text" value>
                        </div>
                      </div>
                      <!-- /Telefone celular -->
                      <!-- Gênero -->
                      <div class="col-md-6">
                        <div class="form-group theme-login-form-group">
                          <label>Gênero</label>
                          <div>
                            <input type="radio" name="genero" id="Masculino">
                            <label for="Masculino">&nbsp;Masculino</label>
                          </div>
                          <div>
                            <input type="radio" name="genero" id="Feminino">
                            <label for="Feminino">&nbsp;Feminino</label>
                          </div>
                        </div>
                      </div>
                      <!-- /Gênero -->
                      <!-- E-mail de Boas Vindas -->
                      <div class="col-md-12 padding-off">
                        <div class="col-md-6">
                          <!-- Vincular ao e-mail do comprador -->
                          <div class="theme-login-checkbox">
                            <label class="icheck-label">
                              <input class="icheck" type="checkbox">
                              <span class="icheck-title">&nbsp;Vincular ao e-mail do comprador</span>
                            </label>
                          </div>
                          <!-- /Vincular ao e-mail do comprador -->
                        </div>
                        <div class="col-md-6">
                          <!-- E-mail de boas vindas -->
                          <div class="form-group">
                            <div class="theme-login-checkbox">
                              <label class="icheck-label">
                                <input class="icheck" type="checkbox">
                                <span class="icheck-title">&nbsp;Enviar e-mail de boas vindas</span>
                              </label>
                            </div>
                          </div>
                          <!-- E-mail de boas vindas -->
                        </div>
                      </div>
                      <!-- E-mail de Boas Vindas -->
                      <!-- Dados pessoais -->
                    </div>
                    <div class="modal-footer change-modal-footer">
                      <div class="col-md-6">
                        <button
                          type="button"
                          class="btn _tt-uc btn-primary-inverse btn-lg mt-20 btn-block"
                          data-dismiss="modal"
                        >Cancelar</button>
                      </div>
                      <div class="col-md-6">
                        <button
                          type="button"
                          class="btn _tt-uc btn-primary btn-lg mt-20 btn-block"
                          data-dismiss="modal"
                        >Adicionar Viajante</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Modal novo viajante -->
              <div class="theme-payment-page-sections-item">
                <div class="theme-payment-page-booking">
                  <div class="theme-payment-page-booking-header">
                    <h3 class="theme-payment-page-booking-title">Preço total para 2 noites</h3>
                    <p
                      class="theme-payment-page-booking-subtitle"
                    >As tarifas são cotadas em Reais Brasileiros.</p>
                    <p class="theme-payment-page-booking-price">R$739.00</p>
                  </div>
                  <div class="col-md-6 padding-left-off mb-20">
                    <a class="btn _tt-uc btn-primary-inverse btn-lg btn-block" href="#">Retornar</a>
                  </div>
                  <div class="col-md-6 padding-right-off mb-20">
                    <a
                      class="btn _tt-uc btn-primary btn-lg btn-block"
                      href="#"
                    >Adicionar ao Carrinho</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="sticky-col">
              <div class="theme-sidebar-section _mb-10">
                <h5 class="theme-sidebar-section-title">Sumário:</h5>
                <ul class="theme-sidebar-section-summary-list">
                  <li>2 noites, 1 quarto, 1 adulto</li>
                  <li>Check-in: Terça-feira, 29 de Janeiro de 2019</li>
                  <li>Check-out: Terça-feira, 31 de Janeiro de 2019</li>
                </ul>
              </div>
              <div class="theme-sidebar-section _mb-10">
                <h5 class="theme-sidebar-section-title">Subtotal:</h5>
                <div class="theme-sidebar-section-charges">
                  <ul class="theme-sidebar-section-charges-list">
                    <li class="theme-sidebar-section-charges-item">
                      <h5 class="theme-sidebar-section-charges-item-title">2 Diárias</h5>
                      <p class="theme-sidebar-section-charges-item-subtitle">1 Adulto</p>
                      <p class="theme-sidebar-section-charges-item-price">R$570.00</p>
                    </li>
                    <li class="theme-sidebar-section-charges-item">
                      <h5 class="theme-sidebar-section-charges-item-title">Taxas</h5>
                      <p class="theme-sidebar-section-charges-item-subtitle"></p>
                      <p class="theme-sidebar-section-charges-item-price">R$115.00</p>
                    </li>
                  </ul>
                  <p class="theme-sidebar-section-charges-total">
                    Total
                    <span>R$739.00</span>
                  </p>
                </div>
              </div>
              <div class="theme-sidebar-section _mb-10">
                <ul class="theme-sidebar-section-features-list">
                  <li>
                    <h5 class="theme-sidebar-section-features-list-title">Impostos e taxas</h5>
                    <p
                      class="theme-sidebar-section-features-list-body"
                    >Os impostos são encargos de restituição de imposto que a etrip paga a seus fornecedores, como hotéis. Para obter mais detalhes, consulte nossos Termos de uso. Mantemos nossas taxas de serviços como remuneração para atender sua reserva de viagem.</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.change-modal {
  border-radius: 0;
}
.change-modal-header {
  border: 0 !important;
}
.change-modal-footer {
  border: 0 !important;
}
.content-change-modal {
  height: 350px;
  overflow-y: auto;
  font-size: 14px;
  padding-left: 40px;
  padding-right: 40px;
}
.theme-account-page-title{
  border-bottom: 1px solid #e6e6e6;
}
</style>
