<template>
  <section
    v-if="needsApprove || mandatoryJustification || needsSelectApprover || costCenterList.length"
    class="border-lighter mb-20 pb-20 clearfix">
    <div
      v-if="needsApprove"
      :class="`col-sm-${countPolicies}`"
      class="form-group mt-20">
      <label>Motivo da viagem</label>
      <div class="theme-payment-page-form-item">
        <i class="fa fa-angle-down"></i>
        <select
          v-model="field.mainReason"
          @change="handleMainReason"
          class="form-control">
          <option value="">Selecione o motivo da viagem</option>
          <option
            v-for="reason in reasons"
            :key="reason.id"
            :value="reason.id">{{ reason.name }}</option>
        </select>
      </div>
    </div>
    <div
      v-if="mandatoryJustification"
      :class="`col-sm-${countPolicies}`"
      class="form-group mt-20">
      <label>Viagem fora do prazo de antecedência</label>
      <div class="theme-payment-page-form-item">
        <i class="fa fa-angle-down"></i>
        <select
          v-model="field.justification"
          @change="$emit('update:mandatory_justification', field.justification)"
          class="form-control">
          <option value="">Justifique a viagem</option>
          <option
            v-for="justification in justifications"
            :key="justification.id"
            :value="justification.id">{{ justification.name }}</option>
        </select>
      </div>
    </div>
    <div
      v-if="needsSelectApprover"
      :class="`col-sm-${countPolicies}`"
      class="form-group mt-20">
      <label>Aprovador inicial</label>
      <div class="theme-payment-page-form-item">
        <i class="fa fa-angle-down"></i>
        <select
          v-model="field.approver"
          @change="$emit('update:approver', field.approver)"
          class="form-control">
          <option value="">Selecione o aprovador inicial</option>
          <option
            v-for="user in approvers.users"
            :key="user.id"
            :value="user.id">{{ user.full_name }}</option>
        </select>
      </div>
    </div>
    <div
      v-if="costCenterList.length"
      :class="`col-sm-${countPolicies}`"
      class="form-group mt-20">
      <label>Centro de custo</label>
      <div class="theme-payment-page-form-item">
        <i class="fa fa-angle-down"></i>
        <select
          v-model="field.costCenter"
          @change="$emit('update:cost_center', field.costCenter)"
          class="form-control">
          <option value="">Selecione um centro de custo para a viagem</option>
          <option
            v-for="cost in costCenterList"
            :key="cost.id"
            :value="cost.id">{{ cost.name }}</option>
        </select>
      </div>
    </div>
  </section>
</template>

<script>
import _ from 'underscore';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  props: [
    'user',
    'reserves',
    // from sync
    'reasons_request',
    'cost_center',
    'approver',
    'mandatory_justification'
  ],
  data () {
    return {
      field: {
        mainReason: '',
        justification: '',
        approver: '',
        costCenter: '',
      },
      approvers: [],
      mandatoryJustification: false
    }
  },
  beforeMount () {
    this.getPolicies('reasons');
    this.getPolicies('justifications');
  },
  computed: {
    ...mapState('policy', [
      'reasons',
      'justifications',
    ]),
    needsApprove () {
      const companies = this.user.companies;

      if (companies[0].policy) {
        const approval_config = companies[0].policy.approval_config || false;
        if (approval_config) {
          return approval_config.active;
        }
      }

      return false;
    },
    needsSelectApprover () {
      const companies = this.user.companies;

      if (companies[0].policy) {
        const approval_config = companies[0].policy.approval_config || false;
        if (approval_config) {
          // in theory only on first stage user can select the approver
          this.approvers = _.findWhere(approval_config.stages[0].group, { can_select_user: true })
          if (this.approvers) {
            return true;
          }
        }
      }

      return false;
    },
    costCenterList () {
      const hasCompanies = this.user.companies.length;
      if (hasCompanies) {
        const company = this.user.companies[0];
        return company.cost_center;
      }
      return [];
    },
    countPolicies () {
      const policies = [this.needsApprove, this.mandatoryJustification, this.needsSelectApprover, this.costCenterList];
      return 12 / policies.filter((policy) => policy).length;
    }
  },
  watch: {
    reserves (data) {
      this.checkOutOfTime();
    }
  },
  methods: {
    ...mapActions('policy', [
      'getPolicies'
    ]),
    handleMainReason()  {
      let all_order_item_ids = []

      const hotel_orders = this.reserves.hotels.map(function(el) {
        return el.id;
      })

      const flight_orders = this.reserves.flights.map(function(el){
        return el.id
      })

      const car_orders = this.reserves.cars.map(function(el) {
        return el.id
      })

      all_order_item_ids = all_order_item_ids.concat(hotel_orders, flight_orders, car_orders);

      let reasons_request = {}
      all_order_item_ids.forEach((el, idx) => {
        reasons_request[el] = this.field.mainReason;
      })

      this.$emit('update:reasons_request', reasons_request);
    },
    checkOutOfTime() {
      const all_orders = [].concat(this.reserves.hotels, this.reserves.flights, this.reserves.cars);
      const user = this.user;
      const company  = user.companies[0];
      const policy = company.policy || false;
      let is_out_of_time = false;

      if (policy) {
        const antecedenceConfig = policy.antecedence_config;

        if (antecedenceConfig && antecedenceConfig.length > 0) {

          all_orders.forEach((el, idx) => {
            if (is_out_of_time) return;

            const orderitem = el.order_item || el;
            const antecedence = _.findWhere(antecedenceConfig, {product: orderitem.product});
            const future = moment().add(antecedence.hours, 'hours');

            if (orderitem.product == 1) {
              // hotel
              is_out_of_time = future > moment(orderitem.room.checkin);
            }

            if (orderitem.product == 2) {
              // flight
              // is_out_of_time = future > moment(orderitem.flight.segments[0].departure);
              is_out_of_time = future > moment(orderitem.flight[0].departure);
            }

            if (orderitem.product == 3) {
              // vehicle
              is_out_of_time = future > moment(orderitem.vehicle.start_date);
            }

            this.mandatoryJustification = is_out_of_time;
          })
        }
      }
      return is_out_of_time;
    }
  }
};
</script>
