<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div class="col-md-10">
            <div class="col-md-12 padding-off">
              <h1 v-if="this.all_orders && $store.getters.getUser.is_agents" class="theme-account-page-title">Todos os pedidos</h1>
              <h1 v-else class="theme-account-page-title">Meus pedidos</h1>
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="col-md-8 padding-left-off">
                  <div
                    class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
                  >
                    <div class="theme-search-area-section-inner">
                      <input
                        type="text"
                        placeholder="Pesquise pelo nº do pedido, código da reserva ou nome do hotel"
                        data-provide="typeahead"
                        class="theme-search-area-section-input"
                        v-model="search"
                      >
                    </div>
                  </div>
                </div>
                <div class="col-md-3 padding-left-off">
                  <div
                    class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
                  >
                    <div class="theme-search-area-section-inner">
                      <select class="theme-search-area-section-select" v-model="search_status">
                        <option value="0">Todos</option>
                        <option value="finished">Finalizada</option>
                        <option value="processing">Processando</option>
                        <option value="canceled">Cancelado</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-md-1 padding-off">
                  <button
                    v-on:click="doSearch"
                    class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary"
                  >
                    <i aria-hidden="true" class="fa fa-search"></i>
                  </button>
                </div>
              </div>

              <div class="col-md-12 mt-20">
                <div class="theme-account-history table-responsive">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Pedido</th>
                        <th>Data</th>
                        <th>Descrição</th>
                        <th>Total</th>
                        <th>Status Pedido</th>
                        <th>Status Item</th>
                      </tr>
                    </thead>
                    <tbody v-if="orders.length">
                      <tr v-for="order in orders" :key="order.id">
                        <!--<td class="text-center">
                          <i
                            class="fa fa-plane theme-account-history-type-icon"
                            v-if="order.products_labels.includes(2)"
                          ></i>
                          <i
                            class="fa fa-building theme-account-history-type-icon"
                            aria-hidden="true"
                            v-if="order.products_labels.includes(1)"
                          ></i>
                          <i
                            class="fa fa-car theme-account-history-type-icon"
                            v-if="order.products_labels.includes(3)"
                          ></i>
                        </td>-->
                        <td class>
                          <a href="javascript:void(0)" v-on:click="open_details(order)">
                            <p class="theme-account-history-type-title">{{order.number}}</p>
                          </a>
                        </td>
                        <td class>
                          <p
                            class="theme-account-history-date"
                          >{{order.modification_date | date_to_human}}</p>
                        </td>
                        <td>
                          <div class="pb-20" v-for="item in order.items" :key="item.id">
                            <p
                              class="theme-account-history-type-title"
                              v-if="item.product === 'flight'"
                            >
                              {{item.origin}}
                              <i class="fa fa-exchange" aria-hidden="true"></i>
                              {{item.destiny}}
                            </p>
                            <p class="theme-account-history-type-title" v-else>{{item.name}}</p>
                            <span class="theme-footer-brand-text">{{item.description}}</span>
                          </div>
                        </td>
                        <td>
                          <p class="theme-account-history-item-price">{{order.total | brl_price}}</p>
                        </td>
                        <td>
                          <p>
                            <i
                              class="fa fa-lg"
                              :class="{'fa-times-circle text-danger': order.status.slug === 'canceled',
                               'fa-check-circle text-success': order.status.slug === 'finished',
                               'fa-clock-o text-warning': order.status.slug === 'processing',
                               }"
                            ></i>
                            &nbsp;{{order.status.name}}
                          </p>
                        </td>
                        <td>
                          <p v-for="item in order.order_item">
                            <i
                                    class="fa fa-plane theme-account-history-type-icon"
                                    v-if="item.product===2"
                            ></i>
                            <i
                                    class="fa fa-building theme-account-history-type-icon"
                                    aria-hidden="true"
                                    v-if="item.product===1"
                            ></i>
                            <i
                                    class="fa fa-car theme-account-history-type-icon"
                                    v-if="item.product===3"
                            ></i>
                            <template v-if="!showStatusApproval(item)">
                              <span class="text-warning" v-if="item.status===1">processando</span>
                              <span class="text-success" v-if="item.status===2">reservado</span>
                              <span class="text-success" v-if="item.status===3">emitido</span>
                              <span class="text-danger" v-if="item.status===4">cancelado</span>
                              <span class="text-danger" v-if="item.status===5">cancelando</span>
                              <span class="text-danger" v-if="item.status===6">pagamento recusado</span>
                              <span class="text-danger" v-if="item.status===7">cancelado</span>
                            </template>
                            <template v-else>
                              <span class="text-warning" v-if="item.approval.status===1">aguardando aprovação</span>
                            </template>
                          </p>
                        </td>
                      </tr>
                    </tbody>
                    <tbody v-else>
                      <tr class="text-center">
                        <td colspan="6">Não existem itens</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <button v-on:click="nextPage(search, next_page)" v-if="next_page" class="btn-block theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary">
                  Carregar mais resultados
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import MenuAccount from "../components/menuaccount.vue";
import lockscreen from "../components/lockscreen/index.vue";

export default {
  data() {
    return {
      search: null,
      search_status: "0",
      all_orders: this.$route.query.all_orders,
      orders: [],
      loading: false,
      next_page: null
    };
  },
  components: {
    MenuAccount,
    lockscreen
  },
  created: function() {
    this.fetchData();
  },
  watch: {
    $route(to, from) {
      this.all_orders = to.query.all_orders
      this.fetchData()
    }
  },
  computed: {
    filteredList() {
      return this.orders.filter(order => {
        let number = order.number.toLowerCase();
        if (number.includes(this.search.toLowerCase())) {
          if (this.search_status !== "all") {
            return order.status === this.search_status;
          }
          return true;
        }
      });
    }
  },
  methods: {
    fetchData() {
      this.get_orders().then((response) => {
        this.orders = response.data.results;
      })
    },
    doSearch() {
      this.get_orders(this.search).then((response) => {
        this.orders = response.data.results;
      });
    },
    nextPage(term, more_orders_url) {
      this.get_orders(this.search, more_orders_url).then((response) => {
        this.orders = this.orders.concat(response.data.results);
      });
    },
    get_orders(term=null, more_orders_url=null) {
      return new Promise((resolve, reject) => {
        let url = "/api/user/orders";
        if (more_orders_url) {
          url = more_orders_url
        }

        if (term) {
          if (url.indexOf('?') > 0) {
            url = `${url}&term=${term}`;
          } else {
            url = `${url}?term=${term}`;
          }
        }

        if (this.search_status) {
          if (url.indexOf('?') > 0) {
            url = `${url}&status=${this.search_status}`;
          } else {
            url = `${url}?status=${this.search_status}`;
          }
        }

        if (this.all_orders) {
          if (url.indexOf('?') > 0) {
            url = `${url}&all_orders=true`;
          } else {
            url = `${url}?all_orders=true`;
          }
        }

        this.loading = true;
        this.$http
          .get(url)
          .then(response => {
            this.loading = false;
            this.next_page = response.data.next;
            return resolve(response)
          })
          .catch(error => {
            return reject(error)
          });
      });
    },
    open_details(order) {
      this.$router.push({
        name: "order_details",
        params: { order: order, id: order.id }
      });
    },
    showStatusApproval(item) {
      if (item.approval) {
        if (item.approval.status == 1) {
          return true;
        }
      }
      return false;
    }
  }
};
</script>

<style scoped>
.theme-search-area-section-input {
  display: block;
  width: 100%;
  border: none !important;
  outline: none !important;
  height: 55px;
  padding-left: 16px;
  font-size: 16px;
  background: none;
}
.theme-search-area-section-select {
  display: block;
  width: 100%;
  border: none !important;
  outline: none !important;
  height: 55px;
  padding-left: 12px;
  font-size: 16px;
  background: none;
  position: relative;
  color: #7a7a7a;
}
.theme-account-history-type-icon {
  opacity: 0.65;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=65)";
  filter: alpha(opacity=65);
  padding-left: 3px;
  padding-right: 3px;
}
</style>
