<template>
  <div
    v-if="item"
    class="col-xs-12 clearfix _pt-20 _pb-20">
    <!-- Fotorama -->
    <div
      class="fotorama-detail"
      data-auto="false"
      data-nav="thumbs"
      data-minwidth="100%"
      data-height="260"
      data-arrows="always"
      data-allowfullscreen="native"
      data-fit="cover">
      <img
        v-for="image in item.images"
        :key="`detail-${image.id}`"
        :src="image.url"
        alt="" />
    </div>
    <!-- // Fotorama -->
    <div class="_pt-15">
      <p class="size-14 col-xs-12 col-sm-6 _pr-0 _pl-0">
        <i class="fa fa-male"></i>
        <template v-if="item.room.max_occupancy < 11">
          Capacidade: {{ item.room.max_occupancy }} {{ 'pessoa' | pluralize(item.room.max_occupancy) }}
        </template>
        <template v-else>
          Capacidade: 10+ pessoas
        </template>
      </p>
      <p class="size-14 col-xs-12 col-sm-6 _pr-0 _pl-0"
        v-for="(amenitie, index) in item.amenities"
        :key="`amenitie-${item.id}-${index}`">
        <i class="fa fa-check"></i>&nbsp;{{ amenitie.detail }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'item'
  ],
  updated () {
    $('.fotorama-detail').fotorama();
  }
};
</script>
