<template>
  <form
    @submit.prevent="handleSubmit"
    name="personal"
    class="clearfix">
    <div class="col-md-6 padding-off">
      <!-- Subtitulo -->
      <div class="col-md-12">
        <h4 class="title-manage-account">Dados pessoais</h4>
      </div>
      <!-- /Subtitulo -->
      <!-- Nome completo -->
      <div class="col-md-12">
        <div class="form-group theme-login-form-group">
          <label>Nome completo*</label>
          <input
            class="form-control"
            type="text"
            name="full_name"
            v-model="form.full_name"
            v-validate="'required'"
            :class="{'alert-danger': submitted && errors.has('full_name')}"
          >
          <span class="error-message-forms">{{ errors.first('full_name') }}</span>
        </div>
      </div>
      <!-- /Nome completo -->
      <!-- E-mail -->
      <div class="col-md-12">
        <div class="form-group theme-login-form-group">
          <label>E-mail*</label>
          <input
            class="form-control"
            type="text"
            name="email"
            v-model="form.email"
            v-validate="'required'"
            :class="{'alert-danger': submitted && errors.has('email')}"
            disabled='disabled'
          >
          <span class="error-message-forms">{{ errors.first('email') }}</span>
        </div>
      </div>
      <!-- /E-mail -->
      <!-- E-mail Secundário -->
      <div class="col-md-12">
        <div class="form-group theme-login-form-group">
          <label>E-mail Adicional</label>
          <input
            class="form-control"
            type="text"
            name="additional_email"
            v-model="form.additional_email"
          >
        </div>
      </div>
      <!-- /E-mail Secundário -->
      <div class="col-md-12 padding-off">
        <!-- CPF -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>CPF*</label>
            <the-mask
              class="form-control"
              type="tel"
              name="document_number"
              v-model="form.document_number"
              :mask="['###.###.###-##', '##.###.###/####-##']"
              v-validate="'required'"
              :class="{'alert-danger': submitted && errors.has('document_number')}"
            />
            <span class="error-message-forms">{{ errors.first('document_number') }}</span>
          </div>
        </div>
        <!-- /CPF -->
        <!-- Data de Nascimento -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Data de nascimento*</label>
            <the-mask
              class="form-control"
              type="text"
              name="birth_date"
              v-model="form.birth_date"
              :mask="['##/##/####']"
              v-validate="'required'"
              :class="{'alert-danger': submitted && errors.has('birth_date')}"
            />
            <span class="error-message-forms">{{ errors.first('birth_date') }}</span>
          </div>
        </div>
        <!-- /Data de Nascimento -->
      </div>
      <div class="col-md-12 padding-off">
        <!-- Telefone fixo -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Telefone*</label>
            <the-mask
              class="form-control"
              type="tel"
              name="phone"
              v-model="form.phone"
              :mask="['(##) ####-####', '(##) #####-####']"
              v-validate="'required'"
              :class="{'alert-danger': submitted && errors.has('phone')}"
            />
            <span class="error-message-forms">{{ errors.first('phone') }}</span>
          </div>
        </div>
        <!-- /Telefone fixo -->
        <!-- Telefone celular -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Celular*</label>
            <the-mask
              class="form-control"
              type="tel"
              name="mobile_phone"
              v-model="form.mobile_phone"
              :mask="['(##) ####-####', '(##) #####-####']"
              v-validate="'required'"
              :class="{'alert-danger': submitted && errors.has('mobile_phone')}"
            />
            <span class="error-message-forms">{{ errors.first('mobile_phone') }}</span>
          </div>
        </div>
        <!-- /Telefone celular -->
      </div>
      <!-- Gênero -->
      <div class="col-md-12">
        <div class="form-group theme-login-form-group">
          <div>Gênero*</div>
          <div class="btn-group theme-search-area-options-list">
            <p-radio
              class="p-default p-round p-smooth p-plain"
              name="genero"
              id="Masculino"
              :value="1"
              v-model="form.gender"
              color="primary-o"
              v-validate="'required|included:1,2'"
            >&nbsp;Masculino</p-radio>
            <p-radio
              class="p-default p-round p-smooth p-plain"
              name="genero"
              id="Feminino"
              :value="2"
              v-model="form.gender"
              color="primary-o"
            >&nbsp;Feminino</p-radio>
          </div>
          <span class="error-message-forms">{{ errors.first('genero') }}</span>
        </div>
      </div>
      <!-- /Gênero -->
    </div><!-- /end col-sm-6 -->
    <div class="col-md-6 padding-off">
      <!-- Subtitulo -->
      <div class="col-md-12">
        <h4 class="title-manage-account">Endereço residencial</h4>
      </div>
      <div class="col-md-12 padding-off">
        <!-- CEP -->
        <div class="col-md-4">
          <div class="form-group theme-login-form-group">
            <label>CEP*</label>
            <the-mask
              class="form-control"
              type="tel"
              name="zip_code"
              v-model="form.address.zip_code"
              :mask="['#####-###']"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('zip_code') }"
            />
            <span class="error-message-forms">{{ errors.first('zip_code') }}</span>
          </div>
        </div>
        <!-- /CEP -->
        <!-- Endereço -->
        <div class="col-md-8">
          <div class="form-group theme-login-form-group">
            <label>Endereço*</label>
            <input
              class="form-control"
              type="text"
              name="street"
              v-model="form.address.street"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('street') }"
            >
            <span class="error-message-forms">{{ errors.first('street') }}</span>
          </div>
        </div>
        <!-- /Endereço -->
      </div>
      <div class="col-md-12 padding-off">
        <!-- Número -->
        <div class="col-md-4">
          <div class="form-group theme-login-form-group">
            <label>Número*</label>
            <input
              class="form-control"
              type="tel"
              name="number"
              v-model="form.address.number"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('number') }"
            >
            <span class="error-message-forms">{{ errors.first('number') }}</span>
          </div>
        </div>
        <!-- /Número -->
        <!-- Complemento -->
        <div class="col-md-8">
          <div class="form-group theme-login-form-group">
            <label>Complemento</label>
            <input class="form-control" type="text" v-model="form.address.complement">
          </div>
        </div>
        <!-- /Complemento -->
      </div>
      <div class="col-md-12 padding-off">
        <!-- Bairro -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Bairro*</label>
            <input
              class="form-control"
              type="text"
              name="neighborhood"
              v-model="form.address.neighborhood"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('neighborhood') }"
            >
            <span class="error-message-forms">{{ errors.first('neighborhood') }}</span>
          </div>
        </div>
        <!-- /Bairro -->
        <!-- Cidade -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Cidade*</label>
            <input
              class="form-control"
              type="text"
              name="city"
              v-model="form.address.city"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('city') }"
            >
            <span class="error-message-forms">{{ errors.first('city') }}</span>
          </div>
        </div>
        <!-- /Cidade -->
      </div>
      <div class="col-md-12 padding-off">
        <!-- Estado -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>Estado*</label>
            <input
              class="form-control"
              type="text"
              name="province"
              v-model="form.address.province"
              v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('province') }"
            >
            <span class="error-message-forms">{{ errors.first('province') }}</span>
          </div>
        </div>
        <!-- /Estado -->
        <!-- País -->
        <div class="col-md-6">
          <div class="form-group theme-login-form-group">
            <label>País*</label>
            <select
              v-model="form.address.country" name="country" id="" class="form-control" v-validate="'required'"
              :class="{ 'alert-danger': submitted && errors.has('form-form.country') }">
              <option :value="country.id" v-for="country in $store.state.countries">
                {{ country.name }}
              </option>
            </select>
            <span class="error-message-forms">{{ errors.first('country') }}</span>
          </div>
        </div>
        <!-- /País -->
      </div>
      <div class="col-sm-12 mt-20 mb-20 clearfix">
        <small>*Campos obrigatórios</small>
      </div>
      <div class="col-sm-12 mt-20 mb-20 padding-off">
        <!-- Parent Action -->
        <slot :handleParentAction="() => $emit('handleParentAction')"></slot>
        <!-- // Parent Action -->
        <div class="col-xs-6">
          <button
            :disabled="disabled"
            type="submit"
            class="btn-blue btn-block btn-extra-large">
            Salvar
          </button>
        </div>
      </div>
    </div><!-- /end col-sm-6 -->
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  data: function() {
    return {
      form: {
        id: null,
        full_name: null,
        document_number: null,
        birth_date: null,
        gender: null,
        mobile_phone: null,
        phone: null,
        email: null,
        additional_email: null,
        frequency_purchases: null,
        buy_flights: null,
        buy_hotels: null,
        rent_cars: null,
        address: {
          street: null,
          number: null,
          complement: null,
          neighborhood: null,
          city: null,
          province: null,
          country: null,
          zip_code: null
        }
      },
      submitted: false,
      disabled: false
    };
  },
  mounted () {
    this.getPersonalAccount();
  },
  computed: {
    ...mapState('personal', ['results', 'alert'])
  },
  watch: {
    results (data) {
      this.form = data;
    }
  },
  methods: {
    ...mapActions('personal', [
      'getPersonalAccount',
      'postPersonalAccount'
    ]),
    handleSubmit (event) {
      this.$validator.validate().then(valid => {
        this.submitted = true;
        if (valid) {
          this.disabled = true;
          this.postPersonalAccount(this.form)
            .then(this.handleParent)
            .catch(this.handleParent)
        }
      }).catch(error => {
        /**/
      })
    },
    handleParent () {
      if (this.$listeners && this.$listeners.handleParentAction) {
        this.$emit('handleParentAction');
      }
      this.disabled = false;
    }
  }
};
</script>

<style scoped>
/* TODO MOVE TO COMMON */
label {
  margin-bottom: 0;
}
.theme-login-form-group {
  margin-bottom: 10px;
}
.form-control {
  height: 48px;
}
</style>
