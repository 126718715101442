<template>
  <div class="theme-hero-area">
    <div class="theme-hero-area-bg-wrap">
      <div
          class="theme-hero-area-bg"
          :style="{'background-image': 'url(' + require('@/assets/img/bg-home-hero.jpg') + ')'}"
      ></div>
      <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
    </div>
    <div class="theme-hero-area-body">
      <div class="theme-page-section theme-page-section-xl">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <div class="theme-login-header">
                <router-link to="/login">
                  <div class="center-block wdt-250 mt-30 mb-20">
                    <img
                        class="img-responsive"
                        src="@/assets/img/logo-login.png"
                        alt="Logo etrip"
                        title="ETrip"
                    >
                  </div>
                </router-link>
              </div>
            </div>
            <!-- cookies  -->
            <div class="cookie" :class="containerPosition" v-if="isOpen">
              <div class="cookie__content">
                <slot name="message">Este site usa cookies para melhorar a sua experiência de navegação, para saber mais por favor consulte os nossos
                  <a href="/TermsConditions">Termos de serviço</a> e
                  <a href="/PoliciesPrivacy">Politica de privacidade.</a>
                </slot>
              </div>
              <div class="cookie__button accept" @click="accept">
                {{ buttonTextAccept }}
              </div>
            </div>
            <!-- end cookies -->
            <div class="col-md-6 col-md-offset-3">
              <div class="theme-login theme-login-white">
                <div class="theme-login-box">
                  <div class="theme-login-box-inner">
                    <div class="col-md-12">
                      <form @submit.prevent="login" class="theme-login-form">
                        <div class="form-group theme-login-form-group">
                          <input
                              class="form-control"
                              type="text"
                              placeholder="Endereço de e-mail"
                              name="username"
                              v-model="username"
                          >
                        </div>
                        <div class="form-group theme-login-form-group">
                          <input
                              class="form-control"
                              type="password"
                              placeholder="Senha"
                              name="password"
                              v-model="password"
                          >
                          <p class="help-block">
                            <router-link to="/forgot_password">
                              <a>Esqueceu sua senha?</a>
                            </router-link>
                          </p>
                        </div>
                        <button
                            type="submit"
                            class="btn btn-uc btn-dark btn-block btn-lg"
                            href="#"
                            v-on:click="login($event)"
                        >
                          <i class="fa fa-sign-in" aria-hidden="true"></i>
                          Entrar
                        </button>
                        <div class="text-center mt-10 mb-20">
                          <p>
                            Não tem uma conta? &nbsp;
                            <router-link to="/signup">
                              <a>Cadastre-se.</a>
                            </router-link>
                          </p>
                        </div>
                      </form>
                    </div>
                    <div class="col-md-12 disclaimers _mb-20" style="text-align:center;">
                      <small>Esse site é protegido por reCAPTCHA e Google onde<br><a
                          href="https://loja.etrip.com.br/PoliciesPrivacy/">Politica de privacidade</a> e <a
                          href="https://loja.etrip.com.br/TermsConditions/">Termos de serviço</a> se aplicam.</small>
                    </div>
                    <!-- <div class="col-md-6">
                      <div class="theme-login-social-separator">
                        <p>Acesse com as redes sociais</p>
                      </div>
                      <div class="theme-login-social-login">
                        <div class="row">
                          <div class="col-md-12 mb-10">
                            <a
                              class="theme-login-social-login-facebook"
                              href="#"
                              v-on:click="login($event, 'facebook')"
                            >
                              <i class="fa fa-facebook"></i>
                              <span>
                                Entrar com
                                <b>Facebook</b>
                              </span>
                            </a>
                          </div>
                          <div class="col-md-12 mb-10">
                            <a class="theme-login-social-login-google" href="#">
                              <i class="fa fa-google-plus"></i>
                              <span>
                                Entrar com
                                <b>Google</b>
                              </span>
                            </a>
                          </div>
                          <div class="col-md-12 mb-10">
                            <a class="theme-login-social-login-linkedin" href="#">
                              <i class="fa fa-linkedin"></i>
                              <span>
                                Entrar com
                                <b>Linkedin</b>
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <hr>
                      <div class="text-center mt-10 mb-20">
                        <p>Não tem uma conta? &nbsp;
                          <router-link to="/signup">
                            <a>Cadastre-se.</a>
                          </router-link>
                        </p>
                      </div>
                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>
<script>


import axios from "axios";
import lockscreen from "@/components/lockscreen/index.vue";


let validate = token => {
  return axios({
    url: `/api/user/verify/${token}`,
    method: "GET"
  });
};

export default {
  components: {lockscreen},
  props: {
    buttonTextAccept: {
      type: String,
      default: "Aceitar",
    },
    buttonTextDeny: {
      type: String,
      default: "Recusar",
    },
    position: {
      type: String,
      default: "bottom",
    },
  },

  data() {
    return {
      username: "",
      password: "",
      message: "",
      isOpen: false,
      loading: false
    };
  },

  computed: {
    containerPosition() {
      return `cookie--${this.position}`;
    },
  },
  created() {
    if (!this.getCookieAccept() === true) {
      this.isOpen = true;
    }
  },
  methods: {

    accept() {
      this.isOpen = false;
      return localStorage.setItem("EtripCookie", true);
    },
    getCookieAccept() {
      return localStorage.getItem("EtripCookie", true);
    },
    login(e, social) {
      this.loading = true;
      let _login = (token = false) => {
        let $http = this.$http;
        $http({
          url: login_url,
          method: method,
          data: {
            username: this.username,
            password: this.password,
            token: token
          }
        })
            .then(response => {
              if (!response.data.error) {
                this.$store.commit("LOGIN_SUCCESS", response.data.user);

                this.$ga.event(
                    {
                      eventCategory: 'Authentication',
                      eventAction: 'Login',
                      eventLabel: 'Login',
                      eventValue: 1
                    }
                )
                if (response.data.user.password_expired) {
                    this.$router.push('/password_change')
                  } else {
                      this.$router.push('/')
                  }
              } else {
                new Error(response.data);
              }
            })
            .catch(err => {
              if (err.response.data.is_active === false) {
                this.$swal({
                  title: 'Confirme seu usuário pelo link enviado ao seu e-mail, caso queira enviar outro link clique no botão abaixo.',
                  type: err.response.data.info ? "info" : "warning",
                  showCancelButton: true,
                  confirmButtonText: "Enviar",
                  cancelButtonText: 'Cancelar',
                }).then((result) => {
                  if (result.value) {
                    this.enviarEmail()
                  }
                })
              } else {
                this.$swal({
                  title: err.response.data.message || 'Ocorreu um erro ao realizar login. Tente novamente.',
                  type: err.response.data.info ? "info" : "warning",
                  confirmButtonText: "SAIR"
                });
              }
            })
            .finally(() => {
              this.loading = false;
            })
      };

      e.preventDefault();

      let login_url = "/api/user/login";
      let method = "POST";

      if (social === "facebook") {
        login_url = "/accounts/facebook/login";
        method = "GET";
      }

      this.$recaptcha("login")
          .then(token => {
            _login(token);
          })
          .catch((err, a) => {
            // any problems with token in development mode bypass recaptcha
            if (process.env.NODE_ENV == "development") {
              _login();
            }
          });
    },
    sendMessage(message, type) {
      this.$swal({
        title: message,
        type: type,
        confirmButtonText: "SAIR"
      });
    },
    enviarEmail() {
      let _login = (token = false) => {
        let $http = this.$http;
        $http({
          url: "/api/user/request/invite",
          method: "POST",
          data: {
            username: this.username,
            password: this.password,
            token: false
          }
        })
            .then(response => {
              if (!response.data.error) {
                this.$swal({
                  title: 'E-mail enviado com sucesso!',
                  type: "success",
                  confirmButtonText: "Confirmar",
                }).then((result) => {
                  if (result.value) {
                    this.$router.push("/");
                  }
                })
              } else {
                new Error(response.data);
              }
            })
            .catch(err => {
              this.$swal({
                title: err.response.data.message,
                type: "error",
                confirmButtonText: "SAIR"
              });
            });
      }

      this.$recaptcha("login")
          .then(token => {
            _login(token);
          })
          .catch((err, a) => {
            // any problems with token in development mode bypass recaptcha
            if (process.env.NODE_ENV == "development") {
              _login();
            }
          });
    },
  },
  beforeRouteEnter(to, from, next) {
    if (to.query.activate) {
      validate(to.query.activate)
          .then(() => {
            next(vm => {
              vm.sendMessage(
                  "Conta verificada com sucesso! Faça seu login",
                  "success"
              );
            });
          })
          .catch(() => {
            next(vm => {
              vm.sendMessage("Token expirado ou inexistente!", "warning");
            });
          });
    } else {
      next();
    }
  },
};


</script>

<style scoped>

.theme-login-box-inner {
  padding: 30px 10px !important;
  padding-bottom: 0 !important;
}

.theme-login-social-login-facebook,
.theme-login-social-login-google,
.theme-login-social-login-linkedin {
  display: block;
  padding: 7px 11px !important;
  color: #fff;
  overflow: hidden;
  border-radius: 3px;
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.theme-login-social-login-facebook .fa,
.theme-login-social-login-google .fa,
.theme-login-social-login-linkedin .fa {
  display: block;
  float: left;
  margin-right: 15px;
  margin-bottom: 0 !important;
  font-size: inherit !important;
  text-shadow: 2px 3px 5px rgba(0, 0, 0, 0.15);
}

.wdt-250 {
  width: 250px;
}

.theme-hero-area {
  height: 100vh;
}

.disclaimers > div {
  margin: 10px auto 10px auto;
}

/* ----------------- starts css style cookies ----------------------*/

.cookie {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 0.9fr;
  width: 1240px;
  top: 900px;
  border-radius: 7px;
  grid-gap: 10px;
  left: 18%;
  background: #f2f2f2;
}

.cookie__content {
  grid-column: auto / span 5;
  padding: 20px;
  text-align: center;
}

.cookie__button {
  align-self: center;
  margin: 10px 0;
  width: 160px;
  grid-column: auto / span 1;
}

.cookie__button.accept {
  padding: 7px;
  background: #20386e;
  color: #ffffff;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
}

.cookie__button.cookie__button.accept:hover {
  background: #42b955;
}

</style>
