<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div class="col-md-10">
            <h1 class="theme-account-page-title">Cartões</h1>
            <div class="row">
              <div class="col-md-12 padding-off">
                <!-- Subtitulo -->
                <div class="col-md-12">
                  <h4 class="title-manage-account">Cartões cadastrados</h4>
                </div>
                <!-- /Subtitulo -->
              </div>
              <!-- Adicionar Novo cartão -->
              <div class="col-md-4 mb-20">
                <router-link :to="{name:'add_cards'}">
                  <div class="theme-account-card theme-account-card-new">
                    <a class="theme-account-card-mask-link"></a>
                    <p class="theme-account-card-new-title">+ Novo cartão</p>
                  </div>
                </router-link>
              </div>
              <!-- Adicionar Novo cartão -->
              <!-- Cartão  -->
              <div class="col-md-4 mb-20" v-for="card in cards">
                <div
                  class="theme-account-card"
                  :class="{'theme-account-card-primary': card.is_corporate}"
                >
                  <div class="theme-account-card-mask">
                    <ul class="theme-account-card-actions">
                      <li>
                        <a href="#" v-on:click="delete_card(card.id)">
                          <i class="fa fa-times"></i>Deletar
                        </a>
                      </li>
                    </ul>
                  </div>
                  <span class="corporate_card" v-if="card.is_corporate">Cartão corporativo</span>
                  <span class="corporate_card" v-else>Cartão pessoal</span>
                  <p class="theme-account-card-number">**** **** **** {{card.partial_number}}</p>
                  <div class="theme-account-card-expire">
                    <span class="theme-account-card-expire-end">valid
                      <br>thru
                    </span>
                    <span
                      class="theme-account-card-expire-date"
                    >{{card.expiry_month}}/{{card.expiry_year}}</span>
                  </div>
                  <div class="theme-account-card-name">
                    <p>{{card.name}}</p>
                  </div>
                  <img
                    class="theme-account-card-logo"
                    src="@/assets/img/credit-icons/mastercard-straight-128px.png"
                    alt="Mastercard"
                    title="Mastercard"
                    v-if="card.card_type === 'mastercard'"
                  >
                  <img
                    class="theme-account-card-logo"
                    src="@/assets/img/credit-icons/visa-straight-128px.png"
                    alt="Visa"
                    title="Visa"
                    v-if="card.card_type === 'visa'"
                  >
                  <img
                    class="theme-account-card-logo"
                    src="@/assets/img/credit-icons/american-express-straight-128px.png"
                    alt="Amex"
                    title="Amex"
                    v-if="card.card_type === 'amex'"
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import MenuAccount from "../components/menuaccount.vue";
import lockscreen from "../components/lockscreen/index.vue";

export default {
  data() {
    return {
      cards: [],
      loading: false
    };
  },
  components: {
    MenuAccount,
    lockscreen
  },
  
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.loading = true;
      return this.$http
        .get("/api/cards/")
        .then(response => {
          if (response.status === 200) {
            this.cards = response.data.results;
            setTimeout(() => {
              this.loading = false;
            }, 1000);
          }
        })
        .catch(error => {
          this.error_messages = error.response.data;
        });
    },
    delete_card(id) {
      this.$swal({
        title: 'Confirme a ação',
        focusConfirm: false,
        type: 'warning',   
        showCancelButton: true,
        confirmButtonText: 'REMOVER',
        cancelButtonText: 'CANCELAR'

      }).then((response) => {
        
        if(!response.dismiss) {
          this.loading = true;
          this.$http.delete(`/api/cards/?id=${id}`).then(response => {
            this.fetchData();
          }).catch((error) => {
            this.loading = false;
            this.$swal({
              title: error.response.data.message,
              type: "error",
              confirmButtonText: "SAIR"
            });
          });
        }
      })
    }
  }
};
</script>

<style scoped>
.corporate_card {
  position: absolute;
  left: 10px;
  top: 10px;
  font-size: 12px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
.theme-account-card-logo {
    position: absolute;
    bottom: 0;
    top: 10px;
    right: 10px;
    display: block;
    width: 51px;
    height: 32px;
}
.theme-account-card-number {
    margin-top: 40px;
    font-size: 22px;
    line-height: 1em;
    margin-bottom: 10px;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}
</style>
