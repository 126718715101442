import Axios from 'axios';
import VueCookies from 'vue-cookies';
import store from '@/store'
import router from '@/router'

const axiosCreate = Axios.create({
  headers: {
    'X-CSRFToken': VueCookies.get('csrftoken')
  }
});

axiosCreate.interceptors.response.use((response) => {
  return response;
}, (error) => {
  if( error.response.status === 401) {
    if (router.app.$route.path !== '/login') {
      store.commit('LOGOUT');
    }
  }
  return Promise.reject(error);
});

export const axiosUpdateHeaders = () => {
  /* UPDATE HEADERS TODO improvements */
  axiosCreate.defaults.headers['X-CSRFToken'] = VueCookies.get('csrftoken');
  return axiosCreate;
}

export default axiosCreate;
