<template>
  <div class="mk-lockscreen backdrop" v-if="loading">
    <div class="spinner-position">
      <circle-spinner :message="getMessage"></circle-spinner>
    </div>
  </div>
</template>

<script>
import CircleSpinner from "./spinners/rounded-spinner.vue";
export default {
  props: ["loading", "message"],
  computed: {
    getMessage() {
      return this.message || false;
    }
  },
  components: {
    CircleSpinner
  }
};
</script>

<style lang="scss">
.body {
  height: 100%;
}
.mk-lockscreen {
  &.backdrop {
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    background: rgba(0, 0, 0, 0.8);
    color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  .spinner-position {
    
  }
  span {
    position: relative;
    float: left;
    right: 70px;
    bottom: -7px;
  }
}
</style>
