<template>
  <div class="ui-modal-dialog">
    <div
      :class="[
        active ? `modal modal-open show in` : `modal`
      ]"
      tabindex="-1"
      role="dialog">
      <div
        @click="closeModal"
        :class="active ? `ui-modal-dialog-backdrop modal-backdrop in`: ``"></div>
      <div
        :class="{ 'modal-lg' : large }"
        class="modal-dialog"
        role="document">
        <div class="ui-modal-dialog-content modal-content">
          <div class="ui-modal-dialog-header">
            <slot
              name="header"
              :active="active"
              :closeModal="closeModal"></slot>
          </div>
          <div class="ui-modal-dialog-body modal-body">
            <slot
              name="body"
              :active="active"
              :closeModal="closeModal"></slot>
          </div>
        </div>
        <button
          @click="closeModal"
          type="button"
          class="ui-modal-dialog-close"
          aria-label="Close"
          :aria-hidden="active ? true : false">
          x
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'active',
    'large'
  ],
  methods: {
    closeModal () {
      this.$emit('handleDialog')
    }
  }
};
</script>
