<template>
  <div class="tabs-group-ui">
    <slot
      name="alert"
      :alert="alert"></slot>
    <slot
      name="triggers"
      :active="active"
      :handleSelected="handleSelected"></slot>
    <slot
      name="content"
      :active="active"></slot>
  </div>
</template>

<script>
export default {
  props: [
    'selected',
    'alert'
  ],
  data() {
    return {
      active: ''
    }
  },
  mounted() {
    this.active = this.selected;
  },
  methods: {
    handleSelected(selected) {
      this.active = selected;
    }
  }
};
</script>
