<template>
  <div class="theme-hero-area">
    <div class="theme-hero-area-bg-wrap">
      <div
        class="theme-hero-area-bg"
        :style="{'background-image': 'url(' + require('@/assets/img/bg-home.jpg') + ')'}"
      ></div>
      <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
    </div>
    <div class="theme-hero-area-body">
      <div class="theme-page-section theme-page-section-xl">
        <div class="container">
          <div class="row">
            <div class="col-md-4 col-md-offset-4">
              <div class="theme-login-header">
                <router-link to="/login">
                  <div class="center-block wdt-250 mt-30 mb-20">
                    <img
                      class="img-responsive"
                      src="@/assets/img/logo-login.png"
                      alt="Logo etrip"
                      title="ETrip"
                    >
                  </div>
                </router-link>
              </div>
            </div>
            <div class="col-md-12">
              <h1
                class="theme-login-title theme-login-title-white text-center mb-20"
              >Esqueceu sua senha?</h1>
            </div>
            <form @submit.prevent="forgot_password">
              <div class="col-md-4 col-md-offset-4">
                <div class="theme-login theme-login-white">
                  <div class="theme-login-box">
                    <div class="theme-login-box-inner">
                      <p
                        class="theme-login-pwd-reset-text"
                      >Digite o e-mail associado a sua conta no campo abaixo, enviaremos em seu e-mail um link para recuperar sua senha.</p>
                      <div class="form-group theme-login-form-group">
                        <input
                          class="form-control"
                          type="text"
                          name="email"
                          v-model="email"
                          v-validate="'required|email'"
                          placeholder="Endereço de e-mail"
                          :class="{ 'alert-danger': submitted && errors.has('email') }"
                        >
                        <span class="error-message-forms">{{ errors.first('email') }}</span>
                      </div>
                      <button type="submit" class="btn btn-uc btn-dark btn-block btn-lg">
                        <i class="fa fa-envelope" aria-hidden="true"></i> Enviar e-mail
                      </button>
                      <router-link to="/login">
                        <a class="theme-login-pwd-reset-back">Lembra sua senha?</a>
                      </router-link>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import alerts from "../mixins/alerts";
import lockscreen from "@/components/lockscreen/index.vue";

export default {
  components: {lockscreen},
  data: function() {
    return {
      email: null,
      loading: false,
      submitted: false
    };
  },
  mixins: [alerts],
  methods: {
    forgot_password(e) {
      e.preventDefault();
      this.$validator.validateAll().then(result => {
        if (!result) return false;
        this.loading = true;
        this.loadingMessage = 'Enviando email'
        this.submitted = true;
        let router = this.$router;
        let http = this.$http;
        http({
          url: "/api/user/password-recover/",
          method: "POST",
          data: { email: this.email }
        }).then(response => {
          if (typeof response.data.error == "undefined") {
            this.$swal({
              title: response.data.message,
              type: "success",
              showConfirmButton: false,
              timer: 2000
            });
            
            setTimeout(() => {
              this.$router.push("/");
            }, 2000);
            
            } else {
            this.$swal({
              title: "Favor verificar os campos",
              type: "error",
              confirmButtonText: "SAIR"
            });
          }
        });
      });
    },
  }
};
</script>

<style scoped>
.theme-login-title-white {
  color: #ffffff;
}
.wdt-250 {
  width: 250px;
}
.theme-hero-area {
  height: 100vh;
}
</style>