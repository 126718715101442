import VueCookies from 'vue-cookies';
export default {
  methods: {
    logout() {
      this.$http({
        url: '/api/user/logout/',
        method: 'GET'
      }).then((response) => {
        this.$store.commit('LOGOUT');
        this.$router.push('/login');
      }).catch((e) => {
        console.log(e)
        console.log('error connection');
      });
    }
  }
}