<template>
  <div>
    <div class="form-group theme-payment-page-form-item">
      <i
        v-if="!loading"
        class="fa fa-angle-down"></i>
      <i
        v-if="loading"
        class="fa fa-spinner fa-spin price-fare-rules size-16"></i>
      <select
        @input="$emit('update:traveler', $event.target.value)"
        :value="traveler"
        :class="{ 'alert-danger': (submitted && !traveler || traveler === 'null') || alert }"
        class="form-control border-blue"
        name="traveler">
        <option v-if="alert" value="">{{ alert }}</option>
        <option v-if="!alert" :value="null">Selecione o viajante*</option>
        <option
          v-for="traveler in travelers"
          :key="traveler.id"
          :value="traveler.id">{{ traveler.name }}</option>
      </select>
    </div>
    <span class="size-12 weight-normal">* Obrigatório selecionar o viajante</span>
  </div>
</template>

<script>
import _ from 'underscore';
import { mapActions, mapState } from 'vuex';

export default {
  props: [
    'traveler',
    'submitted'
  ],
  data () {
    return {
      travelers: [],
      alert: null
    }
  },
  mounted () {
    this.alert = null;

    this.getTravelers()
      .then(this.setTravelers)
      .catch(error => {
        this.alert = 'Não foi possível buscar os viajantes'
      })
  },
  computed: {
    ...mapState('travelers', ['loading'])
  },
  methods: {
    ...mapActions('travelers', ['getTravelers']),
    setTravelers (data) {
      this.travelers = _.sortBy(data, 'name');
    }
  }
};
</script>
