export const SET_LOADING = 'SET_LOADING'
export const RESET_LOADING = 'RESET_LOADING'
export const SET_ERROR = 'SET_ERROR'
export const RESET_ERROR = 'RESET_ERROR'
export const SET_DEFAULT_ERROR = 'SET_DEFAULT_ERROR'

export const state = {
  loading: null,
  error: null
}

export const mutations = {
  [SET_LOADING] (state) {
    state.loading = true
  },
  [RESET_LOADING] (state) {
    state.loading = false
  },
  [SET_ERROR] (state, error) {
    state.error = error
  },
  [RESET_ERROR] (state) {
    state.error = null
  },
  [SET_DEFAULT_ERROR] (state) {
    state.error = 'Não foi possível salvar.'
  }
}
