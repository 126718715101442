<template>
    <div class="user-personal-data">
        <div class="col-md-12">
            <h4 class="title-manage-account">Companhia Aérea</h4>
        </div>
        <div class="col-md-12">
            <wait-loader v-if="loading"></wait-loader>
            <GChart v-else type="PieChart" :data="chartData"  :options="chartOptions"/>
        </div>
    </div>
</template>

<script>
    import { GChart } from 'vue-google-charts';
    import WaitLoader from "../loader/wait-loader.vue";

    export default {
        name: "airlines",
        components: {
            GChart,
            WaitLoader
        },
        data () {
            return {
                loading: false,
                chartData: null,
                chartOptions: {
                    chart: {
                        title: 'Companhia Aérea',
                        subtitle: '',
                    }
                }
            }
        },
        created() {
            this.loading = true;
            this.$http
                .get("/api/chart-airlines")
                .then(response => {
                    if (response.status === 200) {
                        let flight_companies = response.data;
                        let chart_data = [
                            ['Airlines', 'Total'],
                        ];

                        flight_companies.forEach(function(item, index){
                            chart_data.push([item.label, item.total])
                        });
                        this.chartData = chart_data;
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
</script>

<style scoped>

</style>