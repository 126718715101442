<template>
  <div>
    <div class="col-xs-6">
      <div class="color-gray-light-mid weight-lighter size-16">
        <i class="fa fa-calendar"></i>
        Check In:
      </div>
      <p class="_mb-0 size-18">{{ start | date_to_weekday }}</p>
      <p class="_mb-0 size-14">ás {{ start | date_to_hour }}</p>
    </div>
    <div class="col-xs-6">
      <div class="color-gray-light-mid weight-lighter size-16">
        <i class="fa fa-calendar"></i>
        Check Out:
      </div>
      <p class="_mb-0 size-18">{{ end | date_to_weekday }}</p>
      <p class="_mb-0 size-14">ás {{ end | date_to_hour }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'start',
    'end'
  ]
};
</script>
