<template>
  <div class="col-xs-12 flight-segment clearfix">
    <div class="row col-xs-4 flight-segment-info">
      <p class="flight-segment-text-info text-right">
        <i class="fa fa-clock-o fa-fw ico-clock"></i>
        Duração:<br class="visible-xs-block"> {{ flightDuration }}
      </p>
      <p class="flight-segment-text-info text-right">
        <i class="fa fa-plane ico-plane"></i>&nbsp;
        <span class="hidden-xs">Número do voo:</span>
        <span class="hidden-sm hidden-md hidden-lg">Voo:</span>
          <strong>&nbsp;{{ segment.airline.iata_code }}{{ segment.flight_number | normalize_flight_number }}</strong>
      </p>
    </div>

    <div class="row col-xs-8 flight-segment-panel">
      <span
        v-if="indexKey > 0"
        class="flight-segment-mark-index">{{ indexKey }}</span>
      <div class="flight-segment-row">
        <div class="clearfix">
          <div class="col-sm-2">
            <p class="flight-segment-text-info">
              <strong>Partida</strong>
            </p>
          </div>
          <div class="col-sm-10">
            <p class="flight-segment-text-info">
              {{ segment.departure | date_to_weekday }}
            </p>
          </div>
        </div>
      </div>

      <div class="flight-segment-mark-down">
        <div class="clearfix">
          <div class="col-sm-2 flight-segment-mark">
            <p class="flight-segment-text-main">
              {{ segment.departure | date_to_hour }}<sup v-if="dateDiffDeparture > 0">+{{ dateDiffDeparture }}</sup>
            </p>
          </div>
          <div class="col-sm-10">
            <p class="flight-segment-text-main">
              <strong>{{ segment.airline.iata_code }}</strong> {{ segment.airline.name }}
            </p>
          </div>
        </div>
      </div>

      <div class="flight-segment-row flight-segment-mark-mid">
        <div class="clearfix">
          <div class="col-sm-2">
            <p class="flight-segment-text-info">
              <strong>Chegada</strong>
            </p>
          </div>
          <div class="col-sm-10">
            <p class="flight-segment-text-info">
              {{ segment.arrival | date_to_weekday }}
            </p>
          </div>
        </div>
      </div>

      <div class="">
        <div class="clearfix">
          <div class="col-sm-2 flight-segment-mark flight-segment-mark-up">
            <p class="flight-segment-text-main">
              {{ segment.arrival | date_to_hour }}<sup v-if="dateDiffArrival > 0">+{{ dateDiffArrival }}</sup>
            </p>
          </div>
          <div class="col-sm-10">
            <p class="flight-segment-text-main">
              <strong>{{ segment.airline.iata_code }}</strong> {{ segment.airline.name }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  props: [
    'segment',
    'indexKey',
    'dateDeparture'
  ],
  data() {
    return {
      dateDiffDeparture: this.diffDate(this.segment.departure),
      dateDiffArrival: this.diffDate(this.segment.arrival)
    }
  },
  computed: {
    flightDuration () {
      const arrival = this.segment.arrival;
      const departure = this.segment.departure;
      return moment.duration(moment(arrival, 'YYYY-MM-DD HH:mm:ss').diff(moment(departure, 'YYYY-MM-DD HH:mm:ss'))).format('HH[h]mm[m]')
    }
  },
  methods: {
    diffDate (date) {
      return moment(date, 'YYYY, MM, DD').diff(moment(this.dateDeparture, 'YYYY, MM, DD'), 'days')
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/components/flight/FlightSegment';
</style>
