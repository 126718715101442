import axios from 'axios';
export default {
  data() {
    return {
      zipcode_data: {}
    }
  },
  methods: {
    searchZipCode(zipcode) {
      return new Promise((resolve, reject) => {
        if(!zipcode || typeof zipcode == 'undefined') return reject();
        if(zipcode && (zipcode.length > 9 || zipcode.length < 8)) return reject();
        
        axios.create({headers: {
          'Content-Type': 'application/json',
        }})({
          url: `https://apps.widenet.com.br/busca-cep/api/cep.json`,
          params: {
            code: zipcode
          },
          method: 'GET' 
        }).then((response) => {
          let data = response.data;
          if (data.status == 1) {
            this.zipcode_data = {
              code: data.code,
              state: data.state,
              city: data.city,
              district: data.district,
              address: data.address,
            }
          }
          return resolve()
        }).catch((err) => {
          return reject(err);
        });
      })
    }
  }
}