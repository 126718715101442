<template>
    <div></div>
</template>

<script>
    export default {
        name: "chat",
        created() {
            var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
            (function(){
                var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
                s1.async=true;
                if (process.env.NODE_ENV=='homologation') {
                    s1.src='https://embed.tawk.to/59384cbeb3d02e11ecc68b8d/default';
                } else {
                    s1.src='https://embed.tawk.to/58e87a63f7bbaa72709c5097/default';
                }
                
                s1.charset='UTF-8';
                s1.setAttribute('crossorigin','*');
                s0.parentNode.insertBefore(s1,s0);
            })();
        }
    }
</script>

<style scoped>

</style>