<template>
  <select style="width:100%"></select>
</template>

<script>
  let retrieveSearchType = function (text) {
    let type_text = "";
    switch (text) {
      // case "city":
      //   type_text = "Cidade, Regiões";
      //   break;
      case "agency":
        type_text = "Agências";
        break;
      default:
        break;
    }

    return type_text;
  };

  let processResults = function (data) {
    let tmp_data = [];

    for (let data of data.results){

      let tmp_data_city = [];

      let tmp_data_agency = [];
      _.sortBy(data.agencies, 'name').forEach(function (data, idx) {
        let text = `${data.iata_code} - ${data.name}, ${data.city.name}, ${data.city.state.name}`;

        if (text.length) {
          tmp_data_agency.push({
            id: data.id,
            text: text,
            type: 'agency',
            value: data.iata_code,
            data: data
          });
        }
      });

      if(tmp_data_agency.length){
        tmp_data.push({
          text: retrieveSearchType('agency'),
          children: tmp_data_agency
        });
      }

    }
    return {
      results: tmp_data
    };
  };

  import _ from "underscore";

  export default {
    props: ["value", "dataInitial"],
    data() {
      return {
        content: this.value,
        grouped_by_city: []
      };
    },
    watch: {
      dataInitial: function () {
        this.setSelect()
      }
    },
    methods: {
      changeHandle(e) {
        this.$emit("input", this.content);
      },
      setSelect() {
        let destination = $(this.$el);
        this.$nextTick(function () {
          let opt = {
            language: "pt-BR",
            minimumInputLength: 2,
            debug: true,
            placeholder: "Estado, Cidade ou Agência",
            ajax: {
              quietMillis: 400,
              url: "/api/vehicle/autocomplete/",
              processResults: processResults,
              dataType: "json"
            }
          };

          if (this.dataInitial) {
            opt = _.extend(opt, {data: JSON.parse(JSON.stringify(this.dataInitial))});
          }

          destination.select2(opt);

          destination.on("select2:select", e => {
            this.content = e.params.data;
            this.changeHandle(e);
            this.dataInitial = false;
          });
        });
      }
    },
    mounted() {
      this.setSelect();
    }
  };
</script>

<style>
</style>


