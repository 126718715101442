<template>
  <section
    :class="[{'inactive' : active}, 'content-detail-transition col-sm-3 text-left']">
      <div class="content-detail">
        <slot
          name="main"
          :lowest-price="content.lowestPrice"
          :price="content.price"></slot>
        <a
          :href="`content-details-${content.direction}-${indexKey}`"
          @click.prevent="$emit('handleCollapseUpdate')"
          class="content-detail-trigger"
          :class="linkClass">
          ver detalhes <i class="fa fa-chevron-down"></i>
        </a>
      </div>
  </section>
</template>

<script>
export default {
  props: [
    'linkClass',
    'active',
    'indexKey',
    'content'
  ]
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/ContentDetail';
</style>
