<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div class="col-md-10">
            <!-- Titulo -->
            <h1 class="theme-account-page-title">Viajantes</h1>
            <!-- /Titulo -->
            <div class="row">
              <div class="col-md-12 padding-off">
                <!-- Subtitulo -->
                <div class="col-md-12">
                  <h4 class="title-manage-account">Viajantes cadastrados</h4>
                </div>
                <!-- /Subtitulo -->
                <!-- Card adicionar novo Viajante -->
                <div class="col-md-6 mb-20">
                  <div class="theme-account-traveler">
                    <router-link to="/add-traveler">
                      <a class="theme-account-traveler-link-mask"></a>
                      <p class="theme-account-traveler-add-new">+ Adicionar novo viajante</p>
                    </router-link>
                  </div>
                </div>
                <!-- Card adicionar novo Viajante -->
                <!-- Componente card viajante -->
                <card-traveler
                  v-for="traveler in travelers"
                  :traveler="traveler"
                  :key="traveler.id"
                ></card-traveler>
                <!-- Componente card viajante -->
              </div>
              <!-- Convidar novo viajante -->
              <div class="col-md-12 padding-off">
                <!-- Subtitulo -->
                <div class="col-md-12">
                  <h4 class="title-manage-account">Convidar Viajantes</h4>
                </div>
                <!-- Nome completo -->
                <div class="col-md-6">
                  <div class="form-group theme-login-form-group">
                    <label>Nome do convidado*</label>
                    <input
                      class="form-control"
                      name="guest_name"
                      v-model="guest_name"
                      :class="{ 'alert-danger': submitted && errors_guest.has('guest_name') }"
                    >
                    <span
                      v-show="errors_guest.has('guest_name')"
                      class="error-message-forms"
                    >{{ errors_guest.first('guest_name') }}</span>
                  </div>
                </div>
                <!-- /Nome completo -->
                <!-- E-mail -->
                <div class="col-md-6">
                  <div class="form-group theme-login-form-group">
                    <label>E-mail*</label>
                    <input
                      class="form-control"
                      name="guest_email"
                      v-model="guest_email"
                      :class="{ 'alert-danger': submitted && errors_guest.has('guest_email') }"
                    >
                    <span
                      v-show="errors_guest.has('guest_email')"
                      class="error-message-forms"
                    >{{ errors_guest.first('guest_email') }}</span>
                  </div>
                </div>
                <!-- /E-mail -->
                <!-- btn salvar -->
                <div class="col-md-12">
                  <div class="theme-account-preferences-item-change-actions">
                    <button
                      type="button"
                      class="btn btn-lg btn-primary pull-right"
                      @click="validateForm"
                    >Convidar Viajante</button>
                  </div>
                </div>
                <!-- /btn salvar -->
                <!-- /Subtitulo -->
              </div>
              <div class="clearfix"></div>
              <!-- /Convidar novo viajante -->
              <!-- Hostórico -->
              <!-- <div class="col-md-12 mt-m-50 padding-off">
                <div class="col-md-12">
                  <h4 class="title-manage-account">Histórico</h4>
                </div>
                <div class="col-md-12">
                  <div class="table-changes-travalers table-responsive">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Modificador</th>
                          <th>Modificado</th>
                          <th>Mudanças</th>
                          <th>Data de modificação</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Renato Bruno</td>
                          <td>Renato Bruno</td>
                          <td>
                            <a data-toggle="modal" data-target="#modal-change">Visualizar</a>
                          </td>
                          <td>21/01/2019 às 11:43</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>-->
              <!-- /Histórico -->
              <!-- Modal Modificações-->
              <div
                class="modal fade"
                id="modal-change"
                tabindex="-1"
                role="dialog"
                aria-labelledby="modal-changeLabel"
                aria-hidden="true"
              >
                <div class="modal-dialog" role="document">
                  <div class="modal-content change-modal">
                    <div class="modal-header change-modal-header">
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                      <h3
                        class="modal-title text-center"
                        id="exampleModalLabel"
                      >Mudança de dados de Viajantes</h3>
                    </div>
                    <div class="modal-body content-change-modal">
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                      <p>Email: De 01byqnp4fi95@etripdependent.com.br para renato@renato.com.</p>
                    </div>
                    <div class="modal-footer change-modal-footer">
                      <button
                        type="button"
                        class="btn _tt-uc btn-primary btn-lg btn-block"
                        data-dismiss="modal"
                      >Fechar</button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /Modal modificações -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import Vue from "vue";
import MenuAccount from "../components/menuaccount.vue";
import CardTraveler from "../components/cardtraveler.vue";
import lockscreen from "../components/lockscreen/index.vue";
import moment from "moment";
import alerts from "../mixins/alerts";
import { Validator } from "vee-validate";

export default {
  validator: null,
  data() {
    return {
      travelers: [],
      guest_name: "",
      guest_email: "",
      loading: false,
      submitted: false,
      errors_guest: null
    };
  },
  watch: {
    guest_name(value) {
      if (value) {
        this.validator.validate("guest_name", value);
      }
    },
    guest_email(value) {
      if (value) {
        this.validator.validate("guest_email", value);
      }
    }
  },
  components: {
    MenuAccount,
    CardTraveler,
    lockscreen
  },
  mixins: [alerts],
  beforeCreate() {},
  created() {
    this.loading = true;
    this.$http
      .get("/api/user/")
      .then(response => {
        if (response.status === 200) {
          this.travelers = response.data.results;
          setTimeout(() => {
            this.loading = false;
          }, 1000);
        }
      })
      .catch(error => {
        console.log(error);
      });
    this.validator = new Validator({
      guest_name: "required|alpha_spaces|min:2",
      guest_email: "required|email"
    });
    this.$set(this, "errors_guest", this.validator.errors);
  },
  methods: {
    validateForm() {
      this.validator.validateAll({
          guest_name: this.guest_name,
          guest_email: this.guest_email
        }).then(result => {
          if (result) {
            this.$http({
              url: '/api/user/invite',
              method: 'POST',
              data: {
                email: this.guest_email,
                name: this.guest_name
              }
            }).then(() => {
              this.$swal({
                title: "E-mail enviado com sucesso!",
                type: "success",
                confirmButtonText: "SAIR"
              }).then(() => {
                this.clearGuestData();
              });
            }).catch((error)=>{
              this.$swal({
                title: error.response.data.message,
                type: "error",
                confirmButtonText: "SAIR"
              });  
            })
          } else {
            this.$swal({
              title: "Favor verificar os campos",
              type: "error",
              confirmButtonText: "SAIR"
            });
          }
        });
    },
    clearErrors() {
      this.errors_guest.clear();
    },
    clearGuestData() {
      this.guest_name = "";
      this.guest_email = "";
    }
  }
};
const dict = {
  custom: {
    guest_name: {
      min: "Campo nome do convidado deve conter ao menos 2 caracteres",
      alpha_spaces: "O campo nome do convidado deve conter apenas caracteres alfabéticos e espaços."
    },
    guest_email: {
      min: "A senha deve conter no mínimo 8 caracteres"
    },
  }
};
Validator.localize("en", dict);
</script>

<style scoped>
.change-modal {
  border-radius: 0;
}
.change-modal-header {
  border: 0 !important;
}
.change-modal-footer {
  border: 0 !important;
}
.content-change-modal {
  height: 350px;
  overflow-y: auto;
  font-size: 14px;
  padding-left: 40px;
  padding-right: 40px;
}
</style>
