 <template>
  <section
    role="list"
    class="travel-list clearfix">
    <article
      v-for="(item, index) in items"
      :key="`travel-list-item-${index}`"
      class="travel-list-item clearfix"
      role="listitem">
      <slot
        :item="item"
        :order="order"></slot>
    </article>
    <template v-if="items && order">
      <article
        v-if="items.length === 0 && !loading"
        class="mb-20">
        <h2 class="col-xs-12 size-16 weight-mid">Você ainda não adicionou itens em {{ order.name }}.</h2>
      </article>
    </template>
    <footer
      v-if="total"
      class="col-xs-12 size-18 weight-bold">
      Total: <span class="pull-right">{{ total | brl_price }}</span>
    </footer>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: [
    'items',
    'order'
  ],
  computed: {
    ...mapState('reserve', [
      'loading'
    ]),
    ...mapGetters('reserve', ['totalByOrder']),
    total() {
      const total = (this.order) ? this.totalByOrder(this.order.slot) : 0.0;
      return total;
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/travel/TravelList';
</style>
