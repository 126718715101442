<template>
  <article :class="[{ 'active' : active }, 'result-item']">

    <header class="row">
      <!-- Content Date -->
      <content-date :content="flight"></content-date>
      <!-- // Content Date -->
      <!-- Content Hourly -->
      <content-hourly
        :active="active"
        :content="flight"></content-hourly>
      <!-- // Content Hourly -->
      <!-- Content Detail -->
      <content-detail
        :indexKey="indexKey"
        :active="active"
        :content="flight"
        @handleCollapseUpdate="handleCollapseUpdate">
        <template v-slot:main="slotProps">
          <h2 class="content-detail-title">
            A partir de: <strong>{{ slotProps.lowestPrice | brl_price }}</strong>
          </h2>
        </template>
      </content-detail>
      <!-- // Content Detail -->
    </header>

    <section
      v-show="active"
      :id="`result-item-details-${indexKey}`"
      class="row result-item-detail">
      <h2 class="result-item-detail-title">
        Tarifas
      </h2>
      <button
        @click="handleCollapseUpdate"
        class="result-item-detail-close btn-link"
        type="button">
        fechar <i class="fa fa-chevron-up"></i>
      </button>

      <!-- Price List -->
      <prices-list
        :lowest-price="result.lowest_price"
        :picked-flight="pickedFlight"
        :prices="result.prices"
        :has-oubound-id="pickedFlight.flights.outbound.id"
        :carrier="flight.carrier"
        @handlePrices="handlePrices"></prices-list>
      <!-- // Price List -->
      <!-- Item Detail -->
      <flight-detail
        v-for="(detail, index) in result.segments"
        :key="`flight-detail-${indexKey}-${index}`"
        :detail="detail"
        :date-departure="flight.dateDeparture"
        :index-key="index"
        ></flight-detail>
      <!-- // Item Detail -->
    </section>
  </article>
</template>

<script>
import ContentDate from '@/views/flights/search/ContentDate';
import ContentHourly from '@/views/flights/search/ContentHourly';
import ContentDetail from '@/views/flights/search/ContentDetail';
import PricesList from '@/views/flights/search/PricesList';
import FlightDetail from '@/views/flights/search/FlightDetail';

export default {
  components: {
    ContentDate,
    ContentHourly,
    ContentDetail,
    PricesList,
    FlightDetail
  },
  props: [
    'parentResult',
    'result',
    'indexKey',
    'pickedFlight'
  ],
  data(){
    return {
      active: false
    }
  },
  computed: {
    flight() {
      return {
        origin: this.result.segments[0].origin.iata_code,
        destination: this.result.segments[this.result.segments.length - 1].destination.iata_code,
        carrier: this.parentResult.carrier,
        travelTime: this.result.travel_time,
        direct: this.parentResult.direct,
        originCity: this.result.origin.name,
        destinationCity: this.result.destination.name,
        dateDeparture: this.result.segments[0].date_departure,
        dateArrival: this.result.segments[this.result.segments.length - 1].date_arrival,
        segmentsLen: this.result.segments.length,
        lowestPrice: this.result.lowest_price,
        indexKey: this.indexKey,
        designators: this.result.segments.map(segment => (`${segment.flight_number}`))
        // designators: this.result.segments.map(segment => (`${segment.carrier.iata_code} ${segment.flight_number}`))
      }
    }
  },
  methods: {
    handleCollapseUpdate() {
      const active = !this.active;
      this.active = active;
    },
    handlePrices(price) {
      this.$emit('handlePrices', this.result, price);
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/ResultItem';
</style>
