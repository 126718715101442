<template>
  <div class="theme-search-area">
    <form @submit.prevent="search_car">
      <div class="theme-search-area-form">
        <div class="row" data-gutter="10">
          <div class="col-md-2-5" style="color:white">Local da retirada</div>
          <div class="col-md-2-5" style="color:white">Local da devolução</div>
          <div class="col-md-1-5" style="color:white">Data de retirada</div>
          <div class="col-md-1-5" style="color:white"></div>
          <div class="col-md-1-5" style="color:white">Data de devolução</div>
          <div class="col-md-1-5" style="color:white"></div>
          <div class="col-md-1"></div>
        </div>
        <div class="row" data-gutter="10">
          <div class="col-md-2-5">
            <div
              class="theme-search-area-section first theme-search-area-section-curved theme-search-area-section-fade-white"
            >
              <div class="theme-search-area-section-inner">
                <i class="theme-search-area-section-icon lin lin-location-pin"></i>
                <autocomplete
                  class="theme-search-area-section-input typeahead"
                  type="text"
                  placeholder="Retirada"
                  data-provide="typeahead"
                  v-validate="'required'"
                  v-model="pickup_location"
                  :dataInitial="pickupAutoCompleteDataInitial"
                ></autocomplete>
              </div>
            </div>
          </div>
          <div class="col-md-2-5">
            <div
              class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
            >
              <div class="theme-search-area-section-inner">
                <i class="theme-search-area-section-icon lin lin-location-pin"></i>
                <autocomplete
                  class="theme-search-area-section-input typeahead"
                  type="text"
                  placeholder="Devolução"
                  data-provide="typeahead"
                  v-model="delivery_location"
                  v-validate="'required'"
                  :dataInitial="deliveryAutoCompleteDataInitial"
                ></autocomplete>
              </div>
            </div>
          </div>
          <div class="col-md-1-5">
            <div
              class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
            >
              <div class="theme-search-area-section-inner">
                <i class="theme-search-area-section-icon lin lin-calendar"></i>
                <date-range-picker
                        v-model="start_date"
                        v-validate="'required'"
                        :singlePick="true"
                        :timePick="false"
                        :init="init_start_date"
                        :placeholder="'Retirada'"
                        ref="rangedate">
                </date-range-picker>
              </div>
            </div>
          </div>
          <div class="col-md-1-5">
            <div
              class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
            >
              <div class="theme-search-area-section-inner">
                <i class="theme-search-area-section-icon lin lin-clock"></i>
                <select class="theme-search-area-section-input form-control" v-model="start_time">
                  <option :value="duration" v-for="duration in availableTimePickup" :key="duration">
                    {{ duration }}
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-md-1-5">
            <div
              class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
            >
              <div class="theme-search-area-section-inner">
                <i class="theme-search-area-section-icon lin lin-calendar"></i>
                <date-range-picker
                        v-model="end_date"
                        v-validate="'required'"
                        :singlePick="true"
                        :timePick="false"
                        :minDate="minDate"
                        :init="init_end_date"
                        ref="rangedate"
                        :placeholder="'Devolução'"
                ></date-range-picker>
              </div>
            </div>
          </div>
          <div class="col-md-1-5">
            <div
              class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white"
            >
              <div class="theme-search-area-section-inner">
                <i class="theme-search-area-section-icon lin lin-clock"></i>
                <select class="theme-search-area-section-input form-control" v-model="end_time">
                  <option :value="duration" v-for="duration in availableTimeDelivery" :key="duration">
                    {{ duration }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md-1">
            <button
              class="theme-search-area-submit _mt-0 theme-search-area-submit-no-border theme-search-area-submit-curved theme-search-area-submit-primary"
            >
              <i class="fa fa-search" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import moment, { duration } from "moment";
import Autocomplete from "./fields/cars/Autocomplete.vue";
import DateRangePicker from "./fields/DateRangePicker.vue";
import { extendMoment } from 'moment-range';


moment = extendMoment(moment);

const DATE_BR_FORMAT = "DD/MM/YYYY HH:mm";
const DATE_ISO_8601 = "YYYY-MM-DD.HH:mm";

export default {
  data() {
    return {
      pickupAutoCompleteDataInitial: [],
      deliveryAutoCompleteDataInitial: [],
      pickup_location: null,
      delivery_location: null,
      init_start_date: {},
      init_end_date: {},
      start_date: null,
      end_date: null,
      start_time: '11:00',
      end_time: '11:00',
      minDate: null,
      submitted: false,
      availableTimePickup: [],
      availableTimeDelivery: []
    };
  },
  components: {
    Autocomplete,
    DateRangePicker
  },
  computed: {

  },
  beforeMount() {
    let params = this.$route.params;

    if (!Object.keys(params).length) return false;

    this.init_start_date = {
      startDate: params.datetime_start,
      endDate: params.datetime_start,
    };

    this.init_end_date = {
      startDate: params.datetime_end,
      endDate: params.datetime_end
    };

    if (!this.pickup_location) {
      this._fillAutoComplete();
    }

    if(this.$route.params) {
      this.start_time = new moment(this.$route.params.datetime_start).format('HH:mm');
      this.end_time = new moment(this.$route.params.datetime_end).format('HH:mm');
    }
  },
  created (){
    this.$ga.page('/')
  },
  watch: {
    pickup_location: function (pickup_location) {
      if(!this.delivery_location){
        this._fillAutoComplete({delivery_location: pickup_location.value});
        this.delivery_location = pickup_location;
      }
      
      if( this.start_date) {
        this.setAvailableStartDate(this.start_date);
      }
      
      if( this.end_date) {
        this.setAvailableEndDate(this.end_date);
      }
    },
    delivery_location: function(delivery_location) {

      if( this.start_date) {
        this.setAvailableStartDate(this.start_date);
      }

      if( this.end_date) {
        this.setAvailableEndDate(this.end_date);
      }
    },
    start_date: function (start_date) {
      this.setAvailableStartDate(start_date);
    },
    end_date: function(end_date) {
      this.setAvailableEndDate(end_date);
    }

  },
  methods: {
    setAvailableStartDate(start_date) {
      start_date = moment(start_date.dateStart, DATE_BR_FORMAT);
      this.minDate = start_date.clone().add(1, 'day');

      let day = start_date.locale('en').format('dddd').toLowerCase();
      
      let available_pick_times;
      if (this.pickup_location.data) {
        available_pick_times = this.pickup_location.data.opening_times;
      } else {
        available_pick_times = JSON.parse(localStorage.getItem('vehicle::availableTimePickup'))
      }
      
      let range = false;
      
      if (available_pick_times) {
        range = available_pick_times[day];
        this.availableTimePickup = this.list_time(range || false)
        localStorage.setItem('vehicle::availableTimePickup', JSON.stringify(available_pick_times));
      }
    },
    setAvailableEndDate(end_date) {
      let day = moment(end_date.dateStart, DATE_BR_FORMAT).locale('en').format('dddd').toLowerCase();
      let available_delivery_times;
      
      if (this.delivery_location.data) {
        available_delivery_times = this.delivery_location.data.opening_times;
      } else {
        available_delivery_times = JSON.parse(localStorage.getItem('vehicle::availableTimeDelivery'));
      }

      let range = false;

      if (available_delivery_times) {
        range = available_delivery_times[day];
        this.availableTimeDelivery = this.list_time(range || false);
        localStorage.setItem('vehicle::availableTimeDelivery', JSON.stringify(available_delivery_times));
      }
    },
    list_time(range=false, start_time = false) {
      let durations = [];
      if (!range) {
        let start = moment('00:00:00', 'HH:mm:ss');
        let end = moment('24:00:00', 'HH:mm:ss');
  
        let range = moment.range(start,end).snapTo()
        let acc = Array.from(range.by('hour', { step: 0.5 }));
        durations = acc.map(m => m.format('HH:mm'))
        durations.pop();
      } else {
        
        
        range.forEach((period) => {
          let start = moment(period.start, 'HH:mm:ss');
          let end = moment(period.end, 'HH:mm:ss');
          let old_end = end.clone();
          let remainder = 30 - (end.minutes()) % 30;
          end.add(remainder, 'minutes');

          if (old_end > end) {
            end = old_end;
          }
          
          if(start_time) {
            start_time = moment(start_time, 'HH:mm:ss');
            if(start_time < start || start_time > end) {
              return;
            }
          }

          let range = moment.range(start,end).snapTo()
          let acc = Array.from(range.by('hour', { step: 0.5 }));
          let tmp_durations = acc.map(m => m.format('HH:mm'));
          durations = durations.concat(tmp_durations);
        });
      }
      
      durations.sort((a, b) => {
        return moment(a, 'HH:mm') - moment(b, 'HH:mm');
      });

      return durations;
    },
    _fillAutoComplete(params) {

      if(!params){
        params = this.$route.params;
      }

      if (params.startDate && params.endDate) {
        this.init_start_date = {
          startDate: params.startDate.format(DATE_ISO_8601)
        };
        this.init_end_date = {
          startDate: params.endDate.format(DATE_ISO_8601)
        };
      }

      if (!Object.keys(params).length) return false;

      if (params.pickup_location) {
        let text = params.pickup_location;
        this.pickupAutoCompleteDataInitial = [
          {
            text: text,
            id: 1,
            el: {
              city_name: params.pickup_location,
              type: "agency"
            },
            value: params.pickup_location,
            data: {
              opening_times: JSON.parse(localStorage.getItem('vehicle::availableTimePickup'))
            }
          }
        ];
        this.pickup_location = this.pickupAutoCompleteDataInitial[0];
      }

      if (params.delivery_location) {
        let text = params.delivery_location;
        this.deliveryAutoCompleteDataInitial = [
          {
            text: text,
            id: 1,
            el: {
              city_name: params.delivery_location,
              type: "agency"
            },
            value: params.delivery_location,
            data: {
              opening_times: JSON.parse(localStorage.getItem('vehicle::availableTimeDelivery'))
            }
          }
        ];
        this.delivery_location = this.deliveryAutoCompleteDataInitial[0];
      }
    },
    dateBR28901(date) {
      return moment(date, DATE_BR_FORMAT).format(DATE_ISO_8601);
    },
    submit(e) {
      this.$nextTick(() => {
        let datetime_start = this.dateBR28901(`${this.start_date.dateStart} ${this.start_time}`);
        let datetime_end = this.dateBR28901(`${this.end_date.dateStart} ${this.end_time}`);

        let pickup_location = this.pickup_location;
        let delivery_location = this.delivery_location;
        let urls = {
          agency: `/cars/search/${pickup_location.value}/${delivery_location.value}/${datetime_start}/${datetime_end}/`,
          city: `/cars/search/${pickup_location.value}/${delivery_location.value}/${datetime_start}/${datetime_end}/`
        };

        this.$router.push(urls["agency"]);
      });
    },
    search_car(e) {
      e.preventDefault();
      this.$validator.validate().then(valid => {
        this.submitted = true;
        if (valid) {
          this.submit(e);
        } else {
          this.$swal({
            title: "Favor verificar os campos de busca",
            type: "error",
            confirmButtonText: "SAIR"
          });
        }
      });
    }
  }
};
</script>

<style>
</style>
