<template>
  <div id="app">
    <header-tpl v-if="$store.state.isLoggedIn"></header-tpl>
    <router-view/>
    <footer-tpl v-if="$store.state.isLoggedIn"></footer-tpl>
    <chat></chat>
  </div>
</template>

<script>
import headerTpl from './components/template/header.vue';
import footerTpl from './components/template/footer.vue';
import Chat from './components/chat.vue';
import { mapState, mapActions } from 'vuex';

export default {
  watch: {
    isLoggedIn(newV, oldV) {
      if(!newV) {
        this.$router.push('/login');
      } else if (this.$store.state.user.password_expired) {
        this.$router.push('/password_change');
      }
    }
  },
  computed: {
    ...mapState(['isLoggedIn'])
  },
  components: {
    headerTpl,
    footerTpl,
    Chat
  },
  methods: {
    ...mapActions({
      setClearsaleToken: 'set_clearsale_token'
    }),
    handleClearsaleToken (event) {
      const clearSaleSessionID = document.querySelector('#ClearSaleSessionID').value
      this.setClearsaleToken(clearSaleSessionID)
    }
  },
  created () {
    const self = this;
    const input = document.createElement('input');
    input.setAttribute('type', 'hidden');
    input.setAttribute('id', 'ClearSaleSessionID');
    document.getElementsByTagName('body')[0].appendChild(input);

    (function (a, b, c, d, e, f, g) {
      a['CsdpObject'] = e; a[e] = a[e] || function () {
        (a[e].q = a[e].q || []).push(arguments)
      }, a[e].l = 1 * new Date();
        f = b.createElement(c),
        g = b.getElementsByTagName(c)[0];
        f.async = 1;
        f.onload = self.handleClearsaleToken
        f.src = d;
        g.parentNode.insertBefore(f, g)
    })(window, document, 'script', '//device.clearsale.com.br/p/fp.js', 'csdp');
    csdp('app', '?');
    csdp('outputsessionid', 'ClearSaleSessionID');
  }
}
</script>


<style lang="css">

@import url('../node_modules/bootstrap/dist/css/bootstrap.css');
@import url('assets/css/font-awesome.css');
@import url('assets/css/lineicons.css');
@import url('assets/css/main.css');
@import url('assets/css/weather-icons.css');
@import url('../node_modules/select2/dist/css/select2.min.css');
@import url('../node_modules/bootstrap-daterangepicker/daterangepicker.css');
@import url('assets/css/mystyles.css');
</style>
