<template>
  <!-- Fotorama -->
  <div
    id="detailPhotos"
    role="listitem"
    class="fotorama"
    data-auto="false"
    data-nav="thumbs"
    data-minwidth="100%"
    data-height="271"
    data-arrows="always"
    data-allowfullscreen="native"
    data-fit="cover">
    <img
      v-for="image in images"
      :key="image.id"
      :src="image.url"
      :alt="image.caption"
      :title="image.caption" />
  </div>
  <!-- // Fotorama -->
</template>

<script>
export  default {
  props: [
    'active',
    'images'
  ],
  mounted () {
    $('.fotorama').fotorama();
  },
  updated () {
    $('.fotorama').fotorama();
  }
};
</script>
