<template>
    <div
      id="detailInfo"
      class="mt-20 mb-20"
      role="listitem">
      <p
        v-if="policies"
        class="weight-normal">{{ policies.text }}</p>
    </div>
  </div>
</template>

<script>
export  default {
  props: [
    'active',
    'policies'
  ]
};
</script>
