<template>
  <div class="container box-space-nav min-height">
    <!-- Checkout Steps -->
    <checkout-steps></checkout-steps>
    <!-- // Checkout Steps -->
    <div id="checkout-error">
      <!-- Complete Registration -->
      <complete-registration></complete-registration>
      <!-- // Complete Registration -->
      <!-- Alert Warning -->
      <alert-helper
        v-if="alert.length"
        :alert="alert"
        :close="true"
        type="warning">
      </alert-helper>
      <!-- // Alert Warning -->
    </div>
    <!-- CREDIT CARD Single Dialog -->
    <single-dialog
      :active="active"
      :large="dialogLarge"
      :id="`dialog-register`"
      :key="`checkout-register-single-dialog`"
      @handleDialog="handleDialog"
      class="color-gray-dark">
      <template v-slot:body="{ closeModal, active }">
        <component
          :is="dialogComponent"
          @handleParentAction="closeModal">
          <template v-slot:default="{ handleParentAction }">
            <div class="col-xs-6">
              <button
                @click="handleParentAction"
                type="button"
                class="btn-gray-inverse btn-block btn-extra-large">
                Cancelar
              </button>
            </div>
          </template>
        </component>
      </template>
    </single-dialog>
    <!-- // Single Dialog -->
    <form
      name="checkout"
      @submit.prevent="handleSubmit">
      <!-- Approval Fields -->
      <approval-fields
        :user="user"
        :reserves="reserves"
        v-bind.sync="form"></approval-fields>
      <!-- // Approval Fields -->
      <!-- Checkout List -->
      <checkout-list
        v-for="(order, index) in Object.keys(orders)"
        :key="`checkout-list-${index}`"
        :index-key="index"
        :order="orders[order]"
        :items="reserves[orders[order].slot]"
        :validation="validation[orders[order].slot]"
        :submitted="submitted">
        <template v-slot:content="{ order, items, validation }">
          <!-- Items -->
          <component
            :is="order.component"
            :order="order"
            :items="items"
            :validation="validation"
            :creditcards="creditcards"
            @input="handleCreditcardByOrder"
            @handleParentAction="handleDialog"></component>
          <!-- // Items -->
        </template>
      </checkout-list>
      <!-- // Checkout List -->

      <!-- TOTAL AND FINALIZE-->
      <div class="row mt-20 mb-20">
        <div class="col-sm-6 mb-20">
          <div class="btn-gray-inverse btn-block btn-extra-large clearfix">
            <span class="pull-left color-gray">Total da sua viagem</span> <span class="pull-right color-gray">{{ total | brl_price }}</span>
          </div>
        </div>
        <div class="col-sm-3 mb-20">
          <button
            :disabled="disabled"
            type="submit"
            class="btn-blue btn-block btn-extra-large">
            Finalizar
          </button>
        </div>
        <div class="col-sm-3 mt-5">
          <span class="size-14 weight-normal"><span class="color-magenta">*</span>É obrigatório selecionar o cartão de crédito para finalizar a compra.</span>
        </div>
      </div>
      <!-- // TOTAL AND FINALIZE-->
    </form>

    <!-- LOCKSCREEN -->
    <lockscreen :loading="loading"></lockscreen>
    <!-- // LOCKSCREEN -->
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import CheckoutSteps from '@/components/template/CheckoutSteps';
import AlertHelper from '@/components/ui/AlertHelper'
import CompleteRegistration from '@/components/CompleteRegistration';
import SingleDialog from '@/components/ui/SingleDialog';
import CardRegister from "@/components/forms/CardRegister.vue";
import ApprovalFields from '@/views/checkout/ApprovalFields';
import CheckoutList from '@/views/checkout/CheckoutList';
import HotelItem from '@/views/checkout/HotelItem';
import FlightItem from '@/views/checkout/FlightItem';
import CarItem from '@/views/checkout/CarItem';
import lockscreen from "@/components/lockscreen/index";
import scrollToElement from '@/mixins/scrollToElement'

export default {
  mixins: [ scrollToElement ],
  components: {
    CheckoutSteps,
    AlertHelper,
    CompleteRegistration,
    SingleDialog,
    CardRegister,
    ApprovalFields,
    CheckoutList,
    HotelItem,
    FlightItem,
    CarItem,
    lockscreen
  },
  data () {
    return {
      active: false,
      dialogComponent: '',
      dialogLarge: true,
      alert: '',
      form: {
        creditcard_hotel: null,
        creditcard_flight: null,
        creditcard_car: null,
        clearsale: null,
        flight_orders: [],
        //
        reasons_request: [],
        approver: null,
        cost_center: null,
        mandatory_justification: null,
        // not used (?)
        additional_reasons_request: null
      },
      loading: false,
      submitted: false,
      disabled: false
    }
  },
  created () {
    this.getReserves()
      .then(data => {
        if (!(data['99']) > 0) {
          this.$router.push('/');
        } else {
          this.$ga.page({
            page: '/',
            title: 'Checkout',
            location: window.location.href
          });

          this.getCreditcards();
        }
      });
  },
  computed: {
    ...mapState(['user']),
    ...mapState('reserve', [
      'flights',
      'hotels',
      'cars',
      'flightOrders', /* TODO - unify */
      'total'
    ]),
    ...mapState('orders', {
      orders: state => state.types
    }),
    ...mapState('creditcard', {
      creditcards: state => state.results
    }),
    reserves() {
      return {
        hotels: this.hotels,
        flights: this.flights,
        cars: this.cars
      }
    },
    ...mapState('creditcard', {
      creditcardAlert: 'alert'
    }),
    ...mapGetters({
      clearSaleSessionID: 'get_clearsale_token'
    }),
    validation () {
      return {
        hotels: this.hotels.length && !!this.form.creditcard_hotel,
        flights: this.flights.length && !!this.form.creditcard_flight,
        cars: this.cars.length && !!this.form.creditcard_car,
        installments: this.flightOrders.length && !!this.form.creditcard_flight && this.flightOrders.every((flight) => flight.hasOwnProperty('selected_installment'))
      }
    },
    isValid () {
      const validation = this.validation;
      return  Object.keys(validation).filter(key => validation[key] !== 0).every(key => {
        return validation[key]
      });
    }
  },
  watch: {
    creditcardAlert (data) {
      this.alert = data;
    }
  },
  methods: {
    ...mapActions('reserve', [
      'getReserves',
      'postReserveFinalize'
    ]),
    ...mapActions('creditcard', [
      'getCreditcards'
    ]),
    handleDialog (component) {
      this.dialogComponent = component;
      this.dialogLarge = (component === 'card-register') ? false : true;
      this.active = !this.active;
    },
    handleCreditcardByOrder (event) {
      this.form[event.target.name] = event.target.value;
    },
    handleSubmit () {
      this.submitted = true;

      if (this.isValid) {
        this.disabled = true;
        this.loading = true;

        this.postReserveFinalize({
          ...this.form,
          clearsale: this.clearSaleSessionID,
          flight_orders: this.flightOrders
        }).then((data) => {

          if (data.error) {
            this.scrollToElement('checkout-error');
            this.alert = data.message;
          } else {
            this.$ga.event({
              eventCategory: 'Checkout',
              eventAction: 'Success',
              eventLabel: 'Checkout converted',
              eventValue: this.flightOrders.total
            });
            this.$router.push('/paymentsuccess');
          }

          this.loading = false;
          this.disabled = false;
        }).catch((error) => {
          this.scrollToElement('checkout-error');
          this.alert = error.data.message;
        });
      } else {
        this.scrollToElement('checkout-error');
        this.disabled = false;
        this.alert = `Você deve selecionar um cartão de crédito para cada item da sua viagem, ou cadastrar um novo cartão caso não possua.`;
      }
    }
  }
};
</script>
