<template>
  <div>
    <!-- Alert Helper -->
    <alert-helper
      :alert="completedPersonalRegister"
      type="info-inverse"
      class="_mt-10">
      <template v-slot>
        Você ainda não completou seu cadastro pessoal, sem ele, não podemos concretizar sua reserva.
        <a
          @click.prevent="handleDialog('personal-register')"
          href="#dialog-register">Clique aqui para completá-lo.</a>
      </template>
    </alert-helper>
    <!-- // Alert Helper -->
    <!-- Alert Helper -->
    <alert-helper
      :alert="completedCompanyRegister"
      type="info-inverse"
      class="_mt-10">
      <template v-slot>
        Você ainda não completou seu cadastro empresarial, sem ele, não podemos concretizar sua reserva.
        <a
          @click.prevent="handleDialog('company-register')"
          href="#dialog-register-complete-registry">Clique aqui para completá-lo.</a>
      </template>
    </alert-helper>
    <!-- // Alert Helper -->
    <!-- Alert Warning -->
    <alert-helper
      v-if="personalAlert || companyAlert"
      :alert="personalAlert || companyAlert"
      :close="true"
      class="_mt-10"
      type="warning">
    </alert-helper>
    <!-- // Alert Warning -->
    <!-- REGISTER Single Dialog -->
    <single-dialog
      :active="active"
      :large="dialogLarge"
      :id="`complete-register-single-dialog`"
      :key="`complete-register-single-dialog`"
      @handleDialog="handleDialog"
      class="color-gray-dark">
      <template v-slot:body="{ closeModal, active }">
        <component
          :is="dialogComponent"
          @handleParentAction="closeModal">
          <template v-slot:default="{ handleParentAction }">
            <div class="col-xs-6">
              <button
                @click="handleParentAction"
                type="button"
                class="btn-gray-inverse btn-block btn-extra-large">
                Cancelar
              </button>
            </div>
          </template>
        </component>
      </template>
    </single-dialog>
    <!-- // Single Dialog -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import AlertHelper from '@/components/ui/AlertHelper'
import SingleDialog from '@/components/ui/SingleDialog';
import CompanyRegister from "@/components/forms/CompanyRegister.vue";
import PersonalRegister from "@/components/forms/PersonalRegister.vue";

export default {
  components: {
    AlertHelper,
    SingleDialog,
    CompanyRegister,
    PersonalRegister
  },
  data () {
    return {
      active: false,
      dialogComponent: '',
      dialogLarge: true,
      alert: ''
    }
  },
  computed: {
    ...mapState(['user']),
    ...mapState('companies', {
      companyAlert: 'alert'
    }),
    ...mapState('personal', {
      personalAlert: 'alert'
    }),
    completedPersonalRegister () {
      return !this.user.completedRegister && this.personalAlert.length === 0;
    },
    completedCompanyRegister () {
      return !this.user.companies[0].completedRegister && this.companyAlert.length === 0;
    }
  },
  methods: {
    handleDialog (component) {
      this.dialogComponent = component;
      this.active = !this.active;
    }
  }
};
</script>
