/*
 *
 */

export const state = {
  types: {
    hotels: {
      name: 'Hotéis',
      ico: 'building',
      slot: 'hotels',
      component: 'hotel-item'
    },
    flights: {
      name: 'Passagens',
      ico: 'plane',
      slot: 'flights',
      component: 'flight-item'
    },
    cars: {
      name: 'Carros',
      ico: 'car',
      slot: 'cars',
      component: 'car-item'
    }
  },
  routes: ['hoteldetails', 'flightdetails', 'cardetails']
}

export default {
  namespaced : true,
  state
  /* mutations */
  /* actions */
  /* getters */
}
