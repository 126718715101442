<template>
  <div>
    <!-- Modelo de página -->
    <div class="theme-page-section theme-page-section-gray theme-page-section-lg">
      <div class="container">
        <div class="row mt-90">
          <!-- Componente menu -->
          <menu-account></menu-account>
          <!-- /Componente menu -->
          <div class="col-md-10">
            <!-- Titulo -->
            <h1 class="theme-account-page-title">Adicionar cartão</h1>
            <!-- /Titulo -->
            <div class="row">
              <div class="col-md-6 hidden-xs hidden-sm">
                <div class="father">
                  <div class="front">
                    <img
                      class="active img-responsive"
                      src="@/assets/img/card-front.png"
                      alt="Frente do cartão de crédito"
                      title="Frente do cartão de crédito"
                    >
                  </div>
                  <div class="back">
                    <img
                      class="active img-responsive"
                      src="@/assets/img/card-back.png"
                      alt="Verso do cartão de crédito"
                      title="Verso do cartão de crédito"
                    >
                  </div>
                </div>
              </div>
              <div style="text-align: center" class="col-md-6 _mt--40 mb-30">
                <p>Cartões aceitos</p>
                  <img
                    class="active card-img-box"
                    src="@/assets/img/logo-mastercard.png"
                    alt="Mastercard"
                    title="Mastercard"
                  >
                  <img
                    class="active card-img-box"
                    src="@/assets/img/logo-visa.png"
                    alt="Visa"
                    title="Visa"
                  >
                  <img
                    class="active card-img-box"
                    src="@/assets/img/logo-americanexpress.png"
                    alt="Amex"
                    title="Amex"
                  >
              </div>
              <div class="col-md-6">
                <form @submit.prevent="create_card">
                  <div class="user-personal-data">
                    <!-- Dados Do cartão -->
                    <!-- Subtitulo -->
                    <h4 class="title-manage-account">Dados do cartão</h4>
                    <!-- /Subtitulo -->
                    <!-- Número do cartão -->
                    <div class="col-md-10 col-sm-10 col-xs-10 padding-off">
                      <div class="form-group theme-login-form-group">
                        <label>
                          <span class="hidden-xs hidden-sm">(1)</span> Número do cartão*
                        </label>
                        <the-mask
                          class="form-control"
                          type="tel"
                          name="number"
                          v-model="card.number"
                          @input="validate_card"
                          :mask="['#### #### #### ####', '#### #### #### ###', '#### #### #### ##']"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('number') }"
                          onpaste="return false" 
                          ondrop="return false"
                        />
                        <span class="error-message-forms">{{ errors.first('number') }}</span>
                      </div>
                    </div>
                    <div class="col-md-2 col-sm-2 col-xs-2 padding-off">
                      <div class="box-credit-card-img">
                        <img
                          class="active card-img-box"
                          src="@/assets/img/logo-mastercard.png"
                          alt="Mastercard"
                          title="Mastercard"
                          v-if="card.card_type === 'mastercard'"
                        >
                        <img
                          class="active card-img-box"
                          src="@/assets/img/logo-visa.png"
                          alt="Visa"
                          title="Visa"
                          v-if="card.card_type === 'visa'"
                        >
                        <img
                          class="active card-img-box"
                          src="@/assets/img/logo-americanexpress.png"
                          alt="Amex"
                          title="Amex"
                          v-if="card.card_type === 'amex'"
                        >
                      </div>
                    </div>
                    <!-- /Número do cartão -->
                    <!-- Nome impresso no cartão -->
                    <div class="form-group theme-login-form-group">
                      <label>
                        <span class="hidden-xs hidden-sm">(2)</span> Nome impresso no cartão*
                      </label>
                      <input
                        class="form-control"
                        type="text"
                        name="name"
                        v-model="card.name"
                        v-validate="'required'"
                        :class="{ 'alert-danger': submitted && errors.has('name') }"
                        onpaste="return false" 
                        ondrop="return false"
                      >
                      <span class="error-message-forms">{{ errors.first('name') }}</span>
                    </div>
                    <!-- /Nome impresso no cartão -->
                    <div class="clearfix"></div>
                    <!-- Código de Segurança -->
                    <div v-show="!getOptionCardVirtual()" class="col-md-2 padding-left-off">
                      <div class="form-group theme-login-form-group">
                        <label for>
                          <span class="hidden-xs hidden-sm">(4)</span> CVV*
                        </label>
                        <the-mask
                          class="form-control"
                          type="tel"
                          name="code"
                          maxlength="4"
                          v-model="card.code"
                          :mask="['####']"
                          v-validate="'required'"
                          :class="{ 'alert-danger': submitted && errors.has('code') }"
                          onpaste="return false" 
                          ondrop="return false"
                        />
                        <span class="error-message-forms">{{ errors.first('code') }}</span>
                      </div>
                    </div>
                    <!-- /Código de Segurança -->
                    <!-- Data de validade -->
                    <div class="col-md-5 col-sm-12 col-xs-12 padding-left-off">
                      <div class="form-group theme-login-form-group">
                        <label for>
                          <span class="hidden-xs hidden-sm">(4)</span> Validade*
                        </label>
                        <br>
                        <div class="input-group input-group-card btn-100">
                          <select
                            class="form-control"
                            name="expiry_month"
                            v-model="card.expiry_month"
                            v-validate="'required'"
                            :class="{ 'alert-danger': submitted && errors.has('expiry_month') }"
                          >
                            <option value="1">01</option>

                            <option value="2">02</option>

                            <option value="3">03</option>

                            <option value="4">04</option>

                            <option value="5">05</option>

                            <option value="6">06</option>

                            <option value="7">07</option>

                            <option value="8">08</option>

                            <option value="9">09</option>

                            <option value="10">10</option>

                            <option value="11">11</option>

                            <option value="12">12</option>
                          </select>
                          <select
                            class="form-control"
                            name="expiry_date_1"
                            id="id_expiry_date_1"
                            v-model="card.expiry_year"
                            v-validate="'required'"
                            :class="{ 'alert-danger': submitted && errors.has('expiry_date_1') }"
                          >
                            <option value="2019">2019</option>

                            <option value="2020">2020</option>

                            <option value="2021">2021</option>

                            <option value="2022">2022</option>

                            <option value="2023">2023</option>

                            <option value="2024">2024</option>

                            <option value="2025">2025</option>

                            <option value="2026">2026</option>

                            <option value="2027">2027</option>

                            <option value="2028">2028</option>

                            <option value="2029">2029</option>

                            <option value="2030">2030</option>

                            <option value="2031">2031</option>

                            <option value="2032">2032</option>

                            <option value="2033">2033</option>
                          </select>
                        </div>
                        <span class="error-message-forms">{{ errors.first('expiry_month') }}</span>
                        <br>
                        <span class="error-message-forms">{{ errors.first('expiry_date_1') }}</span>
                      </div>
                    </div>
                    <!-- /Data de validade -->
                    <!-- Tipo de Cartão -->

                    <div class="clearfix"></div>

                    <div class="form-group padding-left-off">
                      <div><label>
                        Seu cartão é:
                      </label></div>
                      <div class="col-md-24">
                        <div class="col-md-5 padding-left-off">
                        <p-radio
                          class="p-default p-round p-smooth p-plain"
                          name="is_corporate"
                          :value=false
                          v-model="card.is_corporate"
                          color="primary-o"
                          title="Cartão de crédito que esteja em nome de uma pessoa física"
                        >&nbsp;Pessoal</p-radio>
                        </div>
                        <div class="col-md-5 padding-left-off">
                        <p-radio
                          class="p-default p-round p-smooth p-plain"
                          name="is_corporate"
                          :value=true
                          v-model="card.is_corporate"
                          color="primary-o"
                          title="Cartão de crédito que esteja em nome de uma pessoa jurídica"
                          @change="setOptionCardCorporativoNotVirtual(card.is_corporate)"
                        >&nbsp;Corporativo</p-radio>
                        </div>
                      </div>
                    </div>

                    <div class="clearfix"></div>

                    <div v-show="card.is_corporate" class="form-group padding-left-off mt-10">
                      <div><label>
                        do tipo:
                      </label></div>
                      <div class="col-md-24">
                        <div class="col-md-5 padding-left-off">
                        <p-radio
                          class="p-default p-round p-smooth p-plain"
                          name="is_corporate_physical"
                          :value=true
                          v-model="card.is_corporate_physical"
                          color="primary-o"
                          title="Cartão de crédito físico"
                          @change="setOptionCardCorporativoNotVirtual(true)"
                        >&nbsp;Físico</p-radio>
                        </div>
                        <div class="col-md-5 padding-left-off">
                        <p-radio
                          class="p-default p-round p-smooth p-plain"
                          name="is_corporate_physical"
                          :value=false
                          v-model="card.is_corporate_physical"
                          color="primary-o"
                          title="Cartão de crédito virtual"
                        >&nbsp;Virtual</p-radio>
                        </div>
                      </div>
                    </div>

                    <div class="clearfix"></div>

                    <div v-show="!card.is_corporate_physical" class="form-group padding-left-off mt-10">
                      <div><label>
                        específico para:
                      </label></div>
                      <div class="col-md-24">
                        <div class="col-md-5 padding-left-off">
                        <p-radio
                          class="p-default p-round p-smooth p-plain"
                          name="corporate_virtual_flight"
                          :value=true
                          v-model="card.corporate_virtual_flight"
                          color="primary-o"
                          title="Cartão corporativo virtual aéreo (não permite o parcelamento)"
                          @change="setOptionCardVirtual(card.corporate_virtual_flight, 'flight')"
                        >&nbsp;Aéreo</p-radio>
                        </div>
                        <div class="col-md-6 padding-left-off">
                        <p-radio
                          class="p-default p-round p-smooth p-plain"
                          name="corporate_virtual_hotel"
                          :value=true
                          v-model="card.corporate_virtual_hotel"
                          color="primary-o"
                          title="Cartão corporativo virtual hotel (não permite o parcelamento)"
                          @change="setOptionCardVirtual(card.corporate_virtual_hotel, 'hotel')"
                          v-validate="!card.is_corporate_physical && !card.corporate_virtual_hotel && !card.corporate_virtual_flight? 'required|included:true': ''"
                          :class="{ 'alert-danger': submitted && errors.has('corporate_virtual_hotel') }"
                        >&nbsp;Hotel</p-radio>
                        </div>
                        <span class="error-message-forms">{{ (errors.first('corporate_virtual_flight') || errors.first('corporate_virtual_hotel')) ? 'Escolha o tipo de cartão virtual': '' }}</span>
                      </div>
                    </div>

                    
                    <div class="clearfix"></div>
                    <div class="form-group mt-20">
                      <div class="theme-login-checkbox">
                        <div class="theme-search-results-sidebar-section-checkbox-list-item">

                          <p-check
                              class="p-svg p-default p-fill p-smooth"
                              type="checkbox"
                              name="corporate_parcel_allowed"
                              v-model="card.corporate_parcel_allowed"
                              color="primary"
                              @change="setOptionCardCorporativoNotVirtual(card.corporate_parcel_allowed)"
                              v-show="card.is_corporate && card.is_corporate_physical"
                            >
                              <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                                <path
                                  d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                  style="stroke: white;fill:white"
                                ></path>
                              </svg>
                              <span class="pretty-space">É permitido parcelar ?</span>
                            </p-check>
                            <span class="theme-search-results-sidebar-section-checkbox-list-amount"></span>

                          <div class="clearfix"></div>
                          
                          <p-check
                            class="p-svg p-default p-fill p-smooth"
                            type="checkbox"
                            name="is_shared"
                            v-model="card.is_shared"
                            color="primary"
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">Liberar para todos os viajantes?</span>
                          </p-check>
                          <span class="theme-search-results-sidebar-section-checkbox-list-amount"></span>
                        </div>
                      </div>
                    </div>
                    
                    <!-- /Tipo de Cartão -->
                </div>
                </form>
              </div>
              <div class="col-md-6"></div>
            </div>
            <div class="theme-account-preferences-item-change-actions">
              <button
                type="submit"
                class="btn btn-lg btn-primary pull-right"
                v-on:click="create_card"
              >Salvar</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Modelo de página -->
  </div>
</template>

<script>
import MenuAccount from "../components/menuaccount.vue";
import alerts from "../mixins/alerts";

const card_validator = require("card-validator");

export default {
  data() {
    return {
      card: {
        number: null,
        name: null,
        code: null,
        expiry_month: null,
        expiry_year: null,
        card_type: null,
        is_corporate: false,
        is_corporate_physical: true,
        corporate_parcel_allowed: false,
        corporate_virtual_flight: false,
        corporate_virtual_hotel: false,
        is_shared: null
      },
      invalid: false,
      submitted: false
    };
  },
  components: {
    MenuAccount
  },
  mixins: [alerts],
  methods: {
    validate_card() {
      if (this.card.number.length < 4) {
        this.card.card_type = null;
        this.invalid = false;
      }
      if (this.card.number.length === 4) {
        let numberValidation = card_validator.number(this.card.number);

        if (!numberValidation.isPotentiallyValid) {
          this.invalid = true;
          this.$swal({
            title: "Erro no número do cartão, favor digitar novamente",
            type: "error",
            showConfirmButton: true,
          }).then(() => {
            this.card.number = '';
          });
        }

        if (numberValidation.card) {
          this.card.card_type = numberValidation.card.type;
          if (this.card.card_type === "american-express") {
            this.card.card_type = "amex";
          }
        }
      }
    },
    create_card(e) {
      e.preventDefault();
      this.$validator.validate().then(valid => {
        if (!valid) return false;

        this.submitted = true;
        let router = this.$router;
        this.$http
          .post("/api/cards/", this.card)
          .then(response => {
            if (response.status === 201) {
              this.$swal({
                title: "Salvo com sucesso!",
                type: "success",
                showConfirmButton: false,
                timer: 2000
              }).then(()=>{
                router.push("/cards");
              });
              this.$ga.event({
                eventCategory: 'Cards',
                eventAction: 'Created',
                eventLabel: 'New Card Created',
                eventValue: 1
              })
            }
          })
          .catch(error => {
            let last_error = false;
            let errors = error.response.data;
            if (error.response.data.message) {
              last_error = error.message;
            } else {
              for (let key in errors) { 
                let errors_field = errors[key]
                errors_field.forEach(function(error, idx){
                  if (last_error == false) {
                    last_error = error
                  }
                })
              }
            }
  
            if (last_error) {
              this.$swal({
                title: last_error,
                type: "error",
                showConfirmButton: true,
              });
            }
          });
      });
    },
    setOptionCardPhysical(event){
      if (event == true){
        this.card.is_corporate = false
        this.corporate_parcel_allowed = false
        this.setOptionCardCorporativoNotVirtual(true)
      }
    },
    setOptionCardCorporativo(event){
      if (event == true){
        this.card.is_corporate = true
        this.card.corporate_parcel_allowed = true
      }
    },
    getOptionCardVirtual() {
      if ((!this.card.is_corporate_physical) && (this.card.corporate_virtual_flight || this.card.corporate_virtual_hotel)){
        return true
      }else{
        return false
      }
    },
    setOptionCardVirtual(event, typeOption) {
      if (event == true){
        this.card.is_corporate = true
        this.card.is_corporate_physical = false
        this.card.corporate_parcel_allowed = false
        this.card.code = '123'
        if (typeOption == 'flight'){
          this.card.corporate_virtual_hotel = false
        }else if (typeOption == 'hotel'){
          this.card.corporate_virtual_flight = false
        }
      }

    },
    setOptionCardCorporativoNotVirtual(event) {
      if (event == true){
        this.card.is_corporate_physical = true
        this.card.corporate_parcel_allowed = true
        this.card.corporate_virtual_flight = false
        this.card.corporate_virtual_hotel = false
        if (this.card.code == '123'){
          this.card.code = ''
        }
        
      }
    }
  }
};
</script>

<style scoped>
.input-group-card {
  display: inline-flex !important;
}
.father {
  width: 350px;
  position: relative;
  margin: 50px auto;
  transition: all 0.5s ease-in-out;
  transform-style: preserve-3d;
}
.father:hover {
  transform: rotateY(180deg);
}
.father div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-size: 30px;
  text-align: center;
  line-height: 300px;
  color: #fff;
}
.front {
  z-index: 2;
  backface-visibility: hidden;
}
.back {
  z-index: 1;
  transform: rotateY(180deg);
  backface-visibility: hidden;
}
</style>
