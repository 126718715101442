<template>
    <div class="row">
        <div class="col-md-12 padding-off">
            <div class="user-personal-data">
                <div class="col-md-12">
                    <h4 class="title-manage-account">Transações nos últimos 12 meses</h4>
                </div>
                <div class="col-md-12">
                    <wait-loader v-if="loading"></wait-loader>
                    <div v-else class="theme-account-history table-responsive">
                        <table class="table">
                        <thead>
                        <tr>
                            <th class="text-center">Mês</th>
                            <th class="text-center">Quantidade</th>
                            <th class="text-center">Ticket Médio</th>
                            <th class="text-center">Valor Total</th>
                            <th class="text-center">Baixar</th>
                        </tr>
                        </thead>
                        <tbody v-if="orders.length !== 0">
                            <tr v-for="item in orders.items">
                                <td class="text-center">{{ item.month }}</td>
                                <td class="text-center">{{ item.qtd }}</td>
                                <td class="text-center">{{ item.ticket_avg }}</td>
                                <td class="text-center">{{ item.total }}</td>
                                <td class="text-center">
                                    <a :href="item.download" title="Download">
                                        <small><i class="fa fa-download"></i></small>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2"></td>
                                <td class="text-center">Total</td>
                                <td class="text-center">{{ orders.total }}</td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr class="text-center">
                                <td colspan="4">Nenhum pedido encontrado.</td>
                            </tr>
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import WaitLoader from "../loader/wait-loader.vue";
    export default {
        name: "last-orders",
        data() {
            return {
                orders: [],
                loading: false,
            }
        },
        components: {
            WaitLoader,
        },
        created() {
            this.loading = true;
            this.$http
                .get("/api/last-orders")
                .then(response => {
                    if (response.status === 200) {
                        this.orders = response.data.orders
                    }
                    setTimeout(() => {
                        this.loading = false;
                    }, 1000);
                })
                .catch(error => {
                    console.log(error);
                });
        }
    }
</script>

<style scoped>

</style>