<template>
  <collapse-item>
    <template v-slot:content="{ active }">
      <div
        :aria-labelledby="`collapse-item-password-helper`"
        :id="`collapse-item-password-helper`"
        role="tabpanel"
        class="well well-sm mt-5">
        <p>A senha deve atender os seguintes aspectos:</p>
        <ul>
          <li>conter no mínimo 8 caracteres</li>
          <li>possuir necessariamente letras e números</li>
          <li>possuir opcionalmente caracteres especiais</li>
          <li>não deve conter letras ou números sequenciais</li>
          <li>não conter o seu nome, CPF, e-mail ou data de nascimento</li>
        </ul>
      </div>
    </template>
  </collapse-item>
</template>

<script>
import CollapseItem from '@/components/ui/CollapseItem'

export default {
  components: {
    CollapseItem
  }
}
</script>
