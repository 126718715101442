<template>
  <div>
    <hero-steps :type="'flight'"></hero-steps>

    <!-- Complete Registration -->
    <div class="container">
      <complete-registration class="col-xs-12"></complete-registration>
    </div>
    <!-- // Complete Registration -->

    <div class="container min-height _mb-20">
      <div class="clearfix pt-10">
        <div class="col-md-8 col-lg-9">
          <div>
            <details-breadcrumb
              :go-back="'Passagens'"
              :current="`${outboundTitle} ${inboundTitle}`"></details-breadcrumb>
            <hr
              v-if="!error"
              class="border-top mt-10 block" />
          </div>

          <div
            v-if="journey"
            class="row">

            <div class="col-sm-6 _mb-20">
              <!-- Detail Hourly -->
              <detail-hourly
                :segments="outbound.segments"
                :direction="'Ida'"></detail-hourly>
              <!-- // Detail Hourly -->
              <a
                @click.prevent="handleDialogOpen(outbound)"
                class="btn-link size-14 weight-normal">
                ver detalhes</a>
            </div>
            <div
              v-if="inbound"
              class="col-sm-6 _mb-20 border-left">
              <!-- Detail Hourly -->
              <detail-hourly
                :segments="inbound.segments"
                :direction="'Volta'"></detail-hourly>
              <!-- // Detail Hourly -->
              <a
                @click.prevent="handleDialogOpen(inbound)"
                class="btn-link size-14 weight-normal">
                ver detalhes</a>
            </div>

            <div class="col-sm-12 clearfix">
              <p>
                <i
                  :class="[{ 'fa-users':  journey.adults > 1}, { 'fa-user':  journey.adults === 1}]"
                  class="fa"></i>
                {{ 'Viajantes' | pluralize(journey.adults) }}: {{ journey.adults }} {{ 'adultos' | pluralize(journey.adults) }}
              </p>

              <!-- Alert Warning -->
              <div
                id="flight-details-error"
                class="_mb-10 _mt-10">
                <alert-helper
                  :alert="error"
                  :type="'danger'"
                  :close="true">
                </alert-helper>
              </div>
              <!-- // Alert Warning -->

              <form class="row">
                <div class="col-sm-8 _pb-20 clearfix">
                  <!-- Multiselect Travelers -->
                  <multiselect-travelers
                    :travelers.sync="travelers"
                    :error="travelersError"
                    :max="journey.adults"></multiselect-travelers>
                  <!-- // Multiselect Travelers -->
                </div>
                <div class="col-sm-4">
                  <button
                    class="btn-blue btn-block btn-large pull-right"
                    type="submit"
                    @click.prevent="flightBooking">
                    RESERVAR
                  </button>
                </div>
              </form>
            </div>
          </div>

          <div class="_pt-20 _pb-10 clearfix">
            <div class="bg-gray-lighter-plus size-14 weight-lighter pb-10 col-xs-12">
              <p class="_mt-10 _mb-0">
                <i class="fa fa-fw fa-info-circle color-gray-light"></i>
                As passagens podem não ser reembolsáveis e não são transferíveis. Alterações de nome não são permitidas.
              </p>
              <p class="_mb-0">
                <i class="fa fa-fw fa-info-circle color-gray-light"></i>
                As companhias aéreas podem alterar os horários dos voos e terminais a qualquer momento.
              </p>
              <p class="_mb-0">
                <i class="fa fa-fw fa-info-circle color-gray-light"></i>
                Viaje com todos os documentos necessários. É sua responsabilidade verificar os documentos antes de viajar.
              </p>
            </div>
          </div>
        </div>

        <div
          v-if="journey"
          class="col-md-4 col-lg-3">
          <!-- Detail Summary -->
          <detail-summary
            v-if="selectedPrice"
            :journey="journey"
            :selected-price="selectedPrice"></detail-summary>
          <!-- // Detail Summary -->
        </div>
      </div>
    </div>

    <single-dialog
      :active="dialog.active"
      :large="false"
      :key="`flight-detail-single-dialog`"
      @handleDialog="handleDialogClose"
      class="color-gray-dark">
      <detail-dialog
        slot="body"
        :flight="dialog.item"></detail-dialog>
    </single-dialog>

    <lockscreen :loading="loadingFlight"></lockscreen>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HeroSteps from '@/components/template/HeroSteps';
import CompleteRegistration from '@/components/CompleteRegistration';
import DetailsBreadcrumb from '@/components/template/DetailsBreadcrumb';
import AlertHelper from '@/components/ui/AlertHelper';
import SingleDialog from '@/components/ui/SingleDialog';
import MultiselectTravelers from '@/components/forms/MultiselectTravelers';
import DetailDialog from '@/views/flight/details/DetailDialog';
import DetailSummary from '@/views/flight/details/DetailSummary';
import DetailHourly from '@/components/flight/DetailHourly';
import lockscreen from '@/components/lockscreen/index.vue';
import scrollToElement from '@/mixins/scrollToElement';

export default {
  mixins: [ scrollToElement ],
  components: {
    HeroSteps,
    CompleteRegistration,
    DetailsBreadcrumb,
    AlertHelper,
    SingleDialog,
    MultiselectTravelers,
    DetailDialog,
    DetailSummary,
    DetailHourly,
    lockscreen
  },
  data () {
    return {
      travelers: [],
      travelersError: false,
      journey: null,
      outbound: null,
      outboundTitle: '',
      inbound: null,
      inboundTitle: '',
      selectedPrice: {},
      dialog: {
        item: null,
        active: false
      },
      error: false
    }
  },
  mounted () {
    this.getFlightPricing(this.$route.params.hash)
      .then(this.setFlight)
      .catch(this.setError);
  },
  computed: {
    ...mapState('flights', {
      'loadingFlight': state => state.loading
    })
  },
  methods: {
    ...mapActions('flights', ['getFlightPricing', 'postFlightBooking']),
    setFlight (data) {
      this.journey = { ...data.results };
      // this.journey.hash = this.journey.links[0].booking.split('?hash=')[1];
      this.outbound = this.journey.flights[0];
      this.inbound = this.journey.flights[1] || null;
      this.outboundTitle = `${this.outbound.origin.name}, ${this.outbound.segments[0].origin.iata_code}`;
      this.inboundTitle = (this.inbound) ? `→ ${this.outbound.destination.name}, ${this.outbound.segments[this.outbound.segments.length-1].destination.iata_code}` : '';
      this.selectedPrice = this.journey.prices.filter(price => price.brand.name.toLowerCase() === this.$route.params.slug.toLowerCase())[0];
      this.journey.hash = this.selectedPrice.links.booking.split('/booking/?hash=')[1];
    },
    flightBooking () {
      this.travelersError = false;

      if (this.selectedPrice.hasOwnProperty('id') && this.travelers.length === this.journey.adults) {
        this.postFlightBooking({
          hash: this.journey.hash,
          // price_id: this.selectedPrice.id,
          travelers: this.travelers
        })
          .then(booking => {
            if (booking) {
              this.trackAction('Booking')
              this.$router.push('/travel');
            }
          })
          .catch(() => {
            this.error = 'Não foi possível salvar sua reserva.'
            this.scrollToElement('flight-details-error');
          })

      } else {
        this.travelersError = true;
      }
    },
    trackAction (action) {
      this.$ga.event({
        eventAction: action,
        eventCategory: 'Flight',
        eventValue: true
      })
    },
    setError (error) {
      this.error = (error) ? error : 'A tarifa selecionada não está mais disponível';
    },
    handleDialogOpen (item) {
      this.dialog = {
        ...this.dialog,
        active: true,
        item
      }
    },
    handleDialogClose () {
      this.dialog = {
        ...this.dialog,
        active: false,
        item: null
      }
    }
  }
};
</script>
