import { axiosUpdateHeaders } from '@/axios'

export const state = {
  reasons: [],
  justifications: [],
  group: {}
}

export const mutations = {
  setPolicies (state, {type, data}) {
    state[type] = data;
  }
}

export const actions = {
  getPolicies (context, type) {
    const axios = axiosUpdateHeaders();
    return axios({
      url: `/api/policy/${type}`
    }).then(response => {
      if (response.status === 200) {
        context.commit('setPolicies', { type, data: response.data });
      }
      return Promise.resolve(response.data);
    }).catch(error => {
      return Promise.reject(error.data);
    });
  }
  // /api/policy/reasons
  // /api/policy/justifications
  // /api/policy/group // not used
}

export default {
  namespaced : true,
  state,
  mutations,
  actions
  /* getters */
}
