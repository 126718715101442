<template>
  <div>
    <hero-steps :type="'car'"></hero-steps>

    <!-- Complete Registration -->
    <div class="container">
      <complete-registration class="col-xs-12"></complete-registration>
    </div>
    <!-- // Complete Registration -->

    <div class="container min-height _mb-20">
      <div class="clearfix pt-10">
        <div class="col-md-8 col-lg-9">
          <div>
            <!-- Detail Breadcrumb -->
            <details-breadcrumb
              :go-back="'Carros'"
              :current="breadcrumbCurrent"></details-breadcrumb>
            <!-- // Detail Breadcrumb -->
            <hr
              v-if="!error"
              class="border-top mt-10 block" />
          </div>

          <template v-if="car">
            <div class="row">
              <div class="col-xs-12 col-sm-5 col-lg-4 _pl-0 _pr-0">
                <!-- Car Thumb -->
                <car-thumb
                  :image="car.vehicle.image_url"
                  :model="car.vehicle.model">
                  <template slot="trigger">
                    <a
                      @click.prevent="handleDialog"
                      class="btn-link size-14 weight-normal">
                      ver detalhes</a>
                  </template>
                </car-thumb>
                <!-- // Car Thumb -->
              </div>
              <!-- Car Dates -->
              <div class="col-xs-12 col-sm-7 col-lg-4 _pl-0 _pr-0">
                <car-dates
                  :start="car.start_date"
                  :end="car.end_date"></car-dates>
              </div>
              <!-- // Car Dates -->
              <!-- Car Description -->
              <div class="visible-lg-block col-xs-12 col-sm-12 col-lg-4 _pb-10 _pt-10 border-left">
                <car-description
                  :passengers="car.vehicle.passengers"
                  :transmission="car.vehicle.transmission"
                  :air-conditioner="car.vehicle.air_conditioner"
                  :doors="car.vehicle.doors">
                </car-description>
              </div>
              <!-- // Car Description -->
            </div>

            <form class="clearfix _mt-10">
              <article class="bg-gray-lighter-plus _pr-10 _pl-10 _pb-20 clearfix mb-10">
                <h2 class="size-16 col-xs-12">Deixe seu aluguel ainda mais completo</h2>
                <!-- Car Optionals -->
                <car-optionals
                  :optionals="car.vehicle.optionals"
                  @handleOptional="handleOptional"></car-optionals>
                <!-- // Car Optionals -->
              </article>

              <!-- Alert Warning -->
              <div
                id="car-details-error"
                class="_mb-10 _mt-10">
                <alert-helper
                  :alert="error"
                  :type="'danger'"
                  :close="true">
                </alert-helper>
              </div>
              <!-- // Alert Warning -->

              <div class="row">
                <div
                  id="car-details-select-traveler"
                  class="col-sm-8">
                  <!-- Select Travelers -->
                  <select-travelers
                    :traveler.sync="traveler"
                    :submitted="submitted"></select-travelers>
                  <!-- // Select Travelers-->
                  <p class="_mt-10 _mb-0">
                    <i class="fa fa-fw fa-info-circle color-gray-light"></i>
                    É necessário que o condutor tenha CNH válida.
                  </p>
                </div>
                <div class="col-sm-4">
                  <button
                    class="btn-blue btn-block btn-large pull-right"
                    type="submit"
                    @click.prevent="carBooking">
                    RESERVAR
                  </button>
                </div>
              </div>

              <article class="bg-gray-lighter-plus _pr-10 _pl-10 _pb-20 clearfix mt-10 mb-20">
                <h2 class="size-16 col-xs-12 _mb-0">Coberturas:</h2>
                <div class="col-xs-12">
                  <!-- Car Coverages -->
                  <car-coverages
                    :coverages="car.vehicle.coverages"
                    @handleCoverage="handleCoverage"></car-coverages>
                  <!-- // Car Coverages -->
                </div>
              </article>
            </form>
          </template>
        </div>

        <div
          v-if="car && car.vehicle.rate"
          class="col-md-4 col-lg-3">
          <detail-summary
            :dailyRate="car.days"
            :charges="car.vehicle.rate.charges"
            :optionals="optionals"
            :coverages="coverages"></detail-summary>
        </div>
      </div>
    </div>

    <single-dialog
      :active="dialog"
      :large="false"
      :key="`car-detail-single-dialog`"
      @handleDialog="handleDialog"
      class="color-gray-dark">
      <detail-dialog
        slot="body"
        v-if="car"
        :car="car"></detail-dialog>
    </single-dialog>

    <lockscreen :loading="loadingCar"></lockscreen>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import HeroSteps from '@/components/template/HeroSteps';
import CompleteRegistration from '@/components/CompleteRegistration';
import DetailsBreadcrumb from '@/components/template/DetailsBreadcrumb';
import AlertHelper from '@/components/ui/AlertHelper';
import SingleDialog from '@/components/ui/SingleDialog';
import SelectTravelers from '@/components/forms/SelectTravelers';
import CarThumb from '@/components/car/CarThumb';
import CarDates from '@/components/car/CarDates';
import CarDescription from '@/components/car/CarDescription';
import CarOptionals from '@/views/car/details/CarOptionals';
import CarCoverages from '@/views/car/details/CarCoverages';
import DetailDialog from '@/views/car/details/DetailDialog';
import DetailSummary from '@/views/car/details/DetailSummary';
import lockscreen from '@/components/lockscreen/index.vue';
import scrollToElement from '@/mixins/scrollToElement';

export default {
  mixins: [ scrollToElement ],
  components: {
    HeroSteps,
    DetailsBreadcrumb,
    CompleteRegistration,
    AlertHelper,
    SingleDialog,
    SelectTravelers,
    CarThumb,
    CarDates,
    CarDescription,
    CarOptionals,
    CarCoverages,
    DetailDialog,
    DetailSummary,
    lockscreen
  },
  data () {
    return {
      car: null,
      optionals: [],
      coverages: [],
      dialog: false,
      traveler: null,
      submitted: false,
      error: false
    }
  },
  mounted () {
    this.getVehicleAvailability(this.$route.params.hash)
      .then(this.setCar)
      .catch(this.setError);
  },
  computed: {
    ...mapState('cars', {
      'loadingCar': state => state.loading
    }),
    breadcrumbCurrent () {
      return (this.car) ? `${this.car.provider.name} - ${this.car.vehicle.model}` : '';
    }
  },
  methods: {
    ...mapActions('cars', ['getVehicleAvailability', 'postVehicleBooking']),
    setCar (data) {
      const vehicleId = this.$route.params.vehicle_id;

      if (data.results) {
        this.car = data.results.filter(item => Number(item.vehicle.id) === Number(vehicleId))[0];
      }
    },
    setError (error) {
      this.error = (error) ? error : 'A disponibilidade expirou. Refaça sua busca';
    },
    carBooking () {
      this.submitted = true;
      this.error = null;

      if (this.traveler) {
        this.postVehicleBooking({
          provider: this.car.provider ? this.car.provider.slug : null,
          traveler: this.traveler,
          vehicle_code: this.car.vehicle ? this.car.vehicle.code : null,
          pickup_agency: this.car.pickup_agency ? this.car.pickup_agency.iata_code : null,
          delivery_agency: this.car.delivery_agency ? this.car.delivery_agency.iata_code : null,
          start_date: this.car.start_date,
          end_date: this.car.end_date,
          coverages: this.coverages,
          optionals: this.optionals
        })
          .then(results => {
            if (results) {
              this.$router.push('/travel');
            }
            this.submitted = false;
          })
          .catch(error => {
            /* TODO refactor if response has error that must be show */
            this.error = 'Não foi possível salvar sua reserva.'
            this.submitted = false;
            this.scrollToElement('car-details-error');
          })
      } else {
        this.scrollToElement('car-details-error');
      }
    },
    handleOptional (event, optional) {
      const optionals = (event)
        ? [ ...this.optionals, optional ]
        : this.optionals.filter(item => item.id !== optional.id);
      this.optionals = [ ...optionals ];
    },
    handleCoverage (event, coverage) {
      const coverages = (event)
        ? [ ...this.coverages, coverage ]
        : this.coverages.filter(item => item.id !== coverage.id);
      this.coverages = [ ...coverages ];
    },
    handleDialog () {
      this.dialog = !this.dialog;
    }
  }
};
</script>
