<template>
  <detail-dialog>
    <template v-slot:detail>
      <div class="clearfix _mt-20 _mb-20">
        <!-- Flight Hourly -->
        <flight-hourly
          :segments="item.segments"
          :class="{ 'border-right': item.travelers.length <= 2 }"
          class="col-sm-7"></flight-hourly>
        <!-- // Flight Hourly -->
        <div
          :class="{ 'border-left': item.travelers.length > 2 }"
          class="col-sm-5">
          <!-- <p class="box-pad-5">
            <img
              :src="require(`@/assets/img/carriers/${item.flight.airline.iata}.png`)"
              class="ico-carrier"
              alt="">&nbsp;<span class="size-12 color-gray-light-mid weight-lighter"> Operado por {{ item.segments[0].airline.name }}</span>
          </p> -->
          <i
            :class="[{ 'fa-user': item.travelers.length === 1 }, { 'fa-users': item.travelers.length > 1 }]"
            class="fa fa-fw color-gray-light-mid"></i>&nbsp;
          <span class="weight-lighter">{{ 'Viajantes' | pluralize(item.travelers.length) }}:</span>&nbsp;
          <span
            v-for="(traveler, index) in item.travelers"
            :key="`flight-detail-traveler-${index}`"><br>{{ traveler.full_name }}</span>
            <!-- v-if="index > 0 && index < item.travelers.length" -->
        </div>
      </div>
      <div class="bg-gray-lighter box-radius clearfix">
        <!-- Flight Segment -->
        <detail-segment
          v-for="(segment, index) in item.segments"
          :key="`flight-detail-segment-${index}`"
          :indexKey="index"
          :segment="segment"
          :date-departure="item.segments[0].departure">
        </detail-segment>
        <!-- // Flight Segment -->
      </div>
    </template>
  </detail-dialog>
</template>

<script>
import DetailDialog from '@/components/ui/DetailDialog'
import DetailSegment from '@/components/flight/DetailSegment'
import FlightHourly from '@/components/flight/FlightHourly'

export default {
  components: {
    DetailDialog,
    FlightHourly,
    DetailSegment
  },
  props: [
    'item'
  ]
};
</script>




