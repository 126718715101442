<template>
  <detail-dialog>
    <template v-slot:detail>
      <div class="clearfix mb-20">
        <h2 class="col-sm-12 size-18 upper weight-bold color-gray-dark">
          {{ item.room.hotel.name }}
        </h2>
        <div class="col-xs-12 col-sm-7">
          <div class="col-xs-6 padding-off">
            <div class="color-gray-light-mid size-16 weight-lighter">
              <i class="fa fa-calendar"></i> Check Out:
            </div>
            <p class="size-16">{{ item.room.checkin | date_to_weekday }}</p>
          </div>
          <div class="col-xs-6 border-right">
            <div class="color-gray-light-mid size-16 weight-lighter">
              <i class="fa fa-calendar"></i> Check Out:
            </div>
            <p class="size-16">{{ item.room.checkout | date_to_weekday }}</p>
          </div>
        </div>
        <div class="col-xs-12 col-sm-5">
          <p class="size-14 color-gray-dark">
            <span class="weight-lighter"><i class="fa fa-user color-gray-light-mid"></i> Viajante:</span>&nbsp;<strong>{{ item.room.guests_list }}</strong><br>
            <span class="weight-lighter">{{ item.room.nights }} {{ 'noite' | pluralize(item.room.nights) }},
              {{ item.room.adults }} {{ 'adulto' | pluralize(item.room.adults) }}</span> <br />
            <i class="fa fa-bed fa-fw color-gray-light-mid"></i>&nbsp;{{ item.room.description }} <br />
          </p>
        </div>
      </div>
    </template>
  </detail-dialog>
</template>

<script>
import DetailDialog from '@/components/ui/DetailDialog'

export default {
  props: [
    'item'
  ],
  components: {
    DetailDialog
  }
};
</script>
