<template>
  <form
    @submit.prevent="handleSubmit"
    name="credit-form"
    class="clearfix">
    <div class="col-md-12 clearfix">
      <h4 class="title-manage-account">Novo Cartão</h4>
    </div>
    <!-- Alert Helper -->
    <div class="col-xs-12 clearfix">
      <alert-helper
        :alert="alert"
        :close="true"
        type="danger"></alert-helper>
    </div>
    <!-- // Alert Helper -->
    <!-- Número do cartão -->
    <div class="col-md-10 col-sm-10 col-xs-10">
      <div class="form-group theme-login-form-group">
        <label>Número do cartão*</label>
        <the-mask
          class="form-control"
          type="tel"
          name="number"
          v-model="card.number"
          @input="validate_card"
          :mask="['#### #### #### ####', '#### #### #### ###', '#### #### #### ##']"
          v-validate="'required'"
          :class="{ 'alert-danger': errors.has('number') }"
          onpaste="return false"
          ondrop="return false"/>
        <span class="error-message-forms">{{ errors.first('number') }}</span>
      </div>
    </div>
    <div class="col-md-2 col-sm-2 col-xs-2">
      <div class="box-credit-card-img">
        <img
          class="active card-img-box"
          src="@/assets/img/logo-mastercard.png"
          alt="Masterform"
          title="Masterform"
          v-if="card.card_type === 'mastercard'" />
        <img
          class="active card-img-box"
          src="@/assets/img/logo-visa.png"
          alt="Visa"
          title="Visa"
          v-if="card.card_type === 'visa'" />
        <img
          class="active card-img-box"
          src="@/assets/img/credit-icons/american-express-straight-128px.png"
          alt="Amex"
          title="Amex"
          v-if="card.card_type === 'amex'" />
      </div>
    </div>
    <!-- /Número do cartão -->
    <!-- Nome impresso no cartão -->
    <div class="col-md-10">
      <div class="form-group theme-login-form-group">
        <label>Nome impresso no cartão*</label>
        <input
          class="form-control"
          type="text"
          name="name"
          v-model="card.name"
          v-validate="'required'"
          :class="{ 'alert-danger': errors.has('name') }"
          onpaste="return false"
          ondrop="return false" />
        <span class="error-message-forms">{{ errors.first('name') }}</span>
      </div>
    </div>
    <!-- /Nome impresso no cartão -->
    <!-- Código de Segurança -->
    <div v-show="!getOptionCardVirtual()" class="col-md-2">
      <div class="form-group theme-login-form-group">
        <label for>CVV</label>
        <input
          class="form-control"
          type="tel"
          name="code"
          maxlength="4"
          v-model="card.code"
          :mask="['###']"
          v-validate="'required'"
          :class="{ 'alert-danger': errors.has('code') }"
          onpaste="return false"
          ondrop="return false" />
        <span class="error-message-forms">{{ errors.first('code') }}</span>
      </div>
    </div>
    <!-- /Código de Segurança -->
    <div class="clearfix">
      <!-- Tipo de Cartão -->
      <div class="col-sm-6">
        <div class="form-group theme-login-checkbox clearfix">
          <div>
            <label>Seu cartão é:</label>
          </div>
          <div class="col-md-5 padding-left-off mt-10">
            <p-radio
              class="p-default p-round p-smooth p-plain"
              name="is_corporate"
              :value="false"
              v-model="card.is_corporate"
              checked
              color="primary-o"
              title="Cartão de crédito que esteja em nome de uma pessoa física"
              >&nbsp;Pessoal</p-radio>
          </div>
          <div class="col-md-5 padding-left-off mt-10">
            <p-radio
              class="p-default p-round p-smooth p-plain"
              name="is_corporate"
              :value="true"
              v-model="card.is_corporate"
              color="primary-o"
              title="Cartão de crédito que esteja em nome de uma pessoa jurídica"
              @change="setOptionCardCorporativoNotVirtual(card.is_corporate)"
              >&nbsp;Corporativo</p-radio>
          </div>
        </div>
        <div
          v-show="card.is_corporate"
          class="form-group theme-login-checkbox clearfix">
          <div>
            <label>do tipo:</label>
          </div>
          <div class="col-md-24">
            <div class="col-md-5 padding-left-off  mt-10">
              <p-radio
                class="p-default p-round p-smooth p-plain"
                name="is_corporate_physical"
                :value="true"
                v-model="card.is_corporate_physical"
                color="primary-o"
                title="Cartão de crédito físico"
                @change="setOptionCardCorporativoNotVirtual(true)"
                >&nbsp;Fí­sico</p-radio>
            </div>
            <div class="col-md-5 padding-left-off mt-10">
              <p-radio
                class="p-default p-round p-smooth p-plain"
                name="is_corporate_physical"
                :value="false"
                v-model="card.is_corporate_physical"
                color="primary-o"
                title="Cartão de crédito virtual"
                >&nbsp;Virtual</p-radio>
            </div>
          </div>
        </div>
        <div
          v-show="!card.is_corporate_physical"
          class="form-group theme-login-checkbox clearfix">
          <div>
            <label>especí­fico para:</label>
          </div>
          <div class="col-md-24">
            <div class="col-md-5 padding-left-off mt-10">
              <p-radio
                class="p-default p-round p-smooth p-plain"
                name="corporate_virtual_flight"
                :value="true"
                v-model="card.corporate_virtual_flight"
                color="primary-o"
                title="Cartão corporativo virtual aéreo (não permite o parcelamento)"
                @change="setOptionCardVirtual(card.corporate_virtual_flight, 'flight')"
                >&nbsp;Aéreo</p-radio>
            </div>
            <div class="col-md-6 padding-left-off mt-10">
              <p-radio
                class="p-default p-round p-smooth p-plain"
                name="corporate_virtual_hotel"
                :value="true"
                v-model="card.corporate_virtual_hotel"
                color="primary-o"
                title="Cartão corporativo virtual hotel (não permite o parcelamento)"
                @change="setOptionCardVirtual(card.corporate_virtual_hotel, 'hotel')"
                v-validate="!card.is_corporate_physical && !card.corporate_virtual_hotel && !card.corporate_virtual_flight? 'required|included:true': ''"
                :class="{ 'alert-danger': submitted && errors.has('corporate_virtual_hotel') }">&nbsp;Hotel</p-radio>
            </div>
            <span class="error-message-forms">{{ (errors.first('corporate_virtual_flight') || errors.first('corporate_virtual_hotel')) ? 'Escolha o tipo de cartão virtual': '' }}</span>
          </div>
        </div>
        <div class="form-group mt-30">
          <div class="theme-login-checkbox">
            <div class="theme-search-results-sidebar-section-checkbox-list-item">
              <p-check
                class="p-svg p-default p-fill p-smooth"
                type="checkbox"
                name="is_shared"
                v-model="card.is_shared"
                color="primary">
                <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                  <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white"></path>
                </svg>
                <span class="pretty-space">Liberar para todos os viajantes?</span>
              </p-check>
              <span class="theme-search-results-sidebar-section-checkbox-list-amount"></span>
              <p-check
                class="p-svg p-default p-fill p-smooth"
                type="checkbox"
                name="corporate_parcel_allowed"
                v-model="card.corporate_parcel_allowed"
                color="primary"
                @change="setOptionCardCorporativoNotVirtual(card.corporate_parcel_allowed)"
                v-show="card.is_corporate && card.is_corporate_physical">
                <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                  <path d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z" style="stroke: white;fill:white"></path>
                </svg>
                <span class="pretty-space">É permitido parcelar?</span>
              </p-check>
            </div>
          </div>
        </div>
      </div>
      <!-- /Tipo de Cartão -->
      <!-- Data de validade -->
      <div class="col-sm-6">
        <div>
          <label for>Validade*</label>
        </div>
        <div class="form-group theme-login-form-group row">
          <div class="col-xs-6">
            <select
              class="form-control"
              name="expiry_month"
              v-model="card.expiry_month"
              v-validate="'required'"
              :class="{ 'alert-danger': errors.has('expiry_month') }">
              <option value="">Mês</option>
              <option value="1">01</option>
              <option value="2">02</option>
              <option value="3">03</option>
              <option value="4">04</option>
              <option value="5">05</option>
              <option value="6">06</option>
              <option value="7">07</option>
              <option value="8">08</option>
              <option value="9">09</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
            <span class="error-message-forms">{{ errors.first('expiry_month') }}</span>
          </div>

          <div class="col-xs-6">
            <select
              class="form-control"
              name="expiry_date_1"
              id="id_expiry_date_1"
              v-model="card.expiry_year"
              v-validate="'required'"
              :class="{ 'alert-danger': errors.has('expiry_date_1') }">
              <option value="">Ano</option>
              <option value="2019">2019</option>
              <option value="2020">2020</option>
              <option value="2021">2021</option>
              <option value="2022">2022</option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
              <option value="2031">2031</option>
              <option value="2032">2032</option>
              <option value="2033">2033</option>
            </select>
            <span class="error-message-forms">{{ errors.first('expiry_date_1') }}</span>
          </div>
        </div>
        <div>
          <small>*Campos obrigatórios</small>
        </div>
      </div>
      <!-- /Data de validade -->
    </div>
    <div class="clearfix">
      <div class="col-sm-6 mt-20 mb-20 padding-off">
        <!-- Parent Action -->
        <slot :handleParentAction="() => $emit('handleParentAction')"></slot>
        <!-- // Parent Action -->
        <div class="col-xs-6 padding-off">
          <button
            :disabled="disabled"
            type="submit"
            class="btn-blue btn-block btn-extra-large">
            Salvar
          </button>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { mapActions, mapState } from 'vuex';
/* TODO - MOVE BUSSINESS LOGIC TO STORE */
import AlertHelper from '@/components/ui/AlertHelper';

const card_validator = require('card-validator');

export default {
  components: {
    AlertHelper
  },
  data () {
    return {
      card: {
        is_corporate: false,
        is_corporate_physical: true,
        corporate_parcel_allowed: false,
        corporate_virtual_flight: false,
        corporate_virtual_hotel: false,
        /* FROM ORIGINAL MIXINS */
        number: null,
        name: null,
        code: null,
        expiry_month: '',
        expiry_year: '',
        card_type: null,
        is_corporate: false,
        is_shared: null
      },
      invalid: false,
      submitted: false,
      disabled: false,
      alert: ''
    }
  },
  methods: {
    ...mapActions('creditcard', [
      'postCreditcard'
    ]),
    handleParent () {
      if (this.$listeners && this.$listeners.handleParentAction) {
        this.$emit('handleParentAction');
      }
    },
    handleSubmit (evt) {
      this.alert = '';

      this.$validator.validate().then(valid => {
        this.submitted = true;

        if (valid) {
          this.disabled = true;

          this.postCreditcard(this.card)
            .then(this.handleParent)
            .catch(error => {
              let last_error = false;

              if (error.message) {
                last_error = error.message;
              } else {
                for (let key in error) {
                  let error_field = error[key];
                  error_field.forEach(function(error, idx) {
                    if (last_error == false) {
                      last_error = error;
                    }
                  });
                }
              }

              if (last_error) {
                this.alert = last_error;
              }
              this.disabled = false;
            });
        }
      });
    },
    validate_card () {

      if (this.card.number.length < 4) {
        this.card.card_type = null;
        this.invalid = false;
      }
      if (this.card.number.length > 4) {
        let numberValidation = card_validator.number(this.card.number);

        if (!numberValidation.isPotentiallyValid) {
          this.invalid = true;
        }

        if (numberValidation.card) {
          this.card.card_type = numberValidation.card.type;
          if (this.card.card_type === "american-express") {
            this.card.card_type = "amex";
          }
        }
      }
    },
    setOptionCardPhysical (event) {
      if (event == true){
        this.card.is_corporate = false
        this.corporate_parcel_allowed = false
        this.setOptionCardCorporativoNotVirtual(true)
      }
    },
    setOptionCardCorporativo (event) {
      if (event == true){
        this.card.is_corporate = true
        this.card.corporate_parcel_allowed = true
      }
    },
    getOptionCardVirtual () {
      if ((!this.card.is_corporate_physical) && (this.card.corporate_virtual_flight || this.card.corporate_virtual_hotel)){
        return true
      }else{
        return false
      }
    },
    setOptionCardVirtual (event, typeOption) {
      if (event == true){
        this.card.is_corporate = true
        this.card.is_corporate_physical = false
        this.card.corporate_parcel_allowed = false
        this.card.code = '123'
        if (typeOption == 'flight'){
          this.card.corporate_virtual_hotel = false
        }else if (typeOption == 'hotel'){
          this.card.corporate_virtual_flight = false
        }
      }

    },
    setOptionCardCorporativoNotVirtual (event) {
      if (event == true){
        this.card.is_corporate_physical = true
        this.card.corporate_parcel_allowed = true
        this.card.corporate_virtual_flight = false
        this.card.corporate_virtual_hotel = false
        if (this.card.code == '123'){
          this.card.code = ''
        }
      }
    }
  }
};
</script>

<style scoped>
/* TODO MOVE TO COMMON */
label {
  margin-bottom: 0;
}
.theme-login-form-group {
  margin-bottom: 10px;
}
.form-control {
  height: 48px;
}
</style>
