<template>
  <ul
    class="nav nav-tabs nav-justified travel-tabs"
    role="tablist">
    <li
      v-for="(order, index) in Object.keys(orders)"
      :key="`travel-order-${index}`"
      :class="{'active' : orders[order].slot === active}"
      :aria-selected="orders[order].slot === active"
      role="tab"
      tabindex="0">
      <a
        :href="`#travel-order-content-${index}`"
        @click.prevent="$emit('handleSelected', orders[order].slot)"
        class="travel-tabs-link">
        <i :class="`fa fa-fw fa-${orders[order].ico}`"></i>
        <span>{{ orders[order].name }}</span>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: [
    'orders',
    'active',
    'handleSelected'
  ]
};
</script>

<style lang="scss">
@import '@/scss/views/travel/TravelTabs';
</style>
