<template>
  <div class="tabbable">
    <ul
      class="nav _mh-mob-5 nav-tabs nav-center nav-white nav-active-white nav-no-br nav-lg nav-mob-inline"
      role="tablist"
    >
      <li role="presentation" :class="{'active': selectedTab === 'hotel'}" @click="selectTab('hotel')">
        <a aria-controls="SearchAreaTabs-1" role="tab" data-toggle="tab" href="#SearchAreaTabs-1"
           :aria-expanded="selectedTab === 'hotel'">
          <i class="fa fa-building" aria-hidden="true"></i> Hotéis
        </a>
      </li>
      <li role="presentation" :class="{'active': selectedTab === 'flight'}" @click="selectTab('flight')">
        <a aria-controls="SearchAreaTabs-2" role="tab" data-toggle="tab" href="#SearchAreaTabs-2"
           :aria-expanded="selectedTab === 'flight'">
          <i class="fa fa-plane" aria-hidden="true"></i> Passagens
        </a>
      </li>
      <li role="presentation" :class="{'active': selectedTab === 'car'}" @click="selectTab('car')">
        <a aria-controls="SearchAreaTabs-3" role="tab" data-toggle="tab" href="#SearchAreaTabs-3"
           :aria-expanded="selectedTab === 'car'">
          <i class="fa fa-car" aria-hidden="true"></i> Carros
        </a>
      </li>
    </ul>
    <div class="tab-content _pt-15 _pb-15 tab-content-curved">
      <div class="tab-pane" :class="{'active': selectedTab === 'hotel'}" id="SearchAreaTabs-1" role="tab-panel">
        <hotel-search ref="hotelsearch" :loading="loading"></hotel-search>
      </div>
      <div class="tab-pane" :class="{'active': selectedTab === 'flight'}" id="SearchAreaTabs-2" role="tab-panel">
        <flight-search :flight="flight" ref="flightsearch"></flight-search>
      </div>
      <div class="tab-pane" :class="{'active': selectedTab === 'car'}" id="SearchAreaTabs-3" role="tab-panel">
        <car-search></car-search>
      </div>
    </div>
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import moment from 'moment'
import HotelSearch from "../hotelsearch.vue";
import FlightSearch from "../flightsearch.vue";
import CarSearch from "../carsearch.vue";
import flights from "../../mixins/flights";
import lockscreen from "../lockscreen/index";

export default {
  data(){
    return {
      selectedTab: this.$route.meta.selectedTab,
      predefinedCities: {
        sao_paulo: {
          hotel: 'São Paulo',
          flight: {
            origin: 'CGH',
            destination: 'SDU',
          }
        },
        rio_de_janeiro: {
          hotel: 'Rio de Janeiro',
          flight: {
            origin: 'SDU',
            destination: 'CGH',
          }
        },
        curitiba: {
          hotel: 'Curitiba',
          flight: {
            origin: 'GRU',
            destination: 'BFH',
          }
        },
        belo_horizonte: {
          hotel: 'Belo Horizonte',
          flight: {
            origin: 'GRU',
            destination: 'PLU',
          }
        },
        brasilia: {
          hotel: 'Brasília',
          flight: {
            origin: 'GRU',
            destination: 'BSB',
          }
        },
        recife: {
          hotel: 'Recife',
          flight: {
            origin: 'GRU',
            destination: 'REC',
          }
        },
        porto_alegre: {
          hotel: 'Porto Alegre',
          flight: {
            origin: 'GRU',
            destination: 'POA',
          }
        },
      }
    }
  },
  components: {
    HotelSearch,
    FlightSearch,
    CarSearch,
    lockscreen
  },
  mounted(){
    this.selectedTab = this.$route.meta.selectedTab;
  },
  props: ["flight", "loading"],
  methods: {
    selectTab(tab){
      this.selectedTab = tab;
    },

    addWeekdays(date, days) {
      date = moment(date); // use a clone
      while (days > 0) {
        date = date.add(1, 'days');
        // decrease "days" only if it's a weekday.
        if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
          days -= 1;
        }
      }
      return date;
    },
    setPredefinedCitySearch(city) {
      city = this.predefinedCities[city];
      let dateStart = this.addWeekdays(moment(), 1).format('YYYY-MM-DD');
      let dateEnd = this.addWeekdays(moment(), 1).add(7, 'days').format('YYYY-MM-DD');

      if(this.selectedTab === 'hotel'){
        let urls = {
          city: `/hotels/search/${dateStart}/${dateEnd}/${city.hotel}/1/1`
        };

        this.$router.push(urls.city);
      }
      if(this.selectedTab === 'flight'){
        let origin = city.flight.origin;
        let destination = city.flight.destination;
        let url = `/flights/search/${origin}/${destination}/1/${dateStart}/${dateEnd}`;
        this.$router.push(url);
      }
      if(this.selectedTab === 'car'){
        let dateStart = this.addWeekdays(moment(), 1).add(1, 'hours').format('YYYY-MM-DDTHH:MM');
        let dateEnd = this.addWeekdays(moment(), 1).add(7, 'days').add(1, 'hours').format('YYYY-MM-DDTHH:MM');

        let url = `/cars/search/${city.hotel}/${city.hotel}/${dateStart}/${dateEnd}/`;
        this.$router.push(url);
      }
    }
  },
};
</script>


<style scoped>

</style>
