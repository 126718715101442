<template>
  <div
    class="theme-search-area-section theme-search-area-section-curved theme-search-area-section-fade-white quantity-selector"
    data-increment="Adultos"
  >
    <div class="theme-search-area-section-inner">
      <i class="theme-search-area-section-icon lin lin-people"></i>
      <select class="theme-search-area-section-input">
        <option value="1 Adulto" selected>1 Adulto</option>
        <option value="2 Adultos">2 Adultos</option>
        <option value="3 Adultos">3 Adultos</option>
        <option value="4 Adultos">4 Adultos</option>
        <option value="5 Adultos">5 Adultos</option>
        <option value="6 Adultos">6 Adultos</option>
        <option value="7 Adultos">7 Adultos</option>
        <option value="8 Adultos">8 Adultos</option>
      </select>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      content: this.value
    };
  },
  props: ["value"],
  methods: {
    changeHandle(e) {
      this.$emit("input", this.content);
    }
  },
  mounted() {
    setTimeout(() => {
      $(this.$el).quantitySelector();
    }, 1000);
  }
};
</script>

