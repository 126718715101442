<template>
  <div class="col-xs-12 _pl-0 _pr-0">
    <img
      v-if="image.length > 0"
      :src="image"
      class="thumb-detail-img pull-left _mr-10 _mb-10"
      alt="">
    <p class="size-16 color-gray-light-mid weight-lighter _mb-0">{{ model }}</p>
    <slot name="trigger"></slot>
  </div>
</template>

<script>
export default {
  props: [
    'image',
    'model'
  ]
};
</script>
