<template>
  <div
    v-show="active"
    role="alert"
    :class="`alert alert-${type} pos-rel`">
    <div>
      <slot :alert="alert">
        <span>{{ alert }}</span>
      </slot>
      <button
        v-if="close"
        @click="(active = false)"
        type="button"
        class="close btn-abs"
        aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AlertHelper',
  props: [
    'alert',
    'type',
    'close'
  ],
  data() {
    return {
      active: false
    }
  },
  mounted () {
    this.activate(this.alert)
  },
  watch: {
    alert(data) {
      this.activate(data);
    }
  },
  methods: {
    activate (data) {
      const active = (typeof data === 'string') ? ((data.length > 0) ? true : false) : data;
      this.active = active;
    }
  }
};
</script>

<style lang="scss" scoped>
.alert {
  padding: 10px 24px 8px 10px;
  margin: 0 0 10px 0;
}
.alert-info-inverse {
  color: #333333;
  background-color: #ffffff;
  border-color: #31708f;
}
</style>
