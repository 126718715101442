<template>
  <input
    id="daterange"
    autocomplete="off"
    class="form-control"
    type="text"
    name="daterange"
    value
  >
</template>


<script>
import moment from 'moment';
import _ from 'underscore';

export default {
  props: ['value', 'init', 'singlePick', 'timePick', 'minDate', 'sameDate'],
  data() {
    return {
      content: this.value,
      DATE_FORMAT: this.timePick ? 'YYYY-MM-DDTHH:mm' : 'YYYY-MM-DD',
      BR_DATE_FORMAT: this.timePick ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY',
    }
  },
  methods: {
    setDate(start, end) {
      this.content = {
        dateStart : start,
        dateEnd : end
      };

      this.$emit('input', this.content);
    },
    setInitDate() {
      
      this.$nextTick(function(){
        let daterange = this.$el;
        
        let daterange_inst = $(daterange).data('daterangepicker');
        if(daterange_inst) {
          daterange_inst.remove();
        }

        let opts = {
          autoApply: true,
          showCustomRangeLabel: false,
          alwaysShowCalendars: true,
          autoUpdateInput: false,
          minDate: this.minDate ? this.minDate : moment(),
          singleDatePicker: this.singlePick,
          timePicker: this.timePick,
          timePicker24Hour: true,
          timePickerIncrement: 30,
          // maxSpan: {
          //   days: 30
          // },
          locale: {
            format: this.BR_DATE_FORMAT,
            applyLabel: "Aplicar",
            cancelLabel: "Cancelar",
            daysOfWeek: ["Do", "Se", "Te", "Qa", "Qi", "Sx", "Sa"],
            monthNames: [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro"
            ]
          }
        };

        // @todo: review below if
        let _init = _.clone(this.init);
        if(this.init && Object.keys(this.init)) {
          if (typeof _init.startDate !== "undefined" && typeof _init.endDate !== "undefined") {
            _init.startDate = moment(_init.startDate, this.DATE_FORMAT).format(this.BR_DATE_FORMAT);
            _init.endDate = moment(_init.endDate, this.DATE_FORMAT).format(this.BR_DATE_FORMAT);
            _init.autoUpdateInput = true;

          } else if (typeof _init.startDate !== "undefined") {
            _init.startDate = moment(_init.startDate, this.DATE_FORMAT).format(this.BR_DATE_FORMAT);
            _init.autoUpdateInput = true;
          }
          if (this.singlePick) {
            _init = _.omit(_init, 'endDate')
          } else {
            if(typeof _init.endDate == "undefined") {
              if (this.init.startDate) {
                _init.endDate = moment(this.init.startDate, this.DATE_FORMAT).add(1, 'day').format(this.BR_DATE_FORMAT);
                this.setDate(_init.startDate, _init.endDate);
              }
            }
          }
        }

        opts = _.extend(opts, _init);

        $(daterange).daterangepicker(
          opts,
          (start, end, label) => {
            
            if (!this.sameDate || typeof this.sameDate == 'undefined') {
              if (end.diff(start, 'days') < 1) {
                end = end.add(1, 'days');
              }
            }
            
            // cleaning datetime 
            let noites = moment.duration(moment(end.format(this.DATE_FORMAT)).diff(moment(start.format(
              this.DATE_FORMAT)))).asDays();

            let text = noites <= 1 ? 'noite selecionada' : 'noites selecionadas';
            // $(".info-nights").remove();
            // $("#daterange").parent().append("<label class='col-md-12 text-center info-nights'><b>" + noites + " " + text + "</b></label>");

            let date_start_br = start.format(this.BR_DATE_FORMAT);
            let date_end_br = end.format(this.BR_DATE_FORMAT);
            
            if(!end.isValid()) {
              date_end_br = start.add(1, 'day').format(this.BR_DATE_FORMAT);
            }

            if (!this.singlePick) {
              daterange.value = `${date_start_br} - ${date_end_br}`;
              $('#daterange').data('daterangepicker').setStartDate(date_start_br);
              $('#daterange').data('daterangepicker').setEndDate(date_end_br);
              this.setDate(date_start_br, date_end_br);
            } else {
              daterange.value = `${date_start_br}`;
              $('#daterange').data('daterangepicker').setStartDate(date_start_br);
              this.setDate(date_start_br);
            }

          }
        );
      });
    }
  },
  beforeMount() {
    if(typeof this.init !== "undefined"){
      if (typeof this.init.startDate !== "undefined" && typeof this.init.endDate !== "undefined") {
        let start_date = moment(this.init.startDate, this.DATE_FORMAT);
        let end_date = moment(this.init.endDate, this.DATE_FORMAT);

        if (!this.sameDate || typeof this.sameDate == 'undefined') {
          if (end_date.diff(start_date, 'days') < 1) {
            end_date = end_date.add(1, 'days');
          }
        }
        
        this.setDate(
          start_date.format(this.BR_DATE_FORMAT),
          end_date.format(this.BR_DATE_FORMAT)
        );

      } else if (typeof this.init.startDate !== "undefined") {
        
        let start_date = moment(this.init.startDate, this.DATE_FORMAT);
        this.setDate(start_date.format(this.BR_DATE_FORMAT));
      }

    }
  },
  mounted() {
    this.setInitDate()
  },
  watch: {
    singlePick() {
      this.setInitDate()
    },
    minDate(){
      this.setInitDate()
    }

  }
}

</script>

<style scoped>

</style>


