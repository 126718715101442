<template>
  <div class="card mb-10">
    <div class="card-header" id="headingTho">
      <div class="changes-travalers theme-account-history table-responsive">
        <table class="table table-sm">
          <thead>
          <tr>
            <th></th>
            <th>Item</th>
            <th>Viajante</th>
            <th>Status</th>
            <th>Subtotal</th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>
              <i class="fa fa-car theme-account-history-type-icon"></i>
            </td>
            <td>
              <div>{{ item.vehicle.vehicle.description }}</div>
              <div>
              <small class="theme-account-history-type-icon">Retirada: {{item.vehicle.start_date | datetime_to_human}}</small>
              </div>
              <div>
              <small class="theme-account-history-type-icon">Devolução: {{item.vehicle.end_date | datetime_to_human}}</small>
              </div>
              <div v-if="item.vehicle.reservation_code">
                <small class="theme-account-history-type-icon">Código da Reserva:</small> {{ item.vehicle.reservation_code }}
              </div>
            </td>
            <td>{{item.vehicle.traveler.first_name}} {{item.vehicle.traveler.last_name}}</td>
            <td>
              <div>
                <p>
                  <i class="fa fa-lg"
                    :class="[
                      {'fa-clock-o text-warning': getStatus(item.status) === 'Processando'},
                      {'fa-clock-o text-warning': getStatus(item.status) === 'Reservado'},
                      {'fa-check-circle text-success': getStatus(item.status) === 'Emitido'},
                      {'fa-times-circle text-danger': getStatus(item.status) === 'Cancelado'},
                      {'fa-times-circle text-danger': getStatus(item.status) === 'Pagamento Recusado'},
                      {'fa-clock-o text-warning': getStatus(item.status) === 'Aguardando Aprovação'}
                    ]"></i>&nbsp;{{ getStatus(item.status) }}
                </p>
              </div>
            </td>
            <td>
              <div>{{ item.total|brl_price }}</div>
              <!--<div>-->
              <!--<small class="theme-account-history-type-icon">Valor: 130,00</small>-->
              <!--</div>-->
              <!--<div>-->
              <!--<small class="theme-account-history-type-icon">Impostos e taxas: 13,00</small>-->
              <!--</div>-->
            </td>
            <td>
              <button
                      class="btn btn-primary _tt-uc pull-right"
                      data-toggle="collapse"
                      :data-target="'#car-'+item.id"
                      aria-expanded="false"
                      :aria-controls="'car-'+item.id"
              >+ Mais detalhes
              </button>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div :id="'car-'+item.id" class="collapse">
        <div class="card-body">
          <div class="changes-travalers theme-account-history table-responsive">
            <div class="col-md-12 changes-travalers">
              <div class="row">
                <table class="table table-sm">
                  <thead>
                  <tr>
                    <th></th>
                    <th>Detalhes</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td></td>
                    <td>
                      <div class="col-md-9 mb-20">
                        <div class="theme-search-results-item theme-payment-page-item-thumb">
                          <h5 class="theme-search-results-item-title">
                            <strong>{{item.vehicle.vehicle.description}}</strong>
                          </h5>
                          <div class="row">
                            <div class="col-md-9">
                              <div class="row">
                                <div class="col-md-8 col-sm-8">
                                  <ul class="theme-search-results-item-hotel-room-features">
                                    <li>
                                      <strong>Retirada: </strong>
                                      <p>{{item.vehicle.pickup_agency.address.street}},
                                        {{item.vehicle.pickup_agency.address.number}}
                                        {{item.vehicle.pickup_agency.address.neighborhood? `-
                                        ${item.vehicle.pickup_agency.address.neighborhood}`: ''}}
                                        {{item.vehicle.pickup_agency.address.city}} / {{item.vehicle.pickup_agency.address.province}} -
                                        {{item.vehicle.start_date | datetime_to_human}}</p>

                                    </li>
                                    <li>
                                      <strong>Devolução: </strong>
                                      <p>{{item.vehicle.delivery_agency.address.street}},
                                        {{item.vehicle.delivery_agency.address.number}}
                                        {{item.vehicle.delivery_agency.address.neighborhood? `-
                                        ${item.vehicle.delivery_agency.address.neighborhood}`: ''}}
                                        {{item.vehicle.delivery_agency.address.city}} / {{item.vehicle.delivery_agency.address.province}} -
                                        {{item.vehicle.end_date | datetime_to_human}}</p>
                                    </li>
                                    <li>
                                      <strong>Coberturas:</strong>
                                      <p v-for="coverage in item.vehicle.vehicle.coverages">{{coverage.name}}</p>
                                    </li>
                                    <li>
                                      <strong>Opcionais:</strong>
                                      <p v-for="optional in item.vehicle.vehicle.optionals">{{optional.name}}</p>
                                    </li>
                                  </ul>
                                </div>

                              </div>
                            </div>
                            <div class="col-md-3 padding-left-off">
                              <div class="theme-search-results-item-img-wrap hidden-xs hidden-sm">
                                <img
                                        class="theme-search-results-item-img"
                                        v-bind:src="item.vehicle.vehicle.image_url"
                                        v-bind:alt="item.vehicle.vehicle.description"
                                        v-bind:title="item.vehicle.vehicle.description"
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      <button
                              v-if="canCancel"
                              class="btn btn-danger _tt-uc pull-right"
                              v-on:click="cancelVehicle(item)"
                      >Cancelar item</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import _ from 'underscore'

  export default {
    props: ["item", "fetchParent"],
    methods: {
      getStatus(status) {
        let statuses = {
          1: "Processando",
          2: "Reservado",
          3: "Emitido",
          4: "Cancelado",
          6: "Pagamento Recusado",
          7: "Cancelado",
        };

        if (this.item.approval) {
          if (this.item.approval.status==1) {
            return 'Aguardando Aprovação';
          }
        }

        return statuses[status];
      },
      cancelVehicle(item) {
        this.$swal({
          title: "Tem certeza que deseja cancelar essa reserva?",
          type: "question",
          showConfirmButton: true,
          showCancelButton: true,
        }).then((result) => {
          if(result.value) {
            this.$parent.loading = true;
            this.$http({
              url: "/api/user/order/item/cancel",
              method: "POST",
              data: {
                orderitem_id: item.id
              }
            })
            .then((response) => {
              this.$parent.loading = false;
              this.$swal({
                title: "Pedido cancelado com sucesso!",
                type: "success",
                showConfirmButton: true
              }).then(() =>{
                this.fetchParent();
              });
              this.$ga.event({
                eventCategory: 'Cars',
                eventAction:'Cancel',
                eventValue: 1,
                eventLabel: 'Cancel Car Reserve'
              })
            })
            .catch((error) => {
              this.$parent.loading = false;
              this.$swal({
                title: "Não foi possível cancelar seu pedido, tente novamente",
                type: "error",
                showConfirmButton: true
              });
            });
          }
        })

      },
    },
    computed: {
      canCancel() {
        return _.contains([3, 1], this.item.status);
      }
    }
  };
</script>
