export default {
  /* LATAM */
  'JJ': 'https://www.latam.com/pt_br/planeje-e-compre/conheca-os-perfis-de-tarifas/tarifas-em-voos-nacionais/',
  'LA': 'https://www.latam.com/pt_br/planeje-e-compre/conheca-os-perfis-de-tarifas/tarifas-em-voos-nacionais/',
  '4M': 'https://www.latam.com/pt_br/planeje-e-compre/conheca-os-perfis-de-tarifas/tarifas-em-voos-nacionais/',
  'LU': 'https://www.latam.com/pt_br/planeje-e-compre/conheca-os-perfis-de-tarifas/tarifas-em-voos-nacionais/',
  'PZ': 'https://www.latam.com/pt_br/planeje-e-compre/conheca-os-perfis-de-tarifas/tarifas-em-voos-nacionais/',
  'XL': 'https://www.latam.com/pt_br/planeje-e-compre/conheca-os-perfis-de-tarifas/tarifas-em-voos-nacionais/',
  'LP': 'https://www.latam.com/pt_br/planeje-e-compre/conheca-os-perfis-de-tarifas/tarifas-em-voos-nacionais/',

  /* GOL */
  'G3': 'https://www.voegol.com.br/pt/informacoes/tarifas?_ga=2.180712613.349617456.1583929159-1167600951.1578935819',

  /* Azul */
  'AD': 'https://www.voeazul.com.br/para-sua-viagem/informacoes-para-viajar/remarcacao--cancelamento'
}
