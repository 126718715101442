<template>
  <ul class="list-unstyled">
    <li
      v-for="(coverage, index) in coverages"
      :key="`car-coverage-${index}`"
      class="_pt-5 size-14">
      <template v-if="coverage.required">
        {{ coverage.name }}
        <span class="pull-right">&nbsp;{{ coverage.charge.unit_value | brl_price }}<span class="color-gray-light-mid size-14">/dia</span></span>
        <p class="_mb-0 _mt-0 size-12 weight-lighter">
          ({{coverage.code}}) {{ coverage.description }}
        </p>
      </template>
      <template v-else>
        <p-check
          class="p-switch p-slim p-smooth p-plain"
          name
          id
          color="primary"
          @change="$emit('handleCoverage', $event, coverage)">
          {{ coverage.name }}
        </p-check>
        <span class="pull-right">&nbsp;{{ coverage.charge.unit_value | brl_price }}<span class="color-gray-light-mid size-14">/dia</span></span>
        <p class="_mb-0 _mt-0 size-12 weight-lighter">
          ({{coverage.code}}) {{ coverage.description }}
        </p>
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  props: [
    'coverages'
  ],
  mounted () {
    this.coverages.forEach(coverage => {
      if (coverage.required) {
        this.$emit('handleCoverage', true, coverage);
      }
    })
  }
};
</script>
