<template>
  <section :class="[(active ? 'col-sm-9' : 'col-sm-6'), 'clearfix content-hourly']">
    <div
      :class="[(active ? 'col-xs-3 col-sm-3' : 'col-xs-3'), 'text-right']">
      <h2 class="content-hourly-date">{{ content.dateDeparture | date_to_hour }}</h2>
      <p>
        <strong class="content-hourly-origin">{{ content.origin }}</strong>
        <small class="content-hourly-city">{{ content.originCity }}</small>
      </p>
    </div>
    <div
      :class="[(active ? 'col-xs-6 col-sm-6' : 'col-xs-6'), 'text-center']">
      <h2 class="align-center content-hourly-traveltime">
        <i class="fa fa-clock-o"></i>&nbsp;{{ content.travelTime }}
      </h2>
      <div class="content-hourly-plane">
        <i class="fa fa-plane fa-fw ico-plane"></i>
      </div>
      <h3
        v-if="content.direct"
        class="content-hourly-segments true">direto</h3>
      <h3
        v-else
        class="content-hourly-segments false">
        {{ content.segmentsLen - 1 }} {{ 'paradas'| pluralize(content.segmentsLen - 1) }}
      </h3>
    </div>
    <div
      :class="[(active ? 'col-xs-3 col-sm-3' : 'col-xs-3'), 'text-left']">
      <h2 class="content-hourly-date">
        {{ content.dateArrival | date_to_hour }}<sup v-if="dateDiff > 0">+{{ dateDiff }}</sup>
      </h2>
      <p>
        <strong class="content-hourly-origin">{{ content.destination }}</strong>
        <small class="content-hourly-city">{{ content.destinationCity }}</small>
      </p>
    </div>
  </section>
</template>

<script>
export default {
  props: [
    'active',
    'content'
  ],
  data() {
    return {
      activeDelayed: false,
      dateDiff: moment(this.content.dateArrival, 'YYYY, MM, DD').diff(moment(this.content.dateDeparture, 'YYYY, MM, DD'), 'days')
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/ContentHourly';
</style>
