<template>
  <li
    :class="{'active' : active}"
    class="price-item">
    <div
      :class="[
        {'active' : active},
        `price-item-rule-${indexKey}`
      ]">
      <div
        class="clearfix price-item-box"
        :class="{'active' : active}">
        <i
          class="fa fa-suitcase ico-suitcase"
          :class="{ 'ico-disabled': price.baggage_included }"></i>
          <span style="font-size: 12px"> x{{ price.baggage_pieces }} </span>

        <!-- Price Detail // FARE RULES -->
        <price-detail
          :index="price.id"
          :links="price.links"
          :carrier="carrier"></price-detail>
        <!-- // Price Detail // FARE RULES -->

        {{ price.brand.name }}

        <button
          @click="$emit('handlePrices', price)"
          :class="{'active' : active}"
          :disabled="disabled"
          :title="disabled ? `A tarifa do voo de volta deve ser a mesma do voo de ida`:''"
          class="price-item-trigger pull-right"
          type="button">
          Selecionar<template v-if="disabled">*</template>
        </button>

        <strong
          v-if="!hideValue"
          class="pull-right"
          :class="{'price-item-disabled' : disabled}">
          {{ price.base | brl_price }}
        </strong>
      </div>
    </div>
  </li>
</template>

<script>
import PriceDetail from '@/views/flights/search/PriceDetail'

export default {
  components: {
    PriceDetail
  },
  props: [
    'price',
    'active',
    'indexKey',
    'disabled',
    'hideValue',
    'carrier'
  ]
};
</script>

<style lang="scss">
@import '@/scss/views/flights/search/PriceItem';
</style>
