<template>
  <div>
    <slot
      name="trigger"
      :active="active"
      :openModal="openModal"></slot>
    <div class="ui-modal-dialog">
      <div
        :class="[
          active ? `modal modal-open show in` : `modal`
        ]"
        tabindex="-1"
        role="dialog">
        <div
          @click="closeModal"
          :class="active ? `ui-modal-dialog-backdrop modal-backdrop in`: ``"></div>
        <div
          :class="{ 'modal-lg' : large }"
          class="modal-dialog"
          role="document">
          <div class="ui-modal-dialog-content modal-content">
            <div class="ui-modal-dialog-header">
              <slot
                name="header"
                :closeModal="closeModal"
                :active="active"></slot>
            </div>
            <div class="ui-modal-dialog-body modal-body">
              <slot
                name="body"
                :closeModal="closeModal"
                :active="active"></slot>
            </div>
          </div>
          <button
            @click="closeModal"
            type="button"
            class="ui-modal-dialog-close"
            aria-label="Close"
            :aria-hidden="active ? true : false">
            x
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'handleClose',
    'large'
  ],
  data () {
    return {
      active: false
    }
  },
  watch: {
    handleClose (data) {
      this.active = data;
    }
  },
  methods: {
    closeModal() {
      this.active = false;
    },
    openModal() {
      this.active = true;
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/components/ui/ModalDialog';
</style>
