<template>
  <div :class="{'active' : active}">
    <slot
      name="trigger"
      :active="active"
      :handleCollapse="handleCollapse"></slot>
    <slot
      name="content"
      :active="active"
      :handleParentAction="handleParentAction"></slot>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: false
    }
  },
  methods: {
    handleCollapse () {
      this.active = !this.active;
    },
    handleParentAction (data) {
      this.$emit('handleParentAction', data);
    }
  }
};
</script>

<style lang="scss">
@import '@/scss/components/ui/CollapseItem';
</style>
