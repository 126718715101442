<template>
    <div class="col-md-8 col-md-offset-2">
        <div class="spinner theme-loading-spinner">
            <div class="spinner-grid-dots">
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
        <!--
        <div class="theme-loading-body">
            <h2 class="theme-loading-title">Aguarde</h2>
            <p class="theme-loading-subtitle">Carregando</p>
        </div>
        -->
    </div>
</template>

<script>
    export default {
        name: "wait-loader"
    }
</script>

<style scoped>

</style>