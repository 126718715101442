<template>
  <div>
    <!-- Menu Superior -->
    <div class="theme-hero-area theme-hero-area-primary">
      <div class="theme-hero-area-bg-wrap">
        <div
          class="theme-hero-area-bg ws-action"
          :style="{'background-image': 'url(' + require('@/assets/img/bg-home-car.jpg') + ')'}"
          data-parallax="true"
        ></div>
        <div class="theme-hero-area-mask theme-hero-area-mask-strong"></div>
      </div>
      <div class="theme-hero-area-body">
        <div class="container">
          <div class="theme-search-area theme-search-area-white mt-90 mb-20">
            <div class="row">
              <div class="col-md-12">
                <!-- <flight-search :flight="flight"></flight-search> -->
                <box-search ref="boxsearch" :flight="flight"></box-search>
              </div>
              <div class="col-md-12 mt-30">
                <div class="theme-search-area-header theme-search-area-header-sm">
                  <div class="visible-xs">
                  <h1 class="theme-search-area-title">{{total_results}} Veículos Encontrados</h1>
                  <p class="theme-search-area-subtitle">
                    <i class="fa fa-calendar-check-o"></i>
                    &nbsp;Retirada: {{start_date | datetime_to_human}}
                  </p>
                  <p class="theme-search-area-subtitle">
                    <i class="fa fa-calendar-check-o"></i>
                    &nbsp;Devolução: {{end_date | datetime_to_human}}
                  </p>
                  </div>
                  <div class="hidden-xs">
                    <p class="theme-search-area-subtitle">
                      <strong>{{total_results}}</strong> Veículos Encontrados &nbsp;&nbsp;|&nbsp;&nbsp;
                      <!--<strong>{{ city_name }}</strong> para-->
                      <!--<strong>{{ nights }} {{ nightsLabel }}</strong>&nbsp;&nbsp;|&nbsp;&nbsp;-->
                      <strong>
                        <i class="fa fa-calendar-check-o"></i>&nbsp;Retirada:
                      </strong>
                      {{start_date | datetime_to_human}}&nbsp;&nbsp;
                      <i class="fa fa-arrows-h"></i>&nbsp;&nbsp;
                      <strong>
                        <i class="fa fa-calendar-check-o"></i>&nbsp;Devolução:
                      </strong>
                      {{end_date | datetime_to_human}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- /Menu Superior -->
    <!-- Conteudo -->
    <div class="theme-page-section theme-page-section-gray">
      <div class="container">
        <div class="row row-col-static" id="sticky-parent" data-gutter="20">
          <div class="col-md-3">
            <div class="sticky-col _mob-h">
              <div class="theme-search-results-sidebar">
                <!-- <div class="theme-search-results-sidebar-map-view _mb-10 theme-search-results-sidebar-map-view-primary">
                  <a class="theme-search-results-sidebar-map-view-link" href="#"></a>
                  <div class="theme-search-results-sidebar-map-view-body">
                    <i class="fa fa-map-marker theme-search-results-sidebar-map-view-icon"></i>
                    <p class="theme-search-results-sidebar-map-view-sign">Map View</p>
                  </div>
                  <div class="theme-search-results-sidebar-map-view-mask"></div>
                </div>-->
                <div
                  class="theme-search-results-sidebar-sections _mb-20 _br-2 _b-n theme-search-results-sidebar-sections-white-wrap"
                >
                  <div class="theme-search-results-sidebar-section">
                    <h5 class="theme-search-results-sidebar-section-title">Preço</h5>
                    <div class="theme-search-results-sidebar-section-price">
                      <vue-slider
                        v-model="filter.price"
                        ref="slider"
                        :min="0"
                        :max="10000"
                        :tooltipDir="['bottom', 'bottom']"
                        :formatter="'R$ {value}'"
                        :speed="1"
                      ></vue-slider>
                    </div>
                  </div>
                  <div class="theme-search-results-sidebar-section" v-if="groups.length">
                    <h5 class="theme-search-results-sidebar-section-title">Categoria</h5>
                    <div class="theme-search-results-sidebar-section-checkbox-list">
                      <div class="theme-search-results-sidebar-section-checkbox-list-items">
                        <div>
                          <p-check
                            class="p-svg p-default p-fill p-smooth mt-10"
                            color="primary"
                            type="checkbox"
                            v-for="group in groups"
                            v-on:change="setGroupFilter(group.code)"
                          >
                            <svg slot="extra" class="svg svg-icon" viewBox="0 0 20 20">
                              <path
                                d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
                                style="stroke: white;fill:white"
                              ></path>
                            </svg>
                            <span class="pretty-space">{{group.description}}</span>
                          </p-check>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="theme-ad">
                <a class="theme-ad-link" href="#"></a>
                <p class="theme-ad-sign">Propaganda</p>
                <img
                  class="theme-ad-img"
                  src="@/assets/img/banner-gol.png"
                  alt="Tarifas imperdiveis para seu destino"
                  title="Propaganda"
                >
              </div> -->
              <div class="theme-ad">
                <a class="theme-ad-link" href="#"></a>
                <p class="theme-ad-sign">Propaganda</p>
                <img
                  class="theme-ad-img"
                  src="@/assets/img/banner-gol.png"
                  alt="Tarifas imperdiveis para seu destino"
                  title="Propaganda"
                >
              </div>
            </div>
          </div>
          <div class="col-md-9">
            <div class="theme-search-results">

              <!-- item car -->
              <template v-if="!results.length && loading==false">
                <div class="theme-search-results-item theme-search-results-item-">
                  <div class="theme-search-results-item-preview">
                    Nenhum carro encontrado. Por favor refaça sua busca.
                  </div>
                </div>
              </template>
              <div
                class="theme-search-results-item _mb-10 _b-n theme-search-results-item-rounded theme-search-results-item-"
                v-for="item in filteredCars(results)" v-else
              >
                <div class="theme-search-results-item-preview">
                  <div class="row" data-gutter="20">
                    <div class="col-md-3">
                      <div class="theme-search-results-item-img-wrap">
                        <img
                          class="theme-search-results-item-img"
                          v-bind:src="item.vehicle.image_url"
                          v-bind:alt="item.vehicle.description"
                        >
                        <p class="theme-search-results-item-price-sign text-center">{{item.vehicle.description}}</p>
                      </div>
                      <ul class="theme-search-results-item-car-feature-list mb-10">
                        <li>
                          <i class="fa fa-male" title="Ocupantes"></i>
                          <span>{{item.vehicle.passengers || 0}}</span>
                        </li>
                        <li>
                          <i class="fa fa-cog" title="Câmbio"></i>
                          <span>{{item.vehicle.transmission}}</span>
                        </li>
                        <li v-if="item.vehicle.air_conditioner">
                          <i class="fa fa-snowflake-o" title="Ar-condicionado"></i>
                          <span>A/C</span>
                        </li>
                        <li>
                          <i class="fa fa-car" title="Portas"></i>
                          <span>{{item.vehicle.doors}} portas</span>
                        </li>
                      </ul>
                    </div>
                    <div class="col-md-7">
                      <h5
                        class="theme-search-results-item-title _fw-b _mb-20 _fs theme-search-results-item-title-lg"
                      >{{item.provider.name}} - {{item.vehicle.model}}</h5>
                      <ul class="theme-search-results-item-car-list">
                        <li>
                          <i class="fa fa-check"></i>Retirada e devolução com tanque abastecido
                        </li>
                        <li>
                          <i class="fa fa-check"></i>Quilometragem ilimitada
                        </li>
                        <li>
                          <i class="fa fa-check"></i>Cancelamento gratuito
                        </li>
                      </ul>
                    </div>

                    <div class="col-md-2">
                      <div class="theme-search-results-item-book">
                        <div class="theme-search-results-item-price">
                          <p class="theme-search-results-item-car-location-subtitle">Diária</p>
                          <p class="theme-search-results-item-price-tag">{{item.day_rate | brl_price}}</p>
                          <!-- <p class="theme-search-results-item-price-sign">por dia</p> -->
                        </div>
                        <!-- todo remove <router-link :to="{name: 'carsdetails', params: {hash: request.hash, vehicle_id: item.vehicle.id}}"
                          class="btn btn-primary btn-block theme-search-results-item-price-btn"
                        >Reservar</router-link> -->
                        <router-link :to="{name: 'cardetails', params: {hash: request.hash, vehicle_id: item.vehicle.id}}"
                          class="btn btn-primary btn-block theme-search-results-item-price-btn"
                        >Reservar</router-link>
                      </div>
                    </div>
                    <div class="col-md-7">
                      <div class="theme-search-results-item-car-location">
                        <i
                          class="fa fa-calendar-check-o theme-search-results-item-car-location-icon"
                          title="Retirada"
                        ></i>
                        <div class="theme-search-results-item-car-location-body">
                          <p class="theme-search-results-item-car-location-subtitle">Retirada</p>
                          <p
                            class="theme-search-results-item-car-location-title"
                          >{{item.pickup_agency.address}}, {{item.pickup_agency.address_number}}
                            {{item.pickup_agency.neighborhood? `- ${item.pickup_agency.neighborhood}`: ''}}
                            {{item.pickup_agency.city.name}} / {{item.pickup_agency.city.state.initials}}</p>
                        </div>
                      </div>
                      <div class="theme-search-results-item-car-location">
                        <i
                          class="fa fa-calendar-check-o theme-search-results-item-car-location-icon"
                          title="Devolução"
                        ></i>
                        <div class="theme-search-results-item-car-location-body">
                          <p class="theme-search-results-item-car-location-subtitle">Devolução</p>
                          <p
                            class="theme-search-results-item-car-location-title"
                          >{{item.delivery_agency.address}}, {{item.delivery_agency.address_number}}
                            {{item.delivery_agency.neighborhood? `- ${item.delivery_agency.neighborhood}`: ''}}
                            {{item.delivery_agency.city.name}} / {{item.delivery_agency.city.state.initials}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <a class="btn _tt-uc _fs-sm btn-white btn-block btn-lg" href="#">Mais resultados</a> -->
          </div>
          <!-- <div class="col-md-2-5">
            <div class="sticky-col _mob-h">
              <div class="theme-ad _mb-20">
                <a class="theme-ad-link" href="#"></a>
                <p class="theme-ad-sign">Propaganda</p>
                <img
                  class="theme-ad-img"
                  src="@/assets/img/300x500.png"
                  alt="Image Alternative text"
                  title="Image Title"
                >
              </div>
              <div class="theme-ad">
                <a class="theme-ad-link" href="#"></a>
                <p class="theme-ad-sign">Propaganda</p>
                <img
                  class="theme-ad-img"
                  src="@/assets/img/300x600.png"
                  alt="Image Alternative text"
                  title="Image Title"
                >
              </div>
            </div>
          </div>-->
        </div>
      </div>
    </div>

    <!-- /Conteudo -->
    <lockscreen :loading="loading"></lockscreen>
  </div>
</template>

<script>
import moment from "moment";
import _ from "underscore";
import BoxSearch from "../../components/template/boxsearch.vue";

import lockscreen from "../../components/lockscreen/index";
import spinner from "../../components/spinner/plane-spinner.vue";

import vueSlider from "vue-slider-component";
import flights from "../../mixins/flights";
import travelers from "../../mixins/travelers";

export default {
  data(){
    return {
      pickup_location: null,
      delivery_location: null,
      start_date: null,
      end_date: null,
      results: [],
      groups: [],
      next: null,
      loading: false,
      request: null,
      total_results: 0,
      filter: {
        price: [0, 3500],
        groups: []
      },
    }
  },
  components: {
    lockscreen,
    spinner,
    vueSlider,
    BoxSearch
  },
  mixins: [flights, travelers],
  methods: {
    changeData() {
      this.pickup_location = this.$route.params.pickup_location;
      this.delivery_location = this.$route.params.delivery_location;
      this.start_date = this.$route.params.datetime_start;
      this.end_date = this.$route.params.datetime_end;
      this.total_results = 0
      // let dt_start = moment(this.dt_start, "YYYY-MM-DD");
      // let dt_end = moment(this.dt_end, "YYYY-MM-DD");
      // this.nights = moment.duration(dt_end.diff(dt_start)).asDays();
    },
    fetchData() {
      this.loading = true;
      this.changeData();

      let url = `/api/vehicle/availability/`;

      this.$http({
        url: url,
        method: "GET",
        params: {
          pickup_location: this.pickup_location,
          delivery_location: this.delivery_location,
          start_date: this.start_date,
          end_date: this.end_date
        }
      }).then(response => {
        if (response.data.results.length){
          this.results = response.data.results;
          this.groups = response.data.groups;
          this.next = response.next;
          this.total_results = response.data.total_results;
          this.request = response.data.request;
        } else {
          this.results = [];
        }
        this.loading = false;
      });
    },
    filteredCars(items) {
      return items.filter(vehicle => {
        let response = true;

        if( vehicle.total_rate >= this.filter.price[0] && vehicle.total_rate <= this.filter.price[1]){
          if (this.filter.groups.length){
            response = this.filter.groups.includes(vehicle.vehicle.code);
          }
        } else {
          response = false;
        }
        return response
      });
    },
    setGroupFilter(group){
      if (this.filter.groups.includes(group)){
        this.filter.groups.splice(this.filter.groups.indexOf(group), 1)
      } else {
        this.filter.groups.push(group)
      }
    }
  },
  mounted() {
    this.fetchData();
    $("#price-slider").ionRangeSlider({
      type: "double",
      prefix: "$"
    });

    $("#price-slider-mob").ionRangeSlider({
      type: "double",
      prefix: "$"
    });
  },
  created() {
    this.$ga.page('/');
  },
  watch: {
    $route(to, from) {
      this.fetchData();
    }
  }
};
</script>

<style scoped>
.theme-search-area-header-sm .theme-search-area-subtitle {
  margin-top: 4px;
  font-size: 14px;
  font-weight: 100;
  opacity: 1;
}
.theme-search-results-sort-title {
  font-size: 13px;
  margin: 0;
  margin-right: 14px;
  margin-left: 14px;
  float: left;
  font-weight: 400;
  letter-spacing: 0;
  color: #4c4c4c;
}
.theme-sidebar-section-charges-item-price {
  position: relative;
  top: 0;
  right: 0;
  font-size: 13px;
  font-weight: bold;
  line-height: 1em;
  text-align: right;
}
.theme-search-results-item-flight-details-schedule-dots-fares {
  width: 100%;
  border-top: 2px dotted #000;
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
  filter: alpha(opacity=10);
}
.pb-lr-5 {
  padding-left: 5px;
  padding-right: 5px;
}
.theme-search-results-sort-list li {
  float: left;
  border-left: 0;
}
.theme-search-results-sort-list li:last-child {
  border-right: 0;
}
.theme-search-results-item-flight-section-airline-title {
  font-size: 12px;
  font-weight: bold;
}
.theme-search-results-sort-title {
  font-size: 13px;
  margin: 0;
  margin-right: 0;
  margin-left: 0;
  float: left;
  font-weight: 400;
  letter-spacing: 0;
  color: #4c4c4c;
}

@media only screen and (min-width: 320px) {
  .br-2px {
    border-bottom: 1px dashed #b3b3b3;
    border-right: 0;
  }
}
@media only screen and (min-width: 480px) {
  .br-2px {
    border-bottom: 1px dashed #b3b3b3;
    border-right: 0;
  }
}
@media only screen and (min-width: 768px) {
  .br-2px {
    border-bottom: 0;
    border-right: 1px dashed #b3b3b3;
  }
}
@media only screen and (min-width: 992px) {
  .br-2px {
    border-bottom: 0;
    border-right: 1px dashed #b3b3b3;
  }
}
@media only screen and (min-width: 1200px) {
  .br-2px {
    border-bottom: 0;
    border-right: 1px dashed #b3b3b3;
  }
}
.theme-search-results-sort-price-box {
  color: #4c4c4c;
}
.mt-60 {
  margin-top: 60px;
}
.theme-search-results-item-car-location-icon {
    display: block;
    float: left;
    font-size: 20px;
    margin-right: 10px;
    opacity: 0.75;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=75)";
    filter: alpha(opacity=75);
}
.theme-search-results-item-car-feature-list > li span {
    font-size: 12px;
    display: block;
}
</style>
