<template>
  <svg
    y="0px"
    width="100%"
    height="80px"
    viewBox="0 0 360.3 210.3"
    style="enable-background:new 0 0 360.3 210.3;"
    xml:space="preserve"
  >
    <g>
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 180 105"
        to="360 180 105"
        dur="1s"
        repeatCount="indefinite"
      ></animateTransform>

      <g>
        <ellipse
          transform="matrix(0.8907 -0.4546 0.4546 0.8907 -17.6931 84.4725)"
          cx="166.8"
          cy="79"
          rx="2.1"
          ry="2.1"
        ></ellipse>
        <ellipse
          transform="matrix(0.7774 -0.629 0.629 0.7774 -15.8181 120.0209)"
          class="st0"
          cx="161.7"
          cy="82.4"
          rx="2.1"
          ry="2.1"
        ></ellipse>
        <ellipse
          transform="matrix(0.6335 -0.7737 0.7737 0.6335 -9.2559 153.5523)"
          class="st1"
          cx="157.5"
          cy="86.5"
          rx="2"
          ry="2"
        ></ellipse>
        <ellipse
          transform="matrix(0.4672 -0.8842 0.8842 0.4672 1.352 185.0684)"
          class="st2"
          cx="154.2"
          cy="91.4"
          rx="1.9"
          ry="1.9"
        ></ellipse>
        <ellipse
          transform="matrix(0.2848 -0.9586 0.9586 0.2848 15.957 214.9548)"
          class="st3"
          cx="152"
          cy="96.8"
          rx="1.9"
          ry="1.9"
        ></ellipse>

        <ellipse
          transform="matrix(9.102923e-02 -0.9958 0.9958 9.102923e-02 35.1398 243.4684)"
          class="st4"
          cx="150.9"
          cy="102.5"
          rx="1.8"
          ry="1.8"
        ></ellipse>
        <ellipse
          transform="matrix(0.994 -0.1096 0.1096 0.994 -10.9666 17.2048)"
          class="st5"
          cx="151"
          cy="108.3"
          rx="1.7"
          ry="1.7"
        ></ellipse>

        <ellipse
          transform="matrix(0.9507 -0.3103 0.3103 0.9507 -27.9125 52.8722)"
          class="st6"
          cx="152.3"
          cy="114.2"
          rx="1.6"
          ry="1.6"
        ></ellipse>

        <ellipse
          transform="matrix(0.8657 -0.5005 0.5005 0.8657 -39.1882 93.5601)"
          class="st7"
          cx="154.8"
          cy="119.8"
          rx="1.6"
          ry="1.6"
        ></ellipse>

        <ellipse
          transform="matrix(0.744 -0.6682 0.6682 0.744 -42.8037 137.7359)"
          class="st8"
          cx="158.3"
          cy="124.7"
          rx="1.5"
          ry="1.5"
        ></ellipse>

        <ellipse
          transform="matrix(0.5937 -0.8047 0.8047 0.5937 -37.453 183.2092)"
          class="st9"
          cx="162.7"
          cy="128.7"
          rx="1.4"
          ry="1.4"
        ></ellipse>

        <ellipse
          transform="matrix(0.4229 -0.9062 0.9062 0.4229 -22.5277 227.9658)"
          class="st10"
          cx="167.7"
          cy="131.7"
          rx="1.4"
          ry="1.4"
        ></ellipse>

        <ellipse
          transform="matrix(0.2373 -0.9714 0.9714 0.2373 2.3118 270.1302)"
          class="st11"
          cx="173.2"
          cy="133.6"
          rx="1.3"
          ry="1.3"
        ></ellipse>

        <ellipse
          transform="matrix(4.129524e-02 -0.9991 0.9991 4.129524e-02 37.2594 307.6461)"
          class="st12"
          cx="178.9"
          cy="134.4"
          rx="1.2"
          ry="1.2"
        ></ellipse>

        <ellipse
          transform="matrix(0.9871 -0.1601 0.1601 0.9871 -19.081 31.3222)"
          class="st13"
          cx="184.8"
          cy="134.1"
          rx="1.2"
          ry="1.2"
        ></ellipse>

        <ellipse
          transform="matrix(0.9332 -0.3593 0.3593 0.9332 -34.871 77.3299)"
          class="st14"
          cx="190.6"
          cy="132.5"
          rx="1.1"
          ry="1.1"
        ></ellipse>
        <ellipse
          transform="matrix(0.8384 -0.545 0.545 0.8384 -38.9856 127.8461)"
          class="st15"
          cx="196.1"
          cy="129.7"
          rx="1"
          ry="1"
        ></ellipse>
        <ellipse
          transform="matrix(0.7087 -0.7055 0.7055 0.7087 -30.3264 178.3315)"
          class="st16"
          cx="200.8"
          cy="125.9"
          rx="1"
          ry="1"
        ></ellipse>

        <ellipse
          transform="matrix(0.5527 -0.8334 0.8334 0.5527 -9.6366 224.7354)"
          class="st17"
          cx="204.5"
          cy="121.3"
          rx="0.9"
          ry="0.9"
        ></ellipse>

        <ellipse
          transform="matrix(0.3777 -0.9259 0.9259 0.3777 21.3755 264.2137)"
          class="st18"
          cx="207.3"
          cy="116.2"
          rx="0.8"
          ry="0.8"
        ></ellipse>

        <ellipse
          transform="matrix(0.1891 -0.982 0.982 0.1891 60.7486 294.8562)"
          class="st19"
          cx="208.9"
          cy="110.6"
          rx="0.8"
          ry="0.8"
        ></ellipse>

        <ellipse
          transform="matrix(1 -8.791751e-03 8.791751e-03 1 -0.9137 1.8453)"
          class="st20"
          cx="209.4"
          cy="104.8"
          rx="0.7"
          ry="0.7"
        ></ellipse>
        <ellipse
          transform="matrix(0.9776 -0.2105 0.2105 0.9776 -16.1562 46.1666)"
          class="st21"
          cx="208.8"
          cy="99"
          rx="0.6"
          ry="0.6"
        ></ellipse>

        <ellipse
          transform="matrix(0.9132 -0.4075 0.4075 0.9132 -20.0251 92.3903)"
          class="st22"
          cx="206.9"
          cy="93.2"
          rx="0.6"
          ry="0.6"
        ></ellipse>

        <ellipse
          transform="matrix(0.8089 -0.5879 0.5879 0.8089 -12.7168 136.6051)"
          class="st23"
          cx="203.8"
          cy="87.9"
          rx="0.5"
          ry="0.5"
        ></ellipse>

        <ellipse
          transform="matrix(0.6719 -0.7407 0.7407 0.6719 3.7804 175.3735)"
          class="st24"
          cx="199.8"
          cy="83.4"
          rx="0.4"
          ry="0.4"
        ></ellipse>

        <ellipse
          transform="matrix(0.5105 -0.8599 0.8599 0.5105 26.7707 206.905)"
          class="st25"
          cx="195.1"
          cy="79.9"
          rx="0.3"
          ry="0.3"
        ></ellipse>

        <ellipse
          transform="matrix(0.3317 -0.9434 0.9434 0.3317 53.7849 230.8876)"
          class="st26"
          cx="189.8"
          cy="77.5"
          rx="0.3"
          ry="0.3"
        ></ellipse>

        <ellipse
          transform="matrix(0.1403 -0.9901 0.9901 0.1403 83.0076 247.8171)"
          class="st27"
          cx="184.2"
          cy="76.1"
          rx="0.2"
          ry="0.2"
        ></ellipse>

        <ellipse
          transform="matrix(0.9983 -5.913283e-02 5.913283e-02 0.9983 -4.1742 10.6807)"
          class="st28"
          cx="178.4"
          cy="75.9"
          rx="0.1"
          ry="0.1"
        ></ellipse>

        <ellipse
          transform="matrix(0.9654 -0.2606 0.2606 0.9654 -14.0591 47.6125)"
          class="st29"
          cx="172.5"
          cy="76.8"
          rx="0.1"
          ry="0.1"
        ></ellipse>
        <ellipse
          transform="matrix(0.8907 -0.4546 0.4546 0.8907 -17.6931 84.4725)"
          class="st30"
          cx="166.8"
          cy="79"
          rx="0"
          ry="0"
        ></ellipse>
      </g>
      <g>
        <g>
          <defs>
            <rect
              id="SVGID_1_"
              x="170.9"
              y="68"
              transform="matrix(0.7107 -0.7035 0.7035 0.7107 -2.1768 149.0406)"
              width="18.5"
              height="18.4"
            ></rect>
          </defs>
          <clipPath id="SVGID_2_">
            <use xlink:href="#SVGID_1_" style="overflow:visible;"></use>
          </clipPath>
          <path
            class="st31"
            d="M189.7,79c1.3-0.2,2.3-0.6,2.9-1.1c0.2-0.2,0.3-0.3,0.3-0.5c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1
                                      c0-0.2-0.1-0.3-0.3-0.5c-0.5-0.5-1.6-1-2.8-1.1c-1-0.2-2.2-0.1-3.9-0.1c-0.3,0-0.6,0-0.9,0l-1.1-2l0,0c0.1,0,0.3-0.1,0.3-0.1
                                      c0.1-0.1,0.1-0.2,0.1-0.3l0-0.6c0-0.1-0.1-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1l-0.8,0l-0.2-0.3c0.1,0,0.2-0.1,0.3-0.1
                                      c0.1-0.1,0.1-0.2,0.1-0.3l0-0.6c0-0.1-0.1-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1l-0.8,0l-1.1-2.1c0,0-0.1-0.1-0.1-0.1
                                      c-0.1-0.1-0.3-0.2-0.4-0.2l-1.1,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.2,0.3-0.1,0.5l1.4,5.2c0,0,0,0.1,0,0.1l0,1.9
                                      c-1.8,0-3.5,0.1-4.9,0.3c-0.1,0-0.3,0-0.4,0l-2.3-2.8c0,0,0,0,0,0c-0.1-0.1-0.3-0.2-0.4-0.2l-0.5,0c-0.1,0-0.2,0-0.3,0.1
                                      c-0.1,0.1-0.2,0.3-0.1,0.5l0.9,2.7c0,0,0,0.1,0,0.1l0,0.3c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0.1-0.2,0.3-0.2,0.5l0,0l0,0
                                      c0,0.2,0.1,0.3,0.2,0.5c0.1,0.1,0.2,0.2,0.4,0.3l0,0.3c0,0,0,0.1,0,0.1l-1,2.7c-0.1,0.2,0,0.4,0.1,0.5l0,0
                                      c0.1,0.1,0.2,0.1,0.3,0.1l0.5,0c0.2,0,0.3-0.1,0.4-0.2c0,0,0,0,0,0l2.4-2.8c0.1,0,0.2,0,0.4,0.1c1.4,0.2,3.1,0.3,4.9,0.4l0,1.9
                                      c0,0,0,0.1,0,0.1l-1.5,5.2c-0.1,0.2,0,0.3,0.1,0.5c0.1,0.1,0.2,0.1,0.3,0.1l1.1,0c0.1,0,0.3-0.1,0.4-0.2c0,0,0.1-0.1,0.1-0.1
                                      l1.1-2l0.8,0c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3l0-0.6c0-0.1-0.1-0.3-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.1l0.2-0.3
                                      l0.8,0c0.1,0,0.3-0.1,0.3-0.1c0.1-0.1,0.1-0.2,0.1-0.3l0-0.6c0-0.1-0.1-0.3-0.1-0.4c-0.1-0.1-0.2-0.1-0.3-0.1l0,0l1.1-2
                                      c0.3,0,0.6,0,0.9,0C187.5,79.1,188.7,79.1,189.7,79L189.7,79z M189.7,79"
          ></path>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.st0 {
  opacity: 0.9677;
}
.st1 {
  opacity: 0.9355;
}
.st2 {
  opacity: 0.9032;
}
.st3 {
  opacity: 0.871;
}
.st4 {
  opacity: 0.8387;
}
.st5 {
  opacity: 0.8065;
}
.st6 {
  opacity: 0.7742;
}
.st7 {
  opacity: 0.7419;
}
.st8 {
  opacity: 0.7097;
}
.st9 {
  opacity: 0.6774;
}
.st10 {
  opacity: 0.6452;
}
.st11 {
  opacity: 0.6129;
}
.st12 {
  opacity: 0.5806;
}
.st13 {
  opacity: 0.5484;
}
.st14 {
  opacity: 0.5161;
}
.st15 {
  opacity: 0.4839;
}
.st16 {
  opacity: 0.4516;
}
.st17 {
  opacity: 0.4194;
}
.st18 {
  opacity: 0.3871;
}
.st19 {
  opacity: 0.3548;
}
.st20 {
  opacity: 0.3226;
}
.st21 {
  opacity: 0.2903;
}
.st22 {
  opacity: 0.2581;
}
.st23 {
  opacity: 0.2258;
}
.st24 {
  opacity: 0.1935;
}
.st25 {
  opacity: 0.1613;
}
.st26 {
  opacity: 0.129;
}
.st27 {
  opacity: 9.677419e-2;
}
.st28 {
  opacity: 6.451613e-2;
}
.st29 {
  opacity: 3.225806e-2;
}
.st30 {
  opacity: 0;
}
.st31 {
  clip-path: url(#SVGID_2_);
}
.st33 {
  clip-path: url(#SVGID_4_);
}
ellipse {
  fill: #0093d2;
}
.st31 {
  fill: #0093d2;
}
</style>
