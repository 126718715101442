var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card mb-10"},[_c('div',{staticClass:"card-header",attrs:{"id":"headingOne"}},[_c('div',{staticClass:"changes-travalers theme-account-history table-responsive"},[_c('table',{staticClass:"table table-sm"},[_vm._m(0),_c('tbody',[_c('tr',[_vm._m(1),_c('td',[_c('div',{domProps:{"textContent":_vm._s(_vm.item.room.hotel.name)}}),_c('div',[_c('small',{staticClass:"theme-account-history-type-icon",domProps:{"textContent":_vm._s(_vm.item.room.description)}})]),_c('div',[_c('small',{staticClass:"theme-account-history-type-icon",domProps:{"textContent":_vm._s(_vm.nights)}})]),_c('div',[_c('small',[_vm._v("Reserva Nr. "+_vm._s(_vm.item.external_id))])])]),_c('td',{domProps:{"textContent":_vm._s(_vm.travelers)}}),_c('td',[_c('div',[_c('p',[_c('i',{staticClass:"fa fa-lg",class:[
                      {'fa-clock-o text-warning': _vm.getStatus(_vm.item.status) === 'Processando'},
                      {'fa-clock-o text-warning': _vm.getStatus(_vm.item.status) === 'Reservado'},
                      {'fa-check-circle text-success': _vm.getStatus(_vm.item.status) === 'Emitido'},
                      {'fa-times-circle text-danger': _vm.getStatus(_vm.item.status) === 'Cancelado'},
                      {'fa-times-circle text-danger': _vm.getStatus(_vm.item.status) === 'Pagamento Recusado'},
                      {'fa-clock-o text-warning': _vm.getStatus(_vm.item.status) === 'Aguardando Aprovação'}
                    ]}),_vm._v(" "+_vm._s(_vm.getStatus(_vm.item.status))+"\n                ")])])]),_c('td',[_c('div',[_vm._v(_vm._s(_vm._f("brl_price")(_vm.item.total)))]),_c('div',[_c('small',{staticClass:"theme-account-history-type-icon"},[_vm._v("Valor: "+_vm._s(_vm._f("brl_price")(_vm.item.fare)))])]),_c('div',[_c('small',{staticClass:"theme-account-history-type-icon"},[_vm._v("Taxas: "+_vm._s(_vm._f("brl_price")(_vm.item.taxes)))])])]),_c('td',[_c('button',{staticClass:"btn btn-primary _tt-uc pull-right",attrs:{"data-toggle":"collapse","data-target":`#hotel-${_vm.item.id}`,"aria-expanded":"true","aria-controls":'hotel-'+_vm.item.id}},[_vm._v("+ Mais detalhes")])])])])])]),_c('div',{staticClass:"collapse",attrs:{"id":'hotel-'+_vm.item.id,"aria-labelledby":"headingOne","data-parent":"#accordion"}},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"changes-travalers theme-account-history table-responsive"},[_c('table',{staticClass:"table table-sm"},[_vm._m(2),_c('tbody',[_c('tr',[_c('td'),_c('td',{domProps:{"textContent":_vm._s(_vm.checkin)}}),_c('td',{domProps:{"textContent":_vm._s(_vm.checkout)}}),_c('td',[_vm._v("Estadia de "+_vm._s(_vm.nights))]),_c('td',[_c('div',{domProps:{"textContent":_vm._s(_vm.adults)}}),_c('div',{domProps:{"textContent":_vm._s(_vm.nights)}})]),_c('td',[(_vm.canCancel)?_c('button',{staticClass:"btn btn-danger _tt-uc pull-right",on:{"click":function($event){return _vm.cancelBooking(_vm.item)}}},[_vm._v("Cancelar item")]):_vm._e()])])])]),(_vm.item.room.hotel.cancel_policies)?_c('div',{staticClass:"col-md-12 text-center mt-10 mb-10"},[_c('a',{staticClass:"btn btn-primary _tt-uc",attrs:{"data-toggle":"modal","data-target":"#ModalCancelPolicies"}},[_vm._v("Clique aqui para visualizar a política de cancelamento")])]):_vm._e(),_vm._m(3)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Item")]),_c('th',[_vm._v("Viajante")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Subtotal")]),_c('th')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('td',[_c('i',{staticClass:"fa fa-building theme-account-history-type-icon"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th'),_c('th',[_vm._v("Check-in")]),_c('th',[_vm._v("Check-out")]),_c('th'),_c('th',[_vm._v("Detalhe do preço")]),_c('th')])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal fade",attrs:{"id":"ModalCancelPolicies","tabindex":"-1","role":"dialog","aria-labelledby":"ModalCancelPolicies","aria-hidden":"true"}},[_c('div',{staticClass:"modal-dialog",attrs:{"role":"document"}},[_c('div',{staticClass:"modal-content change-modal"},[_c('div',{staticClass:"modal-header change-modal-header"},[_c('button',{staticClass:"close",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])]),_c('h3',{staticClass:"modal-title text-center",attrs:{"id":"ModalCancelPolicies"}},[_vm._v("Política de cancelamento")])]),_c('div',{staticClass:"modal-body content-change-modal"},[_c('p')]),_c('div',{staticClass:"modal-footer change-modal-footer"},[_c('button',{staticClass:"btn _tt-uc btn-primary btn-lg btn-block",attrs:{"type":"button","data-dismiss":"modal"}},[_vm._v("Fechar")])])])])])
}]

export { render, staticRenderFns }