import { axiosUpdateHeaders } from '@/axios'
import { normalizeOrderHotels, normalizeOrderFlights, normalizeOrderCars } from '@/store/helpers/normalizeReserve'
import { state, mutations, SET_LOADING, RESET_LOADING, SET_ERROR, RESET_ERROR } from '@/store/modules/common'

const SET_RESERVE = 'SET_RESERVE';
const SET_INSTALLMENTS= 'SET_INSTALLMENTS'

const PRODUCT_HOTEL = 1;
const PRODUCT_FLIGHT = 2;
const PRODUCT_CAR = 3;
const TOTAL = 99;

export const modState = {
  ...state,
  hotels: [],
  flights: [],
  flightOrders: [],
  cars: [],
  total: 0
}

export const modMutations = {
  ...mutations,
  [SET_RESERVE] (state, data) {
    const reserve = (data.hasOwnProperty('summary')) ? data.summary : data;

    state.hotels = (reserve[PRODUCT_HOTEL].length) ? normalizeOrderHotels(reserve[PRODUCT_HOTEL]) : [];
    state.flights = (reserve[PRODUCT_FLIGHT].length) ? normalizeOrderFlights(reserve[PRODUCT_FLIGHT]) : [];
    state.flightOrders = reserve[PRODUCT_FLIGHT]; // TODO - UNIFY - used in checkout post finalize selected installments is set in each list item
    state.cars = (reserve[PRODUCT_CAR].length) ? normalizeOrderCars(reserve[PRODUCT_CAR]) : [];
    state.total = reserve[TOTAL] || 0;
  },
  [SET_INSTALLMENTS] (state, item) {
    const flightOrders = state.flightOrders.map((order) => {
      if (order.locator === item.locator) {
        return {
          ...order,
          selected_installment: item.selected_installment
        }
      }
      return order;
    });
    state.flightOrders = flightOrders;
  }
}

export const actions = {
  getReserves ({ commit }) {
    const axios = axiosUpdateHeaders();
    commit(RESET_ERROR);
    commit(SET_LOADING);

    return axios.get('/api/reserve/summary')
      .then(response => {
        if (response.status >= 200) {
          commit(SET_RESERVE, response.data);
          commit(RESET_LOADING);
          return Promise.resolve(response.data);
        }
      }, error => {
        commit(RESET_LOADING);
        commit(SET_ERROR, error);
      });
  },

  postReserveRemove ({ commit, dispatch }, id) {
    const axios = axiosUpdateHeaders();
    commit(RESET_ERROR);
    commit(SET_LOADING);

    return axios({
      url: '/api/reserve/remove',
      method: 'POST',
      data: {
        item_id: id
      }
    }).then(response => {
      if (response.status === 200) {
        dispatch('getReserves')
      }
      // commit(RESET_LOADING);
      return Promise.resolve()
    }, () => {
      commit(RESET_LOADING);
      commit(SET_ERROR, 'Erro ao remover, o item não existe.');
      return Promise.reject();
    });
  },

  postReserveFinalize ({ commit }, data) {
    const axios = axiosUpdateHeaders();
    commit(RESET_ERROR);
    commit(SET_LOADING);

    return axios({
      url: '/api/reserve/finalize',
      method: 'POST',
      data: data
    })
      .then((response) => Promise.resolve(response.data))
      .catch((error) => Promise.reject(error));
  },

  postReserve ({ commit, dispatch }, data) {
    const axios = axiosUpdateHeaders();
    commit(RESET_ERROR);
    commit(SET_LOADING);

    return axios({
      url: '/api/reserve/',
      method: 'POST',
      data
    })
      .then(response => {
        if (response.status === 200) {
          commit(RESET_LOADING);
          return Promise.resolve(response.data)
        }
      })
      .catch(error => dispatch('catchError', error))
  },

  catchError ({ commit }, error) {
    commit(RESET_LOADING);
    return Promise.reject(error);
  }
}

export const getters = {
  totalByOrder: (state) => (orders) => {
    if (state[orders].length)
      return state[orders].map(order => parseFloat(order.total)).reduce((memo, num) => (memo + num))
  }
}

export default {
  namespaced : true,
  state: { ...modState },
  mutations: { ...modMutations },
  actions,
  getters
}
