// import flightPricing from '@/__helpers__/flight-pricing'
// import flightPricing from '@/__helpers__/flight-pricing-out-in'
import { axiosUpdateHeaders } from '@/axios'
import { state, mutations, SET_LOADING, RESET_LOADING } from '@/store/modules/common'

export const actions = {
  getFlightPricing ({ commit }, hash) {
    const axios = axiosUpdateHeaders();
    commit(SET_LOADING);

    return axios.get(`/api/flight/pricing`, { params: { hash } })
      .then(response => {
        commit(RESET_LOADING)

        if ([200, 201].includes(response.status)) {
          if (response.data.results.error) {
            return Promise.reject(response.data.results.error)
          }
          return Promise.resolve(response.data)
        }
      })
      .catch(error => {
        commit(RESET_LOADING)
        return Promise.reject(error)
      })

    /* TEST */
    // return new Promise((resolve, reject) => {
    //   commit(SET_LOADING);
    //   window.setTimeout(() => {
    //     commit(RESET_LOADING);
    //     resolve(flightPricing);
    //     // reject()
    //   }, 3000);
    // });
  },

  postFlightBooking ({ commit }, data) {
    const axios = axiosUpdateHeaders();
    commit(SET_LOADING);

    return axios.post(`/api/flight/booking/`, data)
      .then(response => {
        commit(RESET_LOADING)

        if ([200, 201].includes(response.status)) {
          if (response.data.results.error) {
            return Promise.reject(response.data.results.error)
          }
          return Promise.resolve(response.data.results.booking)
        }
      })
      .catch(error => {
        commit(RESET_LOADING)
        return Promise.reject(error)
      })
  }
}

export default {
  namespaced : true,
  state: { ...state },
  mutations: { ...mutations },
  actions
  /* getters */
}
