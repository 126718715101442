import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Login from './views/Login.vue';
import Logout from './components/users/Logout.vue';
import Signup from './views/Signup.vue';
import PasswordReset from './views/PasswordReset.vue';
import PasswordChange from "@/views/PasswordChange.vue";
import ForgotPassword from './views/ForgotPassword.vue';
import Hotels from './views/Hotels.vue';
import Flights from './views/Flights.vue';
import Cars from './views/Cars.vue';
import HotelsList from './views/hotel/List.vue';
import HotelDetails from '@/views/hotel/details/index.vue';
import Reserve from './views/Reserve.vue';
import MyAccount from './views/MyAccount.vue';
import Company from './views/Company.vue';
import CardsList from './views/CardsList.vue';
import Cards from './views/Cards.vue';
import TravelersList from './views/TravelersList.vue';
import Travelers from './views/Travelers.vue';
import Orders from './views/Orders.vue';
import Travel from '@/views/travel/index';
import Checkout from '@/views/checkout/index';
import OrderDetails from './views/OrderDetails.vue';
import PaymentSuccess from './views/PaymentSuccess.vue';
import FlightsSearch from '@/views/flights/search';
import FlightDetails from '@/views/flight/details/index.vue';
import NotFound from './views/NotFound.vue';
import UnexpectedError from './views/UnexpectedError.vue';
import CarsList from './views/cars/List.vue';
import CarDetails from '@/views/car/details/index.vue';
import Reports from './views/Reports.vue';
// import Tests from './views/Tests.vue';
import PoliciesPrivacy from './views/policies/PoliciesPrivacy.vue';
import TermsConditions from './views/policies/TermsConditions.vue';
import Approvals from './views/approvals/index.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to) {
    return {
      x: 0,
      y: 0
    }
  },
  routes: [{
      path: '*',
      name: 'notFound',
      component: NotFound,
      meta: {
        title: 'Página não encontrada'
      }
    },{
      path: '/',
      name: 'home',
      component: Home,
      meta: {
        requiresAuth: true,
        title: 'Home | etrip - Agência online de viagens a negócios',
        selectedTab: 'hotel'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        hideHeader: true,
        title: 'Login | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/signup',
      name: 'signup',
      component: Signup,
      meta: {
        hideHeader: true,
        title: 'Cadastro | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/forgot_password',
      name: 'forgot_password',
      component: ForgotPassword,
      meta: {
        hideHeader: true,
        title: 'Esqueci minha senha | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/password_reset',
      name: 'password_reset',
      component: PasswordReset,
      meta: {
        hideHeader: true,
        title: 'Alterar senha | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/password_change',
      name: 'password_change',
      component: PasswordChange,
      meta: {
        hideHeader: true,
        title: `Alterar senha | etrip - Agência online de viagens a negócios`
      }
    },
    {
      path: '/hotels',
      name: 'hotels',
      component: Hotels,
      meta: {
        requiresAuth: true,
        title: 'Hotéis | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/hotels/search/:dt_start/:dt_end/:city/:room/:adults/:destination?',
      name: 'hotelslist',
      component: HotelsList,
      meta: {
        requiresAuth: true,
        title: 'Hotéis | etrip - Agência online de viagens a negócios',
        selectedTab: 'hotel'
      }
    },
    {
      path: '/hotels/details/:hash/',
      // path: '/hotel/details/:hash/',
      name: 'hoteldetails',
      component: HotelDetails,
      meta: {
        requiresAuth: true,
        title: 'Detalhes do hotel | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/flights',
      name: 'flights',
      component: Flights,
      meta: {
        requiresAuth: true,
        title: 'Passágens | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/cars',
      name: 'cars',
      component: Cars,
      meta: {
        requiresAuth: true,
        title: 'Carros | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/myaccount',
      name: 'myaccount',
      component: MyAccount,
      meta: {
        requiresAuth: true,
        title: 'Minha conta | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/company',
      name: 'company',
      component: Company,
      meta: {
        requiresAuth: true,
        title: 'Empresa | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/cards',
      name: 'cards',
      component: CardsList,
      meta: {
        requiresAuth: true,
        title: 'Cartões | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/cards/add',
      name: 'add_cards',
      component: Cards,
      meta: {
        requiresAuth: true,
        title: 'Adicionar cartão | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/travelers',
      name: 'travelers',
      component: TravelersList,
      meta: {
        requiresAuth: true,
        title: 'Viajantes | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/add-traveler',
      name: 'add-traveler',
      component: Travelers,
      meta: {
        requiresAuth: true,
        title: 'Adicionar viajante | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/travelers/:id',
      name: 'edit-traveler',
      component: Travelers,
      meta: {
        requiresAuth: true,
        title: 'Viajantes | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/user/orders',
      name: 'orders',
      component: Orders,
      meta: {
        requiresAuth: true,
        title: 'Pedidos | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/user/orders/:id',
      name: 'order_details',
      component: OrderDetails,
      meta: {
        requiresAuth: true,
        title: 'Pedidos | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/reserve',
      name: 'reserve',
      component: Reserve,
      meta: {
        requiresAuth: true,
        title: 'Pedidos | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/travel',
      name: 'travel',
      component: Travel,
      meta: {
        requiresAuth: true,
        title: 'Viagem | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/checkout',
      name: 'checkout',
      component: Checkout,
      meta: {
        requiresAuth: true,
        title: 'Checkout | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/paymentsuccess',
      name: 'paymentsuccess',
      component: PaymentSuccess,
      meta: {
        requiresAuth: true,
        title: 'Pagamento efetuado com sucesso | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/flights/search/:origin/:destination/:adults/:outbound/:inbound?',
      name: 'flightssearch',
      component: FlightsSearch,
      meta: {
        requiresAuth: true,
        title: 'Passagens | etrip - Agência online de viagens a negócios',
        selectedTab: 'flight'
      }
    },
    {
      path: '/flights/details/:hash/:slug',
      // path: '/flight/details/:hash/:slug',
      name: 'flightdetails',
      component: FlightDetails,
      meta: {
        requiresAuth: true,
        title: 'Passagens | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/notfound',
      name: 'norfound',
      component: NotFound,
      meta: {
        hideHeader: true,
        title: 'Página não encontrada | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/unexpectederror',
      name: 'unexpectederror',
      component: UnexpectedError,
      meta: {
        hideHeader: true,
        title: 'Erro | etrip - Agência online de viagens a negócios'
      }
    },
    {
      path: '/cars/search/:pickup_location/:delivery_location/:datetime_start/:datetime_end?',
      name: 'carslist',
      component: CarsList,
      meta: {
        requiresAuth: true,
        title: 'Carros | etrip - Agência online de viagens a negócios',
        selectedTab: 'car'
      }
    },
    {
      path: '/cars/details/:hash/:vehicle_id',
      // path: '/car/details/:hash/:vehicle_id',
      name: 'cardetails',
      component: CarDetails,
      meta: {
        requiresAuth: true,
        title: 'Carros | etrip - Agência online de viagens a negócios',
      }
    },
    {
      path: '/reports',
      name: 'reports',
      component: Reports,
      meta: {
        requiresAuth: true,
        title: 'Relatórios | etrip - Agência online de viagens a negócios',
      }
    },
    {
      path: '/approvals',
      name: 'approvals',
      component: Approvals,
      meta: {
        requiresAuth: true,
        title: 'Aprovações | etrip - Agência online de viagens a negócios',
      }
    },
    {
      path: '/PoliciesPrivacy',
      name: 'policiesprivacy',
      component: PoliciesPrivacy,
      meta: {
        requiresAuth: false,
        title: 'Política de Privacidade | etrip - Agência online de viagens a negócios',
      }
    },
    {
      path: '/TermsConditions',
      name: 'termsconditions',
      component: TermsConditions,
      meta: {
        requiresAuth: false,
        title: 'Termos e condições | etrip - Agência online de viagens a negócios',
      }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
})
