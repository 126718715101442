<template>
    <div
      v-show="active"
      :aria-hidden="!active"
      role="tabpanel">
      <slot :active="active"></slot>
    </div>
</template>

<script>
export default {
  props: [
    'active'
  ]
};
</script>
