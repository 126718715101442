<template>
  <div class="mt-10 mb-10 clearfix">
    <div class="col-sm-6">
      <div class="box-list-item border-bottom size-18">
        Total<sup>[1]</sup> <span class="pull-right weight-mid">{{ total | brl_price }}</span>
      </div>
      <article
        v-for="(item, index) in items"
        :key="`checkout-hotel-item-${index}`"
        :class="{ 'border-bottom': index !== (items.length-1) }"
        class="box-list-item clearfix">
        <div class="col-xs-6 col-sm-9 padding-off">
          <h2 class="size-16 weight-bold margin-off">
            {{ item.room.hotel.name }}
            <span class="size-12 weight-normal color-gray-light">
              {{ item.room.nights }} {{ 'noite' | pluralize(item.room.nights) }},
              {{ item.room.adults }} {{ 'adulto' | pluralize(item.room.adults) }}
            </span><br>
          </h2>
          <!-- Hotel Detail -->
          <hotel-detail :item="item"></hotel-detail>
          <!-- // Hotel Detail -->
        </div>
        <div class="col-xs-6 col-sm-3 padding-off">
          <p class="text-right mt-10">
            {{ item.total | brl_price }}
          </p>
        </div>
      </article>
    </div>
    <div class="col-sm-6">
      <div class="col-sm-10">
        <div class="form-group theme-payment-page-form-item">
          <i class="fa fa-angle-down"></i>
          <select
            v-model="creditcard_hotel"
            @change="$emit('input', $event)"
            name="creditcard_hotel"
            class="form-control"
            :class="{ 'alert-danger': !validation }">
            <option value="">Selecione o cartão de crédito</option>
            <option
              v-for="card in cards"
              :key="card.id"
              :value="card.id">
              {{ card.name }} **** **** **** {{ card.partial_number }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-sm-12 mt-10">
        <a
          @click.prevent="$emit('handleParentAction', 'card-register')"
          href="#"
          class="btn-link size-14 weight-normal mt-20">+Adicionar Novo Cartão</a>
        <p class="size-11 weight-lighter color-gray-light-mid"><sup>[1]</sup> O pagamento será realizado no cartão de crédito cadastrado na plataforma e escolhido no momento da reserva, mas o débito só será realizado no momento o check-out do hóspede.</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HotelDetail from '@/components/hotel/HotelDetail';

export default {
  components: {
    HotelDetail
  },
  props: [
    'order',
    'items',
    'validation',
    'creditcards'
  ],
  data () {
    return {
      creditcard_hotel: ''
    }
  },
  computed: {
    ...mapGetters('reserve', ['totalByOrder']),
    total () {
      return this.totalByOrder('hotels')
    },
    cards () {
      return this.creditcards.filter((card) => !card.corporate_virtual_flight);
    }
  }
};
</script>
